import { Box, SxProps, Theme } from '@mui/material';
import { useMonaco } from '../../spec/use-monaco';
import { useElementSize } from '@mantine/hooks';
import { default as Editor } from '@monaco-editor/react';
import { useMemo } from 'react';
import { editor } from 'monaco-editor';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useIntl } from 'react-intl';
import { Status } from '@xspecs/single-source-model';
import { cn } from '@xspecs/design-system';
import { Loading } from '../../loading/loading';

type SchemaEditorProps = {
  id: string;
  onEditorChange?: (value: string) => void;
};

export const TxtEditor = (props: SchemaEditorProps) => {
  const { id, onEditorChange } = props;
  const { organization } = useActiveOrganization();
  const { formatMessage: f } = useIntl();

  const fileId = `${organization?.id}/${id}`;

  const { onMount, status } = useMonaco({ id: id, placeholder: f({ id: 'start-typing-here' }), initialValue: '' });

  const { ref, height, width } = useElementSize<HTMLDivElement>();

  const options = useMemo<editor.IStandaloneEditorConstructionOptions>(
    () => ({
      language: 'plaintext',
      theme: 'default',
      wordWrap: 'on',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: true,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
      quickSuggestions: false,
    }),
    [],
  );

  const isLoading = !status || [Status.Unknown, Status.Connecting, Status.Initial].includes(status);
  const isDisconnected = status === Status.Disconnected;

  const disabledClass = 'opacity-0 pointer-events-none';
  const enabledClass = 'opacity-100 pointer-events-auto';

  return (
    <Box sx={rootSx} ref={ref} className="w-full h-full relative">
      <Editor
        className={cn('w-full h-full absolute', isLoading || isDisconnected ? disabledClass : enabledClass)}
        language="plaintext"
        width={width}
        height={height}
        options={options}
        onMount={onMount}
        onChange={onEditorChange}
      />
      {isLoading ? (
        <div
          className={cn(
            'w-full h-full flex items-center absolute z-10 top-0',
            isLoading ? enabledClass : disabledClass,
          )}
        >
          <Loading />
        </div>
      ) : null}
      {isDisconnected ? (
        <div
          className={cn(
            'w-full h-full flex items-center justify-center absolute z-10 top-0',
            isDisconnected ? enabledClass : disabledClass,
          )}
        >
          <div className="text-center">
            <p>{f({ id: 'disconnected-from-server' })}</p>
            <p>{f({ id: 'attempting-to-reconnect' })}</p>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  height: '100%',
};
