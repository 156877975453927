import { useMemo, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu';
import { Power, Settings } from 'lucide-react';
import { cn } from '../../utils';
import { LabelElement, LinksElement, SeparatorElement } from '@xspecs/design-system';
import { useIntl } from 'react-intl';

export type MenuItemSettingsActionsProps = {
  onOrganizationSettings: () => void;
  onSwitchOrganization: () => void;
  openOrganizationSettings: () => void;
  onOrganizationApps: () => void;
  openInviteUsers: () => void;
  openMouseOrTrackSelectionModal: () => void;
  onManageLabels: () => void;
};

export type MenuItemSettingsProps = {
  logout: () => void;
  hasApps?: boolean;
  spaceName?: string;
  organizationName: string;
  hideLabels?: boolean;
} & MenuItemSettingsActionsProps;

export const MenuItemSettings = (props: MenuItemSettingsProps) => {
  const {
    logout,
    hasApps = false,
    spaceName,
    organizationName,
    hideLabels = false,
    onSwitchOrganization,
    onOrganizationApps,
    onManageLabels,
    onOrganizationSettings,
    openOrganizationSettings,
    openMouseOrTrackSelectionModal,
    openInviteUsers,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: f } = useIntl();

  const settingsMenuItems: (LabelElement | SeparatorElement | LinksElement)[] = useMemo(
    () =>
      [
        {
          type: 'label',
          text: f({ id: 'organization' }),
        } satisfies LabelElement,
        {
          type: 'links',
          links: [
            spaceName
              ? {
                  label: f({ id: 'plans-and-billing' }, { name: spaceName }),
                  onClick: onOrganizationSettings,
                }
              : undefined!,
            {
              label: f({ id: 'entity-settings' }, { name: organizationName }),
              onClick: openOrganizationSettings,
            },
            {
              label: f({ id: 'switch-organization' }),
              onClick: onSwitchOrganization,
            },
          ].filter(Boolean),
        } satisfies LinksElement,
        {
          type: 'separator',
        } satisfies SeparatorElement,
        {
          type: 'label',
          text: f({ id: 'others' }),
        } satisfies LabelElement,
        {
          type: 'links',
          links: [
            {
              label: f({ id: 'invite-users' }),
              onClick: openInviteUsers,
            },
            {
              label: f({ id: 'mouse-or-trackpad' }),
              onClick: openMouseOrTrackSelectionModal,
            },
            !hideLabels
              ? {
                  label: f({ id: 'manage-labels' }),
                  onClick: onManageLabels,
                }
              : undefined!,
          ].filter(Boolean),
        } satisfies LinksElement,
      ].filter(Boolean),
    [
      f,
      spaceName,
      onOrganizationSettings,
      organizationName,
      openOrganizationSettings,
      onSwitchOrganization,
      openInviteUsers,
      openMouseOrTrackSelectionModal,
      hideLabels,
      onManageLabels,
    ],
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger asChild>
        <div
          className={cn(
            'flex justify-center items-center hover:bg-accent rounded-lg p-1.5',
            isOpen && 'bg-base-primary/10',
          )}
        >
          <Settings size="16" className={cn('text-muted-foreground', isOpen && 'text-base-primary')} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        side="bottom"
        align="end"
        sideOffset={4}
      >
        {settingsMenuItems.map((item, index) => {
          if (item.type === 'label') {
            return (
              <DropdownMenuLabel key={item.text} className="p-0 text-sm text-muted-foreground leading-3 px-2 py-1.5">
                {item.text}
              </DropdownMenuLabel>
            );
          }
          if (item.type === 'separator') {
            return <DropdownMenuSeparator key={`settings-menu-seperator-${index}`} />;
          }
          if (item.type === 'links') {
            return (
              <DropdownMenuGroup key={`settings-menu-links-${index}`}>
                {item.links.map((link) => (
                  <DropdownMenuItem key={link.label} className="text-primary" onClick={link.onClick}>
                    {link.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            );
          }
        })}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>
          <Power />
          {f({ id: 'logout' })}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
