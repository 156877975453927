import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { FileType } from '../../data/File.types';
import { ModelYjsAdaptor } from '../../data/ModelYjsAdaptor';
import { FileBase } from '../../data/FileBase';
import { CommandError } from '../../ErrorStore';
import { SpaceContext } from '../../SpaceContext';

interface DisconnectFileCommandParams extends IParams {
  fileId: string;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

interface FileDisconnectedEventParams extends IParams {
  file: FileBase;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

export class FileDisconnectedEvent extends EventBase {
  static eventType = 'FileDisconnectedEvent';

  constructor(
    public readonly params: FileDisconnectedEventParams,
    public readonly source = DisconnectFileCommand,
  ) {
    super();
  }
}

export class DisconnectFileCommand extends CommandBase<DisconnectFileCommandParams> {
  execute(params: DisconnectFileCommandParams): FileDisconnectedEvent | CommandError | undefined {
    const [file] = Object.values(this.appState.fileStoreClient.files).filter((file) => file.fileId != params.fileId);

    if (!file) return;

    this.appState.fileStoreClient.removeFile(params.fileId);
    if (params.fileType === FileType.Space) {
      SpaceContext.getInstance().setActiveSpace(undefined);
    }
    const modelContext = this.getModelContext();
    if (modelContext.modelFile?.fileId === params.fileId) {
      modelContext.dispose();
      ModelYjsAdaptor.dispose();
    }

    return new FileDisconnectedEvent({
      file: file,
      fileType: params.fileType,
      fileExtension: params?.fileExtension,
      version: params.version,
    });
  }
}
