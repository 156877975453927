import { getNodeParent, getPluginType, toggleNodeType, useEditorState } from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { useCallback, useMemo } from 'react';
import { getListItemEntry } from '@udecode/plate-list';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@xspecs/design-system';

type BalloonMenuTextTypeSelectionProps = {
  closeMenu: () => void;
};

export const BalloonMenuTextTypeSelection = (props: BalloonMenuTextTypeSelectionProps) => {
  const { closeMenu } = props;

  const editor = useEditorState();

  const onChange = useCallback(
    (value: string) => {
      toggleNodeType(editor, { activeType: value });
      closeMenu();
    },
    [closeMenu, editor],
  );

  const options = useMemo(
    () => [
      { label: 'Heading 1', value: getPluginType(editor, ELEMENT_H1) },
      { label: 'Heading 2', value: getPluginType(editor, ELEMENT_H2) },
      { label: 'Heading 3', value: getPluginType(editor, ELEMENT_H3) },
      { label: 'Text', value: getPluginType(editor, ELEMENT_PARAGRAPH) },
    ],
    [editor],
  );

  const value = useMemo(() => {
    if (!editor?.selection?.anchor) return getPluginType(editor, ELEMENT_PARAGRAPH);
    const node = getNodeParent(editor, editor.selection.anchor.path);
    const option = options.find((option) => option.value === getPluginType(editor, node.type as string));
    if (option) return option.value;
    return getPluginType(editor, ELEMENT_PARAGRAPH);
  }, [editor, options]);

  const isList = Boolean(!!editor?.selection && getListItemEntry(editor));

  return (
    <Select data-testid="balloon-menu-text-type-selection" value={value} disabled={isList} onValueChange={onChange}>
      <SelectTrigger className="border-none">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem
            data-testid={`balloon-menu-text-type-menu-item-${option.value}`}
            key={option.value}
            value={getPluginType(editor, option.value)}
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
