import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { EntityChanges } from '../../types';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { ModelContext } from '../../ModelContext';

interface ToggleDevModeCommandParams extends IParams {
  toggle: boolean;
}

export class DevModeToggleEvent extends EventBase {
  static eventType = 'ToggleDevModeEvent';

  constructor(
    public readonly params: ToggleDevModeCommandParams,
    public readonly source = ToggleDevModeCommand,
  ) {
    super();
  }
}

export class ToggleDevModeCommand extends CommandBase<ToggleDevModeCommandParams> {
  execute(params: ToggleDevModeCommandParams): DevModeToggleEvent | CommandError | undefined {
    const { toggle } = params;
    this.appState.store.getState().setDevMode(toggle);
    if (!ModelContext.hasActiveModel()) return new DevModeToggleEvent(params);
    const scripts = this.getModelContext()
      .entityRepository.list()
      .filter((e) => e instanceof ScriptBase)
      .map((e) => ({ entity: e, modifiedProperties: [] }));
    const changeSets: EntityChanges = { added: [], updated: scripts, deleted: [] };
    this.appState.observer.notify(NotificationTypes.OnTransientChange, changeSets);
    return new DevModeToggleEvent(params);
  }
}
