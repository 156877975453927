import { ApplicationContext } from '../ApplicationContext';
import { AddIdePanelCommandCommand } from '../commands/ide/AddIdePanelCommand';
import { RemoveIdePanelCommandCommand } from '../commands/ide/RemoveIdePanelCommand';
import { FileTreeItem } from '../read-models/file-tree/FileTree.types';
import { SelectFileTreeItemCommand } from '../commands/fileTree/SelectFileTreeItemCommand';
import { SelectIdePanelCommand } from '../commands/ide/SelectIdePanelCommand';
import { ToggleDevModeCommand } from '../commands/ide/ToggleDevModeCommand';

export class IdeInteractor {
  constructor(private readonly model: ApplicationContext) {}

  addPanel(file: FileTreeItem) {
    this.model.messageBus.sendInternal(AddIdePanelCommandCommand, { file });
    this.model.messageBus.sendInternal(SelectFileTreeItemCommand, { id: file.id });
    this.model.messageBus.sendInternal(SelectIdePanelCommand, { id: file.id });
  }

  selectFile(id: string | number) {
    this.model.messageBus.sendInternal(SelectFileTreeItemCommand, { id });
  }

  deletePanel(id: string) {
    this.model.messageBus.sendInternal(RemoveIdePanelCommandCommand, { id });
  }

  toggleDevMode(toggle: boolean): void {
    this.model.messageBus.sendInternal(ToggleDevModeCommand, { toggle });
  }
}
