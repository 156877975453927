import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { EventHandlerBase } from '../framework/EventHandlerBase';
import { EntitiesMovedEvent } from './MoveEntitiesCommand';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EntityBase } from '../../entities/EntityBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { EntryData } from '../../read-models/boundary/BoundariesIndex';
import { EntitiesAddedEvent } from './AddEntityCommand';
import { Attachment } from '../../entities/assets/Attachment';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';
import { Position } from '../../types';
import { Upload } from '../../entities/assets/Upload';
import { AttachmentType } from '../../entities/EntityType';
import { Thread } from '../../entities/threads/Thread';
import { AssetBase } from '../../entities/assets/AssetBase';
import { IModelContext } from '../../IModelContext';

interface EntitiesIngestedEventParams extends IParams {
  ingested: { entityId: string; targetId: string; frameId?: number; laneId?: number }[];
  ejected: { entityId: string }[];
  affected: { entityId: string }[];
}

export class EntitiesIngestedEvent extends EventBase {
  static eventType = 'EntitiesIngestedEvent';

  constructor(
    public readonly params: EntitiesIngestedEventParams,
    public readonly source = IngestEntitiesCommand,
  ) {
    super();
  }
}

interface IngestEntitiesCommandParams extends IParams {
  entityIds: string[];
  ignoreIds?: string[];
  cursorPosition: Position;
}

export class IngestEntitiesCommand extends CommandBase<IngestEntitiesCommandParams> {
  execute(params: IngestEntitiesCommandParams): EntitiesIngestedEvent | CommandError | undefined {
    const { cursorPosition } = params;

    const useCursorPosition = params.entityIds.length <= 1;

    const ingested: EntitiesIngestedEventParams['ingested'] = [];
    const ejected: EntitiesIngestedEventParams['ejected'] = [];
    const affected: EntitiesIngestedEventParams['affected'] = [];

    const errors = params.entityIds
      .map((entityId) => {
        if (params.ignoreIds && params.ignoreIds.includes(entityId)) return;
        const modelContext = this.getModelContext();
        const entity = modelContext.entityRepository.get(entityId);

        if (!entity) return new Error(`Entity not found ${entityId}`);

        const intersectingEntities = useCursorPosition
          ? modelContext.boundariesIndex.getIntersectingEntitiesData(cursorPosition)
          : modelContext.boundariesIndex.getIntersectingEntitiesData(entity);

        // TODO This seems to work a lot better, we should use this but the tests need fixing to use cursorPosition
        // const intersectingEntities = this.model.boundariesIndex.getIntersectingEntitiesData(params.cursorPosition);

        const precedentTarget = determinePrecedentTarget(modelContext, entity, intersectingEntities);

        if (entity instanceof ScriptBase) return;

        if (
          (precedentTarget?.entity instanceof ConstructBase ||
            precedentTarget?.entity instanceof ScriptBase ||
            precedentTarget?.entity instanceof Attachment) &&
          entity instanceof Thread
        ) {
          const response = precedentTarget.entity.ingestEntity(entity, 0, 0);
          if (response) {
            modelContext.entityRepository.update(precedentTarget.entity);
            modelContext.entityRepository.update(entity);
          }
        }

        if (
          (!precedentTarget && entity.parent instanceof ScriptBase) ||
          (!precedentTarget && entity.parent instanceof ConstructBase) ||
          (!precedentTarget && entity.parent instanceof Attachment)
        ) {
          // is dropped onto canvas
          const parent = entity.parent;
          const response = parent.eject(entity);
          modelContext.entityRepository.update(parent);
          modelContext.entityRepository.update(entity);
          if (!response) return parent.getError();
          ejected.push({ entityId });
          affected.push({ entityId: parent.id });
        }

        if (
          precedentTarget &&
          (precedentTarget.entity instanceof ScriptBase || precedentTarget.entity instanceof ConstructBase)
        ) {
          if (entity.parent instanceof ScriptBase || entity.parent instanceof ConstructBase) {
            const parent = entity.parent;
            parent.eject(entity);
            affected.push({ entityId: parent.id });
            modelContext.entityRepository.update(parent);
            modelContext.entityRepository.update(entity);
          }

          // if this is a preview with its attachment already attached to construct, we need to eject it first
          if (entity instanceof Preview && entity?.parent?.parent instanceof ConstructBase) {
            const construct = entity.parent.parent;
            construct.eject(entity.parent);
            modelContext.entityRepository.update(construct);
            modelContext.entityRepository.update(entity.parent);
          }
          const response = precedentTarget.entity.ingestEntity(
            entity,
            precedentTarget.entry.frameId!,
            precedentTarget.entry.laneId!,
          );
          modelContext.entityRepository.update(entity);
          modelContext.entityRepository.update(precedentTarget.entity);
          if (!response) return precedentTarget.entity.getError();
          ingested.push({
            entityId,
            targetId: precedentTarget.entity.id,
            frameId: precedentTarget.entry.frameId,
            laneId: precedentTarget.entry.laneId,
          });
        }
      })
      .filter(Boolean);

    if (errors.length) return CommandError.of(new Error(errors.join('\n')), 'warning');

    return new EntitiesIngestedEvent({ ingested, ejected, affected });
  }
}

export class IngestCommandPolicy extends EventHandlerBase {
  handles() {
    return [EntitiesAddedEvent, EntitiesMovedEvent];
  }

  execute(event: EventBase) {
    const modelContext = this.getModelContext();
    switch (event.type) {
      case EntitiesMovedEvent.type:
        const entitiesMovedEvent = event as EntitiesMovedEvent;
        return this.appState.messageBus.sendInternal(IngestEntitiesCommand, {
          entityIds: entitiesMovedEvent.params.entityIds,
          ignoreIds: entitiesMovedEvent.params.skipIngestIds,
          cursorPosition: entitiesMovedEvent.params.cursorPosition,
        });
      case EntitiesAddedEvent.type:
        const entitiesAddedEvent = event as EntitiesAddedEvent;

        const entityIds = entitiesAddedEvent.params.entityIds
          .map((entityId) => {
            const entity = modelContext.entityRepository.get(entityId);
            if (entity instanceof Preview) return null;
            if (entity instanceof Upload) return null;
            if (entity instanceof Attachment) {
              if (entity.subType === AttachmentType.Upload) return null;
            }
            return entityId;
          })
          .filter(Boolean) as string[];

        return this.appState.messageBus.sendInternal(IngestEntitiesCommand, {
          entityIds: entityIds,
          cursorPosition: entitiesAddedEvent.params.cursorPosition,
        });
    }
  }
}

const isScript = (model: IModelContext, e: EntryData) => {
  return e.scriptId && model.entityRepository.get(e.scriptId) instanceof ScriptBase;
};

const isConstruct = (e: EntryData) => {
  return e.entity instanceof ConstructBase;
};

export const determinePrecedentTarget = (
  model: IModelContext,
  entity: EntityBase,
  intersectingEntities: EntryData[],
):
  | {
      entity: EntityBase;
      entry: EntryData;
    }
  | undefined => {
  if (entity instanceof Thread && intersectingEntities.length > 0) {
    const firstEntry = intersectingEntities.sort((a, b) => (b.entity?.zIndex ?? 0) - (a.entity?.zIndex ?? 0))[0];
    const entityId = firstEntry.scriptId ?? firstEntry.entityId;
    return {
      entity: model.entityRepository.get(entityId)!,
      entry: firstEntry,
    };
  }
  if (entity instanceof ConstructBase)
    return intersectingEntities
      .filter((e) => isScript(model, e))
      .map((e) => ({ entity: model.entityRepository.get(e.scriptId!)!, entry: e }))[0]!;

  if (entity instanceof Attachment || entity instanceof AssetBase)
    return intersectingEntities
      .filter((e) => isConstruct(e))
      .map((e) => ({ entity: model.entityRepository.get(e.entityId)!, entry: e }))[0]!;

  if (entity instanceof Preview) {
    return intersectingEntities
      .filter((e) => isConstruct(e))
      .map((e) => ({ entity: model.entityRepository.get(e.entityId)!, entry: e }))[0]!;
  }
};
