import { InputAdornment, TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { ToolbarElementProps, SearchToolbarElement } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';

export const Textbox: FC<ToolbarElementProps<SearchToolbarElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(element.onChange.command, {
        ...element.onChange.params,
        value: e.target.value,
      });
    },
    [dispatch, element.onChange.command, element.onChange.params],
  );

  return (
    <TextField
      size="small"
      placeholder={element.placeholder}
      variant="outlined"
      onChange={onChange}
      value={element.value}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Icon name={element.icon} width={24} height={24} color={element.iconColor} />
            </InputAdornment>
          ),
          className: 'border border-border',
        },
      }}
      sx={{
        '& .MuiInputBase-input::placeholder': {
          color: 'hsl(var(--text-primary))',
          opacity: 0.8,
        },
      }}
    />
  );
};
