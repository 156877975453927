import { scan } from 'react-scan';
import './wdyr';

import { createRoot } from 'react-dom/client';
import { App } from './App';
// import './sentry';
import './dayjs';
import { Application, DEBUG_CONFIG } from '@xspecs/single-source-model';
import { ApplicationProvider } from './wrappers/application-context/application-context';
import { zustandStore } from './zustand-store';

if (DEBUG_CONFIG.reactScan)
  scan({
    enabled: true,
    log: true,
  });

const application = Application.getInstance(zustandStore, undefined, undefined, window);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

const root = createRoot(rootElement);
root.render(
  <ApplicationProvider application={application}>
    <App />
  </ApplicationProvider>,
);
