import { Box, SxProps, Theme } from '@mui/material';
import { useElementSize } from '@mantine/hooks';
import { default as Editor } from '@monaco-editor/react';
import { useEffect, useMemo, useState } from 'react';

type FileUrlEditorProps = {
  url: string;
  fileType: string | undefined;
  onEditorChange?: (value: string) => void;
};

const fileTypeToLanguage = {
  json: 'json',
  graphql: 'graphql',
  ts: 'typescript',
  js: 'javascript',
};

export const FileUrlEditor = (props: FileUrlEditorProps) => {
  const { url, fileType = '' } = props;
  const [value, setValue] = useState('');

  const { ref, height, width } = useElementSize<HTMLDivElement>();

  const language = useMemo(() => fileTypeToLanguage[fileType] || 'plaintext', [fileType]);

  // const options = useMemo<editor.IStandaloneEditorConstructionOptions>(
  //   () => ({
  //     language: language,
  //     theme: 'vs-dark',
  //     wordWrap: 'on',
  //     'semanticHighlighting.enabled': true,
  //     lineNumbers: 'on',
  //     minimap: { enabled: true },
  //     scrollBeyondLastLine: false,
  //     scrollBeyondLastColumn: 0,
  //     fontFamily: "'JetBrains Mono'",
  //     scrollbar: {
  //       horizontal: 'hidden',
  //       vertical: 'hidden',
  //     },
  //     renderLineHighlight: 'none',
  //     overviewRulerBorder: false,
  //     glyphMargin: false,
  //     defaultColorDecorators: false,
  //     overviewRulerLanes: 0,
  //     folding: true,
  //     guides: {
  //       indentation: false,
  //     },
  //     renderWhitespace: 'none',
  //     quickSuggestions: false,
  //     readOnly: true,
  //   }),
  //   [language],
  // );

  useEffect(() => {
    (async () => {
      const response = await fetch(url);
      const text = await response.text();
      setValue(text);
    })();
  }, [url]);

  return (
    <Box sx={rootSx} ref={ref}>
      <Editor
        language={language}
        width={width}
        height={height}
        options={{
          language: language,
          theme: 'vs-dark',
          wordWrap: 'on',
          'semanticHighlighting.enabled': true,
          lineNumbers: 'on',
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          scrollBeyondLastColumn: 0,
          fontFamily: "'JetBrains Mono'",
          scrollbar: {
            horizontal: 'hidden',
            vertical: 'hidden',
          },
          renderLineHighlight: 'none',
          overviewRulerBorder: false,
          glyphMargin: false,
          defaultColorDecorators: false,
          overviewRulerLanes: 0,
          folding: true,
          guides: {
            indentation: false,
          },
          renderWhitespace: 'none',
          quickSuggestions: false,
          readOnly: true,
        }}
        value={value}
      />
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  height: '100%',
};
