import { useApolloClient, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle } from '@xspecs/design-system';
import { DELETE_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../lib/utils';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Trash2 } from 'lucide-react';

export const SpaceDeleteModal = () => {
  const spaceToDelete = useSingleSourceStore.use.spaceToDelete();
  const setSpaceToDelete = useSingleSourceStore.use.setSpaceToDelete();
  const { organization, refreshActiveOrganization } = useActiveOrganization();

  const apolloClient = useApolloClient();
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();

  const { addToast } = useSnackStack();

  const { formatMessage: f } = useIntl();

  const workspace = useMemo(
    () => organization?.workspaces?.find((workspace) => workspace.id === spaceToDelete),
    [organization, spaceToDelete],
  );

  const onClose = useCallback(() => {
    if (!workspace) return;
    setSpaceToDelete(undefined);
  }, [workspace, setSpaceToDelete]);

  const onDelete = useCallback(async () => {
    if (!workspace || !organization) return;
    const response = await deleteWorkspace({
      variables: {
        args: {
          organizationId: organization?.id,
          workspaceId: workspace?.id,
        },
      },
    });
    if (!response.data) return;
    if (response.data.deleteWorkspace.error) {
      addToast({ message: f({ id: 'space-deletion-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'space-deletion-success' }, { name: workspace?.name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceDeleted, { workspaceId: workspace?.id, name: workspace?.name });
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
      await refreshActiveOrganization();
    }
    onClose();
  }, [
    workspace,
    organization,
    deleteWorkspace,
    onClose,
    addToast,
    f,
    trackEvent,
    apolloClient,
    refreshActiveOrganization,
  ]);

  return (
    <Dialog open={!!spaceToDelete} onOpenChange={onClose}>
      <DialogContent className="overflow-visible rounded-lg">
        <div className="flex items-center gap-2">
          <Trash2 className="text-destructive" />
          <DialogTitle className="text-primary">{f({ id: 'delete-space' })}</DialogTitle>
        </div>
        <p className="text-secondary">{f({ id: 'delete-space-confirmation' }, { name: workspace?.name })}</p>
        <DialogFooter className="flex justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>
            {f({ id: 'cancel' })}
          </Button>
          <Button data-testid="WorkspaceSettingsModalDeleteButton" variant="destructive" onClick={onDelete}>
            {f({ id: 'yes-i-want-to-delete-it' })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
