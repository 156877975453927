import { useEffect, useState } from 'react';
import { useSingleSourceStore } from '../store/single-source-store/single-source-store';
import { Status } from '@xspecs/single-source-model';

export const useFileIsLoaded = (id?: string) => {
  const fileStatusesById = useSingleSourceStore.use.filesById();
  const fileStatus = id ? fileStatusesById[id]?.status : undefined;

  const [synced, setSynced] = useState(false);

  useEffect(() => {
    if (!synced && fileStatus === Status.Synced) {
      setSynced(true);
    }
  }, [fileStatus, synced]);

  return { synced } as const;
};
