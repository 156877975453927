import { logger } from '@xspecs/logger';
import { SpaceFile } from './SpaceFile';
import { FileTreeEntry } from '../read-models/file-tree/FileTree';
import * as Y from 'yjs';
import { MessageBus } from '../commands/framework/MessageBus';
import { FileLoadedEvent } from '../commands/spaces/LoadFileCommand';
import { BroadcastRemoteFileTreeChangesCommand } from '../commands/spaces/BroadcastRemoteFileTreeChangesCommand';

type FileTreeMapEntry = { id: string; filePath: string; fileType: string; entityType?: string; timestamp?: string };

export class SpaceRepository {
  private localChanges: Map<string, FileTreeEntry> = new Map();
  private trackedFiles: Map<string, FileTreeEntry> = new Map();
  private deletedFiles: Set<string> = new Set();
  private activeSpace?: SpaceFile;
  private observer?: (events: Y.YEvent<any>[], transaction: Y.Transaction) => void;

  public constructor(private readonly messageBus: MessageBus) {
    messageBus.subscribe([FileLoadedEvent], (event: FileLoadedEvent) => {
      if (event.params.fileType !== 'space') return;
      this.handleSpaceLoad(event.params.file as SpaceFile);
    });
  }

  public get(id: string): FileTreeEntry | undefined {
    return this.trackedFiles.get(id) || this.localChanges.get(id);
  }

  public list(): FileTreeEntry[] {
    return Array.from(new Map([...this.trackedFiles, ...this.localChanges]).values());
  }

  public add(item: FileTreeEntry): void {
    if (!this.activeSpace) {
      logger.warn('SpaceRepository: No active space found, cannot add file.');
      return;
    }
    if (this.trackedFiles.has(item.id) || this.localChanges.has(item.id)) {
      logger.warn(`SpaceRepository: File with id ${item.id} already exists.`);
      return;
    }
    this.localChanges.set(item.id, item);
  }

  public update(fileId: string, updatedData: Partial<FileTreeEntry>): void {
    if (!this.trackedFiles.has(fileId) && !this.localChanges.has(fileId)) {
      logger.warn(`SpaceRepository: Cannot update non-existing file ${fileId}.`);
      return;
    }
    const existingData = this.get(fileId);
    if (!existingData) return;

    const updatedFile = { ...existingData, ...updatedData };
    this.localChanges.set(fileId, updatedFile);
  }

  public delete(fileId: string): void {
    const fileEntry = this.get(fileId);
    if (!fileEntry) return;
    if (fileEntry.fileType === 'folder') {
      const filesToDelete = Array.from(this.trackedFiles.values()).filter(
        (file) => file.parentId === fileId || file.fileName.startsWith(fileEntry.fileName),
      );
      filesToDelete.forEach((file) => {
        this.deletedFiles.add(file.id);
        this.trackedFiles.delete(file.id);
      });
    } else {
      if (this.localChanges.has(fileId)) {
        this.localChanges.delete(fileId);
      } else if (this.trackedFiles.has(fileId)) {
        this.deletedFiles.add(fileId);
      }
    }
  }

  public save(): void {
    if (!this.activeSpace) return;
    try {
      this.activeSpace.filesMap.doc?.transact(() => {
        this.deletedFiles.forEach((fileId) => {
          this.activeSpace!.filesMap.delete(fileId);
          this.trackedFiles.delete(fileId);
        });
        this.deletedFiles.clear();
        this.localChanges.forEach((fileData, fileId) => {
          this.activeSpace!.filesMap.set(fileId, this.fromFileTreeEntry(fileData, fileId));
          this.trackedFiles.set(fileId, fileData);
        });
        this.localChanges.clear();
      }, 'save');
    } catch (error) {
      logger.error('SpaceRepository: Error during save', error);
    }
  }

  private handleSpaceLoad(space: SpaceFile): void {
    if (this.activeSpace === space) return;
    if (this.activeSpace && this.observer) {
      this.activeSpace.filesMap.unobserveDeep(this.observer);
    }
    if (!space) {
      logger.warn('SpaceRepository: No space provided in LoadSpaceCommand.');
      this.activeSpace = undefined;
      return;
    }

    this.trackedFiles.clear();
    this.localChanges.clear();
    this.deletedFiles.clear();

    this.observer = (events: Y.YEvent<any>[], transaction: Y.Transaction) => {
      if (transaction.origin === 'save') return; // Ignore changes triggered by save
      const addedFiles: FileTreeEntry[] = [];
      const updatedFiles: FileTreeEntry[] = [];
      const deletedFiles: Set<string> = new Set();
      const foldersToDelete: Set<string> = new Set();
      events.forEach((event) => {
        if (!(event instanceof Y.YMapEvent)) return;
        event.changes.keys.forEach((change, key) => {
          if (change.action === 'delete') {
            deletedFiles.add(key);

            if (key.startsWith('http://') || key.startsWith('https://')) {
              const filePath = new URL(key).pathname;
              const pathParts = filePath.split('/').filter(Boolean);
              for (let i = 0; i < pathParts.length - 1; i++) {
                const folderPath = pathParts.slice(0, i + 1).join('/');
                foldersToDelete.add(folderPath);
              }
            }
          } else {
            const fileData = event.target.get(key);
            if (!fileData) return;

            const fileTreeEntries = this.toFileTreeEntry(fileData);
            if (change.action === 'add') {
              addedFiles.push(...fileTreeEntries);
            } else if (change.action === 'update') {
              updatedFiles.push(...fileTreeEntries);
            }
          }
        });
      });
      this.messageBus.sendInternal(BroadcastRemoteFileTreeChangesCommand, {
        added: addedFiles,
        updated: updatedFiles,
        deleted: [...deletedFiles, ...foldersToDelete].map((id) => `folder-${id}`),
      });
      addedFiles.forEach((file) => this.trackedFiles.set(file.id, file));
      updatedFiles.forEach((file) => this.trackedFiles.set(file.id, file));
      deletedFiles.forEach((fileId) => this.trackedFiles.delete(fileId));
      foldersToDelete.forEach((folderId) => this.trackedFiles.delete(`folder-${folderId}`));
    };

    // This is called when the provider is disconnected and connected. The observeDeep and observe function is not called
    // when the provider is disconnected and connected. This is a bug in Yjs. This is a workaround to fix the issue.
    const entries = [...space.filesMap.entries()];
    entries.forEach(([, value]) => {
      const fileTreeEntries = this.toFileTreeEntry(value);
      fileTreeEntries.forEach((file) => this.trackedFiles.set(file.id, file));
    });
    if (entries.length !== 0) {
      this.messageBus.sendInternal(BroadcastRemoteFileTreeChangesCommand, {
        added: entries.map(([, value]) => this.toFileTreeEntry(value)).flat(),
        updated: [],
        deleted: [],
      });
    }

    space.filesMap.observeDeep(this.observer);
    this.activeSpace = space;
  }

  private toFileTreeEntry(entry: FileTreeMapEntry): FileTreeEntry[] {
    const entries: FileTreeEntry[] = [];
    const isExternal = entry.id.startsWith('http://') || entry.id.startsWith('https://');
    const pathParts = entry.filePath.split('/');
    let parentId: string | undefined = undefined;
    for (let i = 0; i < pathParts.length - 1; i++) {
      const folderPath = pathParts.slice(0, i + 1).join('/');
      const folderId = `folder-${folderPath}`;
      const folderName = pathParts[i];
      if (!this.trackedFiles.has(folderId)) {
        entries.push({
          id: folderId,
          fileName: folderPath,
          fileType: 'folder',
          parentId,
          text: folderName,
          content: '',
          entityType: undefined,
          timestamp: undefined,
          isExternal,
        });
      }
      parentId = folderId;
    }
    const fileNameWithExt = pathParts[pathParts.length - 1];
    const fileExtension = entry.fileType || fileNameWithExt.split('.').pop() || 'txt';
    const fileNameWithoutExt = fileNameWithExt.replace(/\.[^/.]+$/, '');
    entries.push({
      id: entry.id,
      timestamp: isExternal ? entry.timestamp : undefined,
      fileName: entry.filePath,
      fileType: fileExtension,
      parentId,
      text: fileNameWithoutExt,
      content: isExternal ? entry.id : '',
      entityType: entry.entityType,
      isExternal,
    });
    return entries;
  }

  private fromFileTreeEntry(entry: FileTreeEntry, fileId: string): FileTreeMapEntry {
    return {
      id: fileId,
      filePath: entry.fileName,
      fileType: entry.fileType,
      entityType: entry.entityType,
    };
  }
}
