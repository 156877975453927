import { IStore } from '../../data/Store';
import { DockViewItem, IdeState } from './Ide.types';
import { FileTreeItem } from '../file-tree/FileTree.types';

const defaultIdeState: IdeState = {
  selectedPanel: '',
  panels: [],
};

export class Ide {
  public selectedPanel: string;
  public files: Record<string, FileTreeItem> = {};

  constructor(private readonly store: IStore) {
    this.store.getState().setIde({ ...defaultIdeState });
  }

  add(panel: FileTreeItem) {
    this.files[panel.id] = panel;
    this.updateStore();
  }

  delete(id: string) {
    delete this.files[id];
    this.updateStore();
  }

  private updateStore() {
    const panels: DockViewItem[] = Object.entries(this.files).map(([key, file]) => ({
      id: file.id,
      title: file.text,
      component: file.text === 'Event Model' ? 'REACT_FLOW' : 'SLATE_EDITOR',
      params: { ...file.data, isActive: file.id === this.selectedPanel },
    }));
    this.store.getState().setIde({
      selectedPanel: this.selectedPanel,
      panels: panels,
    });
  }

  public clear() {
    this.store.getState().setIde({ ...defaultIdeState });
  }

  selectPanel(id: string) {
    this.selectedPanel = id;
    this.updateStore();
  }

  dispose() {
    this.clear();
  }
}
