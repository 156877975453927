import { SxProps, Theme } from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { showOrganizationSettingsVar } from '../../state/state';
import { useIntl } from 'react-intl';
import { OrganizationSettingsModalGeneral } from './general/organization-settings-modal-general';
import { OrganizationSettingsModalAdministration } from './administration/organization-settings-modal-administration';
import { OrganizationSettingsModalUsers } from './users/organization-settings-modal-users';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@xspecs/design-system/src/ui/dialog/dialog';
import { TabsContent, TabsList, TabsTrigger, Tabs } from '@xspecs/design-system/src/ui/tabs/tabs';

enum NavItem {
  General = 'general',
  Users = 'users',
  Administration = 'administration',
}

const overflowSx: SxProps<Theme> = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

export const OrganizationSettingsModal = () => {
  const [activeNavItem, setActiveNavItem] = useState<NavItem>(NavItem.General);

  const open = useReactiveVar(showOrganizationSettingsVar);
  const { organization } = useActiveOrganization();

  const { formatMessage: f } = useIntl();

  const onClose = useCallback(() => {
    showOrganizationSettingsVar(false);
    setActiveNavItem(NavItem.General);
  }, []);

  const onTabChange = useCallback((event: SyntheticEvent, newValue: NavItem) => {
    setActiveNavItem(newValue);
  }, []);

  const onOpenChange = useCallback((open: boolean) => {
    showOrganizationSettingsVar(open);
  }, []);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{f({ id: 'entity-settings' }, { name: organization?.name })}</DialogTitle>
        </DialogHeader>
        <div>
          <Tabs defaultValue={NavItem.General} className="w-full">
            <TabsList className="w-full">
              <TabsTrigger value={NavItem.General} className="w-full cursor-pointer">
                {f({ id: 'general' })}
              </TabsTrigger>
              <TabsTrigger value={NavItem.Users} className="w-full cursor-pointer">
                {f({ id: 'users' })}
              </TabsTrigger>
              <TabsTrigger value={NavItem.Administration} className="w-full cursor-pointer">
                {f({ id: 'administration' })}
              </TabsTrigger>
            </TabsList>
            <TabsContent className="mt-4" value={NavItem.General}>
              <OrganizationSettingsModalGeneral />
            </TabsContent>
            <TabsContent className="mt-4" value={NavItem.Users}>
              <OrganizationSettingsModalUsers />
            </TabsContent>
            <TabsContent className="mt-4" value={NavItem.Administration}>
              <OrganizationSettingsModalAdministration onClose={onClose} />
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};
