import { useApolloClient, useMutation } from '@apollo/client';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { RENAME_ORGANIZATION_MUTATION } from '../../../../graphql/mutations';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { isOrganizationNameValid } from '../../../../utils';
import { ORGANIZATIONS_QUERY } from '../../../../graphql/queries';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { Button, Input } from '@xspecs/design-system';
import { Loading } from '../../../loading/loading';

export type OrganizationSettingsModalGeneralNameProps = {
  onClose: () => void;
};

export const OrganizationSettingsModalGeneralName = (props: OrganizationSettingsModalGeneralNameProps) => {
  const { onClose } = props;

  const { addToast } = useSnackStack();

  const { organization } = useActiveOrganization();

  const [newName, setNewName] = useState(organization?.name || '');
  const [nameWarning, setNameWarning] = useState('');
  const [nameError, setNameError] = useState('');

  const isSaveDisabled = Boolean(nameWarning || nameError || !newName || newName === organization?.name);

  const apolloClient = useApolloClient();

  const [renameOrganization, { loading }] = useMutation(RENAME_ORGANIZATION_MUTATION);

  const { formatMessage: f } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    (event) => {
      setNewName(event.target.value);
      if (isOrganizationNameValid(event.target.value)) {
        setNameWarning('');
      } else {
        setNameWarning(f({ id: 'organization-name-input-warning' }));
      }
    },
    [f],
  );

  const onSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (!organization) return;
      const response = await renameOrganization({
        variables: {
          args: {
            organizationId: organization.id,
            newName,
          },
        },
      });
      if (!response.data) return;
      if (response.data.renameOrganization.error) {
        setNameError(response.data.renameOrganization.error);
      } else {
        addToast({
          message: f({ id: 'organization-name-changed' }, { name: organization?.name, newName }),
          severity: 'success',
        });
        onClose();
        await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
        const pathPieces = location.pathname.split('/');
        pathPieces[1] = newName;
        const newPath = pathPieces.join('/');
        navigate(newPath);
      }
    },
    [organization, renameOrganization, newName, addToast, f, onClose, apolloClient, location.pathname, navigate],
  );

  useEffect(() => {
    return () => {
      setNameWarning('');
      setNameError('');
      setNewName('');
    };
  }, []);

  return (
    <div className="w-full px-1">
      <form onSubmit={onSave} className="flex flex-col gap-4">
        <h6 className="text-primary">{f({ id: 'organization-name' })}</h6>
        <Input
          data-testid="OrganizationSettingsModalGeneralTextFieldInput"
          defaultValue={organization?.name}
          onChange={onChange}
        />
        <Button data-testid="OrganizationSettingsModalGeneralSaveButton" disabled={isSaveDisabled}>
          {loading ? <Loading /> : f({ id: 'save' })}
        </Button>
        <Button data-testid="OrganizationSettingsModalGeneralCancelButton" onClick={onClose} variant="outline">
          {f({ id: 'cancel' })}
        </Button>
      </form>
    </div>
  );
};
