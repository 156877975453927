import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { FileTreeItem } from '../../read-models/file-tree/FileTree.types';

interface AddIdePanelCommandParams extends IParams {
  file: FileTreeItem;
}

export class AddIdePanelCommandEvent extends EventBase {
  static eventType = 'AddIdePanelCommandEvent';

  constructor(
    public readonly params: AddIdePanelCommandParams,
    public readonly source = AddIdePanelCommandCommand,
  ) {
    super();
  }
}

export class AddIdePanelCommandCommand extends CommandBase<AddIdePanelCommandParams> {
  execute(params: AddIdePanelCommandParams): AddIdePanelCommandEvent | CommandError | undefined {
    const { file } = params;
    this.appState.ide.add(file);
    return new AddIdePanelCommandEvent(params);
  }
}
