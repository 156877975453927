import { AllowedAction, Frame, FrameGroup, Lane, LaneGroup } from 'narrative-studio-sdk';

export class ScriptConfig {
  constructor(
    public readonly frameGroups: FrameGroup[],
    public readonly laneGroups: LaneGroup[],
  ) {}

  public get defaultFrameWidth(): number {
    return this.frameGroups[0]?.defaultFrameWidth || 200;
  }

  public get defaultLaneHeight(): number {
    return this.laneGroups[0]?.defaultLaneHeight || 200;
  }

  public get defaultLaneColor(): string {
    return this.laneGroups[0]?.style?.backgroundColor || '#F8F8F8';
  }

  public static hasFrameGroupPermission(frameGroup: FrameGroup, requiredAction: AllowedAction): boolean {
    return frameGroup?.allowedActions?.actions?.some((action) => (action & requiredAction) === requiredAction) ?? false;
  }

  public static hasLaneGroupPermission(laneGroup: LaneGroup, requiredAction: AllowedAction): boolean {
    return laneGroup?.allowedActions?.actions?.some((action) => (action & requiredAction) === requiredAction) ?? false;
  }

  public static isEntityAllowed(item: LaneGroup | FrameGroup | Lane | Frame, entityType: string): boolean {
    if (item.allowedEntities?.type === 'ALL') return true;
    if (item.allowedEntities?.type === 'NONE') return false;
    if (item.allowedEntities?.type === 'SPECIFIC') {
      return item.allowedEntities.entities.some((e) =>
        typeof e === 'string' ? e === entityType : e.type === entityType,
      );
    }
    return false;
  }
}
