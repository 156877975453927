import { ChooseOrganization } from '../../pages/choose-organization/choose-organization';
import { useQuery } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../lib/utils';
import { Loading } from '../../components/loading/loading';
import { Icon } from '@xspecs/design-system';
import { LogOut } from 'lucide-react';
import { useAuth } from '../../auth';

export const ChooseOrganizationRoute = () => {
  const { data, loading } = useQuery(ORGANIZATIONS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  useEffect(() => {
    if (loading) return;
    if (data?.organizations.length === 0) navigate(RoutePaths.CreateOrganization);
  }, [data?.organizations.length, loading, navigate]);

  useEffect(() => {
    const lastSelectedOrganizationId = null; //localStorage.getItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
    if (!lastSelectedOrganizationId || loading) return;
    const organization = data?.organizations.find((org) => org.id === lastSelectedOrganizationId);
    if (organization)
      navigate(
        getRoutePathFromParams(RoutePaths.Organization, {
          organizationName: organization.name,
        }),
      );
    else localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
  }, [data?.organizations, loading, location, navigate]);

  if (loading) {
    return <Loading rootProps={{ sx: { width: '100%', height: '100%' } }} />;
  }

  return (
    <Box data-testid="choose-organization-route" className="bg-background-grey w-full h-full">
      <div className="w-full h-11 bg-background-grey py-0.5 px-3 flex justify-between items-center border-b">
        <Icon name="on-auto-icon" width={24} height={24} />
        <LogOut size={16} onClick={logout} />
      </div>
      <ChooseOrganization items={data?.organizations || []} loading={loading} />
    </Box>
  );
};
