import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { TransientStore } from '../../data/Transient';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { CommandError } from '../../ErrorStore';
import { EntityBase } from '../../entities/EntityBase';

interface ViewEntityDetailsCommandParams extends IParams {
  entityId: string;
}

interface EntityDetailsViewEventParams extends IParams {
  entityId: string;
}

export class EntityDetailsViewEvent extends EventBase {
  static eventType = 'EntityDetailsViewEvent';

  constructor(
    public readonly params: EntityDetailsViewEventParams,
    public readonly source = ViewEntityDetailsCommand,
  ) {
    super();
  }
}

export class ViewEntityDetailsCommand extends CommandBase<ViewEntityDetailsCommandParams> {
  execute(params: ViewEntityDetailsCommandParams): EntityDetailsViewEvent | CommandError | undefined {
    const entity = this.getModelContext().entityRepository.get(params.entityId);
    if (!entity) return;
    TransientStore.provider.set('entityDetailView', params.entityId, undefined, true);
    if (!entity.isVisible) this.blinkParent(entity);
    else this.blinkCurrent(entity);

    this.appState.observer.notify(NotificationTypes.OnUserStateChange, {
      added: [],
      updated: [{ entity, modifiedProperties: [] }],
      deleted: [],
    });

    return new EntityDetailsViewEvent({ entityId: params.entityId });
  }

  blinkParent(entity: EntityBase) {
    const visibleParent = entity.visibleParent;
    if (visibleParent) {
      visibleParent.blink = true;
      this.appState.store.setConstructToPanTo(visibleParent.id);
      this.appState.observer.notify(NotificationTypes.OnTransientChange, {
        added: [],
        updated: [{ entity: visibleParent, modifiedProperties: ['blink'] }],
        deleted: [],
      });
    }
  }

  blinkCurrent(entity: EntityBase) {
    this.appState.store.setConstructToPanTo(entity.id);
    entity.blink = true;
    this.appState.store.setConstructToPanTo(entity.id);
    this.appState.observer.notify(NotificationTypes.OnTransientChange, {
      added: [],
      updated: [{ entity: entity, modifiedProperties: ['blink'] }],
      deleted: [],
    });
  }
}
