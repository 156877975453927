import {
  ComponentType,
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, InputBase, Stack, Typography } from '@mui/material';
import { FrameGroup, LabelProps, LaneGroup } from '@xspecs/single-source-model';
import { useWindowEvent } from '@mantine/hooks';
import { useCommandDispatch } from '../../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';
import { Header } from './header';
import { motion } from 'framer-motion';

type ScriptProps = {
  laneGroups: LaneGroup[];
  frameGroups: FrameGroup[];
  width: number;
  height: number;
  mouseToXY: (event: { clientX: number; clientY: number }) => { x: number; y: number };
  position?: { x: number; y: number };
  HeaderContainer?: ComponentType<{ children: ReactNode }>;
};

const frameOffset = 35;

const laneGroupOffset = 100;
const laneOffset = 35;

type MenuOpenForType = {
  frameGroupIndex?: number;
  laneGroupIndex?: number;
  frameIndex?: number;
  laneIndex?: number;
};

const _Script = (props: ScriptProps) => {
  const {
    laneGroups,
    frameGroups,
    width,
    height,
    position = { x: 0, y: 0 },
    HeaderContainer = Fragment,
    mouseToXY = () => ({ x: 0, y: 0 }),
  } = props;

  const id = useId();

  const dispatchCommand = useCommandDispatch();

  const doesFrameGroupOrFrameHaveLabels = useMemo(
    () => frameGroups.some((fg) => fg.labelProps || fg.frames.some((f) => f.labelProps)),
    [frameGroups],
  );

  const frameHeaderOffset = doesFrameGroupOrFrameHaveLabels ? 50 : 0;
  const frameGroupOffset = 80 + frameHeaderOffset;

  const [hovered, setHovered] = useState({
    frameGroupIndex: -1,
    laneGroupIndex: -1,
    frameIndex: -1,
    laneIndex: -1,
  });

  const [menuOpenFor, setMenuOpenFor] = useState<MenuOpenForType>({});
  const rootRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 2;
    ctx.strokeRect(0, 0, canvas.width, canvas.height);

    function drawLaneGroups(laneGroups: LaneGroup[]) {
      if (!ctx) return;
      for (const laneGroup of laneGroups) {
        ctx.fillStyle = laneGroup.style.backgroundColor ?? 'transparent';
        ctx.fillRect(laneGroup.x, laneGroup.y, laneGroup.width, laneGroup.height);

        if (laneGroup.style.borderColor && laneGroup.style.borderWidth) {
          ctx.lineWidth = laneGroup.style.borderWidth;
          ctx.strokeStyle = laneGroup.style.borderColor;
          ctx.strokeRect(laneGroup.x, laneGroup.y, laneGroup.width, laneGroup.height);
        }

        for (const lane of laneGroup.lanes) {
          ctx.fillStyle = lane.style.backgroundColor ?? 'transparent';
          ctx.fillRect(lane.x, lane.y, lane.width, lane.height);

          if (lane.style.borderColor && lane.style.borderWidth) {
            ctx.lineWidth = lane.style.borderWidth;
            ctx.strokeStyle = lane.style.borderColor;
            ctx.strokeRect(lane.x, lane.y, lane.width, lane.height);
          }
        }
      }
    }

    function drawFrameGroups(frameGroups: FrameGroup[]) {
      if (!ctx) return;
      for (const fg of frameGroups) {
        ctx.fillStyle = fg.style.backgroundColor ?? 'transparent';
        ctx.fillRect(fg.x, fg.y, fg.width, fg.height);

        if (fg.style.borderColor && fg.style.borderWidth) {
          ctx.lineWidth = fg.style.borderWidth;
          ctx.strokeStyle = fg.style.borderColor;
          ctx.strokeRect(fg.x, fg.y, fg.width, fg.height);
        }

        for (const frame of fg.frames) {
          ctx.fillStyle = frame.style.backgroundColor ?? 'transparent';
          ctx.fillRect(frame.x, frame.y, frame.width, frame.height);

          if (frame.style.borderColor && frame.style.borderWidth) {
            ctx.lineWidth = frame.style.borderWidth;
            ctx.strokeStyle = frame.style.borderColor;
            ctx.strokeRect(frame.x, frame.y, frame.width, frame.height);
          }
        }
      }
    }

    drawLaneGroups(laneGroups);
    drawFrameGroups(frameGroups);

    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [frameGroups, height, laneGroups, width]);

  const getOnMenuChange = useCallback(
    (key: keyof MenuOpenForType, value: number) => (isOpen: boolean) => {
      setMenuOpenFor(isOpen ? { [key]: value } : {});
    },
    [],
  );

  const labels = [
    laneGroups.flatMap((lg) => {
      const result: (LabelProps & { width: number })[] = lg.labelProps ? [{ ...lg.labelProps, width: lg.width }] : [];
      lg.lanes.forEach((l) => {
        if (l.labelProps) result.push({ ...l.labelProps, width: l.width });
      });
      return result;
    }),
    frameGroups.flatMap((fg) => {
      const result: (LabelProps & { width: number })[] = fg.labelProps ? [{ ...fg.labelProps, width: fg.width }] : [];
      fg.frames.forEach((f) => {
        if (f.labelProps) result.push({ ...f.labelProps, width: f.width });
      });
      return result;
    }),
  ].flatMap((x) => x);

  useWindowEvent('mousemove', (e) => {
    if (!rootRef.current || Object.keys(menuOpenFor).length >= 1) return;
    const { x, y } = mouseToXY(e);
    if (x < 0 - laneGroupOffset || y < 0 - frameGroupOffset || x > width || y > height) {
      setHovered((prev) => {
        if (
          prev.frameGroupIndex === -1 &&
          prev.frameIndex === -1 &&
          prev.laneGroupIndex === -1 &&
          prev.laneIndex === -1
        )
          return prev;
        return { frameGroupIndex: -1, laneGroupIndex: -1, frameIndex: -1, laneIndex: -1 };
      });
      return;
    }

    const frameGroupIndex = frameGroups.findIndex((fg) => {
      const doesAnyFrameHaveHeader = fg.frames.some((f) => f.headerProps.show);
      const offset = fg.headerProps.show || doesAnyFrameHaveHeader ? frameGroupOffset : 0;
      const fgX = fg.x;
      const fgY = fg.y - offset;
      const fgWidth = fg.width;
      const fgHeight = fg.height + offset;
      return x >= fgX && x <= fgX + fgWidth && y >= fgY && y <= fgY + fgHeight;
    });

    const frameIndex =
      frameGroups[frameGroupIndex]?.frames.findIndex((f) => {
        const offset = f.headerProps.show ? frameGroupOffset : 0;
        const fX = f.x;
        const fY = f.y - offset;
        const fWidth = f.width;
        const fHeight = f.height + offset;
        return x >= fX && x <= fX + fWidth && y >= fY && y <= fY + fHeight;
      }) ?? -1;

    const laneGroupIndex = laneGroups.findIndex((lg) => {
      const doesAnyLaneHaveHeader = lg.lanes.some((l) => l.headerProps.show);
      const offset = lg.headerProps.show || doesAnyLaneHaveHeader ? laneGroupOffset : 0;
      const lgX = lg.x - offset;
      const lgY = lg.y;
      const lgWidth = lg.width + offset;
      const lgHeight = lg.height;
      return x >= lgX && x <= lgX + lgWidth && y >= lgY && y <= lgY + lgHeight;
    });

    const laneIndex =
      laneGroups[laneGroupIndex]?.lanes.findIndex((l) => {
        const offset = l.headerProps.show ? laneOffset : 0;
        const lX = l.x - offset;
        const lY = l.y;
        const lWidth = l.width + offset;
        const lHeight = l.height;
        return x >= lX && x <= lX + lWidth && y >= lY && y <= lY + lHeight;
      }) ?? -1;

    setHovered({
      frameGroupIndex,
      laneGroupIndex,
      frameIndex,
      laneIndex,
    });
  });

  const HoveredFrameGroupHeader = useMemo(() => {
    const fg = frameGroups[hovered.frameGroupIndex];

    if (!fg || !fg.headerProps.show) return null;

    return (
      <motion.div
        // key={`FrameGroupHeader-${i}`}
        className="nodrag"
        whileHover={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: menuOpenFor.frameGroupIndex ? 1 : 0.4,
        }}
        style={{
          zIndex: 1000000,
          pointerEvents: 'all',
          position: 'absolute',
          width: fg.width,
          left: fg.x + position.x,
          top: -frameHeaderOffset - 35 + position.y,
          transform: 'translate(0, -100%)',
        }}
      >
        <Header
          onMenuOpenChange={getOnMenuChange('frameGroupIndex', hovered.frameIndex)}
          isGroupHeader
          bgColor="rgba(212, 212, 216, 1)"
          {...fg}
        />
      </motion.div>
    );
  }, [
    frameGroups,
    frameHeaderOffset,
    getOnMenuChange,
    hovered.frameGroupIndex,
    hovered.frameIndex,
    menuOpenFor.frameGroupIndex,
    position.x,
    position.y,
  ]);

  const HoveredFrameHeader = useMemo(() => {
    const fg = frameGroups[hovered.frameGroupIndex];
    const f = fg?.frames[hovered.frameIndex];

    if (!f || !f.headerProps.show) return null;

    return (
      <motion.div
        className="nodrag"
        whileHover={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: menuOpenFor.frameIndex ? 1 : 0.4,
        }}
        style={{
          zIndex: 1000000,
          pointerEvents: 'all',
          position: 'absolute',
          width: f.width,
          left: f.x + position.x,
          top: -frameHeaderOffset - 4 + position.y,
          transform: 'translate(0, -100%)',
        }}
      >
        <Header onMenuOpenChange={getOnMenuChange('frameIndex', hovered.frameIndex)} {...f} />
      </motion.div>
    );
  }, [
    frameGroups,
    frameHeaderOffset,
    getOnMenuChange,
    hovered.frameGroupIndex,
    hovered.frameIndex,
    menuOpenFor.frameIndex,
    position.x,
    position.y,
  ]);

  const HoveredLaneGroupHeader = useMemo(() => {
    const lg = laneGroups[hovered.laneGroupIndex];

    if (!lg || !lg.headerProps.show) return null;

    return (
      <motion.div
        className="nodrag"
        whileHover={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: menuOpenFor.laneGroupIndex ? 1 : 0.4,
        }}
        style={{
          zIndex: 1000000,
          pointerEvents: 'all',
          position: 'absolute',
          height: lg.height,
          left: -4 + position.x,
          top: lg.y + position.y,
          transform: 'translate(-100%, 0)',
        }}
      >
        <Header
          onMenuOpenChange={getOnMenuChange('laneGroupIndex', hovered.laneGroupIndex)}
          isGroupHeader
          orientation="vertical"
          bgColor="rgba(212, 212, 216, 1)"
          {...lg}
        />
      </motion.div>
    );
  }, [getOnMenuChange, hovered.laneGroupIndex, laneGroups, menuOpenFor.laneGroupIndex, position.x, position.y]);

  const HoveredLaneHeader = useMemo(() => {
    const lg = laneGroups[hovered.laneGroupIndex];
    const l = lg?.lanes[hovered.laneIndex];

    if (!l || !l.headerProps.show) return null;

    return (
      <motion.div
        className="nodrag"
        whileHover={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: menuOpenFor.laneIndex ? 1 : 0.4,
        }}
        style={{
          zIndex: 1000000,
          pointerEvents: 'all',
          position: 'absolute',
          height: l.height,
          left: l.x - 8 + position.x,
          top: l.y + position.y,
          transform: 'translate(-100%, 0)',
        }}
      >
        <Header onMenuOpenChange={getOnMenuChange('laneIndex', hovered.laneIndex)} orientation="vertical" {...l} />
      </motion.div>
    );
  }, [
    getOnMenuChange,
    hovered.laneGroupIndex,
    hovered.laneIndex,
    laneGroups,
    menuOpenFor.laneIndex,
    position.x,
    position.y,
  ]);

  return (
    <Box ref={rootRef} width={width} height={height} position="relative">
      <canvas ref={canvasRef} />
      {labels.map((label, index) => (
        <Stack
          key={`ScriptLabel${id}${index}`}
          direction="row"
          gap={0.5}
          sx={{
            position: 'absolute',
            left: label.x,
            top: label.y,
            width: label.width,
          }}
        >
          {label.iconProps ? <Icon name={label.iconProps.source} /> : null}
          {label.text.map((text, i) =>
            text.onRename ? (
              <InputBase
                key={`ScriptLabel${id}LabelText${i}`}
                placeholder="Untitled"
                sx={{
                  width: '100%',
                  px: 1,
                  ...text.style,
                  '& .MuiInputBase-input::placeholder': { color: laneGroups[i].style.textColor },
                }}
                value={text.value}
                onChange={(e) => {
                  if (text.onRename)
                    dispatchCommand(text.onRename.command, {
                      ...text.onRename.params,
                      value: e.target.value,
                    });
                }}
              />
            ) : (
              <Typography key={`ScriptLabel${id}LabelText${i}`} sx={text.style}>
                {text.value}
              </Typography>
            ),
          )}
        </Stack>
      ))}
      <HeaderContainer>
        {HoveredFrameGroupHeader}
        {HoveredFrameHeader}
        {HoveredLaneGroupHeader}
        {HoveredLaneHeader}
      </HeaderContainer>
    </Box>
  );
};

export const Script = memo(_Script);
