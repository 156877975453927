import { editor } from 'monaco-editor';

export const monacoOptions: editor.IStandaloneEditorConstructionOptions = {
  theme: 'default',
  wordWrap: 'on',
  'semanticHighlighting.enabled': true,
  lineNumbers: 'off',
  minimap: { enabled: false },
  scrollBeyondLastLine: true,
  scrollBeyondLastColumn: 0,
  fontFamily: "'JetBrains Mono'",
  scrollbar: {
    horizontal: 'hidden',
    vertical: 'hidden',
  },
  renderLineHighlight: 'none',
  overviewRulerBorder: false,
  glyphMargin: false,
  defaultColorDecorators: false,
  overviewRulerLanes: 0,
  folding: true,
  guides: {
    indentation: false,
  },
  renderWhitespace: 'none',
  quickSuggestions: false,
};
