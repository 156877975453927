import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SetEntityAttributeCommandParams extends IParams {
  entityId: string;
  attributeName: string;
  attributeValue: any;
}

export interface SetEntityAttributeEventParams extends IParams {
  entityId: string;
  attributeName: string;
  attributeValue: any;
}

export class EntityAttributesSetEvent extends EventBase {
  static eventType = 'EntityAttributesSetEvent';

  constructor(
    public readonly params: SetEntityAttributeEventParams,
    public readonly source = SetEntityAttributesCommand,
  ) {
    super();
  }
}

export class SetEntityAttributesCommand extends CommandBase<SetEntityAttributeCommandParams> {
  execute(params: SetEntityAttributeCommandParams): EntityAttributesSetEvent | CommandError | undefined {
    const modelContext = this.getModelContext();
    const entity = modelContext.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of('Entity not found', 'error');
    if (entity.attributes[params.attributeName] === params.attributeValue) return;
    entity.attributes[params.attributeName] = params.attributeValue;
    modelContext.entityRepository.update(entity);
    modelContext.entityRepository.save();
    return new EntityAttributesSetEvent({
      entityId: entity.id,
      attributeName: params.attributeName,
      attributeValue: params.attributeValue,
    });
  }
}
