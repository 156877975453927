export enum RoutePaths {
  AuthReturn = '/auth-return',
  ChooseOrganization = '/choose-organization',
  CreateOrganization = '/create-organization',
  Default = '/',
  Space = '/:organizationName/:workspaceName',
  File = '/:organizationName/:workspaceName/:fileId',
  Organization = '/:organizationName',
  Settings = '/:organizationName/settings',
  InvitePeople = '/:organizationName/invite-people',
  Spaces = '/:organizationName/spaces',
  Login = '/login',
  ConfirmEmail = '/confirm-email',
  NoAccess = '/no-access',
  Xolvio = '/xolvio',
  SwagShop = '/xolvio/swagshop',
}

export const getRoutePathFromParams = (routePath: RoutePaths, params: Record<string, string>): string => {
  let path = routePath.toString();
  Object.keys(params).forEach((key) => (path = path.replace(`:${key}`, encodeURIComponent(params[key]))));
  const currentQueryParams = new URLSearchParams(window.location.search);
  return `${path}${currentQueryParams.toString() ? `?${currentQueryParams.toString()}` : ''}`;
};
