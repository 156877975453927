import { useMutation } from '@apollo/client';
import { AddRounded as AddIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { INVITE_USERS_MUTATION } from '../../graphql/mutations';
import { navBarOverridePropsVar } from '../../state/state';
import { emailRegex } from '../../utils';
import { ShareType } from '../../gql/graphql';
import { Button, Input } from '@xspecs/design-system';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/loading/loading';

export const InvitePeople = () => {
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const navigate = useNavigate();

  const navigateToDocs = useCallback(() => {
    if (!organization || !workspace) return;
    navigate(
      getRoutePathFromParams(RoutePaths.Space, {
        organizationName: organization.name,
        workspaceName: workspace.name,
      }),
    );
  }, [navigate, organization, workspace]);

  useEffect(() => {
    navBarOverridePropsVar({ isLoggedIn: true, isPrimary: true, hideLogo: true, hideOrganizations: true });
    return () => {
      navBarOverridePropsVar({});
    };
  }, []);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'background.paper' }}>
      <Box
        sx={{
          mt: { xs: 4.5, md: 17.375 },
          pb: 4.5,
          px: 2,
          pt: { md: 5.625 },
          mb: { xs: 9.25, md: 19.875 },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: { md: '415px' },
          mx: { md: 'auto' },
          boxShadow: {
            md: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          },
          borderRadius: { md: '4px' },
        }}
      >
        <InvitePeopleContent onSendInvitationSuccess={navigateToDocs} onInviteLater={navigateToDocs} />
      </Box>
    </Box>
  );
};

type InvitePeopleContentProps = {
  onInviteLater: () => void;
  onSendInvitationSuccess: () => void;
};

export const InvitePeopleContent = (props: InvitePeopleContentProps) => {
  const { onInviteLater, onSendInvitationSuccess } = props;

  const [emails, setEmails] = useState(['', '', '']);
  const [error, setError] = useState('');

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();

  const [inviteUsers, { loading }] = useMutation(INVITE_USERS_MUTATION);

  const { addToast } = useSnackStack();

  const onAddMore = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setEmails((prevEmail) => [...prevEmail, '']);
  }, []);

  const onSendInvitations = useCallback(
    async (e) => {
      e.preventDefault();
      if (!organization || !workspace) return;
      const response = await inviteUsers({
        variables: {
          args: {
            organizationId: organization?.id,
            workspaceId: workspace?.id,
            type: ShareType.Organization,
            resourceId: organization?.id,
            emails: emails.filter((email) => emailRegex.test(email)),
          },
        },
      });
      const responseError = response?.data?.inviteUsers?.error;
      if (responseError) {
        setError(responseError);
      } else {
        trackEvent(AppTypeEvent.UserInvited, {
          organizationId: organization?.id,
          workspaceId: workspace?.id,
          invites: emails.length,
        });
        addToast({ message: f({ id: 'invitations-sent' }), severity: 'success' });
        onSendInvitationSuccess();
      }
    },
    [organization, workspace, inviteUsers, emails, trackEvent, addToast, f, onSendInvitationSuccess],
  );

  const disabled = useMemo(() => {
    const allEmailsEmpty = emails.every((email) => !email);
    if (error) {
      return true;
    }
    if (allEmailsEmpty) {
      return true;
    }
    const hasValidEmails = emails.some((email) => emailRegex.test(email));
    const hasInvalidEmails = emails.some((email) => {
      if (!email) {
        return false;
      }
      return !emailRegex.test(email);
    });
    if (hasInvalidEmails) {
      return true;
    }
    return !hasValidEmails;
  }, [emails, error]);

  const onEmailChange = useCallback(
    (index): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
      (event) => {
        setEmails((prevEmails) => {
          const newEmails = [...prevEmails];
          newEmails[index] = event.target.value;
          return newEmails;
        });
        setError('');
      },
    [],
  );

  return (
    <>
      <form onSubmit={onSendInvitations}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1, mt: 2 }}
          data-testid="invite-people-email-fields"
        >
          {emails.map((email, index) => (
            <Input
              key={`InvitePeopleTextFields${index}`}
              value={email}
              placeholder="name@example.com"
              onChange={onEmailChange(index)}
              data-testid={`invite-people-email-${index}`}
            />
          ))}
          <Button type="button" data-testid="invite-people-add-more" variant="outline" onClick={onAddMore}>
            <AddIcon />
            {f({ id: 'add-more' })}
          </Button>
        </Box>
        <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          {error ? (
            <Typography variant="body1" color="error" align="center">
              {f({ id: error })}
            </Typography>
          ) : null}
          <div className="flex gap-1.5 justify-end w-full">
            <Button
              type="submit"
              data-testid="invite-people-send-invitations"
              variant="default"
              disabled={disabled}
              className="w-32"
            >
              {loading ? <Loading /> : f({ id: 'send-invitations' })}
            </Button>
            <Button type="button" data-testid="invite-people-invite-later" variant="outline" onClick={onInviteLater}>
              {f({ id: 'invite-later' })}
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};
