import { MessageBus } from '../../commands/framework/MessageBus';
import { SpaceSettingsChangedEvent } from '../../commands/spaces/BroadcastSpaceSettingChangesCommand';
import { BuildLog } from './BuildLog';
import { BuildLogEntry } from './BuildLog.types';

export class BuildLogProjection {
  constructor(
    private readonly messageBus: MessageBus,
    private readonly buildLog: BuildLog,
  ) {
    this.messageBus.subscribe([SpaceSettingsChangedEvent], (event: SpaceSettingsChangedEvent) => {
      for (const [key, value] of Object.entries(event.params.added)) {
        if (key.startsWith('build-log-') && Array.isArray(value)) {
          const buildId = key.replace('build-log-', '');
          const logEntries: [string, string][] = value as [string, string][];
          logEntries.forEach(([timestamp, status]) => {
            const buildLogEntry: BuildLogEntry = {
              buildId,
              log: status,
              lastUpdated: new Date(timestamp).getTime(),
            };
            this.buildLog.applyChange(buildLogEntry);
          });
        }
      }
      event.params.deleted.forEach((key) => {
        if (key.startsWith('build-log-')) {
          const buildId = key.replace('build-log-', '');
          this.buildLog.delete(buildId);
        }
      });
    });
  }
}
