import { useStoreContext } from '@xspecs/design-system';
import { useCallback } from 'react';
import { useActiveOrganization } from './use-active-organization';
import { useActiveWorkspace } from './use-active-workspace';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../config/route-paths/route-paths';
import { showInviteUsersVar, showOrganizationSettingsVar } from '../state/state';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../lib/utils';

export const useMenuItemSettings = () => {
  const { organization, setOrganization } = useActiveOrganization();
  const { setWorkspace } = useActiveWorkspace();

  const navigate = useNavigate();

  const { store } = useStoreContext();
  const setShowMouseTrackpadSettings = store.use.setShowMouseTrackpadSettings();
  const setShowManageLabelsModal = store.use.setShowManageLabelsModal();

  const onOrganizationSettings = useCallback(() => {
    if (!organization) return;
    navigate(
      getRoutePathFromParams(RoutePaths.Settings, {
        organizationName: organization.name,
      }),
      {
        state: {
          preventNavigation: true,
        },
      },
    );
  }, [navigate, organization]);

  const openOrganizationSettings = useCallback(() => {
    showOrganizationSettingsVar(true);
  }, []);

  const onSwitchOrganization = useCallback(() => {
    setOrganization(null);
    setWorkspace(null);
    navigate(RoutePaths.ChooseOrganization);
    localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
  }, [navigate, setOrganization, setWorkspace]);

  const onOrganizationApps = useCallback(() => {
    if (!organization) return;
    navigate(
      getRoutePathFromParams(RoutePaths.Settings, {
        organizationName: organization?.name,
      }) + '#apps',
      {
        state: {
          preventNavigation: true,
        },
      },
    );
  }, [navigate, organization]);

  const openInviteUsers = useCallback(() => {
    showInviteUsersVar(true);
  }, []);

  const openMouseOrTrackSelectionModal = useCallback(() => {
    setShowMouseTrackpadSettings(true);
  }, [setShowMouseTrackpadSettings]);

  const onManageLabels = useCallback(() => {
    setShowManageLabelsModal(true);
  }, [setShowManageLabelsModal]);

  return {
    onOrganizationSettings,
    onSwitchOrganization,
    openOrganizationSettings,
    onOrganizationApps,
    openInviteUsers,
    openMouseOrTrackSelectionModal,
    onManageLabels,
  } as const;
};
