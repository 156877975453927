import { useSingleSourceStore } from '../../../../../../store/single-source-store/single-source-store';
import { LabelTemp } from '@xspecs/single-source-model';
import { useApplication } from '../../../../../../wrappers/application-context/application-context';
import { useCallback } from 'react';

export const useLabels = (entityId: string) => {
  const { application } = useApplication();

  const labels = useSingleSourceStore.use.labels();
  const setShowManageTagsModal = useSingleSourceStore.use.setShowManageLabelsModal();

  const createAndSelectLabel = useCallback(
    (label: LabelTemp) => {
      application?.getModelContext()?.labelsInteractor.createAndSelect(label, entityId);
    },
    [application, entityId],
  );

  const onSelectedLabelsChange = useCallback(
    (newTags: LabelTemp[]) => {
      application?.getModelContext()?.labelsInteractor.onSelectedChange(newTags, entityId);
    },
    [application, entityId],
  );

  const onManageLabels = useCallback(() => {
    setShowManageTagsModal(true);
  }, [setShowManageTagsModal]);

  return {
    labels,
    createAndSelectLabel,
    onSelectedLabelsChange,
    onManageLabels,
  } as const;
};
