import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface CloseEntityAnnotatorCommandParams extends IParams {
  entityId: string;
}

interface EntityAnnotatorClosedEventParams extends IParams {
  entityId: string;
}

export class EntityAnnotatorClosedEvent extends EventBase {
  static eventType = 'EntityAnnotatorCloseedEvent';

  constructor(
    public readonly params: EntityAnnotatorClosedEventParams,
    public readonly source = CloseEntityAnnotatorCommand,
  ) {
    super();
  }
}

export class CloseEntityAnnotatorCommand extends CommandBase<CloseEntityAnnotatorCommandParams> {
  execute(params: CloseEntityAnnotatorCommandParams): EntityAnnotatorClosedEvent | CommandError {
    const modelContext = this.getModelContext();
    const entity = modelContext.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of(new Error(`Entity with id ${params.entityId} not found`), 'error');
    modelContext.annotator.closeAnnotator();
    return new EntityAnnotatorClosedEvent({ entityId: params.entityId });
  }
}
