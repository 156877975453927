import { FC, useCallback, useRef, useState } from 'react';
import * as monaco from 'monaco-editor';
import { cn, Tabs, TabsContent, TabsList, TabsTrigger } from '@xspecs/design-system';
import { useMonacoAutocomplete } from './use-monaco-auto-complete';
import './gherkin-editor.utils';
import { Editor } from '@monaco-editor/react';
import { monacoOptions } from './monaco.utils';
import { Status } from '@xspecs/single-source-model';
import { Loading } from '../../loading/loading';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

type GherkinEditorProps = {
  id: string;
  onEditorChange?: (value: string) => void;
  schemas?: Schemas;
  name?: string;
  showJsonRepresentation?: boolean;
  initialValue?: string;
};

export const GherkinEditor: FC<GherkinEditorProps> = (props) => {
  const {
    id,
    onEditorChange,
    schemas = { commands: {}, events: {}, states: {} },
    name = '',
    showJsonRepresentation = false,
    initialValue = '',
  } = props;

  const [json, setJson] = useState<string>('');

  const memoizedSetJsonOutput = useCallback((jsonStr: string) => {
    setJson(jsonStr);
  }, []);

  if (!showJsonRepresentation) {
    return (
      <GherkinEditorInner
        id={id}
        schemas={schemas}
        memoizedSetJsonOutput={memoizedSetJsonOutput}
        onEditorChange={onEditorChange}
        name={name}
      />
    );
  }

  return (
    <Tabs defaultValue="gwt" className="w-full h-full">
      <TabsList className="w-full">
        <TabsTrigger value="gwt" className="w-full">
          GWT
        </TabsTrigger>
        <TabsTrigger value="json" className="w-full">
          JSON
        </TabsTrigger>
      </TabsList>
      <TabsContent value="gwt" className="w-full h-full">
        <GherkinEditorInner
          id={id}
          schemas={schemas}
          memoizedSetJsonOutput={memoizedSetJsonOutput}
          onEditorChange={onEditorChange}
          name={name}
          initialValue={initialValue}
        />
      </TabsContent>
      <TabsContent value="json" className="w-full h-full">
        <Editor language="json" value={json} options={{ language: 'json', readOnly: true, ...monacoOptions }} />
      </TabsContent>
    </Tabs>
  );
};

const GherkinEditorInner = ({
  id,
  onEditorChange,
  schemas,
  name,
  memoizedSetJsonOutput,
  initialValue,
}: {
  id: string;
  onEditorChange?: (value: string) => void;
  schemas: Schemas;
  name?: string;
  memoizedSetJsonOutput: (jsonStr: string) => void;
  initialValue?: string;
}) => {
  const { formatMessage: f } = useIntl();

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const editorContainerRef = useRef<HTMLDivElement | null>(null);

  const files = useSingleSourceStore.use.filesById();
  const file = files[id];
  const status = file?.status;

  const isLoading = !status || [Status.Unknown, Status.Connecting, Status.Initial].includes(status);
  const isDisconnected = status === Status.Disconnected;

  const disabledClass = 'opacity-0 pointer-events-none';
  const enabledClass = 'opacity-100 pointer-events-auto';

  useMonacoAutocomplete(
    id,
    editorRef,
    editorContainerRef,
    schemas,
    memoizedSetJsonOutput,
    onEditorChange,
    name,
    initialValue,
  );

  return (
    <div className="w-full h-full relative">
      <div
        ref={editorContainerRef}
        id="editor-container"
        style={{ width: '100%', height: '100%' }}
        className={cn('w-full h-full absolute', isLoading || isDisconnected ? disabledClass : enabledClass)}
      />
      {isLoading ? (
        <div
          className={cn(
            'w-full h-full flex items-center absolute z-10 top-0',
            isLoading ? enabledClass : disabledClass,
          )}
        >
          <Loading />
        </div>
      ) : null}
      {isDisconnected ? (
        <div
          className={cn(
            'w-full h-full flex items-center justify-center absolute z-10 top-0',
            isDisconnected ? enabledClass : disabledClass,
          )}
        >
          <div className="text-center">
            <p>{f({ id: 'disconnected-from-server' })}</p>
            <p>{f({ id: 'attempting-to-reconnect' })}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const registerGwtLanguage = () => {
  if (monaco.languages.getLanguages().some((lang) => lang.id === 'gwt')) {
    return;
  }

  monaco.languages.register({ id: 'gwt' });
  monaco.languages.register({ id: 'gwt' });
  monaco.languages.setMonarchTokensProvider('gwt', {
    tokenizer: {
      root: [
        // ✅ Scenario keyword is pink
        [/^(Scenario)\b/, 'scenario'],
        [/^(Scenario\s+)(.*)$/, ['scenario', 'description']],

        // ✅ Keywords (Given, When, Then, And) in blue
        [/^(Given|When|Then|And)\b/, 'keyword'],

        // ✅ Ensure Event names are detected correctly (after Given or Then)
        [/^(Given|Then)\s+([A-Z][a-zA-Z0-9]*)/, ['keyword', 'event']],

        // ✅ Ensure Command names are detected correctly (after When)
        [/^(When)\s+([A-Z][a-zA-Z0-9]*)/, ['keyword', 'command']],

        // ✅ Fields (starting with "- fieldName is "" ")
        [/^-\s+([a-zA-Z0-9_]+)\s+is\s+["'].*["']/, 'field'],
      ],
    },
  });
};

const createTheme = () => {
  monaco.editor.defineTheme('gwt-theme', {
    base: 'vs',
    inherit: true,
    rules: [
      { token: 'scenario', foreground: 'ff66cc', fontStyle: 'bold' }, // 🎨 Pink for "Scenario"
      { token: 'description', foreground: 'ffffff' }, // 🎨 White for Scenario description
      { token: 'keyword', foreground: '569cd6', fontStyle: 'bold' }, // 🎨 Blue for Given, When, Then
      { token: 'event', foreground: 'FF9D48', fontStyle: 'bold' }, // 🎨 Orange for Events
      { token: 'command', foreground: 'ACD0F7', fontStyle: 'bold' }, // 🎨 Light Blue for Commands
      { token: 'field', foreground: 'FFEA4D' }, // 🎨 Yellow for Fields
    ],
    colors: {
      'editor.foreground': '#1E1E1E', // ✅ Ensure text is white
      'editor.background': '#ffffff', // ✅ Ensure dark theme is applied
    },
  });
};

registerGwtLanguage();
createTheme();

export type Schemas = {
  commands: Record<string, { fields: { name: string }[] }>;
  events: Record<string, { fields: { name: string }[] }>;
  states: Record<string, { fields: { name: string }[] }>;
};
