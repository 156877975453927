import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupAction,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenuButton,
  SidebarTrigger,
} from './sidebar';
import { ChevronsUpDown, LucideIcon, Plus } from 'lucide-react';
import { SidebarNewFileDropdown } from './new-file-dropdown';
import { TreeView } from '../tree-view/tree-view';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { ResizablePanel, ResizablePanelGroup } from '../resizable/resizable';
import { NoFilesResults } from './no-files-results';
import { FileTreeState } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../wrappers/command-dispatch-provider';
import { UserDropdown } from '@xspecs/design-system/src/ui/sidebar/user-dropdown';
import { Avatar, AvatarFallback, AvatarImage } from '../avatar/avatar';

export interface IdeSidebarProps {
  addNewFile: (type: { label: string; value: string; icon: LucideIcon }) => void;
  structure: NodeModel[];
  user: {
    name: string;
    email: string;
    avatar: string;
  };
  collapsed?: boolean;
  collapse?: () => void;
  onSelectFile: (file: NodeModel) => void;
  filesTreeState: FileTreeState;
  logout: () => void;
}

export const IdeSidebar = (props: IdeSidebarProps) => {
  const { filesTreeState, user, collapsed, collapse, onSelectFile, structure = [], logout } = props;
  const [openAccordions, setOpenAccordions] = useState([true, false, false]);
  const filePanelRef = useRef<ImperativePanelHandle>(null);
  const structurePanelRef = useRef<ImperativePanelHandle>(null);
  const changesPanelRef = useRef<ImperativePanelHandle>(null);

  const { dispatchCommand } = useCommandDispatch();

  const handleToggle = (index) => {
    const newOpenAccordions = [...openAccordions];
    newOpenAccordions[index] = !newOpenAccordions[index];
    setOpenAccordions(newOpenAccordions);
  };

  useEffect(() => {
    const refs = [filePanelRef, structurePanelRef, changesPanelRef];
    const totalOpen = openAccordions.filter((isOpen) => isOpen).length;

    refs.forEach((ref, index) => {
      const panel = ref.current;
      if (!panel) return;
      if (openAccordions[index]) {
        if (totalOpen === 1) {
          panel.resize(100);
        } else if (totalOpen === 2) {
          panel.resize(50);
        } else if (totalOpen === 3) {
          panel.resize(33);
        }
      }
    });
  }, [openAccordions, filePanelRef, structurePanelRef, changesPanelRef]);

  const onRenameFilesTreeItem = useCallback(
    (id: string, name: string) => {
      dispatchCommand(filesTreeState.onRenameItem.command, {
        id,
        value: name,
      });
    },
    [dispatchCommand, filesTreeState.onRenameItem.command],
  );

  const onDeleteFilesTreeItem = useCallback(
    (id: string) => {
      dispatchCommand(filesTreeState.onDeleteItem.command, {
        id,
      });
    },
    [dispatchCommand, filesTreeState.onDeleteItem.command],
  );

  return (
    <Sidebar collapsed={collapsed}>
      <SidebarContent className="group-data-[collapsible=icon]:gap-0">
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel ref={filePanelRef}>
            <SidebarGroup className="h-full group-data-[collapsible=icon]:h-auto">
              <SidebarGroupLabel>Files</SidebarGroupLabel>
              <SidebarGroupAction
                title="Add File"
                className="group-data-[collapsible=icon]:hidden mb-auto justify-center items-center"
                asChild
              >
                <div>
                  <SidebarNewFileDropdown
                    trigger={<Plus size="16" className="flex-shrink-0 text-secondary -ml-8" />}
                    side="bottom"
                    align="start"
                    options={filesTreeState.addItemsMenu}
                  />
                  <SidebarTrigger onClick={collapse} className="flex-shrink-0" />
                </div>
              </SidebarGroupAction>
              <SidebarGroupContent>
                {filesTreeState.items.length > 0 ? (
                  <TreeView
                    rootId={filesTreeState.rootId}
                    files={filesTreeState.items}
                    onRenameItem={onRenameFilesTreeItem}
                    onSelectFile={onSelectFile}
                    onDeleteItem={onDeleteFilesTreeItem}
                  />
                ) : (
                  <NoFilesResults addMenuItems={filesTreeState.addItemsMenu} />
                )}
              </SidebarGroupContent>
            </SidebarGroup>
          </ResizablePanel>
          {/*<ResizableHandle*/}
          {/*  className="group-data-[collapsible=icon]:hidden bg-transparent"*/}
          {/*  disabled={!openAccordions[1]}*/}
          {/*/>*/}
          {/*<ResizablePanel*/}
          {/*  className="group-data-[collapsible=icon]:hidden"*/}
          {/*  style={{ minHeight: '48px' }}*/}
          {/*  defaultSize={0}*/}
          {/*  ref={structurePanelRef}*/}
          {/*>*/}
          {/*  <SidebarGroup className="h-full self-end mt-auto p-0">*/}
          {/*    <SidebarGroupContent>*/}
          {/*      <Accordion type="multiple">*/}
          {/*        <AccordionItem value="item-1" className="px-4">*/}
          {/*          <AccordionTrigger onClick={() => handleToggle(1)}>Structure</AccordionTrigger>*/}
          {/*          <AccordionContent>*/}
          {/*            <TreeView*/}
          {/*              rootId={'structure'}*/}
          {/*              files={structure}*/}
          {/*              onRenameItem={() => {}}*/}
          {/*              onSelectFile={() => {}}*/}
          {/*              onDeleteItem={() => {}}*/}
          {/*            />*/}
          {/*          </AccordionContent>*/}
          {/*        </AccordionItem>*/}
          {/*      </Accordion>*/}
          {/*    </SidebarGroupContent>*/}
          {/*  </SidebarGroup>*/}
          {/*</ResizablePanel>*/}
          {/*<ResizableHandle*/}
          {/*  className="group-data-[collapsible=icon]:hidden bg-transparent"*/}
          {/*  disabled={!openAccordions[2]}*/}
          {/*/>*/}
          {/*<ResizablePanel*/}
          {/*  className="group-data-[collapsible=icon]:hidden"*/}
          {/*  style={{ minHeight: '48px' }}*/}
          {/*  defaultSize={0}*/}
          {/*  ref={changesPanelRef}*/}
          {/*>*/}
          {/*  <SidebarGroup className="h-full p-0">*/}
          {/*    <SidebarGroupContent>*/}
          {/*      <Accordion type="multiple">*/}
          {/*        <AccordionItem value="item-1" className="px-4">*/}
          {/*          <AccordionTrigger onClick={() => handleToggle(2)}>Changes</AccordionTrigger>*/}
          {/*          <AccordionContent>*/}
          {/*            <TreeView*/}
          {/*              rootId={'0'}*/}
          {/*              files={filesTreeState.items} // TODO: Do we need this?*/}
          {/*              onRenameItem={() => {}}*/}
          {/*              onSelectFile={() => {}}*/}
          {/*              onDeleteItem={() => {}}*/}
          {/*            />*/}
          {/*          </AccordionContent>*/}
          {/*        </AccordionItem>*/}
          {/*      </Accordion>*/}
          {/*    </SidebarGroupContent>*/}
          {/*  </SidebarGroup>*/}
          {/*</ResizablePanel>*/}
        </ResizablePanelGroup>
      </SidebarContent>
      <SidebarFooter>
        <UserDropdown
          logout={logout}
          trigger={
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-accent data-[state=open]:text-accent-foreground rounded-none px-4 group-data-[collapsible=icon]:p-0 h-16"
            >
              <Avatar className="h-8 w-8 rounded-lg overflow-hidden">
                <AvatarImage src={user.avatar} alt={user.name} />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          }
        />
      </SidebarFooter>
    </Sidebar>
  );
};
