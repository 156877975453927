import { Allotment, AllotmentHandle } from 'allotment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Sidebar } from '../../components/spaces/sidebar/sidebar';
import { useAuth } from '../../auth';
import { Spaces } from '../../components/spaces/spaces';
import { CreateSpaceModal } from '../../pages/create-space-modal/create-space-modal';
import { SpaceRenameModal } from '../../components/space-rename-modal/space-rename-modal';
import { SpaceDeleteModal } from '../../components/space-delete-modal/space-delete-modal';
import { SpacesSidebarLinkValues } from '../../components/spaces/sidebar/list/links-list';
import { TopMenu } from '@xspecs/design-system';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { isAutoUser } from '../../lib/utils';
import { useMenuItemSettings } from '../../hooks/use-menu-item-settings';
import { RoutePaths } from '../../config/route-paths/route-paths';

export const SpacesRoute = () => {
  const ref = useRef<AllotmentHandle>(null);
  const { user } = useAuth();
  const [collapse, setCollapse] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState(SpacesSidebarLinkValues.All);

  const auth = useAuth();
  const logout = auth?.logout;

  const { organization } = useActiveOrganization();
  const menuItemSettings = useMenuItemSettings();

  const defaultSizes = useMemo(() => {
    const DEFAULT = [300, 2000];
    const x = JSON.parse(localStorage.getItem('spaces-allotments') ?? 'null');
    if (!x || x.length !== DEFAULT.length) localStorage.setItem('spaces-allotments', JSON.stringify(DEFAULT));
    return x ?? DEFAULT;
  }, []);

  const onDragEnd = useCallback((sizes: number[]) => {
    localStorage.setItem('spaces-allotments', JSON.stringify(sizes));
  }, []);

  const handleLogout = useCallback(() => {
    logout?.({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <div className="w-full h-full">
      <Allotment
        separator
        defaultSizes={defaultSizes}
        proportionalLayout={true}
        vertical={false}
        ref={ref}
        onDragEnd={onDragEnd}
      >
        <Allotment.Pane minSize={collapse ? 64 : 300} maxSize={collapse ? 64 : 1000}>
          <Sidebar
            profile={user}
            collapsed={collapse}
            onCollapse={() => setCollapse(!collapse)}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        </Allotment.Pane>
        <Allotment.Pane>
          <TopMenu
            hasApps={isAutoUser()}
            organizationName={organization?.name ?? ''}
            hideLabels
            logout={handleLogout}
            menuItemSettingsActions={menuItemSettings}
            hideCodeActions
          />
          <Spaces type={selectedView} />
        </Allotment.Pane>
      </Allotment>
      <CreateSpaceModal />
      <SpaceRenameModal />
      <SpaceDeleteModal />
    </div>
  );
};
