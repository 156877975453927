import { EntityBase } from '../EntityBase';
import { ScriptConfig } from './ScriptConfig';
import { Local, LocalStoreProvider, localStoreProvider } from '../../data/LocalStoreProvider';
import { EntityType } from '../EntityType';
import { logger } from '@xspecs/logger';
import { Position } from '../../types';
import { ConstructBase } from '../constructs/ConstructBase';
import { Z_INDEXES } from '../../ZIndexes';
import { Action } from '../constructs/Action';
import { Interface } from '../constructs/Interface';
import { Moment } from '../constructs/Moment';
import { Thread } from '../threads/Thread';
import {
  FrameGroup as FrameGroupConfig,
  LaneGroup as LaneGroupConfig,
  Lane as LaneConfig,
  AllowedAction,
} from 'narrative-studio-sdk';
import { sid } from '@xspecs/short-id';
import { scriptBaseSchema } from './ScriptBaseSchema';

export enum DropState {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  NEUTRAL = 'NEUTRAL',
  DEFAULT = 'DEFAULT',
}

export type DropStates = Record<string, DropState>;

type Lane = {
  id: string;
  label: string;
  height: number;
  color: string;
};

type Frame = {
  id: string;
  width: number;
  entities: EntityBase[][];
  label: string;
};

type LaneGroup = {
  configIndex: number;
  laneIds: string[];
  label: string;
};

type FrameGroup = {
  configIndex: number;
  frameIds: string[];
  label: string;
  type?: string;
};

type Cell = {
  x: number;
  y: number;
  width: number;
  height: number;
  id: string;
};

interface ErrorMetaData {
  entity: EntityBase;
  lane: Lane;
  laneIndex: number;
  frame: Frame;
  frameIndex: number;
  isNarrativeScriptEntity: boolean;
  isActionScriptEntity: boolean;
  isNarrativeScript: boolean;
  isActionScript: boolean;
  laneConfig: LaneGroupConfig | LaneConfig;
  conflictGroup: (EntityType | string)[];
  scriptType: string;
}

export abstract class ScriptBase extends EntityBase {
  static version = '1.0.4'; // Re-structuring of lane and frames and the addition of laneGroups and frameGroups

  static LANE_PADDING = 80;

  public isExpanded: boolean; // TODO deprecated, remove
  public isOpen: boolean;
  public lanes: Lane[] = [];
  public frames: Frame[] = [];
  public laneGroups: LaneGroup[] = [];
  public frameGroups: FrameGroup[] = [];

  public isVisible = false; // override EntityBase.isVisible for scripts

  private validationResult: { targetFrameIndex: number; targetLaneIndex: number; message?: string } | null = null;

  public popValidationResult() {
    const res = { ...this.validationResult };
    this.validationResult = null;
    return res;
  }

  static parse<T extends ScriptBase>(this: new (...args: any[]) => T, data: unknown): T {
    const validatedData = super.parseBase(data, scriptBaseSchema) as T;
    const script = super.parseBase(validatedData) as T;
    script.frames = validatedData.frames?.map((frame) => ({
      id: frame.id,
      width: frame.width,
      entities: frame.entities.map((lane: any) =>
        lane.entities.map((entityData) => EntityBase.abstractBaseSchema.parse(entityData)),
      ),
      label: frame.label,
    }));
    return script;
  }

  isValid(): boolean {
    return scriptBaseSchema.safeParse(this).success;
  }

  abstract get config(): ScriptConfig;

  static references = ['frames'];

  protected static excludedProperties = new Set([...Array.from(EntityBase.excludedProperties), 'lanePadding']);

  public applyError(error: string, ignoreMessage = false): boolean {
    if (!ignoreMessage) {
      super.applyError(error);
    }
    return false;
  }

  public calculatedHeight(): number {
    return this.lanes.reduce((totalHeight, lane) => totalHeight + lane.height, 0);
  }

  public ingestEntity(entity: EntityBase, frameIndex: number, laneIndex: number, validate = false): boolean {
    if (entity.type === EntityType.Thread) {
      this.ingestThread(entity as Thread);
      return true;
    }
    if (entity.type === EntityType.Capability) {
      return this.applyError(SCRIPT_MESSAGES.CAPABILITY_NOT_ALLOWED_IN_SCRIPT);
    }
    if (laneIndex >= this.lanes.length) {
      return this.applyError('Lane not found.');
    }
    let frame = this.frames[frameIndex];
    if (!frame) {
      return this.applyError('Frame not found.');
    }
    const hasAutoIngest = this.hasAutoIngestInCorrectLane(laneIndex);
    const laneGroupConfig = this.findLaneGroupConfigByLaneIndex(laneIndex);
    if (
      laneGroupConfig?.laneAlignmentFrameIndex !== undefined &&
      frameIndex < laneGroupConfig.laneAlignmentFrameIndex
    ) {
      return false;
    }
    if (!laneGroupConfig) {
      throw new Error('LaneGroup not found.');
    }
    const laneConfig =
      laneGroupConfig?.lanes?.find((lane) => ScriptConfig.isEntityAllowed(lane, entity.type)) ?? laneGroupConfig;

    if (!laneConfig || !ScriptConfig.isEntityAllowed(laneConfig, entity.type)) {
      const error = this.entityNotAllowedInFrameError(this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig));
      return this.applyError(error.message);
    }
    if (!hasAutoIngest) {
      const conflictGroup = this.getConflictingEntitiesInFrame(this.config, entity, frameIndex, laneIndex);
      if (conflictGroup) {
        const error = this.entityConflictError(
          this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig, conflictGroup),
        );
        return this.applyError(error.message, true);
      }
    }
    let correctLaneIngestIndex: number | null = null;
    let correctFrameIngestIndex: number | null = null;
    if (hasAutoIngest) {
      const autoIngestPosition = this.calculateAutoIngestPosition(
        frameIndex,
        correctFrameIngestIndex,
        entity,
        frame,
        correctLaneIngestIndex,
        laneIndex,
      );
      correctFrameIngestIndex = autoIngestPosition.correctFrameIngestIndex;
      correctLaneIngestIndex = autoIngestPosition.correctLaneIngestIndex;
    } else {
      correctLaneIngestIndex = laneIndex;
      correctFrameIngestIndex = frameIndex;
    }
    if (correctLaneIngestIndex !== null && correctFrameIngestIndex !== null) {
      frame = this.frames[correctFrameIngestIndex];
      if (
        frame.entities[correctLaneIngestIndex].filter((e) => e.id != entity.id).length >=
        (laneConfig.entityLimits?.max ?? 1)
      ) {
        const error = this.maxEntitiesError(this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig));
        return this.applyError(error.message, true);
      }
      const entities = validate
        ? frame.entities[correctLaneIngestIndex].filter((p) => p.id != entity.id)
        : frame.entities[correctLaneIngestIndex];
      if (validate) {
        this.validationResult = {
          targetLaneIndex: correctLaneIngestIndex,
          targetFrameIndex: correctFrameIngestIndex,
        };
        return true;
      } else {
        entities.push(entity);
        entity.parent = this;
        entity.zIndex = Z_INDEXES.ConstructInsideFrame;
      }
      this.resetError();
      this.handleError({
        message: SCRIPT_MESSAGES.OK,
        hasError: false,
        laneIndex: correctLaneIngestIndex,
        final: true,
      });
      return true;
    }
    const error = this.entityNotAllowedInFrameError(this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig));
    return this.applyError(error.message, true);
  }

  private calculateAutoIngestPosition(
    frameIndex: number,
    correctFrameIngestIndex: number | null,
    entity: EntityBase,
    frame: Frame,
    correctLaneIngestIndex: number | null,
    laneIndex: number,
  ): { correctFrameIngestIndex: number | null; correctLaneIngestIndex: number | null } {
    if (this.hasAutoIngestInCorrectLane(laneIndex)) {
      for (let f = frameIndex; f < this.frames.length; f++) {
        if (correctFrameIngestIndex !== null) break;
        if (!this.getConflictingEntitiesInFrame(this.config, entity, f, laneIndex)) {
          for (let l = 0; l < frame.entities.length; l++) {
            const langeGroupConfig = this.findLaneGroupConfigByLaneIndex(l);
            if (!langeGroupConfig) {
              throw new Error('LaneGroup not found.');
            }
            const laneConfig = langeGroupConfig?.lanes?.[l] || langeGroupConfig;
            if (ScriptConfig.isEntityAllowed(laneConfig, entity.type)) {
              correctLaneIngestIndex = l;
              correctFrameIngestIndex = f;
              break;
            }
          }
        }
      }
    } else {
      correctLaneIngestIndex = laneIndex;
      correctFrameIngestIndex = frameIndex;
    }
    return { correctFrameIngestIndex, correctLaneIngestIndex };
  }

  eject(entity: EntityBase, nullifyParent = true): boolean {
    if (entity.type === EntityType.Thread) {
      this.ejectThread(entity as Thread);
      return true;
    }

    let found = false;
    let targetLane: Lane | undefined = undefined;
    this.frames.forEach((lanes) => {
      lanes.entities.forEach((entities, laneIndex) => {
        const index = entities.findIndex((e) => e.id === entity.id);
        if (index !== -1) {
          entities.splice(index, 1);
          if (nullifyParent) {
            entity.parent = null!;
          }
          found = true;
          targetLane = this.lanes[laneIndex];
        }
      });
    });

    if (found && targetLane) {
      this.resetError();
      return true;
    }
    return this.applyError(SCRIPT_MESSAGES.ENTITY_NOT_FOUND);
  }

  private getConflictingEntitiesInFrame(
    config: ScriptConfig,
    entity: EntityBase,
    frameIndex: number,
    laneIndex: number,
  ): (EntityType | string)[] | null {
    const frame = this.frames[frameIndex];
    if (!frame) {
      logger.error('Frame not found.');
      return null;
    }
    const frameEntities = frame.entities.flat();
    if (!frameEntities) {
      logger.error('Lane not found.');
      return null;
    }
    const laneGroupConfig = this.findLaneGroupConfigByLaneIndex(laneIndex);
    if (!laneGroupConfig) {
      logger.error('LaneGroup not found.');
      return null;
    }
    for (const conflictingGroup of laneGroupConfig.conflictingEntityGroups || []) {
      const normalizedGroup = conflictingGroup.map((conflictingEntity) =>
        typeof conflictingEntity === 'string' ? conflictingEntity : conflictingEntity.type,
      );
      if (normalizedGroup.includes(entity.type)) {
        if (frameEntities.some((e) => normalizedGroup.includes(e.type) && e.id !== entity.id)) {
          return normalizedGroup;
        }
      }
    }
    return null;
  }

  public resetDropStates(): void {
    this.frames.forEach((lanes) => {
      lanes.entities.forEach((entities, laneIndex) => {
        this.setDropState(laneIndex, DropState.DEFAULT);
      });
    });
    this.notify();
  }

  @Local
  get dropStates(): DropStates {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'dropStates'));
  }

  set dropStates(newValue: DropStates) {
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'dropStates'), newValue);
  }

  /**
   * Gets the number of frames in the ScriptBase.
   * @returns The total number of frames.
   */
  get length(): number {
    return this.frames.length;
  }

  /**
   * Adds a new frame to the script at a specified or the next available index.
   * If a frame already exists at the specified index, the existing frames are shifted to the right.
   * @param frameIndex Optional. The index at which to add the new frame.
   * @param frameGroupIndex The index of the frame group to which the frame belongs.
   * @param skipPermissionsCheck Optional. If true, skips the permissions check.
   * @§param returnId Optional. If true, returns the id of the newly added frame.
   * @returns true if the frame was successfully added; otherwise, false.
   */
  addFrame(frameIndex?: number, frameGroupIndex: number = 0, skipPermissionsCheck: boolean = false): boolean {
    if (frameGroupIndex < 0 || frameGroupIndex >= this.frameGroups.length) {
      return this.applyError(`Invalid frameGroupIndex: ${frameGroupIndex}.`);
    }
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup at index ${frameGroupIndex} does not exist.`);
    }
    const configFrameGroup = this.config.frameGroups[frameGroup.configIndex];
    if (
      !skipPermissionsCheck &&
      !this.hasPermission(configFrameGroup.allowedActions?.actions || [], AllowedAction.ADD)
    ) {
      return this.applyError(`Frames addition not allowed for this frame group.`);
    }
    const newFrameIndex = frameIndex ?? this.frames.length;
    if (newFrameIndex < 0 || newFrameIndex > this.frames.length) {
      return this.applyError(
        `Invalid frame index: ${newFrameIndex}. Cannot be negative or greater than the number of frames`,
      );
    }
    const newFrameId = sid();
    const newFrame: Frame = {
      id: newFrameId,
      width: configFrameGroup.defaultFrameWidth ?? this.config.defaultFrameWidth,
      entities: Array(this.lanes.length)
        .fill(null)
        .map(() => []),
      label: '',
    };
    let frameGroupInsertIndex = frameGroup.frameIds.length;
    for (let i = 0; i < frameGroup.frameIds.length; i++) {
      const existingFrameId = frameGroup.frameIds[i];
      const existingFrameIndex = this.frames.findIndex((f) => f.id === existingFrameId);
      if (existingFrameIndex >= newFrameIndex) {
        frameGroupInsertIndex = i;
        break;
      }
    }
    frameGroup.frameIds.splice(frameGroupInsertIndex, 0, newFrameId);
    this.frames.splice(newFrameIndex, 0, newFrame);
    this.initializeLanesForFrame(newFrameIndex);
    this.reorderFramesToMatchFrameGroupsOrder();
    return true;
  }

  addFrameById(frameId: string, position: 'before' | 'after'): boolean {
    const frameGroupIndex = this.frameGroups.findIndex((fg) => fg.frameIds.includes(frameId));
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup at index ${frameGroupIndex} does not exist.`);
    }
    const configFrameGroup = this.config.frameGroups[frameGroup.configIndex];
    if (!this.hasPermission(configFrameGroup.allowedActions?.actions || [], AllowedAction.ADD)) {
      return this.applyError(`Frames addition not allowed for this frame group.`);
    }
    const frameIndex = this.frames.findIndex((f) => f.id === frameId);
    if (frameIndex === -1) {
      return this.applyError(`Frame with id ${frameId} not found.`);
    }
    const newFrameIndex = position === 'before' ? frameIndex : frameIndex + 1;
    const newFrameId = sid();
    const newFrame: Frame = {
      id: newFrameId,
      width: configFrameGroup.defaultFrameWidth ?? this.config.defaultFrameWidth,
      entities: Array(this.lanes.length)
        .fill(null)
        .map(() => []),
      label: '',
    };
    const frameGroupLocalIndex = frameGroup.frameIds.indexOf(frameId);
    if (frameGroupLocalIndex === -1) {
      return this.applyError(`Frame ID ${frameId} not found in its frame group.`);
    }
    const targetFrameGroupIndex = position === 'before' ? frameGroupLocalIndex : frameGroupLocalIndex + 1;
    frameGroup.frameIds.splice(targetFrameGroupIndex, 0, newFrameId);
    this.frames.splice(newFrameIndex, 0, newFrame);
    this.initializeLanesForFrame(newFrameIndex);
    this.reorderFramesToMatchFrameGroupsOrder();
    return true;
  }

  removeFrame(frameId: string): boolean {
    const frame = this.frames.find((frame) => frame.id === frameId);
    if (!frame) {
      return this.applyError(`Frame with id ${frameId} not found.`);
    }
    if (this.frames.length === 1) {
      return this.applyError('The last frame cannot be deleted.');
    }
    const frameGroup = this.frameGroups.find((fg) => fg.frameIds.includes(frameId));
    if (!frameGroup) {
      return this.applyError(`FrameGroup for frame ${frameId} not found.`);
    }
    this.frames = this.frames.filter((f) => f.id !== frameId);
    frameGroup.frameIds = frameGroup.frameIds.filter((fi) => fi !== frameId);
    if (frameGroup.frameIds.length === 0) {
      this.removeFrameGroup(this.frameGroups.findIndex((fg) => fg === frameGroup));
    }
    this.reorderFramesToMatchFrameGroupsOrder();
    return true;
  }

  removeFrameGroup(frameGroupIndex: number): boolean {
    if (frameGroupIndex < 0 || frameGroupIndex >= this.frameGroups.length) {
      return this.applyError(`FrameGroup with index ${frameGroupIndex} not found.`);
    }
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup with index ${frameGroupIndex} not found.`);
    }
    if (
      !this.hasPermission(
        this.config.frameGroups[frameGroup.configIndex].allowedActions?.actions || [],
        AllowedAction.REMOVE,
      )
    ) {
      return this.applyError(`FrameGroup deletion not allowed.`);
    }
    const frameIdsToRemove = [...frameGroup.frameIds];
    for (const frameId of frameIdsToRemove) {
      const frameIndex = this.frames.findIndex((frame) => frame.id === frameId);
      if (frameIndex !== -1) {
        this.frames.splice(frameIndex, 1);
      }
    }
    this.frameGroups.splice(frameGroupIndex, 1);
    this.reorderFramesToMatchFrameGroupsOrder();
    return true;
  }

  /**
   * Retrieves all frames in a sorted order along with their contained lanes and entities.
   * @returns An array of objects, each representing a frame with its index, lanes, and entities.
   */
  getFrames(): { frameIndex: number; lanes: { laneIndex: number; entities: EntityBase[] }[] }[] {
    const sortedFrames = Array.from(this.frames.entries()).sort((a, b) => a[0] - b[0]);
    return sortedFrames.map(([frameIndex, lanes]) => {
      const sortedLanes = Array.from(lanes.entities.entries()).sort((a, b) => a[0] - b[0]);
      return {
        frameIndex,
        lanes: sortedLanes.map(([laneIndex, entities]) => ({
          laneIndex,
          entities,
        })),
      };
    });
  }

  /**
   * Retrieves a specific frame and its lanes and entities by frame index.
   * @param frameIndex The index of the frame to retrieve.
   * @returns An object representing the frame, if found, with its index and details of its lanes and entities; otherwise, undefined if the frame does not exist.
   */
  getFrame(
    frameIndex: number,
  ): { frameIndex: number; width: number; lanes: { laneIndex: number; entities: EntityBase[] }[] } | undefined {
    const frame = this.frames[frameIndex];
    if (!frame) {
      return undefined;
    }

    const lanes = Array.from(frame.entities.entries()).sort((a, b) => a[0] - b[0]);
    return {
      frameIndex,
      width: frame.width,
      lanes: lanes.map(([laneIndex, entities]) => ({
        laneIndex,
        entities,
      })),
    };
  }

  getFramesFromFrameGroup(frameGroupIndex: number): Frame[] {
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return [];
    }
    return frameGroup.frameIds.map((frameId) => this.frames.find((frame) => frame.id === frameId)!);
  }

  /**
   * Adds a new lane to all frames, optionally at a specified index.
   * If the specified index is greater than the last index, appends a new lane.
   * Each new lane is initially empty.
   * @param laneId The id of the lane from which a new lane will be created based on the position
   * @param position The position of the new lane relative to the specified lane.
   * @returns true if the lane was successfully added; otherwise, false.
   */
  addLane(laneId: string, position: 'before' | 'after'): boolean {
    const lane = this.lanes.find((lane) => lane.id === laneId);
    if (!lane) {
      return this.applyError(`Lane not found.`);
    }

    const laneIndex = this.lanes.findIndex((lane) => lane.id === laneId);
    const laneGroup = this.laneGroups.find((lg) => lg.laneIds.includes(laneId));
    if (!laneGroup) {
      return this.applyError(`LaneGroup for lane ${laneId} not found.`);
    }
    const laneGroupConfig = this.config.laneGroups[laneGroup.configIndex];
    if (!ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.ADD)) {
      return this.applyError(`Lanes addition not allowed.`);
    }
    const laneGroupPosition = laneGroup.laneIds.indexOf(laneId);
    if (laneGroupPosition === -1) {
      return this.applyError(`Lane ${laneId} not found in its lane group.`);
    }
    const newLaneId = sid();
    const newLane: Lane = {
      id: newLaneId,
      label: '',
      height: laneGroupConfig.defaultLaneHeight ?? this.config.defaultLaneHeight,
      color: laneGroupConfig.style?.backgroundColor ?? this.config.defaultLaneColor,
    };
    const targetLaneIndex = position === 'before' ? laneIndex : laneIndex + 1;
    const targetGroupIndex = position === 'before' ? laneGroupPosition : laneGroupPosition + 1;
    this.lanes.splice(targetLaneIndex, 0, newLane);
    // Insert the new lane **only in the affected lane group**
    laneGroup.laneIds.splice(targetGroupIndex, 0, newLaneId);
    // Insert an empty lane row in each frame **only for the affected lane group**
    this.frames.forEach((frame) => {
      frame.entities.splice(targetLaneIndex, 0, []);
    });
    return true;
  }

  /**
   * Renames a lane in all frames based on the given lane index.
   * @param laneId The id of the lane to rename.
   * @param value The new value for the lane.
   * @returns true if the lane was successfully renamed; otherwise, false.
   */
  renameLane(laneId: string, value: string): boolean {
    const lane = this.lanes.find((lane) => lane.id === laneId);
    if (!lane) {
      return this.applyError(`Lane not found.`);
    }
    const laneGroup = this.findLaneGroupByLaneId(laneId);
    if (!laneGroup) {
      return this.applyError(`LaneGroup for lane ${laneId} not found.`);
    }
    const laneGroupConfig = this.config.laneGroups[laneGroup.configIndex];
    if (!ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.UPDATE)) {
      return this.applyError(`Lanes renaming not allowed for ${this?.parent?.type}.`);
    }
    lane.label = value;
    return true;
  }

  /**
   * Removes a lane from all frames based on the given lane id.
   * @param laneId The id of the lane to remove.
   * @returns true if the lane was successfully removed; otherwise, false.
   */
  removeLane(laneId: string): boolean {
    const lane = this.lanes.find((lane) => lane.id === laneId);
    if (!lane) {
      return this.applyError(`Lane not found.`);
    }
    if (this.lanes.length === 1) {
      return this.applyError('The last lane cannot be deleted.');
    }
    const laneIndex = this.lanes.findIndex((lane) => lane.id === laneId);
    const laneGroup = this.findLaneGroupByLaneIndex(laneIndex);
    if (!laneGroup) {
      return this.applyError(`LaneGroup for lane ${laneIndex} not found.`);
    }
    if (
      !this.hasPermission(
        this.config.laneGroups[laneGroup.configIndex].allowedActions?.actions || [],
        AllowedAction.REMOVE,
      )
    ) {
      return this.applyError(`Lanes deletion not allowed for ${this?.parent?.type}.`);
    }
    this.frames.forEach((frame) => {
      frame.entities.splice(laneIndex, 1);
    });
    this.lanes.splice(laneIndex, 1);
    laneGroup.laneIds = laneGroup.laneIds.filter((lid) => lid !== laneId);
    this.reorderFramesToMatchFrameGroupsOrder();
    return true;
  }

  addLaneGroup(laneGroup: LaneGroup): boolean {
    this.laneGroups.push(laneGroup);
    return true;
  }

  removeLaneGroup(index: number): boolean {
    if (index < 0 || index >= this.laneGroups.length) {
      return this.applyError(`LaneGroup with index ${index} not found.`);
    }
    for (const laneId of this.laneGroups[index].laneIds) {
      const laneIndex = this.lanes.findIndex((lane) => lane.id === laneId);
      if (laneIndex !== -1) {
        this.removeLane(laneId);
      }
    }
    this.laneGroups.splice(index, 1);
    return true;
  }

  addFrameGroup(frameGroupIndex: number, position: 'before' | 'after', type?: string): boolean {
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup config with index ${frameGroupIndex} not found.`);
    }
    const frameGroupConfig = this.config.frameGroups[frameGroup.configIndex];
    if (!frameGroupConfig) {
      return this.applyError(`FrameGroup config with index ${frameGroupIndex} not found.`);
    }
    if (!this.hasPermission(frameGroupConfig.allowedActions?.actions || [], AllowedAction.ADD)) {
      return this.applyError(`FrameGroup addition not allowed.`);
    }
    const targetGroupIndex = this.frameGroups.findIndex((fg) => fg === frameGroup);
    if (targetGroupIndex === -1) {
      return this.applyError(`Target FrameGroup with configIndex ${frameGroupIndex} not found.`);
    }
    const targetFrameGroup = this.frameGroups[targetGroupIndex];
    const label = this.getFrameGroupLabelFromSelectionMenu(frameGroupConfig, type) ?? targetFrameGroup.label;
    const insertGroupIndex = position === 'before' ? targetGroupIndex : targetGroupIndex + 1;
    const newFrameGroup: FrameGroup = {
      configIndex: targetFrameGroup.configIndex,
      frameIds: [],
      label: label,
      type: type,
    };
    this.frameGroups.splice(insertGroupIndex, 0, newFrameGroup);
    frameGroupConfig.frames?.forEach((frame, frameIndex) => {
      this.addFrame(frameIndex, insertGroupIndex, true);
    });
    this.reorderFramesToMatchFrameGroupsOrder();
    this.recalculateDimensions();
    return true;
  }

  updateFrameGroupType(frameGroupIndex: number, type: string) {
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup with index ${frameGroupIndex} not found.`);
    }
    frameGroup.type = type;
    frameGroup.label =
      this.getFrameGroupLabelFromSelectionMenu(this.config.frameGroups[frameGroup.configIndex], type) ?? '';
  }

  renameFrameGroup(frameGroupIndex: number, value: string): boolean {
    const frameGroup = this.frameGroups[frameGroupIndex];
    if (!frameGroup) {
      return this.applyError(`FrameGroup with index ${frameGroupIndex} not found.`);
    }
    const frameGroupConfig = this.config.frameGroups[frameGroup.configIndex];
    if (!ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.UPDATE)) {
      return this.applyError(`FrameGroup renaming not allowed.`);
    }
    frameGroup.label = value;
    return true;
  }

  private getFrameGroupLabelFromSelectionMenu(frameGroupConfig: FrameGroupConfig, type?: string): string | undefined {
    if (!type) {
      return undefined;
    }
    return frameGroupConfig.typeSelectMenu?.find((v) => v.type === type)?.frameGroupLabel?.text;
  }

  getEntitiesInLane(laneIndex: number): EntityBase[] {
    return (
      this.frames.reduce((entities, frame) => {
        return entities.concat(frame.entities[laneIndex]);
      }, [] as EntityBase[]) ?? []
    );
  }

  getEntitiesInFrame(frameIndex: number) {
    return this.frames[frameIndex].entities.flat();
  }

  getEntitiesInFrameById(frameId: string) {
    const frame = this.frames.find((f) => f.id === frameId);
    return frame ? frame.entities.flat() : [];
  }

  getCells(): Cell[][] {
    return this.frames.map((frame, frameIndex) =>
      frame.entities.map((lane, laneIndex) => ({
        x: this.getFrameAbsolutePosition(frameIndex).x,
        y: this.getLaneAbsolutePosition(frameIndex, laneIndex).y,
        width: frame.width,
        height: this.lanes[laneIndex].height,
        id: `${frameIndex}_${laneIndex}`,
      })),
    );
  }

  getIngestPosition(frameIndex: number, laneIndex: number): Position {
    const framePos = this.getFrameAbsolutePosition(frameIndex);
    const lanePos = this.getLaneAbsolutePosition(frameIndex, laneIndex);
    return {
      x: framePos.x + 10,
      y: lanePos.y + 10,
    };
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      frames: this.frames.map((frame) => ({
        id: frame.id,
        width: frame.width,
        label: frame.label,
        entities: frame.entities.map((entities) => entities.filter((e) => !!e).map((entity) => entity.serialize(true))),
      })),
      lanes: this.lanes.map((lane) => ({
        id: lane.id,
        label: lane.label,
        height: lane.height,
        color: lane.color,
      })),
      laneGroups: this.laneGroups.map((laneGroup) => ({
        configIndex: laneGroup.configIndex,
        laneIds: [...laneGroup.laneIds],
        label: laneGroup.label,
      })),
      frameGroups: this.frameGroups.map((frameGroup) => ({
        configIndex: frameGroup.configIndex,
        label: frameGroup.label,
        frameIds: [...frameGroup.frameIds],
        type: frameGroup.type,
      })),
    };
  }

  static initialize(entity: ScriptBase, recalculateDimensions = true): void {
    if (!entity.getFrame(0)) {
      entity.config.laneGroups.forEach((laneGroupConfig, laneGroupIndex) => {
        entity.laneGroups.push({ laneIds: [], configIndex: laneGroupIndex, label: laneGroupConfig.label?.text ?? '' });
      });
      entity.config.frameGroups.forEach((frameGroupConfig, frameGroupIndex) => {
        entity.frameGroups.push({
          label: frameGroupConfig.label?.text ?? '',
          frameIds: [],
          configIndex: frameGroupIndex,
          type: frameGroupConfig.defaultType,
        });
        frameGroupConfig.frames?.forEach((_, frameIndex) => {
          entity.addFrame(frameIndex, frameGroupIndex, true);
        });
      });
    }

    entity.reorderFramesToMatchFrameGroupsOrder();

    if (recalculateDimensions) {
      entity.recalculateDimensions();
    }
  }

  private reorderFramesToMatchFrameGroupsOrder(): void {
    const orderedFrameIds = this.frameGroups.flatMap((group) => group.frameIds);
    this.frames.sort((a, b) => {
      return orderedFrameIds.indexOf(a.id) - orderedFrameIds.indexOf(b.id);
    });
  }

  private initializeLanesForFrame(frameIndex: number): void {
    const frame = this.frames[frameIndex];
    if (!frame) {
      return;
    }

    let currentLaneIndex = 0;

    this.laneGroups.forEach((laneGroup) => {
      const laneGroupConfig = this.config.laneGroups[laneGroup.configIndex];

      // Ensure we use **actual lanes present** instead of just the config
      const laneIds = laneGroup.laneIds.length ? laneGroup.laneIds : (laneGroupConfig.lanes ?? []).map(() => sid());

      laneIds.forEach((laneId, index) => {
        const globalLaneIndex = currentLaneIndex + index;

        // Ensure frame.entities has space for new lanes
        while (frame.entities.length <= globalLaneIndex) {
          frame.entities.push([]);
        }

        // Ensure lane exists in `this.lanes`
        if (!this.lanes[globalLaneIndex]) {
          const laneConfig = laneGroupConfig.lanes?.[index] ?? {};
          const newLane: Lane = {
            id: laneId,
            label: laneConfig.label?.text ?? '',
            height: laneConfig.height ?? laneGroupConfig.defaultLaneHeight ?? this.config.defaultLaneHeight,
            color: laneConfig.style?.backgroundColor ?? '#F8F8F8',
          };

          this.lanes[globalLaneIndex] = newLane;
          if (!laneGroup.laneIds.includes(laneId)) {
            laneGroup.laneIds.push(laneId);
          }
        }
      });

      currentLaneIndex += laneIds.length;
    });
  }

  private potentiallyResizeAndPositionFrameEntities(frameIndex: number) {
    const laneSizes = this.determineLanesSizes();
    const frameGroup = this.findFrameGroupByFrameIndex(frameIndex);
    if (!frameGroup) {
      throw new Error(`FrameGroup not found for frameIndex: ${frameIndex}.`);
    }
    const frameGroupConfig = this.config.frameGroups[frameGroup.configIndex];
    const frameSizes = this.determineFrameSizes(frameGroupConfig);
    const frame = this.frames[frameIndex];
    frame.entities.forEach((_, laneIndex) => {
      const laneGroup = this.findLaneGroupConfigByLaneIndex(laneIndex);
      this.lanes[laneIndex].height = Math.max(
        laneSizes[laneIndex] + ScriptBase.LANE_PADDING,
        laneGroup?.defaultLaneHeight ?? this.config.defaultLaneHeight,
      );
      frame.width = frameSizes[frameIndex];
    });
    frame.entities.forEach((entities, laneIndex) => {
      entities.forEach((entity) => {
        if (entity instanceof ConstructBase) this.positionConstructInCellCenter(entity, frameIndex, laneIndex);
      });
    });
  }

  private determineLanesSizes() {
    const laneCount = this.lanes.length;
    const sizes = Array.from({ length: laneCount }, () => 0);
    for (let i = 0; i < laneCount; i++) {
      this.frames.forEach((frame) => {
        const entities = frame.entities[i];
        const max = entities.reduce((acc, entity) => {
          const height = entity ? entity.dimensions().height : 80; // THIS IS NEEDED TEMPORARILY FOR MIGRATION OF DUFF DATA
          return Math.max(acc, height);
        }, 0);
        sizes[i] = Math.max(sizes[i], max);
      });
    }
    return sizes;
  }

  private determineFrameSizes(frameGroupConfig: FrameGroupConfig) {
    const sizes = Array.from(
      { length: this.frames.length },
      () => frameGroupConfig.defaultFrameWidth ?? this.config.defaultFrameWidth,
    );
    this.frames.forEach((frame, frameIndex) => {
      frame.entities.forEach((entities) => {
        const max = entities.reduce((acc, entity) => {
          const width = entity ? entity.width : 120; // THIS IS NEEDED TEMPORARILY FOR MIGRATION OF DUFF DATA
          return Math.max(acc, width);
        }, 0);
        sizes[frameIndex] = Math.max(sizes[frameIndex], max);
      });
    });
    return sizes;
  }

  private getFrameAbsolutePosition(frameIndex: number): Position {
    return {
      x: this.position.x + this.frames.slice(0, frameIndex).reduce((sum, frame) => sum + frame.width, 0),
      y: this.position.y,
    };
  }

  private getLaneAbsolutePosition(frameIndex: number, laneIndex: number): Position {
    return {
      x: this.position.x + this.frames.slice(0, frameIndex).reduce((sum, frame) => sum + frame.width, 0),
      y: this.position.y + this.lanes.slice(0, laneIndex).reduce((sum, lane) => sum + lane.height, 0),
    };
  }

  private positionConstructInCellCenter(entity: ConstructBase, frameIndex: number, laneIndex: number) {
    const [frame, lane] = [this.frames[frameIndex], this.lanes[laneIndex]];
    const dimensions = this.getEntityDimensions(entity);
    const [frameAbsolutePosition, laneAbsolutePosition] = [
      this.getFrameAbsolutePosition(frameIndex),
      this.getLaneAbsolutePosition(frameIndex, laneIndex),
    ];
    const oldPosition = { ...entity.position };
    entity.position = {
      x: frameAbsolutePosition.x + (frame.width - dimensions.width) / 2,
      y: laneAbsolutePosition.y + entity.getTopOffset() + (lane.height - dimensions.height) / 2,
    };
    if (entity.hasRequiredScript) {
      const script = entity.script;
      script.position = {
        x: script.position.x + entity.position.x - oldPosition.x,
        y: script.position.y + entity.position.y - oldPosition.y,
      };
    }
  }

  private getEntityDimensions(entity: EntityBase) {
    if (entity instanceof ConstructBase) {
      return entity.dimensions();
    }
    return { width: entity.width, height: entity.height };
  }

  recalculateDimensions() {
    this.frames.forEach((frame, index) => this.potentiallyResizeAndPositionFrameEntities(index));
    this.updateScriptDimensions();
  }

  private updateScriptDimensions() {
    this.width = this.frames.reduce((totalWidth, frame) => totalWidth + frame.width, 0);
    this.height = this.lanes.reduce((totalHeight, lane) => totalHeight + lane.height, 0);
  }

  private getNextAvailableIndex(): number {
    let maxIndex = -1;
    this.frames.forEach((_, index) => {
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    return maxIndex + 1;
  }

  private setDropState(laneIndex: number, dropState: DropState) {
    this.dropStates = {
      ...this.dropStates,
      [laneIndex]: dropState,
    };
    this.notify();
  }

  private handleError(params: { message: string; hasError: boolean; laneIndex: number; final?: boolean }) {
    const { laneIndex, hasError, final } = params;
    if (!laneIndex) return params;
    if (final) {
      this.resetDropStates();
      return params;
    }
    this.setDropState(laneIndex, hasError ? DropState.NOT_ALLOWED : DropState.ALLOWED);
    return params;
  }

  private getErrorMetaData(
    entity: EntityBase,
    frameIndex: number,
    laneIndex: number,
    laneConfig: LaneGroupConfig | LaneConfig,
    conflictGroup?: (EntityType | string)[],
  ): ErrorMetaData {
    const narrativeScript = this.type === EntityType.NarrativeScript;
    const actionScript = this.type === EntityType.ActionScript;
    const narrativeScriptEntity = entity instanceof Action || entity instanceof Interface || entity instanceof Moment;
    const actionScriptEntity = !narrativeScriptEntity;

    return {
      entity,
      lane: this.lanes[laneIndex],
      laneIndex,
      frame: this.frames[frameIndex],
      frameIndex,
      laneConfig,
      conflictGroup: conflictGroup ?? [],
      isNarrativeScript: narrativeScript,
      isActionScript: actionScript,
      isNarrativeScriptEntity: narrativeScriptEntity,
      isActionScriptEntity: actionScriptEntity,
      scriptType: this.type,
    };
  }

  private entityNotAllowedInFrameError(errorMetaData: ErrorMetaData) {
    const laneIndex =
      this.config.laneGroups
        ?.flatMap((lg) => lg.lanes)
        .findIndex((l) => l && ScriptConfig.isEntityAllowed(l, errorMetaData.entity.type)) ??
      this.config.laneGroups.findIndex((laneGroup) =>
        ScriptConfig.isEntityAllowed(laneGroup, errorMetaData.entity.type),
      );
    if (errorMetaData.isNarrativeScript && errorMetaData.isActionScriptEntity) {
      return this.handleError({
        message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_NARRATIVE_SCRIPT,
        hasError: true,
        laneIndex: laneIndex,
      });
    }
    if (errorMetaData.isActionScript && errorMetaData.isNarrativeScriptEntity) {
      return this.handleError({
        message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_ACTION_SCRIPT,
        hasError: true,
        laneIndex: laneIndex,
      });
    }

    return this.handleError({
      message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_FRAME,
      hasError: true,
      laneIndex: laneIndex,
    });
  }

  private maxEntitiesError(errorMetaData: ErrorMetaData) {
    return this.handleError({
      message: SCRIPT_MESSAGES(errorMetaData).MAX_ENTITIES_ERROR,
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }

  private entityConflictError(errorMetaData: ErrorMetaData) {
    if (errorMetaData.isNarrativeScriptEntity) return this.entityConflictErrorNarrative(errorMetaData);
    else return this.entityConflictErrorAction(errorMetaData);
  }

  private entityConflictErrorNarrative(errorMetaData: ErrorMetaData) {
    if (errorMetaData.frame.entities.flat().find((e) => e.type === errorMetaData.entity.type)) {
      return this.handleError({
        message: SCRIPT_MESSAGES(errorMetaData).MAX_ENTITIES_ERROR,
        hasError: true,
        laneIndex: errorMetaData.laneIndex,
      });
    }

    return this.handleError({
      message: SCRIPT_MESSAGES(errorMetaData).ENTITY_CONFLICT_ERROR(errorMetaData.conflictGroup),
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }

  private entityConflictErrorAction(errorMetaData: ErrorMetaData) {
    return this.handleError({
      // I don't think this error can happen yet, so keeping it simple for now.
      message: 'ActionScript entity conflict error',
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }

  private hasAutoIngestInCorrectLane(laneIndex: number): boolean {
    const laneGroup = this.findLaneGroupConfigByLaneIndex(laneIndex);
    if (!laneGroup) {
      return false;
    }
    return laneGroup.autoIngestInCorrectLane ?? false;
  }

  private findLaneGroupConfigByLaneIndex(laneIndex: number): LaneGroupConfig | null {
    const laneGroup = this.findLaneGroupByLaneIndex(laneIndex);
    if (!laneGroup) {
      return null;
    }
    return this.config.laneGroups[laneGroup.configIndex];
  }

  /**
   * Finds the LaneGroup containing the specified laneIndex.
   * @param laneIndex The index of the lane to locate.
   * @returns The LaneGroup containing the laneIndex, or null if not found.
   */
  private findLaneGroupByLaneIndex(laneIndex: number): LaneGroup | null {
    if (laneIndex < 0 || laneIndex >= this.lanes.length) {
      throw new Error(`Invalid laneIndex: ${laneIndex}.`);
    }
    const laneId = this.lanes[laneIndex]?.id;
    if (!laneId) {
      throw new Error(`Lane at index ${laneIndex} does not have an ID.`);
    }
    const laneGroup = this.laneGroups.find((group) => group.laneIds.includes(laneId));
    return laneGroup ?? null;
  }

  private findLaneGroupByLaneId(laneId: string): LaneGroup | undefined {
    return this.laneGroups.find((group) => group.laneIds.includes(laneId));
  }

  /**
   * Finds the FrameGroup containing the specified frameIndex.
   * @param frameIndex The index of the frame to locate.
   * @returns The FrameGroup containing the frameIndex, or null if not found.
   */
  private findFrameGroupByFrameIndex(frameIndex: number): FrameGroup | null {
    if (frameIndex < 0 || frameIndex >= this.frames.length) {
      throw new Error(`Invalid frameIndex: ${frameIndex}.`);
    }
    const frameId = this.frames[frameIndex]?.id;
    if (!frameId) {
      throw new Error(`Frame at index ${frameIndex} does not have an ID.`);
    }
    const frameGroup = this.frameGroups.find((group) => group.frameIds.includes(frameId));
    return frameGroup ?? null;
  }

  private hasPermission(actions: AllowedAction[], requiredAction: AllowedAction): boolean {
    return actions.some((action) => (action & requiredAction) === requiredAction);
  }
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const SCRIPT_MESSAGES = (errorMetaData: ErrorMetaData) => {
  const vowelRegex = /^[aeiouAEIOU]/;
  const article = vowelRegex.test(errorMetaData.entity.type) ? 'An' : 'A';
  const maxEntities = errorMetaData.laneConfig?.entityLimits?.max ?? 1;
  const capitalizedLaneName = errorMetaData.lane.label ? capitalize(errorMetaData.lane.label) : '';
  const entityType = capitalize(errorMetaData.entity.type);
  const pluralizedEntityType = entityType + (maxEntities > 1 ? 's' : '');
  const isAre = maxEntities > 1 ? 'are' : 'is';
  const targetLane =
    errorMetaData.entity.type === EntityType.Interface
      ? 'Interaction'
      : errorMetaData.entity.type === EntityType.Moment
        ? 'Context'
        : errorMetaData.entity.type === EntityType.Action
          ? 'System'
          : 'the correct';

  let maxEntitiesError: string;
  const entitiesInCell = errorMetaData.frame.entities[errorMetaData.laneIndex];
  if (entitiesInCell && entitiesInCell[0]) {
    maxEntitiesError = `Cell is occupied with a ${capitalize(
      entitiesInCell[0].type,
    )} entity. \nTIP: Add a frame and put the new ${pluralizedEntityType} entity in it.`;
  } else
    maxEntitiesError = `Only ${maxEntities} ${pluralizedEntityType} ${isAre} allowed in this cell. TIP: Create a new frame either before or after this one.`;

  return {
    ENTITY_NOT_ALLOWED_IN_FRAME: `${capitalize(
      errorMetaData.entity.type,
    )}s are not allowed on the ${capitalizedLaneName} lane. TIP: Try moving it to the ${targetLane} lane.`,
    ENTITY_NOT_ALLOWED_IN_NARRATIVE_SCRIPT: `${article} ${capitalize(
      errorMetaData.entity.type,
    )} is not valid in Narrative Scripts. TIP: Try moving it to an Action Script.`,
    ENTITY_NOT_ALLOWED_IN_ACTION_SCRIPT: `${article} ${capitalize(
      errorMetaData.entity.type,
    )} is not valid in Action Scripts. TIP: Try moving it to a Narrative Script.`,
    MAX_ENTITIES_ERROR: maxEntitiesError,
    ENTITY_CONFLICT_ERROR: (types: (EntityType | string)[]) =>
      `An ${types
        .map((type) => (type === EntityType.Interface ? 'Interaction' : capitalize(type)))
        .join(' and an ')} cannot happen at the exact same time. TIP: Sequence them correctly using separate frames.`,
  };
};
SCRIPT_MESSAGES.OK = 'OK';
SCRIPT_MESSAGES.MIXED_CONSTRUCT_TYPE_BACKEND_ERROR =
  'Cannot ingest backend construct of different types in a single frame';
SCRIPT_MESSAGES.ENTITY_NOT_FOUND = 'Entity not found in frame';
SCRIPT_MESSAGES.CAPABILITY_NOT_ALLOWED_IN_SCRIPT = `Capability cannot be ingested into scripts. TIP: Use transition arrows to connect related scripts to a capability.`;
