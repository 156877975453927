import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useCallback } from 'react';
import { CirclePlus } from 'lucide-react';

export const AddSpace = () => {
  const { formatMessage: f } = useIntl();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const openCreateSpaceModal = useCallback(() => {
    setShowCreateSpaceModal(true);
  }, [setShowCreateSpaceModal]);

  return (
    <div
      className="border border-primary rounded-md flex flex-col justify-center items-center gap-1.5 p-4 h-[200px] transition-all hover:bg-primary-100 hover:border-2 hover:cursor-pointer"
      onClick={openCreateSpaceModal}
    >
      <CirclePlus />
      <p className="text-primary">{f({ id: 'create-new-space' })}</p>
    </div>
  );
};
