import { CommandBase, IParams } from '../framework/CommandBase';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { Filter, FilterType } from '../../entities/Filter';
import { CommandError } from '../../ErrorStore';

interface ApplyFilterParams extends IParams {
  id: string;
  criteria: EntityBase[];
}

export class FilterUnsavedSetEvent extends EventBase {
  static eventType = 'FilterUnsavedSetEvent';

  constructor(
    public readonly params: ApplyFilterParams,
    public readonly source = SetUnsavedFilterCommand,
  ) {
    super();
  }
}

export class SetUnsavedFilterCommand extends CommandBase<ApplyFilterParams> {
  execute(params: ApplyFilterParams): FilterUnsavedSetEvent | CommandError {
    const filter = new Filter(params.id, '', FilterType.unsaved, params.criteria, ['*']);
    this.appState.messageBus.send(ApplyFiltersCommand, { filter });
    return new FilterUnsavedSetEvent(params);
  }
}
