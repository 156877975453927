import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const HideSidebar: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 6C22.75 4.74 22.25 3.532 21.359 2.641C20.468 1.75 19.26 1.25 18 1.25H6C4.74 1.25 3.532 1.75 2.641 2.641C1.75 3.532 1.25 4.74 1.25 6V18C1.25 19.26 1.75 20.468 2.641 21.359C3.532 22.25 4.74 22.75 6 22.75H18C19.26 22.75 20.468 22.25 21.359 21.359C22.25 20.468 22.75 19.26 22.75 18V6Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 6C22.75 4.74 22.25 3.532 21.359 2.641C20.468 1.75 19.26 1.25 18 1.25H6C4.74 1.25 3.532 1.75 2.641 2.641C1.75 3.532 1.25 4.74 1.25 6V18C1.25 19.26 1.75 20.468 2.641 21.359C3.532 22.25 4.74 22.75 6 22.75H18C19.26 22.75 20.468 22.25 21.359 21.359C22.25 20.468 22.75 19.26 22.75 18V6ZM21.25 6V18C21.25 18.862 20.908 19.689 20.298 20.298C19.689 20.908 18.862 21.25 18 21.25H10L10 2.75H18C18.862 2.75 19.689 3.092 20.298 3.702C20.908 4.311 21.25 5.138 21.25 6ZM6 21.25H8.5L8.5 2.75H6C5.138 2.75 4.311 3.092 3.702 3.702C3.092 4.311 2.75 5.138 2.75 6V18C2.75 18.862 3.092 19.689 3.702 20.298C4.311 20.908 5.138 21.25 6 21.25ZM19.25 12.798H13.5606L15.2803 14.6279C15.3519 14.7016 15.409 14.7896 15.4483 14.887C15.4876 14.9843 15.5083 15.0891 15.5092 15.195C15.5101 15.301 15.4911 15.4061 15.4534 15.5042C15.4157 15.6022 15.36 15.6913 15.2896 15.7663C15.2191 15.8412 15.1354 15.9004 15.0432 15.9406C14.9511 15.9807 14.8523 16.0009 14.7527 16C14.6531 15.999 14.5547 15.977 14.4632 15.9352C14.3717 15.8934 14.289 15.8326 14.2198 15.7564L11.2198 12.5642C11.1501 12.4901 11.0948 12.4022 11.0571 12.3054C11.0194 12.2086 11 12.1048 11 12C11 11.8952 11.0194 11.7914 11.0571 11.6946C11.0948 11.5978 11.1501 11.5099 11.2198 11.4358L14.2198 8.24363C14.289 8.16741 14.3717 8.10662 14.4632 8.06479C14.5547 8.02297 14.6531 8.00095 14.7527 8.00003C14.8523 7.99911 14.9511 8.0193 15.0432 8.05943C15.1354 8.09955 15.2191 8.15881 15.2896 8.23374C15.36 8.30867 15.4157 8.39777 15.4534 8.49585C15.4911 8.59393 15.5101 8.69901 15.5092 8.80497C15.5083 8.91094 15.4876 9.01566 15.4483 9.11302C15.409 9.21038 15.3519 9.29844 15.2803 9.37206L13.5606 11.202H19.25C19.4489 11.202 19.6397 11.286 19.7803 11.4357C19.921 11.5854 20 11.7883 20 12C20 12.2117 19.921 12.4146 19.7803 12.5643C19.6397 12.714 19.4489 12.798 19.25 12.798Z"
        fill="black"
        fillOpacity="0.54"
      />
    </>
  );
};
