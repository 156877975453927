import { EntityChanges, SerializedEntityChanges } from '../types';
import { DEBUG_CONFIG } from '../debug-config';
import { logger } from '@xspecs/logger';
import { Label } from '../entities/assets/Label';

export enum NotificationTypes {
  OnUndo = 'on-undo',
  OnRedo = 'redo',
  OnSave = 'on-save',
  OnTransientChange = 'on-transient-change',
  OnUserStateChange = 'on-user-state-change',
  OnError = 'on-error',
}

type ObserverType = {
  callback: (data?: any) => void;
  originalObserver: (changes: any, notificationType: NotificationTypes) => void;
  owner?: string | object;
};

export class SingleSourceObserver {
  private disabled: boolean = false;
  private actionObservers: Map<NotificationTypes, Array<ObserverType>> = new Map();

  constructor() {}

  public disable() {
    this.disabled = true;
  }

  public enable() {
    this.disabled = false;
  }

  /**
   * Subscribe to one or more notification types
   * @param notificationTypes The notification type(s) to subscribe to
   * @param observer The callback function to be called when the notification occurs
   * @param owner Optional owner identifier used for grouping subscriptions for later unsubscription
   */
  public subscribe(
    notificationTypes: NotificationTypes | NotificationTypes[],
    observer: (changes: any, notificationType: NotificationTypes) => void,
    owner?: string | object,
  ) {
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: subscribe', notificationTypes, observer, owner);
    const types = Array.isArray(notificationTypes) ? notificationTypes : [notificationTypes];

    types.forEach((type) => {
      const wrappedObserver: ObserverType = {
        callback: (changes: any) => observer(changes, type),
        originalObserver: observer,
        owner,
      };
      const observers = this.actionObservers.get(type) || [];
      observers.push(wrappedObserver);
      this.actionObservers.set(type, observers);
    });
  }

  /**
   * Unsubscribe a specific observer from notification types
   * @param notificationTypes The notification type(s) to unsubscribe from
   * @param observer The original callback function used when subscribing
   */
  public unsubscribe(
    notificationTypes: NotificationTypes | NotificationTypes[],
    observer: (changes: any, notificationType: NotificationTypes) => void,
  ) {
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: unsubscribe', notificationTypes, observer);
    const types = Array.isArray(notificationTypes) ? notificationTypes : [notificationTypes];

    types.forEach((type) => {
      const observers = this.actionObservers.get(type);
      if (!observers) return;

      const filteredObservers = observers.filter((ob) => ob.originalObserver !== observer);

      if (filteredObservers.length > 0) {
        this.actionObservers.set(type, filteredObservers);
      } else {
        this.actionObservers.delete(type);
      }

      if (DEBUG_CONFIG.observer) logger.log('current observers after unsubscribe', this.actionObservers);
    });
  }

  /**
   * Unsubscribe all observers registered with a specific owner
   * @param owner The owner identifier used when subscribing
   */
  public unsubscribeByOwner(owner: string | object) {
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: unsubscribeByOwner', owner);

    this.actionObservers.forEach((observers, type) => {
      const filteredObservers = observers.filter((ob) => ob.owner !== owner);

      if (filteredObservers.length > 0) {
        this.actionObservers.set(type, filteredObservers);
      } else {
        this.actionObservers.delete(type);
      }
    });
  }

  public notify(
    notificationType: NotificationTypes,
    data: EntityChanges | SerializedEntityChanges = { added: [], updated: [], deleted: [] },
  ): void {
    if (this.disabled) return;
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: notify', notificationType, data);

    const observers = this.actionObservers.get(notificationType) || [];
    const changeset = data as EntityChanges;

    observers.forEach(({ callback }) => {
      callback(changeset);
    });

    if (notificationType === NotificationTypes.OnTransientChange) {
      this.notifyEntityObservers(changeset);
    }
  }

  private notifyEntityObservers(changes: EntityChanges) {
    if (this.disabled) return;
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: notifyEntityObservers', changes);

    const modifiedChanges = changes.added.concat(changes.updated.map((entry) => entry.entity));
    modifiedChanges.forEach((entity) => {
      if (entity instanceof Label) entity.entities.forEach((entityWithLabel) => entityWithLabel.notify());
      entity.notify();
    });
    // changes.deleted.forEach((entity) => {});
  }

  public dispose() {
    if (DEBUG_CONFIG.observer) logger.log('SingleSourceObserver: dispose');
    this.actionObservers.forEach((observers) => (observers.length = 0));
    this.actionObservers.clear();
  }
}
