export type AddFileMenuItem = {
  label: string;
  icon: string;
  fileType: string;
  onClick: {
    command: string;
    params: {
      fileType: string;
      EntityType?: string;
    };
  };
};

export const fileTreeAddItemsMenu: AddFileMenuItem[] = [
  {
    label: 'Narrative',
    icon: 'lucide-sent-to-back',
    fileType: 'canvas',
    onClick: {
      command: 'CreateNewFileCommand',
      params: {
        fileType: 'ndd',
        EntityType: 'NARRATIVE',
      },
    },
  },
  {
    label: 'Event Model',
    icon: 'lucide-sent-to-back',
    fileType: 'canvas',
    onClick: {
      command: 'CreateNewFileCommand',
      params: {
        fileType: 'em',
        EntityType: 'EVENT_MODELING',
      },
    },
  },
  {
    label: 'Document',
    icon: 'doc',
    fileType: 'doc',
    onClick: {
      command: 'CreateNewFileCommand',
      params: {
        fileType: 'doc',
      },
    },
  },
  {
    label: 'Text File',
    icon: 'txt',
    fileType: 'txt',
    onClick: {
      command: 'CreateNewFileCommand',
      params: {
        fileType: 'txt',
      },
    },
  },
  {
    label: 'Markdown',
    icon: 'md',
    fileType: 'md',
    onClick: {
      command: 'CreateNewFileCommand',
      params: {
        fileType: 'md',
      },
    },
  },
];

// ToDo: @Osama add this shit please

// Text File .txt
// Document .doc
// Markdown .md
// JSON .json
// Narrative .ndd
// Event Model .em

// .doc, .actor, .schema, .gql

// ts, gql, json, .js
// content: https://

// unknown extensions open as text
