import { SpacesSideBarLink, SpacesSideBarLinkProps } from './item/spaces-sidebar-link';

export interface SpacesSidebarList {
  links: SpacesSideBarLinkProps[];
  collapsed: boolean;
}

export enum SpacesSidebarLinkValues {
  Recents = 'recents',
  All = 'All Spaces',
}

export const SpacesSidebarList = (props: SpacesSidebarList) => {
  const { links, collapsed } = props;

  return (
    <div className="flex flex-col gap-2">
      {links.map((link) => (
        <SpacesSideBarLink {...link} key={link.label} collapsed={collapsed} />
      ))}
    </div>
  );
};
