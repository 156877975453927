import { useCallback } from 'react';
import { focusEditor, useEditorState } from '@udecode/plate-common';
import { triggerFloatingLink } from '@udecode/plate-link';
import { Link2 } from 'lucide-react';
import { Toggle } from '@xspecs/design-system';

type BalloonMenuLinkProps = {
  closeMenu: () => void;
};
export const BalloonMenuLink = (props: BalloonMenuLinkProps) => {
  const { closeMenu } = props;

  const editor = useEditorState();

  const onAddLink = useCallback(
    async (event) => {
      if (!editor) return;
      closeMenu();
      event.preventDefault();
      event.stopPropagation();
      const selection = editor.selection ?? editor.prevSelection;
      if (!selection) return;
      focusEditor(editor, selection);
      setTimeout(() => {
        triggerFloatingLink(editor, { focused: true });
      }, 0);
    },
    [closeMenu, editor],
  );

  return (
    <Toggle data-testid="balloon-menu-link" value="" onMouseDown={onAddLink}>
      <Link2 />
    </Toggle>
  );
};
