import { CommandBase } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

type UpdateEntityPayloadParams = {
  entityId: string;
  payload: string;
};

type EntityPayloadUpdatedParams = {
  entityId: string;
  payload: string;
};

export class EntityResizedEvent extends EventBase {
  static eventType = 'EntityAddedEvent';

  constructor(
    public readonly params: EntityPayloadUpdatedParams,
    public readonly source = UpdateEntityPayloadCommand,
  ) {
    super();
  }
}

export class UpdateEntityPayloadCommand extends CommandBase<UpdateEntityPayloadParams> {
  execute(params: UpdateEntityPayloadParams): EntityResizedEvent | CommandError {
    const modelContext = this.getModelContext();
    const entity = modelContext.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of('Entity not found', 'error');
    if (entity instanceof ConstructBase) {
      entity.payload = params.payload;
      modelContext.entityRepository.update(entity);
    } else return CommandError.of('Entity is not a construct', 'error');

    return new EntityResizedEvent({
      entityId: entity.id,
      payload: entity.payload,
    });
  }
}
