import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../dialog/dialog';
import { Button } from '../button/button';
import { RadioGroup, RadioGroupItem } from '../radio-group/radio-group';
import { Icon } from '../icons/icon';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Label } from '../label/label';
import { useIntl } from 'react-intl';
import { Separator } from '@xspecs/design-system';

export type MouseInputValue = 'mouse' | 'trackpad';
type MouseInputSelectionDialogProps = {
  open: boolean;
  value?: MouseInputValue;
  onSave: (selected: MouseInputValue) => void;
  onChange: (open: boolean) => void;
};

enum Tab {
  SelectOne = 'SelectOne',
  LearnMore = 'LearnMore',
}

export const MouseInputSelectionDialog: FC<MouseInputSelectionDialogProps> = (props) => {
  const { open, onSave: onSaveProp, onChange, value = 'trackpad' } = props;

  const [mouseInputValue, setMouseInputValue] = useState<MouseInputValue>(value);
  const { formatMessage: f } = useIntl();

  const onSave = useCallback(() => {
    onSaveProp(mouseInputValue);
  }, [mouseInputValue, onSaveProp]);

  const onCancel = useCallback(() => {
    onChange(false);
  }, [onChange]);

  return (
    <Dialog open={open} onOpenChange={onChange}>
      <DialogContent className="w-md p-6 flex flex-col gap-4">
        <DialogHeader>
          <div className="flex items-center gap-1">
            <Icon name="mouse-pointer-click" />
            <DialogTitle>{f({ id: 'mouse-or-trackpad' })}</DialogTitle>
          </div>
          <DialogDescription>{f({ id: 'mouse-or-trackpad-description' })}</DialogDescription>
        </DialogHeader>

        <div>
          <SelectOneTab value={mouseInputValue} onChange={setMouseInputValue} />
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost" onClick={onCancel}>
              {f({ id: 'cancel' })}
            </Button>
          </DialogClose>
          <Button variant="default" onClick={onSave}>
            {f({ id: 'save-changes' })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type SelectOneTabProps = {
  value: MouseInputValue;
  onChange: (value: MouseInputValue) => void;
};

const SelectOneTab: FC<SelectOneTabProps> = (props) => {
  const { value, onChange } = props;

  const items = useMemo(
    () => [
      {
        type: 'mouse',
        instructions: [
          {
            icon: 'mouse-left-click',
            text: 'To move around, press the <strong>left mouse button</strong> while holding <strong>Space on the keyboard</strong> and move the cursor around',
          },
          {
            icon: 'mouse-wheel',
            text: 'To zoom, use the <strong>mouse wheel</strong> while holding <strong>Cmd/Ctrl</strong> on the keyboard',
          },
          {
            icon: 'mouse-left-click',
            text: 'To select objects, press the <strong>left mouse button</strong> and move the cursor through the screen',
          },
        ],
      },
      {
        type: 'trackpad',
        instructions: [
          {
            icon: 'trackpad-click',
            text: 'To move around, <strong>press the trackpad</strong> while holding <strong>Space on the keyboard</strong> and move the cursor around OR just simply <strong>slide two fingers</strong> around',
          },
          {
            icon: 'trackpad-zoom',
            text: 'To zoom, <strong>pinch two fingers</strong>',
          },
          {
            icon: 'trackpad-click',
            text: 'To select objects, <strong>press the trackpad</strong> and move the cursor through the screen',
          },
        ],
      },
    ],
    [],
  );

  const onValueChange = useCallback(
    (value) => {
      onChange(value as MouseInputValue);
    },
    [onChange],
  );

  return (
    <RadioGroup value={value} className="space-y-2" onValueChange={onValueChange}>
      {items.map((item, index, allItems) => (
        <Fragment key={item.type}>
          <div className="space-y-2 flex flex-col gap-2">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value={item.type} id={item.type} />
              <Label htmlFor={item.type} className="text-sm capitalize">
                {item.type}
              </Label>
            </div>
            <div className="flex flex-col gap-2">
              {item.instructions.map((instruction) => (
                <div key={instruction.text} className="flex items-center space-x-2">
                  <div className="w-[24px] h-[24px]">
                    <Icon name={instruction.icon} />
                  </div>
                  <p
                    className="text-secondary text-xs font-light"
                    dangerouslySetInnerHTML={{ __html: instruction.text }}
                  />
                </div>
              ))}
            </div>
          </div>
          {allItems.length - 1 !== index ? <Separator orientation="horizontal" /> : null}
        </Fragment>
      ))}
    </RadioGroup>
  );
};
