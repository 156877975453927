import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { EventBase } from '../framework/EventBase';

interface DeleteFileTreeItemCommandParams extends IParams {
  id: string;
  value: string;
}

interface FileTreeItemDeletedEventParams extends IParams {
  id: string;
}

export class FileTreeItemDeletedEvent extends EventBase {
  static eventType = 'FileTreeItemDeletedEvent';
  constructor(
    public readonly params: FileTreeItemDeletedEventParams,
    public readonly source = DeleteFileTreeItemCommand,
  ) {
    super();
  }
}

export class DeleteFileTreeItemCommand extends CommandBase<DeleteFileTreeItemCommandParams> {
  execute(params: DeleteFileTreeItemCommandParams): FileTreeItemDeletedEvent | CommandError {
    const item = this.appState.spaceRepository.get(params.id);
    if (!item) return CommandError.of(new Error(`Cannot find file with id ${params.id}`), 'error');
    this.appState.spaceRepository.delete(params.id);
    this.appState.spaceRepository.save();
    return new FileTreeItemDeletedEvent({ id: params.id });
  }
}
