import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { OrganizationSettingsModalGeneralJoiningOrganization } from './organization-settings-modal-general-joining-organization/organization-settings-modal-general-joining-organization';
import { OrganizationSettingsModalGeneralName } from './organization-settings-modal-general-name/organization-settings-modal-general-name';
import { OrganizationSettingsModalContentSharing } from './organization-settings-modal-general-content-sharing/organization-settings-modal-general-content-sharing';
import { DialogDescription, DialogTitle } from '@xspecs/design-system/src/ui/dialog/dialog';
import { Button } from '@xspecs/design-system';

enum View {
  Name = 'Name',
  JoiningOrganization = 'JoiningOrganization',
}

export const OrganizationSettingsModalGeneral = () => {
  const [view, setView] = useState<View | null>(null);

  const { formatMessage: f } = useIntl();

  const onExpandName = useCallback(() => setView(View.Name), []);

  const onExpandJoiningOrganization = useCallback(() => setView(View.JoiningOrganization), []);

  const onCloseSubMenu = useCallback(() => setView(null), []);

  if (view === View.Name) {
    return <OrganizationSettingsModalGeneralName onClose={onCloseSubMenu} />;
  }

  if (view === View.JoiningOrganization) {
    return <OrganizationSettingsModalGeneralJoiningOrganization onClose={onCloseSubMenu} />;
  }

  return (
    <Stack data-testid="OrganizationSettingsModalGeneralRoot" px={1} width="100%" spacing={3}>
      <div className="flex flex-col gap-2 justify-start items-start">
        <DialogTitle>{f({ id: 'organization-name' })}</DialogTitle>
        <DialogDescription>{f({ id: 'change-name-of-this-organization' })}</DialogDescription>
        <Button
          data-testid="OrganizationSettingsModalGeneralNameExpandButton"
          size="sm"
          variant="outline"
          onClick={onExpandName}
        >
          {f({ id: 'expand' })}
        </Button>
      </div>
      <div className="flex flex-col gap-2 justify-start items-start">
        <DialogTitle>{f({ id: 'joining-this-organization' })}</DialogTitle>
        <DialogDescription>{f({ id: 'choose-how-people-join-organization' })}</DialogDescription>
        <Button size="sm" variant="outline" onClick={onExpandJoiningOrganization}>
          {f({ id: 'expand' })}
        </Button>
      </div>
      <div className="flex flex-col gap-2 justify-start items-start">
        <DialogTitle>{f({ id: 'sharing-content' })}</DialogTitle>
        <DialogDescription>{f({ id: 'who-can-see-details-of-links' })}</DialogDescription>
        <OrganizationSettingsModalContentSharing />
      </div>
    </Stack>
  );
};
