import { ApplicationContext } from '../ApplicationContext';
import { ResolveThreadCommand } from '../commands/comments/ResolveThreadCommand';
import { UnresolveThreadCommand } from '../commands/comments/UnresolveThreadCommand';
import { AddCommentToThreadCommand, AddCommentToThreadParams } from '../commands/comments/AddCommentToThreadCommand';
import { ToggleResolvedThreadsVisibilityCommand } from '../commands/comments/ToggleResolvedThreadsVisibilityCommand';

export class ThreadInteractor {
  constructor(private readonly model: ApplicationContext) {}

  addCommentToThread(params: AddCommentToThreadParams) {
    this.model.messageBus.send(AddCommentToThreadCommand, params);
  }

  resolveThread(threadId: string) {
    this.model.messageBus.send(ResolveThreadCommand, { threadId });
  }

  unresolvedThread(threadId: string) {
    this.model.messageBus.send(UnresolveThreadCommand, { threadId });
  }

  toggleResolvedThreadsVisibility(showResolvedThreads: boolean) {
    this.model.messageBus.send(ToggleResolvedThreadsVisibilityCommand, { value: showResolvedThreads });
  }
}
