import { FileBase } from './FileBase';
import { FileType, Status } from './File.types';
import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { sid } from '@xspecs/short-id';

export class DocFile extends FileBase {
  getType(): FileType {
    return FileType.Doc;
  }

  public get sharedType(): Y.XmlText {
    return this.document?.get('content', Y.XmlText) as Y.XmlText;
  }

  public get awareness(): Awareness {
    return this.provider.awareness!;
  }

  load(): void {
    // this.sharedTypeInternal = this.provider.document.getText('content');
    this.status = Status.Initial;
  }

  //
  // public get sharedType(): YText {
  //   return this.sharedTypeInternal;
  // }

  public get yjsConfig() {
    return {
      autoConnect: true,
    };
  }

  protected initialValue() {
    return JSON.stringify([{ id: sid(), type: 'p', children: [{ text: '' }] }]);
  }
}
