import { Stack } from '@mui/material';
import { SubscriptionPlansQuery } from '../../../../gql/graphql';
import { PlanCard } from '@xspecs/design-system/src/ui/plan-card/plan-card';

type PlansListProps = {
  plans: SubscriptionPlansQuery['subscriptionPlans'];
};

export const PlansList = (props: PlansListProps) => {
  const { plans } = props;

  return (
    <Stack direction="row" gap={3} flexWrap="wrap" alignItems="stretch">
      {plans.map((plan) => (
        <PlanCard key={`plan-${plan.id}`} plan={plan} variant={plan.isCustomPlan ? 'secondary' : 'primary'} />
      ))}
    </Stack>
  );
};
