import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Workspace } from '../../../../state/types';
import { getRoutePathFromParams, RoutePaths } from '../../../../config/route-paths/route-paths';
import { useActiveWorkspace } from '../../../../hooks/use-active-workspace';
import { useNavigate } from 'react-router-dom';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@xspecs/design-system';
import { Edit, FolderOpen, MoreHorizontal, Trash2 } from 'lucide-react';

export const SpaceItem = (space: Workspace) => {
  const { name } = space;
  const [menuOpen, setMenuOpen] = useState(false);
  const { organization } = useActiveOrganization();
  const { setWorkspace } = useActiveWorkspace();
  const navigate = useNavigate();

  const setSpaceToRename = useSingleSourceStore.use.setSpaceToRename();
  const setSpaceToDelete = useSingleSourceStore.use.setSpaceToDelete();
  const setRecentSpacesByOrg = useSingleSourceStore.use.setRecentSpacesByOrg();

  const { formatMessage: f } = useIntl();

  const onSpaceClick = useCallback(() => {
    setWorkspace(space);
    if (!organization || !space) return;
    setRecentSpacesByOrg(organization.id, space);
    navigate(
      getRoutePathFromParams(RoutePaths.Space, {
        organizationName: organization.name,
        workspaceName: space.name,
      }),
    );
  }, [navigate, organization, setRecentSpacesByOrg, setWorkspace, space]);

  const openSpaceRename = useCallback(() => {
    if (!space) return;
    setSpaceToRename(space.id);
  }, [setSpaceToRename, space]);

  const openSpaceDelete = useCallback(() => {
    if (!space) return;
    setSpaceToDelete(space.id);
  }, [setSpaceToDelete, space]);

  return (
    <div className="relative group">
      <DropdownMenu onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className={`absolute top-2 right-2 p-1 rounded-md transition-all ${
              menuOpen ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
            }`}
          >
            <MoreHorizontal className="w-5 h-5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-48">
          <DropdownMenuItem onClick={onSpaceClick}>
            <FolderOpen className="w-4 h-4 mr-2" /> {f({ id: 'open' })}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={openSpaceDelete}>
            <Trash2 className="w-4 h-4 mr-2 text-red-500" /> {f({ id: 'delete' })}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={openSpaceRename}>
            <Edit className="w-4 h-4 mr-2" /> {f({ id: 'rename' })}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <div
        onClick={onSpaceClick}
        className="border border-primary rounded-md flex flex-col justify-center items-center gap-1.5 p-4 h-[200px] transition-all hover:bg-primary-100 hover:border-2 hover:cursor-pointer"
      >
        <p className="text-primary">{name}</p>
      </div>
    </div>
  );
};
