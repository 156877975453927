import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { DeletedEntity, EntityChanges } from '../../types';
import { AttachmentType } from '../../entities/EntityType';
import {
  AssetExplorerItem,
  AssetExplorerResult,
  AssetExplorerUsageItem,
  AssetScope,
  AssetSort,
} from './AssetExplorerItem';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Upload } from '../../entities/assets/Upload';
import Fuse from 'fuse.js';
import { EntityBase } from '../../entities/EntityBase';
import { Attachment } from '../../entities/assets/Attachment';
import { logger } from '@xspecs/logger';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

export class AssetExplorer {
  constructor(
    public readonly entityRepository: EntityRepository,
    public readonly store: IStore,
  ) {}

  assets: Map<string, Omit<AssetExplorerItem, 'usages'>> = new Map();
  assetConstructMap: Map<string, Set<string>> = new Map();
  attachmentConstructMap: Map<string, string> = new Map();
  assetAttachmentMap: Map<string, Set<string>> = new Map();

  results: AssetExplorerResult = [];
  search: string = '';
  sort: AssetSort = AssetSort.Newest;
  scope: AssetScope = AssetScope.Current;

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    let updateStore = false;
    changes.added.forEach((entity) => {
      const result = this.handleChange(entity);
      if (result) {
        updateStore = true;
      }
    });
    changes.updated.forEach((change) => {
      if (!change.modifiedProperties.every((prop) => prop.startsWith('position'))) {
        const result = this.handleChange(change.entity);
        if (result) {
          updateStore = true;
        }
      }
    });
    changes.deleted.forEach((entity) => {
      const result = this.handleDelete(entity);
      if (result) {
        updateStore = true;
      }
    });
    if (updateStore) {
      this.updateStore();
    }
  }

  private handleChange(_entity: EntityBase) {
    const entity = this.entityRepository.get(_entity.id);
    if (entity instanceof AssetBase) {
      this.assets.set(entity.id, {
        id: entity.id,
        name: entity.name,
        type: AttachmentType[entity.type],
        url: entity instanceof Upload ? entity.url : undefined,
        icon: Attachment.iconMap[entity.type],
        scopes: entity.scopes,
      });
      return true;
    }

    if (entity instanceof Attachment) {
      if (entity.parent) {
        this.attachmentConstructMap.set(entity.id, entity.parent.id);
      } else {
        this.attachmentConstructMap.delete(entity.id);
      }

      if (entity.asset) {
        const current = this.assetAttachmentMap.get(entity.asset.id) || new Set();
        current.add(entity.id);
        this.assetAttachmentMap.set(entity.asset.id, current);
      } else {
        this.assetAttachmentMap.forEach((attachmentIds) => {
          attachmentIds.delete(entity.id);
        });
      }
    }

    if (entity instanceof Attachment && entity.parent && entity.asset) {
      if (!this.assetConstructMap.has(entity.asset.id)) {
        this.assetConstructMap.set(entity.asset.id, new Set());
      }
      const constructsSet = this.assetConstructMap.get(entity.asset.id);
      if (constructsSet) constructsSet.add(entity.parent.id);
      else logger.error(`While handling attachment, construct set not found for asset with id ${entity.asset.id}`);

      return true;
    }

    if (entity instanceof ConstructBase) {
      let shouldUpdate = false;
      this.assetConstructMap.forEach((constructIds) => {
        if (shouldUpdate) return;
        if (constructIds.has(entity.id)) {
          shouldUpdate = true;
        }
      });
      if (shouldUpdate) return true;
    }
  }

  private handleDelete(deletedEntity: DeletedEntity) {
    this.assets.delete(deletedEntity.id);
    this.assetConstructMap.delete(deletedEntity.id);
    this.assetConstructMap.forEach((constructIds) => {
      constructIds.delete(deletedEntity.id);
    });
    return true;
  }

  private updateStore() {
    let results: AssetExplorerResult = Array.from(this.assets.values()).map(
      (asset) =>
        ({
          ...asset,
          usages: this.getUsages(asset.id),
        }) satisfies AssetExplorerItem,
    );

    if (this.search) {
      results = this.applySearch(results);
    }

    if (this.sort) {
      results = this.applySort(results);
    }

    this.results = results;
    this.store.getState().setAssetExplorer({
      results: this.results,
      searchQuery: this.search,
      sortQuery: this.sort,
      scope: this.scope,
    });
  }

  private getUsages(assetId: string): AssetExplorerItem['usages'] {
    const attachmentsSet = this.assetAttachmentMap.get(assetId) || new Set();
    if (attachmentsSet.size === 0) return [];
    const attachments: Attachment[] = Array.from(attachmentsSet)
      .map((attachmentId) => this.entityRepository.get<Attachment>(attachmentId))
      .filter(Boolean) as Attachment[];

    const constructIds = (attachments.map((attachment) => attachment.parent).filter(Boolean) as ConstructBase[]).map(
      (construct) => construct.id,
    );
    return constructIds
      .map((constructId) => {
        const entity = this.entityRepository.get(constructId);
        if (!entity) {
          logger.error(`While finding usages for asset, entity with id ${constructId} not found`);
          return;
        }

        if (!(entity instanceof ConstructBase)) {
          logger.error(`While finding usages for asset, entity with id ${constructId} not a construct`);
          return;
        }

        return {
          id: entity.id,
          name: entity.name,
          type: entity.type,
          shape: entity.shape,
          scopes: entity.scopes,
        } satisfies AssetExplorerUsageItem;
      })
      .filter(Boolean) as AssetExplorerUsageItem[];
  }

  private applySearch(results: AssetExplorerResult): AssetExplorerResult {
    const fuse = new Fuse(results, { keys: ['name'], threshold: 0.4 });
    return fuse.search(this.search).map((fuseItem) => fuseItem.item);
  }

  public updateSearch(search: string) {
    this.search = search.toLowerCase();
    this.updateStore();
  }

  private handleSortAscending(a: { name: string }, b: { name: string }) {
    return a.name.localeCompare(b.name);
  }

  private handleSortDescending(a: { name: string }, b: { name: string }) {
    return b.name.localeCompare(a.name);
  }

  private applySort(results: AssetExplorerResult): AssetExplorerResult {
    switch (this.sort) {
      case AssetSort.Newest:
        return results.reverse();
      case AssetSort.Ascending:
        return results.sort(this.handleSortAscending).map((asset) => ({
          ...asset,
          usages: asset.usages.sort(this.handleSortAscending),
        }));
      case AssetSort.Descending:
        return results.sort(this.handleSortDescending).map((asset) => ({
          ...asset,
          usages: asset.usages.sort(this.handleSortDescending),
        }));
      default:
        return results;
    }
  }

  public updateSort(sort: AssetSort) {
    this.sort = sort;
    this.updateStore();
  }

  public dispose() {
    this.results = [];
    this.search = '';
    this.sort = AssetSort.Newest;
    this.scope = AssetScope.Current;
    this.store.getState().setAssetExplorer({
      results: this.results,
      searchQuery: this.search,
      scope: AssetScope.Current,
      sortQuery: AssetSort.Newest,
    });
  }
}
