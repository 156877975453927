import { ApplicationContext } from '../ApplicationContext';
import { ViewEntityDetailsCommand } from '../commands/entities/ViewEntityDetailsCommand';
import { CloseEntityDetailsCommand } from '../commands/entities/CloseEntityDetailsCommand';

export class EntityDetailsInteractor {
  constructor(private readonly model: ApplicationContext) {}

  viewEntity(entityId: string) {
    return this.model.messageBus.send(ViewEntityDetailsCommand, { entityId });
  }

  closeEntity(entityId: string) {
    return this.model.messageBus.send(CloseEntityDetailsCommand, { entityId });
  }
}
