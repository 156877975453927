import { ToggleGroupItem, ToggleGroupItemProps } from '@xspecs/design-system';
import * as React from 'react';
import { LucideIcon } from 'lucide-react';

type BalloonMenuBoldProps = { Icon: LucideIcon } & ToggleGroupItemProps;

export const BalloonMenuMarkButton = (props: BalloonMenuBoldProps) => {
  const { Icon, ...buttonProps } = props;

  return (
    <ToggleGroupItem className="data-[state=on]:bg-background rounded" {...buttonProps}>
      <Icon />
    </ToggleGroupItem>
  );
};
