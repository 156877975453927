import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface ClearErrorParams extends IParams {
  errors: CommandError | CommandError[];
}

export class ClearErrorCommand extends CommandBase<ClearErrorParams> {
  shouldSave() {
    return false;
  }

  execute(params: ClearErrorParams): EventBase | CommandError | undefined {
    const { errors } = params;
    const errorArray = Array.isArray(errors) ? errors : [errors];
    errorArray.forEach((error) => this.appState.errorStore.clearError(error));
    return undefined;
  }
}
