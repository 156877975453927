import { CommandBase, IParams } from '../framework/CommandBase';
import { FileTreeEntry } from '../../read-models/file-tree/FileTree';
import { EventBase } from '../framework/EventBase';

interface BroadcastSpaceFileTreeChangesParams extends IParams {
  added: FileTreeEntry[];
  updated: FileTreeEntry[];
  deleted: string[];
}

interface BroadcastSpaceFileTreeEventParams extends IParams {
  added: FileTreeEntry[];
  updated: FileTreeEntry[];
  deleted: string[];
}

export class SpaceFileTreeChangedEvent extends EventBase {
  static eventType = 'SpaceFileTreeChangedEvent';

  constructor(
    public readonly params: BroadcastSpaceFileTreeEventParams,
    public readonly source = BroadcastRemoteFileTreeChangesCommand,
  ) {
    super();
  }
}

export class BroadcastRemoteFileTreeChangesCommand extends CommandBase<BroadcastSpaceFileTreeChangesParams> {
  execute(params: BroadcastSpaceFileTreeChangesParams): SpaceFileTreeChangedEvent {
    return new SpaceFileTreeChangedEvent({
      added: params.added,
      updated: params.updated,
      deleted: params.deleted,
    });
  }
}
