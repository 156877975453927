import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { EntityType } from '@xspecs/single-source-model';
import { Box, IconButton, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { EntityDetailsMenu } from './entity-details/entity-details-menu';
import { CustomEntityDetails } from '../../app-routes/space-route/file-route/custom-entity-details';
import { EntityDetails } from './entity-details/entity-details';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useCommandDispatch } from '@xspecs/design-system';
import { Loading } from '../loading/loading';
import { useFileIsLoaded } from '../../hooks/use-file-is-loaded';

export const WorkspaceEntityRoute = () => {
  const { workspace } = useActiveWorkspace();
  const { organization } = useActiveOrganization();
  const entityDetails = useSingleSourceStore.use.entityDetails();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const [searchParams, setSearchParams] = useSearchParams();
  const entityId = searchParams.get('entityId');
  const { dispatchCommand } = useCommandDispatch();
  const { fileId } = useParams();
  const { synced } = useFileIsLoaded(fileId);

  const isAttachmentWithoutAsset =
    entityDetails && entityDetails.type === EntityType.Attachment && !entityDetails.asset;

  const onClose = useCallback(() => {
    dispatchCommand('CloseEntityDetailsCommand', { entityId: entityId });
    searchParams.delete('entityId');
    setSearchParams(searchParams);
  }, [dispatchCommand, entityId, searchParams, setSearchParams]);

  useEffect(() => {
    if (!organization || !workspace) return;
    if (!synced) return;
    setTimeout(() => {
      dispatchCommand('ViewEntityDetailsCommand', { entityId });
    }, 0);
  }, [dispatchCommand, entityId, synced, organization, workspace]);

  useEffect(() => {
    if (!synced) return;
    if (entityDetails && workspace && organization) {
      if (
        entityDetails.type === EntityType.Thread ||
        (entityDetails.type === EntityType.Attachment && !entityDetails.asset)
      ) {
        onClose();
      }
      setTimeout(() => {
        setConstructToPanTo(entityDetails.id);
        if (entityDetails.type === EntityType.Thread) {
          onClose();
        }
      }, 10);
    }
  }, [entityDetails, synced, onClose, organization, setConstructToPanTo, workspace]);

  const setGraphDetails = useSingleSourceStore.use.setGraphDetails();
  const setLoadingVariantsProposal = useSingleSourceStore.use.setLoadingVariantsProposal();

  useEffect(() => {
    return () => {
      setGraphDetails(null);
      setLoadingVariantsProposal(false);
      dispatchCommand('CloseEntityDetailsCommand', { entityId });
    };
  }, [dispatchCommand, entityId, setGraphDetails, setLoadingVariantsProposal]);

  if (!synced) {
    return (
      <Loading
        rootProps={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        circularProgressProps={{ size: 18 }}
      />
    );
  }

  // if (!entity) {
  //   onClose();
  //   return null;
  // }

  if (!entityDetails) {
    return null;
  }

  if (isAttachmentWithoutAsset) {
    onClose();
    return null;
  }

  return (
    <Stack height="100%" width="100%" p={1} display="flex">
      <Stack direction="row" justifyContent="end">
        <EntityDetailsMenu id={entityDetails.id} type={entityDetails.type} name={entityDetails.name} />
        <IconButton size="small" sx={{ alignSelf: 'flex-end' }} onClick={onClose}>
          <Close fontSize="small" className="text-primary" />
        </IconButton>
      </Stack>
      <Box flexGrow={1} minHeight={0} height="100%">
        {entityDetails.detailsPaneLayout ? (
          <CustomEntityDetails layout={entityDetails.detailsPaneLayout} />
        ) : (
          <EntityDetails key={entityDetails.id} entity={entityDetails} />
        )}
      </Box>
    </Stack>
  );
};
