import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { Query } from '../../entities/assets/Query';
import { Upload } from '../../entities/assets/Upload';
import { EntitiesAddedEvent } from './AddEntityCommand';

interface UpdateQueryUploadParams extends IParams {
  id: string;
  uploadId?: string;
}

export class UpdateQueryUploadCommand extends CommandBase<UpdateQueryUploadParams> {
  execute(params: UpdateQueryUploadParams): EntitiesAddedEvent | CommandError | undefined {
    const modelContext = this.getModelContext();
    const queryEntity = modelContext.entityRepository.get<Query>(params.id);
    if (!(queryEntity instanceof Query)) return CommandError.of(new Error('Entity is not a Query'), 'error');
    if (params.uploadId) {
      const uploadEntity = modelContext.entityRepository.get<Upload>(params.uploadId)!;
      queryEntity.linkAsset(uploadEntity);
      if (queryEntity.annotation) {
        queryEntity.annotation.url = uploadEntity.url!;
      } else {
        queryEntity.annotation = {
          url: uploadEntity.url!,
          width: 1000 * ((uploadEntity.metadata.width ?? 0) / (uploadEntity.metadata.height ?? 0)),
          height: 1000,
          position: { x: 0, y: 0 },
        };
      }
    } else {
      queryEntity.unlinkAsset();
    }
    modelContext.entityRepository.update(queryEntity);
    if (params.uploadId) {
      return new EntitiesAddedEvent({
        entityIds: [params.uploadId],
        cursorPosition: { x: 0, y: 0 },
      });
    }
  }
}
