import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../resizable/resizable';
import { ExplorerMenu } from '../explorer-menu/explorer-menu';
import { TopMenu, TopMenuProps } from '../top-menu/top-menu';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { SidebarProvider } from '../sidebar/sidebar';
import { useIntl } from 'react-intl';
import { getRoutePathFromParams, RoutePaths } from '@xspecs/client/src/config/route-paths/route-paths';
import { useActiveOrganization } from '@xspecs/client/src/hooks/use-active-organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { PricingSidebar } from '@xspecs/design-system/src/ui/pricing-sidebar/pricing-sidebar';
import { useAuth } from '@xspecs/client/src/auth';

export interface PricingLayoutProps {
  topMenu: TopMenuProps;
  children: ReactNode;
}

export const PricingLayout = (props: PricingLayoutProps) => {
  const { topMenu, children } = props;
  const [sidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const sidebarRef = useRef<ImperativePanelHandle>(null);
  const { formatMessage: f } = useIntl();
  const { organization } = useActiveOrganization();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [currentTab, setCurrentTab] = useState('Plans');

  const navigateToSpaces = useCallback(() => {
    if (!organization) return;
    navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
      state: {
        preventNavigation: true,
      },
    });
  }, [navigate, organization]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const sidebarLinks = useMemo(
    () => [
      {
        label: f({ id: 'plans' }),
        active: currentTab === 'Plans',
        onClick: () => setCurrentTab('Plans'),
      },
      {
        label: f({ id: 'billing' }),
        active: currentTab === 'Billing',
        onClick: () => setCurrentTab('Billing'),
      },
    ],
    [currentTab, f],
  );

  const handleLogout = useCallback(() => {
    logout?.({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <ResizablePanelGroup direction="horizontal">
      <SidebarProvider>
        <ResizablePanel
          className="flex"
          defaultSize={15}
          onCollapse={() => setIsSidebarCollapsed(true)}
          onExpand={() => setIsSidebarCollapsed(false)}
          minSize={15}
          ref={sidebarRef}
          id="ide-sidebar"
        >
          <ExplorerMenu
            logout={handleLogout}
            user={user}
            showUser={sidebarCollapsed}
            bottomItems={[]}
            topItems={[]}
            viewSpaces={navigateToSpaces}
          />
          <PricingSidebar collapsed={sidebarCollapsed} goBack={goBack} links={sidebarLinks} />
        </ResizablePanel>
        <ResizableHandle className="bg-border dark:bg-border" />
        <ResizablePanel defaultSize={85}>
          <TopMenu {...topMenu} />
          {children}
        </ResizablePanel>
      </SidebarProvider>
    </ResizablePanelGroup>
  );
};
