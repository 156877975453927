import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const OnAuto: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M38 0H2C0.888889 0 0 0.888889 0 2V38C0 39.1111 0.888889 40 2 40H38C39.1111 40 40 39.1111 40 38V2C40 0.888889 39.1111 0 38 0Z"
        fill="rgb(24, 24, 27)"
      />
      <path
        d="M28.8888 35.1113C32.3333 35.1113 35.1111 32.3335 35.1111 28.8891C35.1111 25.4446 32.3333 22.6668 28.8888 22.6668C25.4444 22.6668 22.6666 25.4446 22.6666 28.8891C22.6666 32.3335 25.4444 35.1113 28.8888 35.1113Z"
        fill="white"
      />
      <path
        d="M4.88892 22.8002C4.88892 22.7335 4.95559 22.6668 5.02225 22.6668H17.2223C17.2889 22.6668 17.3556 22.7335 17.3556 22.8002V28.778C17.3556 28.8446 17.2889 28.9113 17.2223 28.9113H14.4667C14.4 28.9113 14.3334 28.978 14.3334 29.0446V35.0224C14.3334 35.0891 14.2667 35.1557 14.2 35.1557H8.13337C8.0667 35.1557 8.00003 35.0891 8.00003 35.0224V29.0446C8.00003 28.978 7.93337 28.9113 7.8667 28.9113H5.00003C4.93337 28.9113 4.8667 28.8446 4.8667 28.778V22.8002H4.88892Z"
        fill="white"
      />
      <path
        d="M22.8 4.88892C22.7333 4.88892 22.6666 4.95558 22.6666 5.02225V11.3111C22.6666 14.6445 25.4444 17.3334 28.8888 17.3334C32.3333 17.3334 35.1111 14.6445 35.1111 11.3111V5.02225C35.1111 4.95558 35.0444 4.88892 34.9777 4.88892H22.7777H22.8Z"
        fill="white"
      />
      <path
        d="M4.9112 17.1335C4.86675 17.2224 4.9112 17.3113 5.02231 17.3113H17.2001C17.289 17.3113 17.3556 17.2224 17.3112 17.1335L11.2223 4.97795C11.1779 4.88906 11.0445 4.88906 11.0001 4.97795L4.9112 17.1335Z"
        fill="white"
      />
    </>
  );
};
