import { useAuth } from '../../auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { AuthRedirect } from '../../pages/auth-redirect/auth-redirect';
import dayjs from 'dayjs';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACCEPT_INVITATION_MUTATION } from '../../graphql/mutations';
import { logger } from '@xspecs/logger';
import { THRESHOLD_MINUTES } from '../../hooks/use-track-user-logged-in';
import { getUser, LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../lib/utils';
import { AppTypeEvent } from '../../hooks/use-track-events';
import { Analytics } from '@xspecs/single-source-model';

export const AuthReturnRoute = () => {
  const navigate = useNavigate();
  const { handleRedirectCallback } = useAuth();

  const [getOrganizations] = useLazyQuery(ORGANIZATIONS_QUERY, { fetchPolicy: 'no-cache' });
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION_MUTATION);

  const handleCallback = async () => {
    const { appState } = await handleRedirectCallback();
    const trackUserLoggedIn = () => {
      const user = getUser();
      if (!user) return;
      Analytics.getInstance().track({
        event: AppTypeEvent.LoggedIn,
        params: {
          id: user.id,
          email: user.email,
        },
      });
      localStorage.setItem('lastLoggedIn', now.toISOString());
    };
    const lastLoggedIn = localStorage.getItem('lastLoggedIn') || '';
    const now = dayjs();
    if (lastLoggedIn) {
      const lastLoggedInTime = dayjs(lastLoggedIn);
      if (dayjs().diff(lastLoggedInTime, 'minutes') > THRESHOLD_MINUTES) {
        trackUserLoggedIn();
      }
    } else {
      trackUserLoggedIn();
    }

    if (appState?.returnTo) {
      navigate(appState.returnTo);
      return;
    }
    if (!appState?.invitationId) {
      localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
      navigate(RoutePaths.ChooseOrganization);
      return;
    }
    const invitation = JSON.parse(localStorage.getItem(appState.invitationId) || '{}');
    localStorage.removeItem(appState.invitationId);
    const expiry = dayjs(invitation.expires);
    const isExpired = expiry < now;
    if (!isExpired) {
      const { data: acceptInvitationData } = await acceptInvitation({
        variables: { args: { invitationId: invitation.inviteId, organizationId: invitation.organizationId } },
      });
      if (!acceptInvitationData) {
        logger.error('Failed to accept invitation');
        localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
        navigate(RoutePaths.ChooseOrganization);
        return;
      }
      const { data } = await getOrganizations();
      if (!data) {
        logger.error('Failed to fetch organizations');
        localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
        navigate(RoutePaths.ChooseOrganization);
        return;
      }
      const organization = data.organizations.find((organization) => organization.id === invitation.organizationId);
      if (!organization) {
        navigate(RoutePaths.ChooseOrganization);
        localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
        return;
      }
      if (acceptInvitationData.acceptInvite.error) {
        localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
        navigate(RoutePaths.ChooseOrganization);
        return;
      }
      const workspace = organization.workspaces.find((workspace) => workspace.id === invitation.workspaceId);
      if (!workspace) {
        navigate(getRoutePathFromParams(RoutePaths.Organization, { organizationName: organization.name }));
        return;
      }
      if (invitation.specId) {
        navigate(
          getRoutePathFromParams(RoutePaths.File, {
            organizationName: organization.name,
            workspaceName: workspace.name,
            fileId: invitation.docId,
          }),
        );
      } else if (invitation.docId) {
        navigate(
          getRoutePathFromParams(RoutePaths.Space, {
            organizationName: organization.name,
            workspaceName: workspace.name,
            documentId: invitation.docId,
          }),
        );
      } else {
        navigate(getRoutePathFromParams(RoutePaths.Organization, { organizationName: organization.name }));
      }
    }
  };

  // useTrackUserLoggedIn();

  useEffect(() => {
    handleCallback();
    // The following line is disabled because we want to run this effect only once. handleRedirectCallback does not
    // work if it is called multiple times.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthRedirect />;
};
