import { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu';
import { CircleHelp } from 'lucide-react';
import { cn } from '../../utils';
import { MenuLinkItem } from '@xspecs/design-system/src/ui/top-menu/top-menu';

export interface MenuItemHelpProps {
  items: MenuLinkItem[];
}

export const MenuItemHelp = (props: MenuItemHelpProps) => {
  const { items } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <div
          className={cn(
            'flex justify-center items-center hover:bg-accent rounded-lg p-1.5',
            isOpen && 'bg-base-primary/10',
          )}
        >
          <CircleHelp size="16" className={cn('text-muted-foreground', isOpen && 'text-base-primary')} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        side="bottom"
        align="end"
        sideOffset={4}
      >
        <DropdownMenuGroup>
          {items.map((item) => (
            <DropdownMenuItem key={item.label} className="text-primary" onClick={item.onClick}>
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
