import { Stack } from '@mui/material';
import { ToolbarLayout } from '@xspecs/single-source-model';
import { renderPaletteComponent } from '../components/palette-renderer';

type CanvasToolbarBottomPaneProps = ToolbarLayout['body'];

export const CanvasToolbarBottomPane = (props: CanvasToolbarBottomPaneProps) => {
  const { rows } = props;

  return (
    <Stack gap={2} height="100%" width="100%" p={2} sx={rootSx} className="bg-background-paper">
      {rows.map((row, rowIndex) => (
        <Stack
          key={`CanvasToolbarBottomPaneRow${rowIndex}`}
          direction="row"
          gap={1.5}
          sx={{
            flexShrink: rowIndex === 0 ? 0 : 1,
            overflowY: 'hidden',
            overflowX: 'auto',
            flexGrow: rowIndex === 0 ? 0 : 1,
          }}
        >
          {row.elements.map((element, itemIndex) => {
            return renderPaletteComponent({ element, key: `CanvasToolbarBottomPaneElement${rowIndex}${itemIndex}` });
          })}
        </Stack>
      ))}
    </Stack>
  );
};

const rootSx = {
  pointerEvents: 'auto',
};
