import { SubscriptionPlansQuery } from '@xspecs/client/src/gql/graphql';
import { BadgeCheck } from 'lucide-react';
import { cn } from '@xspecs/design-system/src/utils/index';

type PlanFeatureListProps = {
  description: SubscriptionPlansQuery['subscriptionPlans'][0]['description'];
  features: SubscriptionPlansQuery['subscriptionPlans'][0]['features'];
  variant: 'primary' | 'secondary';
};

export const PlanFeatureList = (props: PlanFeatureListProps) => {
  const { description, features, variant } = props;

  return (
    <div className="flex flex-col gap-3">
      <p className={cn('text-sm font-semibold', variant === 'primary' ? 'text-primary' : 'text-white')}>
        {description}
      </p>
      <div className="flex flex-col gap-3 mt-3">
        {features.map((feature) => {
          return (
            <div key={`feature-${feature}`} className="flex gap-2 items-center">
              <BadgeCheck
                size={16}
                className={cn('flex-shrink-0', variant === 'primary' ? 'text-icon' : 'text-icon-white')}
              />
              <p className={cn('text-sm font-medium', variant === 'primary' ? 'text-muted' : 'text-white')}>
                {feature}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
