import { useState } from 'react';
import { Avatar, Box, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Role } from '../../../gql/graphql';
import { Clear } from '@mui/icons-material';
import { RemoveUserFromOrganizationConfirmationDialog } from './remove-user-from-organization-confirmation-dialog';

export interface UserRolesListItemProps {
  name?: string;
  picture?: string;
  userId: string;
  role: Role;
  onRoleChange: ({ newRole, userToUpdateId }: { newRole: Role; userToUpdateId: string }) => void;
  onRemove: () => void;
  myRole?: Role;
}

export const UserRolesListItem = (props: UserRolesListItemProps) => {
  const { name, picture, userId, role, onRoleChange, onRemove, myRole } = props;

  const [localRole, setLocalRole] = useState(role);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const handleRoleChange = (e: SelectChangeEvent<Role>) => {
    const newRole = e.target.value as Role;
    setLocalRole(newRole);
    onRoleChange({ newRole, userToUpdateId: userId });
  };

  const sx = { display: 'flex', justifyContent: 'space-between' };

  return (
    <Box sx={sx}>
      <Box sx={{ ...sx, alignItems: 'center' }}>
        <Avatar alt={name} src={picture} className="rounded-lg" />
        <Typography
          variant="body1"
          sx={{ pl: 1, whiteSpace: 'nowrap', maxWidth: '150px', overflowY: 'hidden', textOverflow: 'ellipsis' }}
        >
          {name}
        </Typography>
      </Box>
      <Box sx={{ ...sx, alignItems: 'center' }}>
        <Select
          data-testid={`roleSelect_user-${userId}`}
          value={localRole}
          onChange={handleRoleChange}
          size="small"
          sx={{ minWidth: '100px' }}
          disabled={myRole !== Role.Admin}
        >
          {Object.keys(Role).map((role) => (
            <MenuItem
              data-testid={`menuItem_user-${userId}_role-${role}`}
              key={role}
              value={Role[role]}
              sx={{ minWidth: '100px' }}
            >
              {role}
            </MenuItem>
          ))}
        </Select>
        <IconButton data-testid={`clearButton_user-${userId}`} onClick={() => setIsConfirmationDialogOpen(true)}>
          <Clear />
        </IconButton>
        {isConfirmationDialogOpen ? (
          <RemoveUserFromOrganizationConfirmationDialog
            userId={userId}
            userName={name}
            onCancel={() => setIsConfirmationDialogOpen(false)}
            onConfirm={() => {
              setIsConfirmationDialogOpen(false);
              onRemove();
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};
