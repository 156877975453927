import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AppRegistry } from '../../apps/AppRegistry';
import { App, AppAssets, AppConstructs } from '../../apps/App.types';

interface DeRegisterAppParams extends IParams {
  app: App;
}

export class DeRegisterAppCommand extends CommandBase<DeRegisterAppParams> {
  execute(params: DeRegisterAppParams): EventBase | CommandError | undefined {
    const entities: { constructs: AppConstructs; assets: AppAssets } = {
      constructs: [],
      assets: [],
    };
    params.app.schemes.forEach((scheme) => {
      scheme.categories.forEach((category) => {
        if (category.constructs) {
          entities.constructs.push(...category.constructs);
        }
        if (category.assets) {
          entities.assets.push(...category.assets);
        }
      });
    });
    AppRegistry.deRegisterEntities(entities.constructs.map((asset) => asset.type));
    AppRegistry.deRegisterEntities(entities.assets.map((construct) => construct.type));
    this.appState.toolbar.removeSchemes(params.app.schemes);
    return;
  }
}
