import { useIntl } from 'react-intl';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@xspecs/design-system';
import { EllipsisVertical, Pencil, Trash } from 'lucide-react';

export type CommentItemMoreMenuProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

export const CommentItemMoreMenu = (props: CommentItemMoreMenuProps) => {
  const { onEdit, onDelete } = props;

  const { formatMessage: f } = useIntl();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVertical size={16} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[100000]" align="end">
        <DropdownMenuItem onClick={onEdit} disabled={!onEdit}>
          <Pencil size={16} />
          <span>{f({ id: 'edit' })}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onDelete} disabled={!onDelete}>
          <Trash size={16} />
          <span>{f({ id: 'delete' })}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
