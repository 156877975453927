import { ArrowUp } from 'lucide-react';
import { Button } from '@xspecs/design-system';

type CommentTextAreaSubmitButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const CommentTextAreaSubmitButton = (props: CommentTextAreaSubmitButtonProps) => {
  const { onClick, disabled } = props;

  return (
    <Button variant="default" size="icon" disabled={disabled} onClick={onClick} className="h-8 w-8">
      <ArrowUp />
    </Button>
  );
};
