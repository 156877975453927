import { forwardRef, useMemo } from 'react';
import { Actor } from './components/actor';
import { Query } from './components/query';
import { Spec } from './components/spec';
import { KeyValuePairs } from './components/key-value-pairs';
import { AddFrame } from './components/add-frame';
import { RemoveFrame } from './components/remove-frame';
import { Doc } from './components/doc';
import { ConstructPreview } from './components/construct-preview';
import { Maximize } from './components/maximize';
import { Minimize } from './components/minimize';
import { Script } from './components/script';
import { ConstructRectanglePreview } from './components/construct-rectangle-preview';
import { ShowDetails } from './components/show-details';
import { Comment } from './components/comment';
import {
  Add,
  AttachFileRounded,
  Block,
  CloseOutlined,
  ContentCopy,
  ContentPaste,
  ControlPointDuplicate,
  Done,
  FolderOutlined,
  FormatColorFill,
  FormatColorText,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LabelOutlined,
  LineStyle,
  LineWeight,
  Link,
  Lock,
  LockOpen,
  MoreHoriz,
  Redo,
  Remove,
  SearchOutlined,
  SwapHoriz,
  Undo,
} from '@mui/icons-material';
import { Folder, MousePointerClick, SendToBack, Text } from 'lucide-react';
import { ExpandScript } from './components/expand-script';
import { CollapseScript } from './components/collapse-script';
import { Subscript } from './components/subscript';
import { ExpandSubscript } from './components/expand-subscript';
import { CollapseSubscript } from './components/collapse-subscript';
import { Expand } from './components/expand';
import { ExpandImage } from './components/expand-image';
import { CopyGqlLink } from './components/copy-gql-link';
import { Jira } from './components/jira';
import { Confluence } from './components/confluence';
import { Slack } from './components/slack';
import { Linear } from './components/linear';
import { Notion } from './components/notion';
import { Miro } from './components/miro';
import { GoogleSheets } from './components/google-sheets';
import { GoogleDocs } from './components/google-docs';
import { Figma } from './components/figma';
import { SortingAZ } from './components/sorting-a-z';
import { SortingZA } from './components/sorting-z-a';
import { ShowSidebar } from './components/show-sidebar';
import { HideSidebar } from './components/hide-sidebar';
import { Explorer } from './components/explorer';
import { AssetsStack } from './components/assets-stack';
import { Square } from './components/square';
import { Rectangle } from './components/rectangle';
import { logger } from '@xspecs/logger';
import { Cursor } from './components/cursor';
import { ArrowLeft } from './components/arrow-left';
import { ArrowRight } from './components/arrow-right';
import { TextRemove } from './components/text-remove';
import { TextAdd } from './components/text-add';
import { DashedLine } from './components/dashed-line';
import { DottedLine } from './components/dotted-line';
import { Line } from './components/line';
import { CurvedEdge } from './components/curved-edge';
import { OrthogonalEdge } from './components/orthogonal-edge';
import { StraightEdge } from './components/straight-edge';
import { GripHorizontal } from './components/grip-horizontal';
import { InsertBefore } from './components/insert-before';
import { InsertAfter } from './components/insert-after';
import { RecentFolder } from './components/recent-folder';
import { Boxes } from './components/boxes';
import { Ghost } from './components/ghost';
import { Files } from './components/files';
import { Json } from './components/json';
import { StateChange } from './components/state-change';
import { StateView } from './components/state-view';
import { Translation } from './components/translation';
import { Automation } from './components/automation';
import { Grid3x3 } from './components/grid3x3';
import { Delete } from './components/delete';
import { MouseLeftClick } from './components/mouse-left-click';
import { MouseWheel } from './components/mouse-wheel';
import { TrackpadZoom } from './components/trackpad-zoom';
import { TrackpadClick } from './components/trackpad-click';
import { Rtf } from './components/rtf';
import { Feature } from './components/feature';
import { Graphql } from './components/graphql';
import { Markdown } from './components/markdown';
import { Ts } from './components/ts';
import { Js } from './components/js';
import { OnAuto } from '@xspecs/design-system/src/ui/icons/components/on-auto';
import { OnAutoIcon } from '@xspecs/design-system/src/ui/icons/components/on-auto-icon';

export type IconName = keyof typeof iconsMap;

export type IconProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  name: IconName | (string & {});
  height?: number;
  width?: number;
  color?: string;
  iconBorder?: string;
  'data-testid'?: string;
  strokeWidth?: number;
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const iconsWithPadding: (IconName | (string & {}))[] = [
  'key-value-pairs',
  'confluence',
  'jira',
  'slack',
  'notion',
  'asset-stack',
  'explorer',
];

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { name, height = 24, width = 24, color = 'currentColor', strokeWidth, iconBorder, className } = props;

  const IconComponent = iconsMap[name];

  const aspectRatio = width / height;

  const requiresPadding = useMemo(() => iconsWithPadding.includes(name), [name]);

  if (!IconComponent) {
    logger.error(`Icon ${name} not found`);
    return null;
  }

  if (name.startsWith('lucide')) {
    return (
      <IconComponent data-testid={props['data-testid']} ref={ref} width={width} height={height} className={className} />
    );
  }

  return (
    <svg
      data-testid={props['data-testid']}
      ref={ref}
      width={width}
      height={height}
      viewBox={Math.floor(aspectRatio) === 1 ? `0 0 24 24` : `0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        paddingLeft: requiresPadding ? 3 : 0,
        paddingTop: requiresPadding ? 3 : 0,
      }}
    >
      {/* @ts-ignore */}
      {typeof IconComponent === 'function' ? (
        <IconComponent color={color} strokeWidth={strokeWidth} borderColor={props.iconBorder} />
      ) : (
        <IconComponent sx={{ color }} />
      )}
    </svg>
  );
});

Icon.displayName = 'Icon';

export const iconsMap = {
  actor: Actor,
  spec: Spec,
  'key-value-pairs': KeyValuePairs,
  query: Query,
  'add-frame': AddFrame,
  'remove-frame': RemoveFrame,
  done: Done,
  doc: Doc,
  box: ConstructPreview, //TODO: rename this icon to construct-preview
  maximize: Maximize,
  minimize: Minimize,
  script: Script,
  'construct-rect-preview': ConstructRectanglePreview,
  'show-details': ShowDetails,
  comment: Comment,
  upload: AttachFileRounded,
  attachment: AttachFileRounded,
  'expand-script': ExpandScript,
  'collapse-script': CollapseScript,
  'expand-subscript': ExpandSubscript,
  'collapse-subscript': CollapseSubscript,
  subscript: Subscript,
  label: LabelOutlined,
  search: SearchOutlined,
  close: CloseOutlined,
  expand: Expand,
  'expand-image': ExpandImage,
  'copy-gql-link': CopyGqlLink,
  jira: Jira,
  confluence: Confluence,
  slack: Slack,
  linear: Linear,
  notion: Notion,
  miro: Miro,
  'google-sheets': GoogleSheets,
  'google-docs': GoogleDocs,
  figma: Figma,
  'sorting-a-z': SortingAZ,
  'sorting-z-a': SortingZA,
  'show-sidebar': ShowSidebar,
  'hide-sidebar': HideSidebar,
  'asset-stack': AssetsStack,
  square: Square,
  rectangle: Rectangle,
  cursor: Cursor,
  explorer: Explorer,
  folder: FolderOutlined,
  link: Link,
  'centered-line': (props) => <Remove color="action.active" {...props} />,
  'swap-horiz': SwapHoriz,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'line-style': LineStyle,
  'format-color-fill': FormatColorFill,
  'line-weight': LineWeight,
  'text-remove': TextRemove,
  'text-add': TextAdd,
  'format-color-text': FormatColorText,
  lock: Lock,
  'lock-open': LockOpen,
  'more-horiz': MoreHoriz,
  'keyboard-down': KeyboardArrowDown,
  'keyboard-up': KeyboardArrowUp,
  'dotted-line': DottedLine,
  'dashed-line': DashedLine,
  undo: Undo,
  redo: Redo,
  copy: ContentCopy,
  paste: ContentPaste,
  duplicate: ControlPointDuplicate,
  delete: Delete,
  line: Line,
  'curved-edge': CurvedEdge,
  'orthogonal-edge': OrthogonalEdge,
  'straight-edge': StraightEdge,
  GripHorizontal: GripHorizontal,
  block: Block,
  add: Add,
  'insert-before': InsertBefore,
  'insert-after': InsertAfter,
  'recent-folder': RecentFolder,
  boxes: Boxes,
  ghost: Ghost,
  files: Files,
  json: Json,
  'state-change': StateChange,
  'state-view': StateView,
  translation: Translation,
  automation: Automation,
  grid3x3: Grid3x3,
  'mouse-pointer-click': MousePointerClick,
  'mouse-left-click': MouseLeftClick,
  'mouse-wheel': MouseWheel,
  'trackpad-click': TrackpadClick,
  'trackpad-zoom': TrackpadZoom,
  'lucide-folder': Folder,
  'lucide-sent-to-back': SendToBack,
  'lucide-text': Text,
  txt: Text,
  rtf: Rtf,
  feature: Feature,
  graphql: Graphql,
  model: SendToBack,
  em: SendToBack,
  ndd: SendToBack,
  md: Markdown,
  ts: Ts,
  js: Js,
  'on-auto': OnAuto,
  'on-auto-icon': OnAutoIcon,
} as const;

export type IconComponentProps = {
  color: string;
  strokeWidth?: string;
  borderColor?: string;
};
