import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Cursor: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M18.0957 13.2816L7.93216 3.12163C7.55987 2.82603 7 3.10816 7 3.55026V17.435C7 17.7458 7.25218 17.9979 7.56289 17.9979C7.70925 17.9979 7.84956 17.9416 7.92996 17.8605L10.7458 15.4071L13.0425 20.7726C13.0815 20.8641 13.155 20.9362 13.2474 20.9738C13.2924 20.9909 13.3397 21 13.387 21C13.4373 21 13.4876 20.9894 13.5349 20.9692L15.9515 19.9334C16.1421 19.8525 16.2307 19.6318 16.149 19.4412L13.9258 14.2453H17.695C18.0057 14.2453 18.2579 13.9931 18.2579 13.6824C18.2579 13.5915 18.2347 13.4549 18.0957 13.2816Z"
        fill={color}
      />
    </>
  );
};
