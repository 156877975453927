import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Attachment } from '../../entities/assets/Attachment';
import { EntityType } from '../../entities/EntityType';
import { CommandError } from '../../ErrorStore';

interface LinkAssetToAttachmentParams extends IParams {
  attachmentId: string;
  assetId: string;
}

interface AssetLinkedToAttachmentEventParams extends IParams {
  attachmentId: string;
  assetId: string;
}

export class AssetLinkedToAttachmentEvent extends EventBase {
  static eventType = 'AssetLinkedToAttachmentEvent';

  constructor(
    public readonly params: AssetLinkedToAttachmentEventParams,
    public readonly source = LinkAssetToAttachmentCommand,
  ) {
    super();
  }
}

export class LinkAssetToAttachmentCommand extends CommandBase<LinkAssetToAttachmentParams> {
  execute(params: LinkAssetToAttachmentParams): AssetLinkedToAttachmentEvent | CommandError {
    const model = this.getModelContext();
    const attachment = model.entityRepository.get<Attachment>(params.attachmentId);
    if (!attachment) return CommandError.of(new Error('Attachment not found'), 'error');
    const asset = model.entityRepository.get(params.assetId);
    if (!asset) {
      return CommandError.of(new Error('Asset not found'), 'error');
    }
    if ((attachment.subType as unknown as EntityType) !== asset.type)
      return CommandError.of(new Error('Asset type and attachment subtype mismatch'), 'error');

    attachment.linkAsset(asset);
    model.entityRepository.update(attachment);
    model.entityRepository.update(asset);
    return new AssetLinkedToAttachmentEvent({ attachmentId: params.attachmentId, assetId: params.assetId });
  }
}
