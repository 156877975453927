import { logger } from '@xspecs/logger';
import * as Y from 'yjs';
import { SpaceFile } from './SpaceFile';
import { MessageBus } from '../commands/framework/MessageBus';
import { FileLoadedEvent } from '../commands/spaces/LoadFileCommand';
import { BroadcastSpaceSettingChangesCommand } from '../commands/spaces/BroadcastSpaceSettingChangesCommand';

export class SpaceSettingsRepository {
  private activeSpace?: SpaceFile;
  private observer?: (events: Y.YEvent<any>[], transaction: Y.Transaction) => void;
  private trackedSettings: Map<string, any> = new Map();

  public constructor(private readonly messageBus: MessageBus) {
    messageBus.subscribe([FileLoadedEvent], (event: FileLoadedEvent) => {
      if (event.params.fileType !== 'space') return;
      this.handleSpaceLoad(event.params.file as SpaceFile);
    });
  }

  public get(key: string): any | undefined {
    return this.trackedSettings.get(key);
  }

  public list(): Record<string, any> {
    return Object.fromEntries(this.trackedSettings);
  }

  private handleSpaceLoad(space: SpaceFile): void {
    if (this.activeSpace === space) return;
    if (this.activeSpace && this.observer) {
      this.activeSpace.settingsMap.unobserveDeep(this.observer);
    }
    if (!space) {
      logger.warn('SpaceSettingsRepository: No space provided in LoadFileCommand.');
      this.activeSpace = undefined;
      return;
    }
    this.trackedSettings.clear();

    this.observer = (events: Y.YEvent<any>[], transaction: Y.Transaction) => {
      if (transaction.origin === 'save') return;
      const addedSettings: Record<string, any> = {};
      const updatedSettings: Record<string, any> = {};
      const deletedSettings: string[] = [];

      events.forEach((event) => {
        if (event instanceof Y.YMapEvent) {
          event.changes.keys.forEach((change, key) => {
            if (change.action === 'delete') {
              deletedSettings.push(key);
            } else {
              let value = event.target.get(key);
              if (value instanceof Y.Array) {
                value.observe((arrayEvent: Y.YArrayEvent<any>) => this.handleArrayChange(arrayEvent, key));
                value = value.toArray();
              }
              if (change.action === 'add') {
                addedSettings[key] = value;
              } else if (change.action === 'update') {
                updatedSettings[key] = value;
              }
              this.trackedSettings.set(key, value);
            }
          });
        } else if (event instanceof Y.YArrayEvent) {
          this.handleArrayChange(event, null);
        }
      });

      this.messageBus.sendInternal(BroadcastSpaceSettingChangesCommand, {
        added: addedSettings,
        updated: updatedSettings,
        deleted: deletedSettings,
      });
    };

    space.settingsMap.observeDeep(this.observer);
    this.activeSpace = space;
  }

  private handleArrayChange(event: Y.YArrayEvent<any>, key: string | null) {
    const targetArray = event.target as Y.Array<any>;
    const updatedValue = targetArray.toArray();

    if (key) {
      this.trackedSettings.set(key, updatedValue);
      this.messageBus.sendInternal(BroadcastSpaceSettingChangesCommand, {
        added: { [key]: updatedValue },
        updated: {},
        deleted: [],
      });
    }
  }
}
