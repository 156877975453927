import { CommandBase, IParams } from '../framework/CommandBase';
import { EntitiesSelectionsUpdatedEvent } from './UpdateEntitiesSelectionsCommand';
import { CommandError } from '../../ErrorStore';

interface SelectAllEntitiesCommandParams extends IParams {}

export class SelectAllEntitiesCommand extends CommandBase<SelectAllEntitiesCommandParams> {
  execute(_params: SelectAllEntitiesCommandParams): EntitiesSelectionsUpdatedEvent | CommandError {
    const entities = this.getModelContext().entityRepository.list();
    entities.forEach((entity) => entity.select());
    return new EntitiesSelectionsUpdatedEvent({
      entitySelections: entities.map((entity) => ({
        id: entity.id,
        selected: true,
      })),
    });
  }
}
