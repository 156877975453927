import { MouseEventHandler, ReactNode } from 'react';
import { DropdownMenuItem } from '@xspecs/design-system';

type InsertMenuItemProps = {
  Icon: ReactNode;
  title: string;
  description: string;
  focused: boolean;
  disabled?: boolean;
  onItemClick?: MouseEventHandler;
};

export const InsertMenuItem = (props: InsertMenuItemProps) => {
  const { Icon, title, description, disabled, onItemClick } = props;

  return (
    <DropdownMenuItem data-testid={title} disabled={disabled} onMouseDown={onItemClick} aria-label={description}>
      {Icon}
      <span>{title}</span>
    </DropdownMenuItem>
  );
};
