import { CommandBase, IParams } from '../framework/CommandBase';
import { EntitiesSelectionsUpdatedEvent } from './UpdateEntitiesSelectionsCommand';
import { CommandError } from '../../ErrorStore';
import { EntityBase } from '../../entities/EntityBase';

interface DeselectAllEntitiesCommandParams extends IParams {}

export class DeselectAllEntitiesCommand extends CommandBase<DeselectAllEntitiesCommandParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(_params: DeselectAllEntitiesCommandParams): EntitiesSelectionsUpdatedEvent | CommandError {
    const modelContext = this.getModelContext();
    const entities: EntityBase[] = [];
    modelContext.graph.selectedCache.forEach((value) => {
      const entity = modelContext.entityRepository.get(value);
      if (entity) {
        entity.deselect();
        entities.push(entity);
      }
    });
    return new EntitiesSelectionsUpdatedEvent({
      entitySelections: entities.map((entity) => ({ id: entity.id, selected: false })),
    });
  }
}
