import { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip';
import { SpaceMoreMenu } from './space-more-menu';

export interface SpaceNameProps {
  spaceName: string;
  spaces: { id: string; name: string; onClick: () => void }[];
  viewSpaces?: () => void;
  onSaveSpaceRename?: (name: string) => void;
}

export const SpaceName = (props: SpaceNameProps) => {
  const { spaceName, spaces, viewSpaces, onSaveSpaceRename: onSaveSpaceRenameProp } = props;
  const [newSpaceName, setNewSpaceName] = useState(spaceName);
  const [spaceNameEditMode, setSpaceNameEditMode] = useState(false);
  const spaceNameInputRef = useRef<HTMLInputElement>(null);

  const onSaveSpaceRename = (name: string) => {
    if (name !== spaceName) onSaveSpaceRenameProp?.(name);
  };

  const handleSpaceNameOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setSpaceNameEditMode(false);
      onSaveSpaceRename(newSpaceName);
    }
  };

  const handleSpaceNameOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    setSpaceNameEditMode(false);
    onSaveSpaceRename(newSpaceName);
  };

  useEffect(() => {
    setNewSpaceName(spaceName);
  }, [spaceName]);

  useEffect(() => {
    if (spaceNameInputRef.current) {
      setTimeout(() => {
        spaceNameInputRef.current?.focus();
        spaceNameInputRef.current?.select();
      }, 0);
    }
  }, [spaceNameInputRef, spaceNameEditMode]);

  return (
    <div className="flex gap-2 items-center py-1">
      {spaceNameEditMode ? (
        <input
          className="w-[200px] text-primary self-start text-left font-sm font-semibold outline-0 selection:bg-base-primary-dark/25"
          value={newSpaceName}
          onChange={(e) => setNewSpaceName(e.target.value)}
          ref={spaceNameInputRef}
          onKeyDown={handleSpaceNameOnKeyDown}
          onBlur={handleSpaceNameOnBlur}
          readOnly={!onSaveSpaceRenameProp}
        />
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className="w-[200px] h-full text-primary self-start text-left font-sm font-semibold whitespace-nowrap overflow-hidden text-ellipsis"
              onClick={() => setSpaceNameEditMode(true)}
            >
              {newSpaceName}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{newSpaceName}</p>
          </TooltipContent>
        </Tooltip>
      )}
      {viewSpaces ? (
        <SpaceMoreMenu spaces={spaces} onRename={() => setSpaceNameEditMode(true)} viewSpaces={viewSpaces} />
      ) : null}
    </div>
  );
};
