import { IStore } from '../../data/Store';
import { FileTreeItem, FileTreeState } from './FileTree.types';
import { fileTreeAddItemsMenu } from './FileTreeAddFileItems';

export const DEFAULT_FILE_TREE_STATE: FileTreeState = {
  rootId: 'file-tree-root',
  items: [],
  addItemsMenu: fileTreeAddItemsMenu,
  onRenameItem: {
    command: 'RenameFileTreeItemCommand',
  },
  onDeleteItem: {
    command: 'DeleteFileTreeItem',
  },
};

export type FileTreeEntry = {
  id: string;
  isExternal: boolean;
  fileName: string;
  fileType: string;
  timestamp: string | undefined;
  entityType?: string;
  entityId?: string;
  parentFileName?: string;
  parentId: string | undefined;
  text: string;
  content: string | undefined;
};

export class FileTree {
  public cache: Record<string, FileTreeEntry> = {};
  public rootId: string = 'file-tree-root';
  public selectedItem: string;

  constructor(private readonly store: IStore) {
    this.store.getState().setFileTree({ ...DEFAULT_FILE_TREE_STATE });
  }

  applyChange(entity: FileTreeEntry) {
    const existingEntity = this.cache[entity.id];
    if (existingEntity && this.isEqual(existingEntity, entity)) {
      return;
    }
    this.cache[entity.id] = entity;
    this.updateStore();
  }

  getCurrentState(): FileTreeEntry[] {
    return Object.values(this.cache);
  }

  delete({ id }: { id: string }) {
    delete this.cache[id];
    this.updateStore();
  }

  updateStore() {
    const devMode = this.store.getState().devMode;
    const items: FileTreeItem[] = Object.entries(this.cache)
      .filter(([_, entity]) => devMode || !entity.isExternal)
      .map(([key, entity]) => {
        return {
          id: entity.id,
          parent: entity.parentId ?? this.rootId,
          droppable: entity.fileType === 'folder',
          text: entity.text,
          data: {
            ...entity,
            content: entity.content || '',
            isActive: entity.id === this.selectedItem,
            icon: entity.fileType,
            timestamp: entity.timestamp,
          },
        } satisfies FileTreeItem;
      });
    this.store.getState().setFileTree({
      rootId: this.rootId,
      items: items,
      addItemsMenu: fileTreeAddItemsMenu,
      onRenameItem: {
        command: 'RenameFileTreeItemCommand',
      },
      onDeleteItem: {
        command: 'DeleteFileTreeItemCommand',
      },
    });
  }

  hasPath(path: string): boolean {
    return Object.keys(this.cache).some((key) => key.startsWith(path));
  }

  clear() {
    this.store.getState().setFileTree({ ...DEFAULT_FILE_TREE_STATE });
    this.cache = {};
  }

  private isEqual(a: FileTreeEntry, b: FileTreeEntry): boolean {
    return (
      a.id === b.id &&
      a.fileName === b.fileName &&
      a.fileType === b.fileType &&
      a.entityId === b.entityId &&
      a.parentFileName === b.parentFileName &&
      a.parentId === b.parentId &&
      a.text === b.text &&
      a.timestamp === b.timestamp &&
      a.content === b.content
    );
  }

  selectItem(id: string) {
    this.selectedItem = id;
    this.updateStore();
  }

  dispose() {
    this.clear();
  }
}
