import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AppRegistry } from '../../apps/AppRegistry';
import { App } from '../../apps/App.types';
import { Construct, Asset, Script } from 'narrative-studio-sdk';

interface RegisterAppParams extends IParams {
  apps: App[];
}

export class RegisterAppsCommand extends CommandBase<RegisterAppParams> {
  execute(params: RegisterAppParams): EventBase | CommandError | undefined {
    const entities: { constructs: Construct[]; assets: Asset[]; scripts: Script[] } = {
      constructs: [],
      assets: [],
      scripts: [],
    };
    params.apps.forEach((app) => {
      this.appState.workerManager.addWorker({
        appName: app.name,
        workerScriptUrl: app.appUrl,
      });
      entities.constructs.push(
        ...app.schemes.flatMap((scheme) => scheme.categories.flatMap((category) => category.constructs ?? [])),
      );
      entities.assets.push(
        ...app.schemes.flatMap((scheme) => scheme.categories.flatMap((category) => category.assets ?? [])),
      );
      this.appState.toolbar.addSchemes(app.schemes);
    });
    AppRegistry.registerEntities(entities);
    return;
  }
}
