import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export type EntitySelection = { id: string; selected: boolean };

export interface UpdateEntitiesSelectionsParams extends IParams {
  entitySelections: EntitySelection[];
  immediate?: boolean;
}

interface EntitiesSelectionsUpdatedParams extends IParams {
  entitySelections: EntitySelection[];
}

export class EntitiesSelectionsUpdatedEvent extends EventBase {
  static eventType = 'EntitiesSelectionsUpdatedEvent';

  constructor(
    public readonly params: EntitiesSelectionsUpdatedParams,
    public readonly source = UpdateEntitiesSelectionsCommand,
  ) {
    super();
  }
}

export class UpdateEntitiesSelectionsCommand extends CommandBase<UpdateEntitiesSelectionsParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(params: UpdateEntitiesSelectionsParams): EntitiesSelectionsUpdatedEvent | CommandError {
    params.entitySelections.forEach((entitySelection) => {
      const entity = this.getModelContext().entityRepository.get(entitySelection.id);
      if (!entity) return;
      if (entitySelection.selected) entity.select(params.immediate);
      else entity.deselect();
    });
    return new EntitiesSelectionsUpdatedEvent({ entitySelections: params.entitySelections });
  }
}
