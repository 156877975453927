import { ApplicationContext } from './ApplicationContext';
import { User } from './types';
import { SingleSourceModelTestFactory } from './test-utilities/SingleSourceModelTestFactory';
import { FileStoreClient } from './data/FileStoreClient';
import { IStore } from './data/Store';
import { VERSION } from '@xspecs/app-version';
import { TestCodeHandler } from './TestCodeHandler';
import { Analytics } from './analytics/analytics';
import { SchemeName } from './commands/apps/SetSchemeCommand';
import { AppSchemesBuilder } from './apps/AppSchemesBuilder';
import { AppRegistry } from './apps/AppRegistry';
import { eventModelSerializationRules } from './apps/eventModeling/EventModelingSerializationRules';
import { FileTreeProjection } from './read-models/file-tree/FileTreeProjection';
import { BuildLogProjection } from './read-models/build-log/BuildLogProjection';
import { BuildLog } from './read-models/build-log/BuildLog';
import { logger } from '@xspecs/logger';
import { ModelContext } from './ModelContext';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const location = isBrowser ? window.location : { origin: 'http://localhost', hostname: 'localhost' };

function purgeLocalDatabases() {
  const idPattern = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/[a-zA-Z0-9]{10}-?([a-zA-Z0-9]*)$/;
  indexedDB.databases().then((databases) => {
    databases.forEach((db) => {
      if (db.name && idPattern.test(db.name)) {
        indexedDB.deleteDatabase(db.name);
        logger.debug(`Deleted local database: ${db.name}`);
      }
    });
  });
}

function storedVersionMismatches(appVersion: number) {
  const storedVersion = localStorage.getItem('APP_VERSION') ?? -1;
  return appVersion !== storedVersion;
}

function handleAppVersionMismatch(appVersion: number) {
  if (isBrowser) {
    if (storedVersionMismatches(appVersion)) purgeLocalDatabases();
    localStorage.setItem('APP_VERSION', `${appVersion}`);
  }
}

export class Application {
  private readonly fileStoreClient: FileStoreClient;
  public state: ApplicationContext;
  public readonly store: IStore;

  private static instance: Application;
  private readonly testEnv: boolean;

  public static getInstance(
    store: IStore,
    appVersion = VERSION,
    testEnv = false,
    analyticsContainer = { dataLayer: [] },
  ) {
    if (testEnv) return new Application(store, appVersion, true, analyticsContainer);
    if (!Application.instance && !store)
      throw new Error('You must provide a store if no Application instance has been requested yet');
    if (!Application.instance) Application.instance = new Application(store, appVersion, false, analyticsContainer);
    return Application.instance;
  }

  private constructor(store: IStore, appVersion: number, testEnv: boolean, analyticsContainer: any) {
    this.testEnv = testEnv;
    this.store = store;
    handleAppVersionMismatch(appVersion);
    this.fileStoreClient = new FileStoreClient(resolveFileStoreUrl(), this.store, appVersion, testEnv);
    Analytics.setInstance(analyticsContainer, undefined, undefined);
  }

  start(params: { token: string; user: User }) {
    const { token, user } = params;
    this.fileStoreClient.setContext({ token, user, host: resolveHost() });
    // ToDo: this a temp solution while we move the Event Modelling app out of the main app
    const schemes = AppSchemesBuilder.Build(SchemeName.EventModelling);
    schemes.forEach((scheme) => {
      AppRegistry.registerScheme(scheme);
    });
    AppRegistry.registerSerializationRules(eventModelSerializationRules);
    if (this.testEnv) this.state = SingleSourceModelTestFactory.create(this.store, this.fileStoreClient);
    else this.state = ApplicationContext.initialize(this.store, this.fileStoreClient);
    new FileTreeProjection(this.state.messageBus, this.state.fileTree);
    new BuildLogProjection(this.state.messageBus, new BuildLog(this.store));
    const testCodeHandler = new TestCodeHandler(this.state);
    this.state.messageBus.registerEventHandler(testCodeHandler.handles(), testCodeHandler);
  }

  getModelContext = () => {
    if (ModelContext.hasActiveModel()) return ModelContext.getInstance();
    return undefined;
  };

  loadModelVersion({ fileId, version }: { fileId: string; version: string }) {
    return this.fileStoreClient.loadModelVersion({ fileId, version });
  }

  dispose() {
    this.state.dispose();
  }
}

export const resolveHost = () => {
  if (location.origin.includes('localhost')) return undefined;
  if (location.origin.includes('app.xspecs.ai') || location.origin.includes('app.narrative.tech')) return undefined;
  return `${location.origin.split('.')[0].split('//')[1]}`;
};

const resolveFileStoreUrl = () => {
  if (location.origin.includes('localhost')) return 'ws://127.0.0.1:8080';
  if (location.origin.includes('app.xspecs.ai')) return 'wss://fs.narrative.tech';
  if (location.origin.includes('app.narrative.tech')) return 'wss://fs.narrative.tech';
  return 'wss://fsdev.xspecs.io';
};
