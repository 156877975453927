import { Button, cn, Icon, IconName } from '@xspecs/design-system';

export interface SpacesSideBarLinkProps {
  label: string;
  icon: IconName;
  onChange: () => void;
  selected: boolean;
  collapsed: boolean;
}

export const SpacesSideBarLink = (props: SpacesSideBarLinkProps) => {
  const { label, icon, onChange, selected, collapsed } = props;

  return (
    <Button
      className={cn(
        'flex gap-1 items-center justify-start p-2',
        selected ? 'bg-accent text-primary' : 'text-secondary',
      )}
      variant="ghost"
      onClick={onChange}
    >
      {icon ? <Icon name={icon} width={16} height={16} /> : null}
      {label && !collapsed ? <p className="text-primary">{label}</p> : null}
    </Button>
  );
};
