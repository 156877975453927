import { Box } from '@mui/material';
import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
} from '@floating-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Node, NodeProps } from '@xyflow/react';
import { ThreadNodePrimitive } from './thread-node-primitive';
import { Thread } from './thread/thread';
import { useSearchParams } from 'react-router-dom';
import { AppTypeEvent, useTrackEvents } from '../../../../../hooks/use-track-events';
import { ThreadNodeData } from '@xspecs/single-source-model';
import { useApplication } from '../../../../../wrappers/application-context/application-context';

export const ThreadNode = (props: NodeProps<Node<ThreadNodeData>>) => {
  const { id, data } = props;

  const [searchParams] = useSearchParams();
  const entityId = searchParams.get('entityId');

  const { trackEvent } = useTrackEvents();

  const [showCommentsList, setShowCommentsList] = useState(data.isNew ?? false);

  const { application } = useApplication();
  const model = application?.getModelContext();

  const options = useMemo<UseFloatingOptions>(
    () => ({
      placement: 'right',
      middleware: [offset(5), autoPlacement({ allowedPlacements: ['right', 'right-end', 'right-end'] })],
      open: showCommentsList,
      onOpenChange: (open) => {
        setShowCommentsList(open);
        if (!open && data.comments.length === 0) {
          model?.entities.delete(id);
          trackEvent(AppTypeEvent.CommentThreadDeleted, { threadId: id });
        }
      },
      whileElementsMounted: autoUpdate,
    }),
    [data.comments.length, id, model?.entities, showCommentsList, trackEvent],
  );
  const { refs, context, floatingStyles } = useFloating(options);
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  const onClose = useCallback(() => {
    setShowCommentsList(false);
    if (data.comments.length === 0) {
      model?.entities.delete(id);
      trackEvent(AppTypeEvent.CommentThreadDeleted, { threadId: id });
    }
  }, [data.comments.length, model?.entities, id, trackEvent]);

  useEffect(() => {
    if (id === entityId) {
      setShowCommentsList(true);
    }
  }, [entityId, id, model?.entities]);

  return (
    <Fragment>
      <Box className="dragHandle" ref={refs.setReference} {...getReferenceProps()} sx={rootSx}>
        <ThreadNodePrimitive
          participantIds={data.participantIds}
          isResolved={data.isResolved}
          getReferenceProps={getReferenceProps}
        />
      </Box>
      {showCommentsList ? (
        <FloatingPortal root={document.body}>
          <div
            className="nodrag nowheel rounded-lg shadow-sm cursor-default p-2 w-xs bg-popover border"
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            <Thread onClose={onClose} threadId={id} comments={data.comments} isResolved={data.isResolved} />
          </div>
        </FloatingPortal>
      ) : null}
    </Fragment>
  );
};

const rootSx = {
  width: 'max-content',
};
