import { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu';
import { Eye, FilePenLine, MoreHorizontal } from 'lucide-react';
import { cn } from '../../utils';
import { useIntl } from 'react-intl';

export interface SpaceMoreMenuProps {
  spaces: { id: string; name: string; onClick: () => void }[];
  onRename: () => void;
  viewSpaces: () => void;
}

export const SpaceMoreMenu = (props: SpaceMoreMenuProps) => {
  const { spaces, onRename, viewSpaces } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: f } = useIntl();

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <div className={cn('flex justify-center items-center hover:bg-accent rounded', isOpen && 'bg-base-primary')}>
          <MoreHorizontal
            size="16"
            className={cn('flex-shrink-0 text-icon group-data-[collapsible=icon]:hidden', isOpen && 'text-icon-white')}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        side="bottom"
        align="start"
        sideOffset={4}
      >
        <DropdownMenuLabel className="p-0 text-sm text-muted-foreground leading-3 px-2 py-1.5 font-medium">
          {f({ id: 'select-another-space' })}
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          {spaces.map((space) => (
            <DropdownMenuItem key={space.id} className="text-primary" onClick={space.onClick}>
              {space.name}
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem onClick={viewSpaces}>
            <Eye className="text-muted-foreground" />
            {f({ id: 'view-all-spaces' })}
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel className="p-0 text-sm text-muted-foreground leading-3 px-2 py-1.5 font-medium">
          {f({ id: 'action' })}
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={onRename}>
            <FilePenLine className="text-icon-muted" />
            {f({ id: 'rename' })}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
