import { SpaceItem } from './space-item/space-item';
import { AddSpace } from './add-space/add-space';
import { Workspace } from '../../../gql/graphql';

export interface SpaceListProps {
  spaces: Workspace[];
}

export const SpaceList = (props: SpaceListProps) => {
  const { spaces } = props;

  return (
    <div className="grid gap-3 grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))]">
      <AddSpace />
      {spaces.map((space) => (
        <SpaceItem key={space.id} {...space} />
      ))}
    </div>
  );
};
