import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { DEBUG_CONFIG } from '../../debug-config';
import { CommandError } from '../../ErrorStore';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Attachment } from '../../entities/assets/Attachment';

interface DeleteAssetParams extends IParams {
  entityId: string;
}

interface EntitiesDeletedParams extends IParams {
  entityId: string;
  affected?: { entityId: string }[];
}

export class EntitiesDeletedEvent extends EventBase {
  static eventType = 'EntitiesDeletedEvent';

  constructor(
    public readonly params: EntitiesDeletedParams,
    public readonly source = DeleteAssetCommand,
  ) {
    super();
  }
}

export class DeleteAssetCommand extends CommandBase<DeleteAssetParams> {
  execute(params: DeleteAssetParams): EntitiesDeletedEvent | CommandError {
    this.deleteIndexEntry(params.entityId);
    let attachments: { entityId: string }[] = [];
    const modelContext = this.getModelContext();
    const asset = modelContext.entityRepository.get(params.entityId);
    if (!asset || !(asset instanceof AssetBase))
      return CommandError.of(new Error(`Asset with id ${params.entityId} not found`), 'error');
    attachments = modelContext.entityRepository
      .list()
      .filter((entity) => {
        if (entity instanceof Attachment) {
          if (entity.asset?.id === params.entityId) {
            return true;
          }
        }
      })
      .map((entity) => ({ entityId: entity.id }));

    attachments.forEach(({ entityId }) => {
      modelContext.entityRepository.delete(entityId);
    });

    modelContext.entityRepository.delete(params.entityId);

    return new EntitiesDeletedEvent({ entityId: params.entityId, affected: attachments });
  }

  // THIS SHOULD ALL BE EVENT DRIVEN

  private deleteIndexEntry(id: string) {
    const modelContext = this.getModelContext();
    modelContext.boundariesIndex.removeEntry(id);
    const entity = modelContext.entityRepository.get(id);
    if (entity instanceof ScriptBase) {
      for (const frame of entity.getFrames()) {
        for (const lane of frame.lanes) {
          this.deleteIndexEntry(`${entity.id}_${frame.frameIndex}_${lane.laneIndex}`);
        }
      }
    }
    if (DEBUG_CONFIG.boundaries) modelContext.graph['showBoundaries']();
  }
}
