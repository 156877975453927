import { Bot, ChevronDown, FileCode2, Play, RotateCcwIcon } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu';
import { useMemo } from 'react';

export interface DeployButtonsDropdownProps {
  triggerCodegen: () => void;
  triggerImplementation: () => void;
  triggerDeployment: () => void;
  cleanFiles: () => void;
  toggleLogsPanel: (type: string, open?: boolean) => void;
}

export function DeployButtonsDropdown(props: DeployButtonsDropdownProps) {
  const { triggerCodegen, triggerImplementation, triggerDeployment, cleanFiles, toggleLogsPanel } = props;

  const buttons = useMemo(
    () => [
      {
        label: 'Reset',
        onClick: () => {
          cleanFiles();
          toggleLogsPanel('Reset', true);
        },
        icon: RotateCcwIcon,
      },
      {
        label: 'Scaffold',
        onClick: () => {
          triggerCodegen();
          toggleLogsPanel('Scaffold', true);
        },
        icon: FileCode2,
      },
      {
        label: 'Implement with AI',
        onClick: () => {
          triggerImplementation();
          toggleLogsPanel('Implement with AI', true);
        },
        icon: Bot,
      },
      {
        label: 'Deploy',
        onClick: () => {
          triggerDeployment();
          toggleLogsPanel('Deploy', true);
        },
        icon: Play,
      },
    ],
    [cleanFiles, toggleLogsPanel, triggerCodegen, triggerDeployment, triggerImplementation],
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="group">
        <div className="flex-1 text-left text-sm flex gap-1 items-center group-data-[state=open]:bg-accent h-full p-2 rounded-md">
          <FileCode2 size={20} className="text-muted-foreground" />
          <span className="font-medium text-primary">Code</span>
          <ChevronDown className="ml-auto size-4 text-icon group-data-[state=open]:rotate-180" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
        side="bottom"
        align="start"
        sideOffset={4}
      >
        {buttons.map((button) => (
          <DropdownMenuItem key={button.label} onClick={button.onClick}>
            <button.icon />
            {button.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
