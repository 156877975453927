import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface AddScriptLaneParams extends IParams {
  scriptId: string;
  laneId: string;
  position: 'before' | 'after';
}

interface ScriptLaneAddedParams extends IParams {
  scriptId: string;
  laneId: string;
  position: 'before' | 'after';
}

export class ScriptLaneAddedEvent extends EventBase {
  static eventType = 'ScriptLaneAddedEvent';

  constructor(
    public readonly params: ScriptLaneAddedParams,
    public readonly source = AddScriptLaneCommand,
  ) {
    super();
  }
}

export class AddScriptLaneCommand extends CommandBase<AddScriptLaneParams> {
  execute(params: AddScriptLaneParams): ScriptLaneAddedEvent | CommandError {
    const script = this.getModelContext().entityRepository.get<ScriptBase>(params.scriptId)!;
    if (!script.addLane(params.laneId, params.position)) return CommandError.of(script.getError()!, 'error');
    this.getModelContext().entityRepository.update(script);
    return new ScriptLaneAddedEvent({
      scriptId: params.scriptId,
      laneId: params.laneId,
      position: params.position,
    });
  }
}
