import { useMonaco } from '../../spec/use-monaco';
import { default as Editor } from '@monaco-editor/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { editor } from 'monaco-editor';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { monacoOptions } from './monaco.utils';
import { Button, cn, Tabs, TabsContent, TabsList, TabsTrigger } from '@xspecs/design-system';
import { convertJsonToSchema, safeParseJson } from './json-editor.utils';
import { useIntl } from 'react-intl';
import { jsonrepair } from 'jsonrepair';
import { RadioGroup, RadioGroupItem, Label } from '@xspecs/design-system';
import { CommandStrategy, Status } from '@xspecs/single-source-model';
import { Loading } from '../../loading/loading';
import { WandSparkles } from 'lucide-react';

type SchemaEditorProps = {
  id: string;
  onEditorChange?: (value: string) => void;
  showJsonRepresentation?: boolean;
  initialCommandStrategy?: CommandStrategy;
  showCommandStrategy?: boolean;
  initialValue?: string;
};

export const JsonEditor = (props: SchemaEditorProps) => {
  const {
    id,
    onEditorChange,
    showJsonRepresentation = false,
    initialCommandStrategy,
    showCommandStrategy = false,
    initialValue = '{}',
  } = props;

  const { formatMessage: f } = useIntl();

  const [structure, setStructure] = useState('');
  const [commandStrategy, setCommandStrategy] = useState<CommandStrategy>();

  const options = useMemo<editor.IStandaloneEditorConstructionOptions>(
    () => ({
      ...monacoOptions,
      language: 'json',
    }),
    [],
  );

  const onChange = useCallback(
    (value = '') => {
      const modify = (temp: string) => {
        const result = convertJsonToSchema(safeParseJson(temp));
        if (showCommandStrategy) (result as any).strategy = commandStrategy;
        return result;
      };

      const structure = showJsonRepresentation ? JSON.stringify(modify(value), null, 2) : value;
      onEditorChange?.(structure);
      setStructure(structure);
    },
    [commandStrategy, onEditorChange, showCommandStrategy, showJsonRepresentation],
  );

  useEffect(() => {
    if (initialCommandStrategy) {
      setCommandStrategy(initialCommandStrategy);
    }
  }, [initialCommandStrategy]);

  if (!showJsonRepresentation) {
    return <JsonEditorInner options={options} id={id} onChange={onChange} />;
  }

  return (
    <div className="w-full h-full">
      <Tabs defaultValue="test-data" className="w-full h-full">
        <TabsList className="w-full">
          <TabsTrigger value="test-data" className="w-full">
            {f({ id: 'test-data' })}
          </TabsTrigger>
          <TabsTrigger value="schema" className="w-full">
            {f({ id: 'schema' })}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="test-data" className="w-full h-full">
          <JsonEditorInner options={options} id={id} onChange={onChange} initialValue={initialValue} />
        </TabsContent>
        <TabsContent value="schema" className="w-full h-full">
          {showCommandStrategy ? (
            <div className="my-[16px]">
              <Label>Command Strategies:</Label>
              <RadioGroup
                style={{
                  marginTop: 4,
                }}
                value={commandStrategy}
                onValueChange={(value) => {
                  setCommandStrategy(value as CommandStrategy);
                  try {
                    const parsedJson = JSON.parse(structure);
                    if (showCommandStrategy) parsedJson.strategy = value;
                    const str = JSON.stringify(parsedJson, null, 2);
                    setStructure(str);
                    onEditorChange?.(str);
                  } catch {
                    // Do nothing
                  }
                }}
              >
                {Object.values(CommandStrategy).map((strategy) => (
                  <div key={strategy} className="flex items-center space-x-2">
                    <RadioGroupItem value={strategy} id={strategy} />
                    <Label htmlFor={strategy}>{f({ id: strategy })}</Label>
                  </div>
                ))}
              </RadioGroup>
            </div>
          ) : null}
          <Editor language="json" value={structure} options={{ ...options, readOnly: true }} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const JsonEditorInner = ({
  id,
  options,
  onChange,
  initialValue = '{}',
}: {
  id: string;
  options: editor.IStandaloneEditorConstructionOptions;
  onChange: (value: string) => void;
  initialValue?: string;
}) => {
  const { organization } = useActiveOrganization();
  const fileId = `${organization?.id}/${id}`;
  const { formatMessage: f } = useIntl();

  const onBlur = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const { onMount, status, normalizeValue } = useMonaco(
    useMemo(
      () => ({
        id: fileId,
        placeholder: 'Type here...',
        initialValue,
        onBlur,
      }),
      [fileId, initialValue, onBlur],
    ),
  );

  const isLoading = !status || [Status.Unknown, Status.Connecting, Status.Initial].includes(status);
  const isDisconnected = status === Status.Disconnected;

  const disabledClass = 'opacity-0 pointer-events-none';
  const enabledClass = 'opacity-100 pointer-events-auto';

  return (
    <div className="w-full h-full relative flex flex-col items-end">
      <Button variant="secondary" className="w-8 h-8" onClick={() => normalizeValue(jsonrepair)}>
        <WandSparkles />
      </Button>
      <Editor
        language="json"
        className={cn('w-full h-full absolute', isLoading || isDisconnected ? disabledClass : enabledClass)}
        options={options}
        onMount={onMount}
        onChange={onChange}
        loading={<Loading />}
      />
      {isLoading ? (
        <div
          className={cn(
            'w-full h-full flex items-center absolute z-10 top-0',
            isLoading ? enabledClass : disabledClass,
          )}
        >
          <Loading />
        </div>
      ) : null}
      {isDisconnected ? (
        <div
          className={cn(
            'w-full h-full flex items-center justify-center absolute z-10 top-0',
            isDisconnected ? enabledClass : disabledClass,
          )}
        >
          <div className="text-center">
            <p>{f({ id: 'disconnected-from-server' })}</p>
            <p>{f({ id: 'attempting-to-reconnect' })}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};
