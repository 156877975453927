import { FC, ReactNode } from 'react';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../components/resizable/resizable';
import { useStoreContext } from '@xspecs/design-system';
import { debounce } from '@xspecs/design-system/src/ui/ux-sketch-components/utils/debounce';

type FileLayoutProps = {
  Left: ReactNode;
  Right?: ReactNode;
};

export const FileLayout: FC<FileLayoutProps> = (props) => {
  const { Left, Right } = props;
  const { store } = useStoreContext();
  const detailsPane = store.use.panels()['details'];
  const setPanels = store.use.setPanels();

  const debouncedSize = debounce((size) => setPanels('details', 'size', size), 300);

  return (
    <ResizablePanelGroup direction="horizontal" className="w-full h-full">
      <ResizablePanel defaultSize={detailsPane?.size ? 100 - detailsPane.size : 70}>{Left}</ResizablePanel>
      {Right ? (
        <>
          <ResizableHandle />
          <ResizablePanel onResize={debouncedSize} defaultSize={detailsPane?.size ? detailsPane.size : 30}>
            {Right}
          </ResizablePanel>
        </>
      ) : null}
    </ResizablePanelGroup>
  );
};
