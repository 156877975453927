import { Box, SxProps, Theme } from '@mui/material';
import { default as Editor } from '@monaco-editor/react';
import { useElementSize } from '@mantine/hooks';
import { FC, useMemo } from 'react';
import { editor } from 'monaco-editor';
import { useMonaco } from '../../spec/use-monaco';
import { useIntl } from 'react-intl';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import { Status } from '@xspecs/single-source-model';
import { cn } from '@xspecs/design-system';
import { Loading } from '../../loading/loading';

type GraphqlEditorProps = {
  id: string;
};

export const GraphqlEditor: FC<GraphqlEditorProps> = (props: GraphqlEditorProps) => {
  const { id } = props;

  const { formatMessage: f } = useIntl();
  const { ref, height, width } = useElementSize<HTMLDivElement>();
  const { onMount, status } = useMonaco({
    id,
    placeholder: f({ id: 'graphql-editor-placeholder' }),
  });

  const options = useMemo<IStandaloneEditorConstructionOptions>(
    () => ({
      value: '',
      language: 'graphql',
      theme: 'default',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontSize: 13,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: false,
      wrappingIndent: 'none',
      wordWrap: 'on',
      quickSuggestions: false,
      automaticLayout: true,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
    }),
    [],
  );

  const isLoading = !status || [Status.Unknown, Status.Connecting, Status.Initial].includes(status);
  const isDisconnected = status === Status.Disconnected;

  const disabledClass = 'opacity-0 pointer-events-none';
  const enabledClass = 'opacity-100 pointer-events-auto';

  return (
    <Box ref={ref} sx={rootSx} className="w-full h-full relative">
      <Editor
        className={cn('w-full h-full absolute', isLoading || isDisconnected ? disabledClass : enabledClass)}
        width={width}
        height={height}
        language="graphql"
        options={options}
        onMount={onMount}
      />
      {isLoading ? (
        <div
          className={cn(
            'w-full h-full flex items-center absolute z-10 top-0',
            isLoading ? enabledClass : disabledClass,
          )}
        >
          <Loading />
        </div>
      ) : null}
      {isDisconnected ? (
        <div
          className={cn(
            'w-full h-full flex items-center justify-center absolute z-10 top-0',
            isDisconnected ? enabledClass : disabledClass,
          )}
        >
          <div className="text-center">
            <p>{f({ id: 'disconnected-from-server' })}</p>
            <p>{f({ id: 'attempting-to-reconnect' })}</p>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  minHeight: 184,
  flexGrow: 1,
  height: '100%',
};
