import { SingleSourceModelCanvas } from './canvas/single-source-model-canvas';
import { Stack } from '@mui/material';
import { FileLayout } from '@xspecs/design-system';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceEntityRoute } from './workspace-entity-route';

type SingleSourceModelProps = {
  id: string;
  fileType: string;
};
export const SingleSourceModel: FC<SingleSourceModelProps> = (props) => {
  const { id, fileType } = props;

  const [searchParams] = useSearchParams();

  const result = searchParams.get('entityId');

  return (
    <Delayed>
      <FileLayout
        Left={
          <Stack position="relative" width="100%" height="100%">
            <SingleSourceModelCanvas id={id} fileType={fileType} />
          </Stack>
        }
        Right={
          result ? (
            <Stack width="100%" height="100%">
              <WorkspaceEntityRoute />
            </Stack>
          ) : null
        }
      />
    </Delayed>
  );
};

type DelayedProps = {
  children: ReactNode;
  waitBeforeShow?: number;
};

const Delayed = ({ children, waitBeforeShow = 10 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
