import { ReactFlowState, useStore } from '@xyflow/react';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ActiveUser } from '@xspecs/single-source-model';
import { isEqual } from 'lodash';

const storeSelector = (state: ReactFlowState) => ({
  width: state.width,
  height: state.height,
  transform: state.transform,
});

export const Cursors = ({ id }: { id: string }) => {
  const { application } = useApplication();
  const [users, setUsers] = useState<ActiveUser[]>([]);
  const _activeUsers = useSingleSourceStore.use.activeUsersByFile()[id];
  const activeUsers = useMemo(() => {
    if (!_activeUsers || !application?.getModelContext()?.entities.activeUser.id) return [];
    return (
      _activeUsers
        ?.filter((user) => user.id !== application?.getModelContext()?.entities.activeUser.id)
        .filter((user) => user.position) ?? []
    );
  }, [_activeUsers, application]);

  // transform [x, y, zoom]
  const { width, height, transform } = useStore(storeSelector);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (!ctx || !canvas) {
      return;
    }

    const dpi = window.devicePixelRatio;
    canvas.width = width * dpi;
    canvas.height = height * dpi;

    ctx.scale(dpi, dpi);
    ctx.clearRect(0, 0, width, height);

    users.forEach(({ position, color, name }) => {
      if (!position) return;
      ctx.beginPath();
      const cursorPath = new Path2D('M4 4 L11.07 21 L13.58 13.61 L21 11.07 Z');
      const x = position.x * transform[2] + transform[0];
      const y = position.y * transform[2] + transform[1];

      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.fillStyle = 'none';

      ctx.save();
      ctx.translate(x, y);
      ctx.stroke(cursorPath);
      ctx.restore();

      ctx.font = '14px Arial';
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.fillText(name, x + 50, y + 20);
    });
  }, [height, transform, users, width]);

  useEffect(() => {
    setUsers((prev) => {
      if (isEqual(prev, activeUsers)) return prev;
      return activeUsers;
    });
  }, [activeUsers]);

  return <canvas ref={canvasRef} className="react-flow__canvas" style={canvasStyle} />;
};

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
  pointerEvents: 'none',
};
