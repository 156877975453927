import { ApplicationContext } from '../../ApplicationContext';
import { EventBase } from './EventBase';
import { CommandError } from '../../ErrorStore';
import { IModelContext } from '../../IModelContext';
import { ModelContextFactory } from './ModelContextProvider';

export interface IParams {
  // modelId: string;
}

export type CommandConstructor<T extends CommandBase<IParams>> = new (model: ApplicationContext) => T;

export abstract class CommandBase<T extends IParams> {
  constructor(protected readonly appState: ApplicationContext) {}

  shouldSave(): boolean {
    return true;
  }

  public readonly getModelContext = (): IModelContext => ModelContextFactory.create();

  abstract execute(params: T): EventBase | CommandError | undefined;
}
