import { Code } from 'lucide-react';
import { Switch } from '../switch/switch';

export interface DevModeProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const DevMode = (props: DevModeProps) => {
  const { checked, onCheckedChange } = props;

  return (
    <div className="flex gap-1 items-center">
      <Code className="text-muted-foreground" size={20} />
      <span className="text-primary text-sm font-medium">Dev Mode:</span>
      <Switch checked={checked} onCheckedChange={onCheckedChange} />
    </div>
  );
};
