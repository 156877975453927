export const MouseLeftClick = () => {
  return (
    <>
      <path d="M12 10V2C11.1667 2 9.00003 2.4 7.00003 4C5.00003 5.6 4.83336 8.66667 5.00003 10H12Z" fill="#599AFF" />
      <path
        d="M12 6V10M12 2C15.866 2 19 5.13401 19 9V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 5.13401 8.13401 2 12 2Z"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};
