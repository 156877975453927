import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Thread } from '../../entities/threads/Thread';
import { Errors } from '@xspecs/errors';

interface ResolveThreadCommandParams extends IParams {
  threadId: string;
}

interface ThreadResolvedEventParams extends IParams {
  threadId: string;
}

export class ThreadResolvedEvent extends EventBase {
  static eventType = 'ThreadResolvedEvent';

  constructor(
    public readonly params: ThreadResolvedEventParams,
    public readonly source = ResolveThreadCommand,
  ) {
    super();
  }
}

export class ResolveThreadCommand extends CommandBase<ResolveThreadCommandParams> {
  execute(params: ResolveThreadCommandParams): ThreadResolvedEvent | CommandError {
    const result = this.resolveThread(params.threadId);
    if (result instanceof Error) return CommandError.of(result, 'error');
    return new ThreadResolvedEvent({ threadId: params.threadId });
  }

  private resolveThread(threadId: string) {
    const model = this.getModelContext();
    const thread = model.entityRepository.get(threadId) as Thread;
    if (!thread) return new Error(Errors.EntityNotFound);
    thread.resolve();
    model.entityRepository.update(thread);
  }
}
