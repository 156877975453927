export const TrackpadZoom = () => {
  return (
    <>
      <path
        d="M2 14H22M12 20V14M4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4Z"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9.5" cy="9.5" r="1.5" fill="#599AFF" />
      <circle cx="14.5" cy="9.5" r="1.5" fill="#599AFF" />
    </>
  );
};
