import { IStore } from '../../data/Store';
import { BuildLogEntry, BuildLogState } from './BuildLog.types';

export class BuildLog {
  private cache: Record<string, BuildLogEntry[]> = {};

  constructor(private readonly store: IStore) {}

  applyChange(entry: BuildLogEntry) {
    if (!this.cache[entry.buildId]) {
      this.cache[entry.buildId] = [];
    }
    const existingLogs = this.cache[entry.buildId];
    const isDuplicate = existingLogs.some((log) => log.log === entry.log && log.lastUpdated === entry.lastUpdated);
    if (!isDuplicate) {
      existingLogs.push(entry);
    }
    this.updateStore();
  }

  delete(buildId: string) {
    delete this.cache[buildId];
    this.updateStore();
  }

  private updateStore() {
    const allBuildLogs: BuildLogEntry[] = Object.values(this.cache)
      .flat()
      .sort((a, b) => a.lastUpdated - b.lastUpdated);

    const buildLogState: BuildLogState = {
      buildId: 'all-build-logs',
      buildLogs: allBuildLogs,
    };

    this.store.getState().setBuildLog(buildLogState);
  }

  clear() {
    this.cache = {};
    this.updateStore();
  }

  dispose() {
    this.clear();
  }
}
