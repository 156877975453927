import { SxProps, Theme } from '@mui/material';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@xspecs/design-system';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { FiltersButtonMenuProps } from './menu/filters-button-menu';
import { AssetBase, FilterByItem } from '@xspecs/single-source-model';

export type FiltersButtonProps = {
  filtersButtonMenuProps: FiltersButtonMenuProps;
  ButtonComponent?: ReactNode;
  buttonSx?: SxProps<Theme>;
  filterByAssetsList: FilterByItem[];
  assets: AssetBase[];
  selectedAssets: AssetBase[];
  onSelectedAssetsChange: (assets: AssetBase[]) => void;
};

export const FiltersButton = (props: FiltersButtonProps) => {
  const { filterByAssetsList, assets } = props;

  const { formatMessage: f } = useIntl();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary">{f({ id: 'filters' })}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="bottom" align="end" className="w-xs">
        <DropdownMenuLabel>{f({ id: 'select-category' })}</DropdownMenuLabel>
        <div className="max-h-[100px] overflow-scroll px-2">
          {filterByAssetsList.map((item) => {
            const assetType = assets.filter((asset) => asset.type === item.type);

            return (
              <Accordion key={`FiltersType${item.type}`} type="single" collapsible>
                <AccordionItem className="border-none" value={item.type}>
                  <AccordionTrigger className="p-1 border-none">
                    <div className="flex items-center gap-2">
                      <img alt={`Icon${item.icon}`} width={16} height={16} src={item.icon} />
                      <p className="text-primary">{item.label}</p>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="mt-2">
                    {assetType.length === 0 ? (
                      <p className="text-primary">{f({ id: 'no-entities' })}</p>
                    ) : (
                      assetType.map((asset) => {
                        const key = `FiltersItem${asset.id}`;
                        return (
                          <div key={key} className="flex gap-2">
                            <Checkbox
                              id={key}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  const set = new Set(props.selectedAssets);
                                  set.add(asset);
                                  props.onSelectedAssetsChange(Array.from(set));
                                } else {
                                  const set = new Set(props.selectedAssets);
                                  set.delete(asset);
                                  props.onSelectedAssetsChange(Array.from(set));
                                }
                              }}
                            />
                            <div className="grid gap-1.5 leading-none">
                              <label
                                htmlFor={key}
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {asset.name}
                              </label>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          })}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
