import { MouseEvent, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowEvent } from '@mantine/hooks';
import { InsertMenuItem } from '../item/insert-menu-item';
import { useIntl } from 'react-intl';

import { getBlockAbove, getNodeString, getPluginType, toggleNodeType, useEditorState } from '@udecode/plate-common';
import { findNextEnabledItem, findPreviousEnabledItem } from '../helpers/floating-insert-menu-helpers';
import { ELEMENT_OL, ELEMENT_UL, getListItemEntry, toggleList, unwrapList } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { insertMediaModalToShow, showInsertMenuVar } from '../../../../state/state';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@xspecs/design-system';
import { CaseSensitive, Heading1, Heading2, Heading3, List, ListOrdered, Video, Image } from 'lucide-react';

type InsertMenuProps = {
  parentRef?: MutableRefObject<HTMLElement | null>;
};

export const InsertMenu = (props: InsertMenuProps) => {
  const { parentRef } = props;

  const [focused, setFocused] = useState(0);

  const { formatMessage: f } = useIntl();

  const editor = useEditorState();

  const isSelectionOnList = useMemo(() => Boolean(getListItemEntry(editor)), [editor]);

  const closeMenuAndDeleteSlash = useCallback(() => {
    const blockEntryAbove = getBlockAbove(editor);
    if (!blockEntryAbove) return;
    const [node] = blockEntryAbove;
    const nodeTextContent = getNodeString(node).trim();
    if (nodeTextContent.endsWith('/')) {
      editor.deleteBackward('character');
    }
    showInsertMenuVar(false);
  }, [editor]);

  const onTextTypeChange = useCallback(
    (pluginType: string) => () => {
      if (isSelectionOnList) unwrapList(editor);
      toggleNodeType(editor, { activeType: pluginType });
      closeMenuAndDeleteSlash();
    },
    [closeMenuAndDeleteSlash, editor, isSelectionOnList],
  );

  const handleToggleList = useCallback(
    (type: string) => () => {
      toggleList(editor, { type });
      closeMenuAndDeleteSlash();
    },
    [closeMenuAndDeleteSlash, editor],
  );

  const onAddMedia = useCallback(
    (type: 'image' | 'video') => (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      insertMediaModalToShow(type);
      closeMenuAndDeleteSlash();
    },
    [closeMenuAndDeleteSlash],
  );

  const items = useMemo(
    () =>
      [
        {
          Icon: <CaseSensitive data-testid="InsertMenuBasicBlocksTextIcon" />,
          title: f({ id: 'text' }),
          description: f({ id: 'start-with-plain-text' }),
          onClick: onTextTypeChange(getPluginType(editor, ELEMENT_PARAGRAPH)),
        },
        {
          Icon: <Heading1 data-testid="InsertMenuBasicBlocksH1Icon" />,
          title: f({ id: 'heading-1' }),
          description: f({ id: 'big-section-heading' }),
          onClick: onTextTypeChange(getPluginType(editor, ELEMENT_H1)),
        },
        {
          Icon: <Heading2 data-testid="InsertMenuBasicBlocksH2Icon" />,
          title: f({ id: 'heading-2' }),
          description: f({ id: 'medium-section-heading' }),
          onClick: onTextTypeChange(getPluginType(editor, ELEMENT_H2)),
        },
        {
          Icon: <Heading3 data-testid="InsertMenuBasicBlocksH3Icon" />,
          title: f({ id: 'heading-3' }),
          description: f({ id: 'small-section-heading' }),
          onClick: onTextTypeChange(getPluginType(editor, ELEMENT_H3)),
        },
        {
          Icon: <List data-testid="InsertMenuBasicBlocksBulletedListIcon" />,
          title: f({ id: 'bulleted-list' }),
          description: f({ id: 'simple-bulleted-list' }),
          onClick: handleToggleList(getPluginType(editor, ELEMENT_UL)),
        },
        {
          Icon: <ListOrdered data-testid="InsertMenuBasicBlocksNumberedListIcon" />,
          title: f({ id: 'numbered-list' }),
          description: f({ id: 'a-list-with-numbering' }),
          onClick: handleToggleList(getPluginType(editor, ELEMENT_OL)),
        },
        {
          Icon: <Image data-testid="InsertMenuMediaImageIcon" />,
          title: f({ id: 'image' }),
          description: f({ id: 'upload-or-embed-an-image' }),
          onClick: onAddMedia('image'),
        },
        {
          Icon: <Video data-testid="InsertMenuMediaVideoIcon" />,
          title: f({ id: 'video' }),
          description: f({ id: 'embed-from-sources' }),
          onClick: onAddMedia('video'),
        },
      ].map((item) => ({ ...item, disabled: !item.onClick })),
    [f, onTextTypeChange, editor, handleToggleList, onAddMedia],
  );

  const menuItems = useMemo(
    () => [
      { header: f({ id: 'basic-blocks' }), items: items.slice(0, 6) },
      { header: f({ id: 'media' }), items: items.slice(6, 8) },
    ],
    [f, items],
  );

  const executeItemAction = useCallback(
    (event, index) => {
      const item = items[index];
      if (!item.disabled) {
        item.onClick(event);
      }
    },
    [items],
  );

  const onArrowUp = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setFocused((prev) => findPreviousEnabledItem(items, prev));
    },
    [items],
  );

  const onArrowDown = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setFocused((prev) => findNextEnabledItem(items, prev));
    },
    [items],
  );

  const onEnter = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      executeItemAction(event, focused);
    },
    [executeItemAction, focused],
  );

  const onItemClick = useCallback(
    (index: number) => (event) => {
      setFocused(index);
      executeItemAction(event, index);
    },
    [executeItemAction],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowUp') onArrowUp(event);
      if (event.key === 'ArrowDown') onArrowDown(event);
      if (event.key === 'Enter') onEnter(event);
    },
    [onArrowDown, onArrowUp, onEnter],
  );

  const scrollItemsIntoView = useCallback(() => {
    if (parentRef?.current) {
      const parent = parentRef.current;
      const elementHeight = 56;
      const visibleItems = Math.floor(parent.clientHeight / elementHeight);
      if (focused <= visibleItems - 3) {
        parent.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        parent.scrollTo({ top: focused * elementHeight, behavior: 'smooth' });
      }
    }
  }, [focused, parentRef]);

  useWindowEvent('keydown', onKeyDown);

  useEffect(() => {
    scrollItemsIntoView();
  }, [scrollItemsIntoView]);

  return (
    <DropdownMenu data-testid="insert-menu" defaultOpen={true}>
      <DropdownMenuTrigger></DropdownMenuTrigger>
      <DropdownMenuContent>
        {menuItems.map((menuItem, index) => (
          <DropdownMenuGroup key={menuItem.header}>
            <DropdownMenuLabel>{menuItem.header}</DropdownMenuLabel>
            {menuItem.items.map((item) => {
              const position = items.findIndex((i) => i.title === item.title);
              return (
                <InsertMenuItem
                  key={item.title}
                  {...item}
                  focused={focused === position}
                  onItemClick={onItemClick(position)}
                />
              );
            })}
            {index !== menuItems.length - 1 ? <DropdownMenuSeparator /> : null}
          </DropdownMenuGroup>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
