import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { CommandError } from '../../ErrorStore';

export interface UpdateLabelParams extends IParams {
  id: string;
  name: string;
  color: string;
}

export class LabelUpdatedEvent extends EventBase {
  static eventType = 'LabelUpdatedEvent';

  constructor(
    public readonly params: UpdateLabelParams,
    public readonly source = UpdateLabelCommand,
  ) {
    super();
  }
}

export class UpdateLabelCommand extends CommandBase<UpdateLabelParams> {
  execute(params: UpdateLabelParams): EventBase | CommandError | undefined {
    const modelContext = this.getModelContext();
    const label = modelContext.entityRepository.get<Label>(params.id)!;
    label.name = params.name;
    label.color = params.color;
    modelContext.entityRepository.update(label);
    return new LabelUpdatedEvent(params);
  }
}
