import { SubscriptionPlansQuery } from '@xspecs/client/src/gql/graphql';
import { FormattedNumber, useIntl } from 'react-intl';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@xspecs/design-system/src/ui/card/card';
import { PlansListItemActionButton } from '@xspecs/client/src/components/settings/plans/list/item/plans-list-item';
import { Separator } from '../separator/separator';
import { PlanFeatureList } from '@xspecs/design-system/src/ui/plan-card/plan-feature-list';
import { cn } from '@xspecs/design-system/src/utils/index';

type PlanCardProps = {
  plan: SubscriptionPlansQuery['subscriptionPlans'][0];
  variant: 'primary' | 'secondary';
};

export const PlanCard = (props: PlanCardProps) => {
  const { plan, variant } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Card
      className={cn(
        'flex-1 rounded-lg shadow-none pb-0 flex flex-col max-w-96',
        variant === 'primary' ? 'bg-background' : 'bg-card-foreground dark:bg-card-foreground',
      )}
    >
      <CardHeader>
        <CardTitle className={cn('font-medium text-lg', variant === 'primary' ? 'text-primary' : 'text-white')}>
          {plan.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <FormattedNumber value={plan.price} style="currency" currency={plan.currency} maximumFractionDigits={0}>
          {(value) => (
            <div>
              <h5 className={cn('text-4xl font-semibold', variant === 'primary' ? 'text-primary' : 'text-white')}>
                {plan.isCustomPlan && plan.price === 0 ? f({ id: 'custom' }) : value}
              </h5>
              <p className={cn('text-sm', variant === 'primary' ? 'text-muted' : 'text-white')}>
                {f({ id: 'per-user-per-period' }, { period: plan.priceInterval })}
              </p>
            </div>
          )}
        </FormattedNumber>
        <Separator orientation="horizontal" className="my-5" />
        <PlanFeatureList description={plan.description} features={plan.features} variant={variant} />
      </CardContent>
      <CardFooter className="mt-auto pt-2.5">
        <PlansListItemActionButton plan={plan} />
      </CardFooter>
    </Card>
  );
};
