import { CommandBase, CommandConstructor, IParams } from '../framework/CommandBase';
import { FilterType } from '../../entities/Filter';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface DeleteFilterParams extends IParams {
  id: string;
  type: FilterType;
}

export class FilterDeletedEvent extends EventBase {
  static eventType = 'FilterDeletedEvent';

  constructor(
    public source: CommandConstructor<DeleteFilterCommand>,
    public params: DeleteFilterParams,
  ) {
    super();
  }
}

export class DeleteFilterCommand extends CommandBase<DeleteFilterParams> {
  execute(params: DeleteFilterParams): FilterDeletedEvent | CommandError {
    if (params.type != FilterType.unsaved) {
      this.getModelContext().entityRepository.delete(params.id);
    }
    this.appState.messageBus.send(ApplyFiltersCommand, {});
    return new FilterDeletedEvent(DeleteFilterCommand, params);
  }
}
