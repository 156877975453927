import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Edge } from '../../entities/transitions/Edge';
import { CommandError } from '../../ErrorStore';
import { DeleteEntitiesCommand } from '../entities/DeleteEntitiesCommand';
import { PasteEdgeStyleCommand } from './PasteEdgeStyleCommand';

interface UpdateEdgeParams extends IParams {
  id: string;
  key: string;
  markerStart?: string;
  markerEnd?: string;
  color?: string;
  lineStyle?: string;
  lineWeight?: string;
  labelColor?: string;
  labelFontSize?: number;
  moreActionValue?: string;
  lineType?: string;
}

interface EdgeUpdatedParams extends IParams {
  id: string;
  key: string;
  markerStart?: string;
  markerEnd?: string;
  color?: string;
  lineStyle?: string;
  lineWeight?: string;
  labelColor?: string;
  labelFontSize?: number;
  moreActionValue?: string;
  lineType?: string;
}

export type EdgeStyles = {
  type: 'edgeStyles';
  data: {
    color: string;
    lineStyle: string;
    lineWeight: string;
    labelColor: string;
    labelFontSize: number;
    lineType: string;
  };
};

export class EdgeUpdatedEvent extends EventBase {
  static eventType = 'EdgeUpdatedEvent';

  constructor(
    public readonly params: EdgeUpdatedParams,
    public readonly source = UpdateEdgeCommand,
  ) {
    super();
  }
}

export class UpdateEdgeCommand extends CommandBase<UpdateEdgeParams> {
  execute(params: UpdateEdgeParams): EdgeUpdatedEvent | CommandError | undefined {
    const {
      id,
      key,
      markerStart,
      markerEnd,
      color,
      lineStyle,
      lineWeight,
      labelColor,
      labelFontSize,
      moreActionValue,
      lineType,
    } = params;

    const modelContext = this.getModelContext();
    const edge = modelContext.entityRepository.get<Edge>(id);
    if (!edge) return CommandError.of(new Error('Edge not found'), 'error');

    switch (key) {
      case 'swap-markers':
        edge.swapMarkers();
        break;
      case 'update-marker-start':
        edge.updateMarkerStart(markerStart);
        break;
      case 'update-marker-end':
        edge.updateMarkerEnd(markerEnd);
        break;
      case 'update-color':
        edge.updateColor(color);
        break;
      case 'update-line-style':
        edge.updateLineStyle(lineStyle);
        break;
      case 'update-line-weight':
        edge.updateLineWeight(lineWeight);
        break;
      case 'update-label-color':
        edge.updateLabelColor(labelColor);
        break;
      case 'update-font-size':
        edge.updateFontSize(labelFontSize);
        break;
      case 'update-line-type':
        edge.updateLineType(lineType);
        break;
      case 'more-action':
        if (moreActionValue === 'deleteEdge') {
          this.appState.messageBus.sendInternal(DeleteEntitiesCommand, {
            entityIds: [id],
          });
          return;
        }
        if (moreActionValue === 'copyStyle') {
          void modelContext.clipboard.copyRaw({
            type: 'edgeStyles',
            data: {
              color: edge.color,
              lineStyle: edge.lineStyle,
              lineWeight: edge.lineWeight,
              labelColor: edge.labelColor,
              labelFontSize: edge.labelFontSize,
              lineType: edge.lineType,
            },
          } satisfies EdgeStyles);
        }
        if (moreActionValue === 'pasteStyle') {
          this.appState.messageBus.sendInternal(PasteEdgeStyleCommand, {});
        }
        break;
      default:
        return CommandError.of(new Error('Invalid key'), 'error');
    }

    modelContext.entityRepository.update(edge);

    return new EdgeUpdatedEvent({
      ...params,
    });
  }
}
