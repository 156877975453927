import { Button, Card } from '@xspecs/design-system';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Ghost, Plus } from 'lucide-react';

export const NoSpaceResults = () => {
  const { formatMessage: f } = useIntl();
  const { organization } = useActiveOrganization();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const openCreateSpaceModal = useCallback(() => {
    if (organization === undefined) return;
    setShowCreateSpaceModal(true);
  }, [organization, setShowCreateSpaceModal]);

  return (
    <div className="flex flex-col items-center py-10 bg-background-grey h-full">
      <Card className="p-4 rounded-full bg-gray-100 mb-4">
        <Ghost className="w-10 h-10" />
      </Card>
      <h2 className="text-lg font-semibold text-primary mb-2">{f({ id: 'no-spaces-yet' })}</h2>
      <p className="text-secondary text-center max-w-md mb-4">{f({ id: 'space-list-description' })}</p>
      <Button onClick={openCreateSpaceModal}>
        <Plus className="w-5 h-5 mr-2" /> {f({ id: 'create-new-space' })}
      </Button>
    </div>
  );
};
