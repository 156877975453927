import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { EntityChanges } from '../../types';
import { EventBase } from '../framework/EventBase';

interface BroadcastChangesCommandParams extends IParams {
  changes: EntityChanges;
}

export class ChangesSavedEvent extends EventBase {
  static eventType = 'ChangesSavedEvent';

  constructor(public readonly changes: EntityChanges) {
    super();
  }
}

export class BroadcastSavedChangesCommand extends CommandBase<BroadcastChangesCommandParams> {
  execute(params: BroadcastChangesCommandParams): ChangesSavedEvent | CommandError | undefined {
    if (
      !(params.changes.added.length === 0 && params.changes.updated.length === 0 && params.changes.deleted.length === 0)
    ) {
      return new ChangesSavedEvent(params.changes);
    }
  }
}
