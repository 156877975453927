import { AuthorizationParams, AuthProvider } from './auth';
import { Box, ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './app-routes/app-routes';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './config/auth0/Auth0Config';
import { RoutePaths } from './config/route-paths/route-paths';
import messages from './assets/languages/en.json';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './config/apollo/ApolloClient';
import { useMemo } from 'react';
import { lightTheme } from './themes/light';
import { SnackStackProvider } from './wrappers/snack-stack-context';
import { Snackbar } from './components/snackbar/snackbar';
import { StyledEngineProvider } from '@mui/material/styles';

import '@xyflow/react/dist/style.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-contexify/ReactContexify.css';
import 'allotment/dist/style.css';
import '@xspecs/design-system/styles/styles.css';
import { CommandDispatchProvider, Toaster } from '@xspecs/design-system';
import { useCommandDispatch } from './wrappers/application-context/application-context';
import { ThemeProvider as DSThemeProvider } from '@xspecs/design-system/src/hooks/use-theme';
import { Head } from './Head';

export const App = () => {
  const dispatch = useCommandDispatch();

  const authorizationParams = useMemo<AuthorizationParams>(
    () => ({ redirect_uri: `${window.location.origin}${RoutePaths.AuthReturn}` }),
    [],
  );

  return (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <ThemeProvider theme={lightTheme}>
        <DSThemeProvider>
          <Head />
          <StyledEngineProvider injectFirst>
            <CommandDispatchProvider dispatchCommand={dispatch}>
              <SnackStackProvider>
                <Box sx={rootSx}>
                  <AuthProvider
                    domain={AUTH0_DOMAIN}
                    clientId={AUTH0_CLIENT_ID}
                    authorizationParams={authorizationParams}
                    cacheLocation="localstorage"
                    authorizeTimeoutInSeconds={10}
                  >
                    <ApolloProvider client={apolloClient}>
                      <BrowserRouter>
                        <AppRoutes />
                        <Snackbar />
                      </BrowserRouter>
                    </ApolloProvider>
                  </AuthProvider>
                </Box>
                <Toaster />
              </SnackStackProvider>
            </CommandDispatchProvider>
          </StyledEngineProvider>
        </DSThemeProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

const rootSx = { backgroundColor: 'background.paper', height: '100%', width: '100%' };
