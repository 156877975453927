import { EntityRepository } from './EntityRepository';
import { Awareness } from 'y-protocols/awareness';
import { StatesArray } from '@hocuspocus/provider/src/types';
import { monotonicFactory } from 'ulid';
import { DEBUG_CONFIG } from '../debug-config';
import { logger } from '@xspecs/logger';
import { NotificationTypes, SingleSourceObserver } from '../observable/SingleSourceObserver';
import { EntityBase } from '../entities/EntityBase';

export class SynchronizedUlid {
  static monotonicUlid = monotonicFactory();

  static time: number;

  static ulid = () => {
    return this.monotonicUlid(this.time);
  };

  static updateTime(time: number) {
    this.time = time;
  }

  static {
    this.updateTime(Date.now());
  }
}

export const ulid = () => {
  return SynchronizedUlid.ulid();
};

// DEBUGGING CODE
// const delay = (ms: number) => {
//   const start = Date.now();
//   while (Date.now() - start < ms);
// };
// const DELAY = 2000;
//
// const logAndDelay = (msg: any, ms = DELAY) => {
//   logger.log(msg);
//   // delay(ms);
// };

export class EntitySelectionTracker {
  readonly #clientId: number;
  #globalSelections = {};
  #localSelections = {};
  #clients = {};
  #globalChanges: Map<string, { key: string; field: string; value: boolean | number }> = new Map();
  #localChanges: Map<string, { key: string; field: string; value: boolean | number }> = new Map();

  constructor(
    private readonly entityRepository: EntityRepository,
    awareness: Awareness,
    private readonly observer: SingleSourceObserver,
  ) {
    this.#clientId = awareness.clientID;
  }

  public get globalSelections() {
    return this.#globalSelections;
  }

  public get localSelections() {
    return this.#localSelections;
  }

  public get clients() {
    return this.#clients;
  }

  public clearLocalSelections() {
    const oldSelections = this.#localSelections;
    this.#localSelections = {};
    Object.keys(oldSelections).forEach((key) => {
      this.#localChanges.set(key, { key, field: 'isSelected', value: false });
    });
    this.notifyChangedEntities();
  }

  // // TODO PoC - remove and design properly
  // private moveShit(states) {
  //   if (!this.model || !this.model.graph) return;
  //   if (states[0]?.dxDy?.x !== 0 || states[0]?.dxDy?.y !== 0) {
  //     const keys = Object.keys(this.#localSelections);
  //     const allKeys = [...this.collectEntities(keys)];
  //     this.model.graph.drag(allKeys, states[0]?.dxDy);
  //   }
  // }

  public onAwarenessUpdate(states: StatesArray) {
    // this.moveShit(states);
    if (!this.isTransient(states)) return;
    if (DEBUG_CONFIG.entitySelectionTracker) logger.log('EntitySelectionTracker:onAwarenessUpdate', states);
    this.storeClients(states);
    this.processLocalSelectionStates(states);
    const selectionStates = this.processGlobalSelectionStates(states);
    if (this.#globalSelections) this.deselectAndNotifyGlobalOrphans(selectionStates);
    this.#globalSelections = selectionStates;
    if (this.#globalSelections) this.deselectAndNotifyLocalOrphans();
    this.notifyChangedEntities();
    if (DEBUG_CONFIG.entitySelectionTracker) {
      logger.log('EntitySelectionTracker:globalSelections', this.#globalSelections);
      logger.log('EntitySelectionTracker:localSelections', this.#localSelections);
      logger.log('clients', this.#clients);
    }
  }

  private processLocalSelectionStates(states: StatesArray) {
    const localState = states.find((state) => state.clientId === this.#clientId);

    if (localState && localState.selected) {
      // ensure that the selected entities are still in the repository
      localState.selected = Object.fromEntries(
        Object.entries(localState.selected).filter(([key]) => {
          const entity = this.entityRepository.get(key)!;
          this.notifyEntityOfAnyLocalSelectionChanges(entity, key);
          return entity;
        }),
      );

      this.#localSelections = localState.selected || {};

      if (DEBUG_CONFIG.entitySelectionTracker) {
        logger.log('EntitySelectionTracker:findLocalSelections', this.#localSelections);
      }
    }
  }

  private notifyEntityOfAnyLocalSelectionChanges(entity: EntityBase, key: string) {
    if (entity && !this.#localSelections[key]) this.#localChanges.set(key, { key, field: 'isSelected', value: true });
  }

  private processGlobalSelectionStates(awarenessStates: StatesArray) {
    const globalSelections = this.#globalSelections;
    const selectionsTrackerTemp = {};
    const newSelectionStates = {};
    for (const state of awarenessStates) {
      if (!this.hasSelections(state)) continue;
      this.getSelections(state).forEach(([key, ulid]) => {
        if (
          this.selectionIsNotTracked(selectionsTrackerTemp, key) ||
          this.selectionIsNewerThanTracked(selectionsTrackerTemp, key, ulid as string)
        ) {
          // maybe calculate checksum too?
          if (globalSelections[key] !== state.clientId) {
            const field = state.clientId === this.#clientId ? 'isSelected' : 'selectedBy';
            const value = state.clientId === this.#clientId ? true : state.clientId;
            if (field === 'isSelected') this.#localChanges.set(key, { key, field, value });
            else this.#globalChanges.set(key, { key, field, value });
          }
          selectionsTrackerTemp[key] = { ulid, client: state.clientId };
          newSelectionStates[key] = state.clientId;
        }
      });
    }
    if (DEBUG_CONFIG.entitySelectionTracker)
      logger.log('EntitySelectionTracker:processSelectionStates', newSelectionStates);
    return newSelectionStates;
  }

  private selectionIsNewerThanTracked(
    remoteSelectionsMapBuild: {
      [key: string]: { ulid: string };
    },
    key: string,
    value: string,
  ) {
    return remoteSelectionsMapBuild[key].ulid.localeCompare(value) < 0;
  }

  private selectionIsNotTracked(remoteSelectionsMapBuild: { [key: string]: boolean }, key: string) {
    return !remoteSelectionsMapBuild[key];
  }

  private getSelections(state: { clientId: number; [p: string]: any; [p: number]: any }) {
    return Object.entries(state.selected);
  }

  private hasSelections(state: { clientId: number; [p: string]: any; [p: number]: any }) {
    return !!state.selected;
  }

  private notifyChangedEntities() {
    const updated: {
      entity: EntityBase;
      modifiedProperties: string[];
      modifiedValues: { key: string; value: boolean | number }[];
    }[] = [];
    for (const change of this.#globalChanges.values()) {
      const entity = this.entityRepository.get(change.key);
      if (entity) {
        // modifiedValues is needed for test robustness, it's not used anywhere else
        updated.push({
          entity,
          modifiedProperties: [change.field],
          modifiedValues: [{ key: change.field, value: change.value }],
        });
      } else {
        delete this.#localSelections[change.key];
        delete this.#globalSelections[change.key];
      }
    }
    for (const change of this.#localChanges.values()) {
      const entity = this.entityRepository.get(change.key)!;
      if (entity) {
        // modifiedValues is needed for test robustness, it's not used anywhere else
        updated.push({
          entity,
          modifiedProperties: [change.field],
          modifiedValues: [{ key: change.field, value: change.value }],
        });
      } else {
        delete this.#localSelections[change.key];
        delete this.#globalSelections[change.key];
      }
    }

    if (DEBUG_CONFIG.entitySelectionTracker) logger.log('EntitySelectionTracker:notifyChangedEntities', updated);
    if (updated.length)
      this.observer.notify(NotificationTypes.OnTransientChange, {
        added: [],
        updated,
        deleted: [],
      });
    this.#localChanges = new Map();
    this.#globalChanges = new Map();
  }

  private deselectAndNotifyGlobalOrphans(selectionStates: { [key: string]: number }) {
    if (DEBUG_CONFIG.entitySelectionTracker)
      logger.log('EntitySelectionTracker:deselectAndNotifyGlobalOrphans', selectionStates);
    Object.keys(this.#globalSelections).forEach((key) => {
      if (!selectionStates[key]) {
        delete this.#globalSelections[key];
        this.#globalChanges.set(key, { key, field: 'selectedBy', value: undefined! });
      }
    });
  }

  private deselectAndNotifyLocalOrphans() {
    if (DEBUG_CONFIG.entitySelectionTracker) logger.log('EntitySelectionTracker:deselectAndNotifyLocalOrphans');
    Object.keys(this.#globalSelections).forEach((key) => {
      if (this.#localSelections[key] && this.#clientId !== this.#globalSelections[key]) {
        delete this.#localSelections[key];
        this.#localChanges.set(key, { key, field: 'isSelected', value: false });
      }
    });
  }

  private storeClients(states: StatesArray) {
    this.#clients = {};
    for (const state of states) {
      const client = { ...state };
      delete client.selected;
      this.#clients[state.clientId] = client;
    }
  }

  private isTransient(states: StatesArray) {
    return states.filter((state) => ['transient'].includes(state.changeType)).length !== 0;
  }
}
