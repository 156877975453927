import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { EntityBase } from '../../entities/EntityBase';
import { CommandError } from '../../ErrorStore';

export interface CreateLabelParams extends IParams {
  id: string;
  name: string;
  color: string;
  createdBy: string;
  entities?: string[];
}

export class LabelCreatedEvent extends EventBase {
  static eventType = 'LabelCreatedEvent';

  constructor(
    public readonly params: CreateLabelParams,
    public readonly source = CreateLabelCommand,
  ) {
    super();
  }
}

export class CreateLabelCommand extends CommandBase<CreateLabelParams> {
  execute(params: CreateLabelParams): EventBase | CommandError | undefined {
    const label = new Label(params.id, params.name, params.color, params.createdBy, ['*']);
    if (params.name == null || params.name.trim() === '') {
      return;
    }
    const modelContext = this.getModelContext();
    const entities = (params.entities ?? []).map((id) => modelContext.entityRepository.get<EntityBase>(id)!);
    if (entities.length > 0) {
      label.addEntities(entities);
      for (const entity of entities) {
        modelContext.entityRepository.update(entity);
      }
    }
    modelContext.entityRepository.add(label);
    return new LabelCreatedEvent(params);
  }
}
