import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle, Input } from '@xspecs/design-system';
import { RENAME_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { Pencil } from 'lucide-react';

export const SpaceRenameModal = () => {
  const spaceToRename = useSingleSourceStore.use.spaceToRename();
  const setSpaceToRename = useSingleSourceStore.use.setSpaceToRename();
  const { organization, refreshActiveOrganization } = useActiveOrganization();

  const [name, setName] = useState('');

  const [renameWorkspace] = useMutation(RENAME_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();
  const { addToast } = useSnackStack();
  const { formatMessage: f } = useIntl();

  const workspace = useMemo(
    () => organization?.workspaces?.find((workspace) => workspace.id === spaceToRename),
    [organization, spaceToRename],
  );

  const onClose = useCallback(() => {
    setSpaceToRename(undefined);
  }, [setSpaceToRename]);

  const onNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onSave = useCallback(async () => {
    if (!workspace) return;
    const response = await renameWorkspace({
      variables: {
        args: {
          newName: name,
          workspaceId: workspace?.id,
        },
      },
    });
    if (!response.data) return;
    if (response.data.renameWorkspace.error) {
      addToast({ message: f({ id: 'space-rename-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'space-rename-success' }, { name: workspace?.name, newName: name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceNameChanged, {
        workspaceId: workspace?.id,
        name: workspace?.name,
        newName: name,
      });
      await refreshActiveOrganization();
      onClose();
    }
    onClose();
  }, [workspace, renameWorkspace, name, onClose, addToast, f, trackEvent, refreshActiveOrganization]);

  useEffect(() => {
    setName(workspace?.name ?? '');
  }, [workspace?.name]);

  return (
    <Dialog open={!!spaceToRename} onOpenChange={onClose}>
      <DialogContent className="overflow-visible rounded-lg">
        <div className="flex items-center gap-2">
          <Pencil className="text-primary" />
          <DialogTitle>{f({ id: 'rename-space' })}</DialogTitle>
        </div>
        <div className="mt-2 mb-2">
          <p className="text-sm text-primary">{f({ id: 'space-name' })}</p>
          <Input
            data-testid="WorkspaceSettingsModalNameTextFieldInput"
            value={name}
            onChange={onNameChange}
            className="mt-1 w-full"
          />
        </div>
        <DialogFooter className="flex justify-end gap-2">
          <Button variant="secondary" onClick={onClose}>
            {f({ id: 'cancel' })}
          </Button>
          <Button variant="default" disabled={!name || name === workspace?.name} onClick={onSave}>
            {f({ id: 'save' })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
