import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';

interface BroadcastSpaceSettingChangesParams extends IParams {
  added: Record<string, any>;
  updated: Record<string, any>;
  deleted: string[];
}

interface BroadcastSpaceSettingEventParams extends IParams {
  added: Record<string, any>;
  updated: Record<string, any>;
  deleted: string[];
}

export class SpaceSettingsChangedEvent extends EventBase {
  static eventType = 'SpaceSettingsChangedEvent';

  constructor(
    public readonly params: BroadcastSpaceSettingEventParams,
    public readonly source = BroadcastSpaceSettingChangesCommand,
  ) {
    super();
  }
}

export class BroadcastSpaceSettingChangesCommand extends CommandBase<BroadcastSpaceSettingChangesParams> {
  execute(params: BroadcastSpaceSettingChangesParams): SpaceSettingsChangedEvent {
    return new SpaceSettingsChangedEvent({
      added: params.added,
      updated: params.updated,
      deleted: params.deleted,
    });
  }
}
