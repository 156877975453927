import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChanges } from '../../types';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Attachment } from '../../entities/assets/Attachment';
import { AttachmentsByAssetIdState } from './Attachments.types';

export class Attachments {
  constructor(
    public readonly entityRepository: EntityRepository,
    public readonly store: IStore,
  ) {}

  attachmentsByAssetId: Record<string, Record<string, Attachment>> = {};

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes);
    this.handleAdditions(changes);
    this.handleUpdates(changes);
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      Object.entries(this.attachmentsByAssetId || {}).forEach(([key, attachments]) => {
        if (this.attachmentsByAssetId[entity.id]) {
          delete this.attachmentsByAssetId[entity.id];
          return;
        }
        if (this.attachmentsByAssetId[key]) {
          delete this.attachmentsByAssetId[key][entity.id];
        }
      });
      this._updateStore();
    });
  }

  private updateStore(entityId: string) {
    const entity = this.entityRepository.get(entityId)!;
    if (entity instanceof AssetBase && !this.attachmentsByAssetId[entity.id]) {
      this.attachmentsByAssetId[entity.id] = {};
    }
    if (!(entity instanceof Attachment)) return;
    if (entity.asset?.id) {
      this.attachmentsByAssetId[entity.asset?.id] = {
        ...this.attachmentsByAssetId[entity.asset?.id],
        [entity.id]: entity,
      };
      this._updateStore();
    }
  }

  private _updateStore() {
    const state = Object.entries(this.attachmentsByAssetId).reduce((acc, [key, value]) => {
      acc[key] = Object.values(value);
      return acc;
    }, {} as AttachmentsByAssetIdState);
    this.store.getState().setAttachmentsByAssetId(state satisfies AttachmentsByAssetIdState);
  }

  public dispose() {
    this.store.getState().setAttachmentsByAssetId({});
  }
}
