import { ExplorerMenuItemProps } from './explorer-menu';
import { cn } from '../../utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip';

export const ExplorerMenuItem = (props: ExplorerMenuItemProps) => {
  const { active, count, onClick, name, variant } = props;

  return (
    <Tooltip>
      <TooltipTrigger asChild className="mr-auto group-data-[collapsible=icon]:hidden">
        <div
          className={cn(
            'relative w-6 h-6 rounded-sm flex justify-center items-center hover:bg-accent cursor-pointer',
            active && variant === 'primary' && 'bg-base-primary-foreground',
            active && variant === 'secondary' && 'bg-accent',
          )}
          onClick={onClick}
        >
          <props.icon
            className={cn(
              'size-4 text-icon',
              active && variant === 'primary' && 'text-base-primary',
              active && variant === 'secondary' && 'text-icon',
            )}
          />
          {count ? (
            <div className="absolute -bottom-0 -right-0 w-3 h-3 rounded-full bg-base-primary flex justify-center items-center">
              <span className="text-[10px] text-white">{count}</span>
            </div>
          ) : null}
        </div>
      </TooltipTrigger>
      <TooltipContent side="right">{name}</TooltipContent>
    </Tooltip>
  );
};
