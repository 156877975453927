import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { useIntl } from 'react-intl';
import { Button, Card } from '@xspecs/design-system';

export interface UpgradePlanProps {
  collapsed: boolean;
}

export const UpgradePlan = ({ collapsed }: UpgradePlanProps) => {
  const navigate = useNavigate();
  const { formatMessage: f } = useIntl();
  const { organization } = useActiveOrganization();

  const navigateToPlans = useCallback(() => {
    const link = `/${organization?.name}/settings#plan`;
    navigate(link, {
      state: {
        preventNavigation: true,
      },
    });
  }, [organization?.name, navigate]);

  if (collapsed) {
    return (
      <Button variant="ghost" className="p-0 m-0 min-w-auto" onClick={navigateToPlans}>
        <img
          alt={f({ id: 'upgrade-your-plan' })}
          src="/upgrade-plan.svg"
          loading="lazy"
          width={40}
          height={40}
          className="mx-auto dark:invert"
        />
      </Button>
    );
  }

  return (
    <Card className="border border-border rounded-lg p-4 space-y-4 bg-background text-foreground">
      <div className="flex justify-between items-center">
        <div className="space-y-1 w-3/4">
          <h4 className="text-base font-semibold">{f({ id: 'upgrade-your-plan' })}</h4>
          <p className="text-sm text-muted-foreground">{f({ id: 'premium-features-with-one-click' })}</p>
        </div>
        <img
          alt={f({ id: 'upgrade-your-plan' })}
          src="/upgrade-plan.svg"
          loading="lazy"
          width={100}
          height={100}
          className="w-1/5 max-w-[100px]"
        />
      </div>

      <Button variant="secondary" className="w-full" onClick={navigateToPlans}>
        {f({ id: 'upgrade-now' })}
      </Button>
    </Card>
  );
};
