import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

type DispatchCommand = (command: string, params: object) => void;

const CommandDispatcherContext = createContext<{
  dispatchCommand: DispatchCommand;
} | null>(null);

type CommandDispatchProviderProps = {
  dispatchCommand: DispatchCommand;
  children: ReactNode;
};
export const CommandDispatchProvider: FC<CommandDispatchProviderProps> = ({ dispatchCommand, children }) => {
  const value = useMemo(() => ({ dispatchCommand }), [dispatchCommand]);

  return <CommandDispatcherContext.Provider value={value}>{children}</CommandDispatcherContext.Provider>;
};

export const useCommandDispatch = () => {
  const context = useContext(CommandDispatcherContext);
  if (!context) {
    throw new Error('useCommandDispatcher must be used within a CommandDispatcherProvider');
  }
  return context;
};
