import { AddScriptFrameCommand } from '../commands/scripts/AddScriptFrameCommand';
import { AddScriptLaneCommand } from '../commands/scripts/AddScriptLaneCommand';
import { DeleteScriptFrameCommand } from '../commands/scripts/DeleteScriptFrameCommand';
import { DeleteScriptLaneCommand } from '../commands/scripts/DeleteScriptLaneCommand';
import { ApplicationContext } from '../ApplicationContext';
import { RenameScriptLaneCommand } from '../commands/scripts/RenameScriptLaneCommand';

export class ScriptInteractor {
  constructor(private readonly model: ApplicationContext) {}

  addFrame(scriptId: string, frameId: string, position: 'before' | 'after') {
    this.model.messageBus.send(AddScriptFrameCommand, {
      scriptId: scriptId,
      frameId: frameId,
      position: position,
    });
  }

  deleteFrame(scriptId: string, frameIndex: number) {
    this.model.messageBus.send(DeleteScriptFrameCommand, {
      scriptId: scriptId,
      frameId: scriptId,
    });
  }

  addLane(scriptId: string, laneId: string, position: 'before' | 'after') {
    this.model.messageBus.send(AddScriptLaneCommand, {
      scriptId: scriptId,
      laneId: laneId,
      position: position,
    });
  }

  deleteLane(scriptId: string, laneId: string) {
    this.model.messageBus.send(DeleteScriptLaneCommand, {
      scriptId: scriptId,
      laneId: laneId,
    });
  }

  renameLane(scriptId: string, laneId: string, name: string) {
    this.model.messageBus.send(RenameScriptLaneCommand, {
      scriptId: scriptId,
      laneId: laneId,
      value: name,
    });
  }
}
