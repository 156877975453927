export const Rtf = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12V18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18V12Z"
        fill="#27272A"
      />
      <path
        d="M3.773 12.698H5.222C6.202 12.7027 6.77367 12.7143 6.937 12.733C7.413 12.7983 7.79567 12.9453 8.085 13.174C8.30433 13.3607 8.44433 13.5893 8.505 13.86C8.52367 13.9627 8.52367 14.0817 8.505 14.217C8.46767 14.3617 8.40933 14.49 8.33 14.602C8.22733 14.7467 8.09667 14.8657 7.938 14.959C7.854 15.0057 7.742 15.0593 7.602 15.12L7.532 15.148L7.588 15.176C7.74667 15.2787 7.86567 15.3883 7.945 15.505C8.01033 15.589 8.05467 15.6707 8.078 15.75C8.11067 15.82 8.14333 15.995 8.176 16.275C8.21333 16.5597 8.24133 16.7277 8.26 16.779C8.33467 17.0217 8.48867 17.143 8.722 17.143C8.77333 17.143 8.81067 17.1383 8.834 17.129C8.92733 17.0497 8.98333 16.9447 9.002 16.814C9.002 16.7627 9.02533 16.7207 9.072 16.688C9.10467 16.674 9.20733 16.6717 9.38 16.681C9.41267 16.681 9.43133 16.6833 9.436 16.688C9.46867 16.7067 9.48967 16.7347 9.499 16.772C9.51767 16.814 9.513 16.8863 9.485 16.989C9.41033 17.2643 9.24467 17.4463 8.988 17.535C8.94133 17.549 8.89467 17.5607 8.848 17.57C8.70333 17.5933 8.45833 17.5887 8.113 17.556C7.47367 17.4907 7.10267 17.2573 7 16.856C6.99067 16.828 6.98367 16.7977 6.979 16.765C6.97433 16.7323 6.972 16.5853 6.972 16.324C6.972 16.0393 6.96033 15.8573 6.937 15.778C6.87167 15.5727 6.75033 15.4303 6.573 15.351C6.50767 15.323 6.35133 15.309 6.104 15.309H5.656V16.996H6.412V17.5H6.321C6.32567 17.5 6.10633 17.4953 5.663 17.486C5.285 17.4813 4.90467 17.4813 4.522 17.486C4.07867 17.4953 3.85933 17.5 3.864 17.5H3.773V16.996H4.529V13.202H3.773V12.698ZM6.524 13.209C6.48667 13.2043 6.32333 13.202 6.034 13.202H5.656V14.882H6.104C6.44 14.8773 6.65933 14.8633 6.762 14.84C6.874 14.8167 6.98133 14.7723 7.084 14.707C7.17267 14.637 7.231 14.5273 7.259 14.378C7.28233 14.2193 7.28467 14.028 7.266 13.804C7.252 13.5893 7.18433 13.44 7.063 13.356C7.03967 13.342 7.014 13.328 6.986 13.314C6.85533 13.258 6.70133 13.223 6.524 13.209Z"
        fill="white"
      />
      <path
        d="M9.97013 12.817V12.775H14.6951V12.817L14.7721 13.706C14.8141 14.2893 14.8375 14.5857 14.8421 14.595V14.637H14.5901C14.4221 14.637 14.3381 14.6347 14.3381 14.63C14.3381 14.5367 14.3265 14.4153 14.3031 14.266C14.2751 14.056 14.2355 13.8833 14.1841 13.748C14.1141 13.5847 13.9951 13.4633 13.8271 13.384C13.7571 13.3467 13.6638 13.3187 13.5471 13.3C13.5051 13.2907 13.3861 13.2837 13.1901 13.279H12.9171V16.996H13.9881V17.5H13.8761L13.0501 17.486C12.5695 17.4813 12.0911 17.4813 11.6151 17.486L10.7891 17.5H10.6771V16.996H11.7481V13.279H11.4751C11.2791 13.2837 11.1601 13.2907 11.1181 13.3C10.8988 13.342 10.7308 13.4237 10.6141 13.545C10.5395 13.6197 10.4788 13.7317 10.4321 13.881C10.3948 14.0163 10.3645 14.224 10.3411 14.504C10.3318 14.5833 10.3271 14.6253 10.3271 14.63C10.3271 14.6347 10.2431 14.637 10.0751 14.637H9.82313V14.595L9.90013 13.706C9.94213 13.1227 9.96547 12.8263 9.97013 12.817Z"
        fill="white"
      />
      <path
        d="M15.4078 12.74H19.6428V12.768C19.6521 12.8007 19.6871 13.1017 19.7478 13.671C19.8178 14.2543 19.8551 14.5553 19.8598 14.574V14.602H19.3558C19.3558 14.5833 19.3488 14.5343 19.3348 14.455C19.3114 14.2683 19.2858 14.126 19.2578 14.028C19.1644 13.664 18.9591 13.4283 18.6418 13.321C18.5951 13.3023 18.5438 13.2883 18.4878 13.279C18.3944 13.2603 18.1658 13.2487 17.8018 13.244H17.3328V14.868H17.4448C17.7154 14.868 17.9021 14.8167 18.0048 14.714C18.0934 14.6253 18.1448 14.4503 18.1588 14.189V14.049H18.6628V16.191H18.1588V16.051C18.1448 15.7897 18.0934 15.6147 18.0048 15.526C17.9021 15.4233 17.7154 15.372 17.4448 15.372H17.3328V16.996H18.2778V17.5H18.1798L17.4168 17.486C16.9734 17.4813 16.5581 17.4813 16.1708 17.486C15.7181 17.4953 15.4941 17.5 15.4988 17.5H15.4078V16.996H16.1638V13.244H15.4078V12.74Z"
        fill="white"
      />
    </svg>
  );
};
