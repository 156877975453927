import { IModelContext } from '../../IModelContext';

// this is a factory to break break circular dependencies
export class ModelContextFactory {
  private static modelContextFactory: (() => IModelContext) | null = null;

  static registerFactory(factory: () => IModelContext): void {
    this.modelContextFactory = factory;
  }

  static create(): IModelContext {
    if (!this.modelContextFactory) {
      throw new Error('ModelContext factory not registered. Call ModelContextFactory.registerFactory() first.');
    }
    return this.modelContextFactory();
  }
}
