import { BalloonMenuMarkButton } from '../balloon-menu-mark-button';
import { getPluginType, isMarkActive, toggleMark, useEditorState } from '@udecode/plate-common';
import { useCallback, useState } from 'react';
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE } from '@udecode/plate-basic-marks';
import { ToggleGroup } from '@xspecs/design-system';
import { Bold, Italic, Strikethrough, Underline } from 'lucide-react';

export const BalloonMenuMarks = () => {
  const [activeMarks, setActiveMarks] = useState<string[]>([]);

  const editor = useEditorState();

  const updateActiveMarks = useCallback(
    (key: string) => {
      if (isMarkActive(editor, key)) {
        setActiveMarks((prev) => [...prev, key]);
      } else {
        setActiveMarks((prev) => prev.filter((mark) => mark !== key));
      }
    },
    [editor],
  );

  const handleToggleMark = useCallback(
    (key: string) => (event) => {
      event.preventDefault();
      toggleMark(editor, { key });
      updateActiveMarks(key);
    },
    [editor, updateActiveMarks],
  );

  const onToggleMark = useCallback(
    (key: string) => {
      if (editor) {
        return handleToggleMark(key);
      }
    },
    [editor, handleToggleMark],
  );

  return (
    <ToggleGroup type="multiple" size="sm" data-testid="balloon-menu-marks" value={activeMarks}>
      <BalloonMenuMarkButton
        data-testid="balloon-menu-bold"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_BOLD))}
        Icon={Bold}
        value={MARK_BOLD}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-italic"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_ITALIC))}
        Icon={Italic}
        value={MARK_ITALIC}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-underline"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_UNDERLINE))}
        Icon={Underline}
        value={MARK_UNDERLINE}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-strikethrough"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_STRIKETHROUGH))}
        Icon={Strikethrough}
        value={MARK_STRIKETHROUGH}
      />
    </ToggleGroup>
  );
};
