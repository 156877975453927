import { Status as StatusEnum } from '@xspecs/single-source-model';
import { cn } from '../../utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip';

export interface StatusProps {
  status: StatusEnum;
}

export const Status = (props: StatusProps) => {
  const { status } = props;

  return (
    <div className="flex gap-2 items-center">
      <span className="text-primary text-sm font-medium">Status:</span>
      <Tooltip>
        <TooltipTrigger asChild className="mr-auto group-data-[collapsible=icon]:hidden">
          <div
            className={cn(
              'w-2 h-2 rounded-full',
              'bg-semantic-info',
              status === StatusEnum.Synced && 'bg-semantic-success',
              status === StatusEnum.Unsynced && 'bg-semantic-warning',
              status === StatusEnum.Disconnected && 'bg-semantic-destructive',
              status === StatusEnum.AuthenticationFailed && 'bg-semantic-destructive',
            )}
          />
        </TooltipTrigger>
        <TooltipContent side="bottom">{status}</TooltipContent>
      </Tooltip>
    </div>
  );
};
