import { useTheme } from '@mui/material';
import { Button, Table, TableBody, TableCell, TableRow } from '@xspecs/design-system';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { Organization } from '../../state/types';
import { Loading } from '../../components/loading/loading';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@xspecs/design-system/src/ui/dialog/dialog';
import { ChevronRight } from 'lucide-react';

export type ChooseOrganizationProps = {
  items: Organization[];
  loading: boolean;
};

export const ChooseOrganization = (props: ChooseOrganizationProps) => {
  const { items, loading } = props;

  const theme = useTheme();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const onCreateNewOrganization = useCallback(() => {
    navigate(RoutePaths.CreateOrganization);
  }, [navigate]);

  const getOrganization = useCallback((id: string) => items.find((item) => item.id === id), [items]);

  const navigateToOrganization = useCallback(
    (organization: Organization) => {
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
        state: {
          preventNavigation: true,
        },
      });
    },
    [navigate],
  );

  const navigateToSpaces = useCallback(
    (organization: Organization) => {
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
        state: {
          preventNavigation: true,
        },
      });
    },
    [navigate],
  );

  const selectOrganization = useCallback(
    (id: string) => () => {
      const organization = getOrganization(id);
      if (!organization) return;
      if (organization.workspaces.length > 0) {
        navigateToOrganization(organization);
        trackEvent(AppTypeEvent.OrganizationSelected, { organizationId: organization.id });
      } else {
        navigateToSpaces(organization);
      }
    },
    [getOrganization, navigateToOrganization, trackEvent, navigateToSpaces],
  );

  const renderContent = useCallback(() => {
    return (
      <>
        <Table data-testid="organizations-list" className="mb-2">
          <TableBody>
            <div className="max-h-96 overflow-y-scroll">
              {items.map((organization) => (
                <OrganizationItem
                  key={organization.id}
                  {...organization}
                  onSelectOrganization={selectOrganization(organization.id)}
                />
              ))}
            </div>
          </TableBody>
        </Table>
        <Button data-testid="create-new-organization-button" variant="default" onClick={onCreateNewOrganization}>
          {f({ id: 'create-new-organization' })}
        </Button>
      </>
    );
  }, [f, items, onCreateNewOrganization, selectOrganization]);

  return (
    <Dialog open={true} modal={false}>
      <DialogContent noClose>
        <DialogHeader>
          <DialogTitle>{f({ id: 'choose-organization' })}</DialogTitle>
        </DialogHeader>
        {loading ? <Loading /> : renderContent()}
      </DialogContent>
    </Dialog>
  );
};

type OrganizationItemProps = {
  name: string;
  onSelectOrganization: () => void;
};

const OrganizationItem = (props: OrganizationItemProps) => {
  const { name, onSelectOrganization } = props;

  return (
    <TableRow className="flex items-center justify-between" onClick={onSelectOrganization}>
      <TableCell className="text-lg">{name}</TableCell>
      <TableCell className="flex justify-end items-center">
        <ChevronRight size={16} />
      </TableCell>
    </TableRow>
  );
};
