import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SelectIdePanelCommandParams extends IParams {
  id: string;
}

export class SelectIdePanelCommandEvent extends EventBase {
  static eventType = 'SelectIdePanelCommandEvent';

  constructor(
    public readonly params: SelectIdePanelCommandParams,
    public readonly source = SelectIdePanelCommand,
  ) {
    super();
  }
}

export class SelectIdePanelCommand extends CommandBase<SelectIdePanelCommandParams> {
  execute(params: SelectIdePanelCommandParams): SelectIdePanelCommandEvent | CommandError | undefined {
    const { id } = params;
    this.appState.ide.selectPanel(id);
    return new SelectIdePanelCommandEvent(params);
  }
}
