import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '../sidebar/sidebar';
import { useIntl } from 'react-intl';
import { ArrowLeft } from 'lucide-react';

export interface PricingSidebarProps {
  collapsed?: boolean;
  goBack: () => void;
  links: { label: string; active: boolean; onClick: () => void }[];
}

export const PricingSidebar = (props: PricingSidebarProps) => {
  const { collapsed, goBack, links } = props;
  const { formatMessage: f } = useIntl();

  return (
    <Sidebar collapsed={collapsed}>
      <SidebarHeader className="h-11">
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="default" onClick={goBack} className="hover:bg-transparent cursor-pointer p-0">
              <div className="flex aspect-square size-6 items-center justify-center rounded-md bg-accent text-sidebar-primary-foreground">
                <ArrowLeft className="size-4 text-base-primary" />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight text-primary">
                <span className="truncate font-semibold">{f({ id: 'back' })}</span>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup className="h-full">
          <SidebarGroupLabel>{f({ id: 'plans-and-billing' })}</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {links.map((link) => (
                <SidebarMenuItem key={`pricing-sidebar-menu-item-${link.label}`} onClick={link.onClick}>
                  <SidebarMenuButton isActive={link.active}>{link.label}</SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
};
