import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const OnAutoIcon: FC<IconComponentProps> = () => {
  return (
    <>
      <g clipPath="url(#clip0_9726_19187)">
        <path
          d="M22.8 0H1.2C0.533333 0 0 0.533333 0 1.2V22.8C0 23.4667 0.533333 24 1.2 24H22.8C23.4667 24 24 23.4667 24 22.8V1.2C24 0.533333 23.4667 0 22.8 0Z"
          fill="#0057DD"
        />
        <path
          d="M17.3333 21.0668C19.4 21.0668 21.0667 19.4001 21.0667 17.3334C21.0667 15.2668 19.4 13.6001 17.3333 13.6001C15.2667 13.6001 13.6 15.2668 13.6 17.3334C13.6 19.4001 15.2667 21.0668 17.3333 21.0668Z"
          fill="white"
        />
        <path
          d="M2.93335 13.6801C2.93335 13.6401 2.97335 13.6001 3.01335 13.6001H10.3333C10.3733 13.6001 10.4133 13.6401 10.4133 13.6801V17.2668C10.4133 17.3068 10.3733 17.3468 10.3333 17.3468H8.68001C8.64001 17.3468 8.60001 17.3868 8.60001 17.4268V21.0134C8.60001 21.0534 8.56001 21.0934 8.52001 21.0934H4.88001C4.84001 21.0934 4.80001 21.0534 4.80001 21.0134V17.4268C4.80001 17.3868 4.76001 17.3468 4.72001 17.3468H3.00001C2.96001 17.3468 2.92001 17.3068 2.92001 17.2668V13.6801H2.93335Z"
          fill="white"
        />
        <path
          d="M13.68 2.93335C13.64 2.93335 13.6 2.97335 13.6 3.01335V6.78668C13.6 8.78668 15.2667 10.4 17.3333 10.4C19.4 10.4 21.0667 8.78668 21.0667 6.78668V3.01335C21.0667 2.97335 21.0267 2.93335 20.9867 2.93335H13.6667H13.68Z"
          fill="white"
        />
        <path
          d="M2.94668 10.2801C2.92001 10.3334 2.94668 10.3868 3.01335 10.3868H10.32C10.3733 10.3868 10.4133 10.3334 10.3867 10.2801L6.73335 2.98678C6.70668 2.93344 6.62668 2.93344 6.60002 2.98678L2.94668 10.2801Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9726_19187">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};
