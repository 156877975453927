import { Avatar, Avatars } from '../../../avatars/avatars';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useMemo } from 'react';

export const SingleSourceModelToolbarAvatars = ({ id }: { id: string }) => {
  const activeUsers = useSingleSourceStore.use.activeUsersByFile()[id];

  const users = useMemo<Avatar[]>(() => {
    if (!activeUsers) return [];
    return activeUsers.map(
      (user) =>
        ({ id: user.id, sub: user.sub, name: user.name, picture: user.picture, color: user.color }) satisfies Avatar,
    );
  }, [activeUsers]);

  return <Avatars users={users} />;
};
