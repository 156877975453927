import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface RemoveIdePanelCommandParams extends IParams {
  id: string;
}

export class RemoveIdePanelCommandEvent extends EventBase {
  static eventType = 'RemoveIdePanelCommandEvent';

  constructor(
    public readonly params: RemoveIdePanelCommandParams,
    public readonly source = RemoveIdePanelCommandCommand,
  ) {
    super();
  }
}

export class RemoveIdePanelCommandCommand extends CommandBase<RemoveIdePanelCommandParams> {
  execute(params: RemoveIdePanelCommandParams): RemoveIdePanelCommandEvent | CommandError | undefined {
    const { id } = params;
    this.appState.ide.delete(id);
    return new RemoveIdePanelCommandEvent(params);
  }
}
