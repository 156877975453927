import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_PORTAL_SESSION } from '../../../../graphql/queries';
import { useCallback, useState } from 'react';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { Button } from '@xspecs/design-system';
import { Loading } from '../../../loading/loading';

export const CustomerPortalButton = () => {
  const { formatMessage: f } = useIntl();

  const [loading, setLoading] = useState(false);

  const { organization } = useActiveOrganization();

  const { addToast } = useSnackStack();

  const [fetchCustomerPortalSessionUrl] = useLazyQuery(CUSTOMER_PORTAL_SESSION, {
    variables: {
      organizationId: organization ? organization.id : '',
    },
  });

  const onGoToBillingHistory = useCallback(async () => {
    if (!organization) {
      return;
    }
    try {
      setLoading(true);
      const result = await fetchCustomerPortalSessionUrl();
      const url = result?.data?.customerPortalSession.url;
      if (url) {
        window.location.replace(url);
      } else {
        addToast({ severity: 'warning', message: f({ id: 'unable-to-fetch-billing-history' }) });
      }
    } catch (error) {
      addToast({ severity: 'warning', message: f({ id: 'unable-to-fetch-billing-history' }) });
    } finally {
      setLoading(false);
    }
  }, [addToast, f, fetchCustomerPortalSessionUrl, organization]);

  if (!organization) {
    return <Typography>{f({ id: 'no-organization-selected' })}</Typography>;
  }

  return organization.hasBilling ? (
    <Button variant="outline" size="lg" onClick={onGoToBillingHistory}>
      {loading ? <Loading /> : null}
      {f({ id: 'go-to-customer-billing-portal' })}
    </Button>
  ) : (
    <p className="text-sm text-muted-foreground">{f({ id: 'no-invoices-to-show' })}</p>
  );
};
