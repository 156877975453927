import { formatDistance, isBefore } from 'date-fns';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { USERS_QUERY } from '../../graphql/queries';
import { useMemo } from 'react';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { Avatar, AvatarFallback, AvatarImage } from '@xspecs/design-system';

type CommentUserInfoProps = {
  userId: string;
  createdAt: Date;
  updatedAt?: Date;
};

export const CommentUserInfo = (props: CommentUserInfoProps) => {
  const { userId, updatedAt, createdAt } = props;

  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();

  const { data: usersData } = useQuery(USERS_QUERY, {
    variables: {
      organizationId: organization ? organization.id : '',
    },
    skip: !organization?.id,
  });

  const user = useMemo(() => usersData?.users.find((user) => user.id === userId), [userId, usersData?.users]);

  const dateToUse = updatedAt && isBefore(createdAt, updatedAt) ? updatedAt : createdAt;

  const getUserInitials = (name: string) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map((part) => part[0].toUpperCase());
    return initials.slice(0, 2).join('');
  };

  return (
    <div className="flex items-center gap-2">
      <Avatar className="h-8 w-8 rounded-lg overflow-hidden">
        <AvatarImage src={user?.picture ?? undefined} alt={user?.name ?? undefined} />
        <AvatarFallback>{user?.name ? getUserInitials(user?.name) : ''}</AvatarFallback>
      </Avatar>
      <div className="grid flex-1 text-left text-sm leading-tight">
        <span className="truncate font-semibold">{user?.name ?? f({ id: 'unknown-user' })}</span>
        <span className="truncate text-xs">{formatDistance(dateToUse, Date.now())} ago</span>
      </div>
    </div>
  );
};
