import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { DEBUG_CONFIG } from '../../debug-config';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { AssetBase } from '../../entities/assets/AssetBase';
import { EntityBase } from '../../entities/EntityBase';
import { Edge } from '../../entities/transitions/Edge';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { IModelContext } from '../../IModelContext';

interface DeleteEntityParams extends IParams {
  entityIds?: string[];
}

export interface EntitiesDeletedParams extends IParams {
  entityIds: string[];
  affected?: { entityId: string }[];
}

export class EntitiesDeletedEvent extends EventBase {
  static eventType = 'EntitiesDeletedEvent';

  constructor(
    public readonly params: EntitiesDeletedParams,
    public readonly source = DeleteEntitiesCommand,
  ) {
    super();
  }
}

export class DeleteEntitiesCommand extends CommandBase<DeleteEntityParams> {
  execute(params: DeleteEntityParams): EntitiesDeletedEvent | CommandError {
    const modelContext = this.getModelContext();
    const entities = modelContext.entityRepository.getAllUniqueChildren(
      Object.keys(modelContext.entitySelectionTracker.localSelections),
      (e) => !(e instanceof AssetBase),
    );
    if (params.entityIds) {
      const providedEntities: EntityBase[] = params.entityIds
        .map((id) => modelContext.entityRepository.get(id))
        .filter(Boolean) as EntityBase[];
      providedEntities.forEach((entity) => entities.add(entity));
    }
    const entityIds = Array.from(entities)
      .map((item) => item.id)
      .reverse();
    entityIds.forEach((id) => {
      this.deleteIndexEntry(id, modelContext);
    });
    const affected: { entityId: string }[] = [];
    entityIds.forEach((id) => {
      const entity = modelContext.entityRepository.get(id);
      if (!entity) return;
      if (entity instanceof Edge && !entity.isDeletable) return;
      if (
        entity instanceof ScriptBase &&
        entity.parent &&
        entity.parent instanceof ConstructBase &&
        entity.parent.hasRequiredScript
      ) {
        if (entity.parent.parent) affected.push({ entityId: entity.parent.parent.id });
        modelContext.entityRepository.delete(entity.parent.id);
      } else if (entity instanceof Preview) {
        const attachment = entity.parent!;
        const entitiesToDelete = [entity.id, attachment.id].filter(Boolean);
        entitiesToDelete.forEach((entityId) => modelContext.entityRepository.delete(entityId));
        if (attachment?.parent?.id) affected.push({ entityId: attachment.parent.id });
      } else {
        if (entity.parent) affected.push({ entityId: entity.parent.id });
        modelContext.entityRepository.delete(id);
      }
      if (entity instanceof GqlEntityBase) {
        const query = entity.query!;
        query.syncGqlEntitiesToQuery();
        modelContext.entityRepository.update(query);
      }
    });
    return new EntitiesDeletedEvent({ entityIds, affected });
  }

  // THIS SHOULD ALL BE EVENT DRIVEN

  private deleteIndexEntry(id: string, modelContext: IModelContext) {
    modelContext.boundariesIndex.removeEntry(id);
    const entity = modelContext.entityRepository.get(id);
    if (entity instanceof ScriptBase) {
      for (const frame of entity.getFrames()) {
        for (const lane of frame.lanes) {
          this.deleteIndexEntry(`${entity.id}_${frame.frameIndex}_${lane.laneIndex}`, modelContext);
        }
      }
    }
    if (DEBUG_CONFIG.boundaries) modelContext.graph['showBoundaries']();
  }
}
