import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChanges } from '../../types';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { Attachment } from '../../entities/assets/Attachment';
import { TransientStore } from '../../data/Transient';
import { Query } from '../../entities/assets/Query';
import { DetailsElement } from '../../apps/DetailsElement';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { FileType as EntityDetailsEditor } from 'narrative-studio-sdk';
import { EntityBase } from '../../entities/EntityBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';

export { EntityDetailsEditor };

export enum CommandStrategy {
  LOAD_OR_ERROR = 'LOAD_OR_ERROR',
  LOAD_OR_NEW = 'LOAD_OR_NEW',
  CREATE_OR_ERROR = 'CREATE_OR_ERROR',
}

export type EntityDetailsState = {
  id: string;
  type: EntityType | string;
  name: string;
  subType?: AttachmentType;
  asset?: {
    id: string;
    type: EntityType | string;
  };
  queryText?: string;
  iconUrl?: string;
  detailsPaneLayout?: DetailsElement[];
  editor: EntityDetailsEditor;
  commands: Record<string, { fields: { name: string }[] }>;
  events: Record<string, { fields: { name: string }[] }>;
  states: Record<string, { fields: { name: string }[] }>;
  commandStrategy?: CommandStrategy;
};

export class EntityDetails {
  private modelCommandsEventsCache: Record<string, Record<string, ConstructBase>> = {};

  constructor(
    public readonly entityRepository: EntityRepository,
    public readonly store: IStore,
  ) {}

  private get entityId() {
    return TransientStore.provider.get('entityDetailView') ?? null;
  }

  private set entityId(value: string | null) {
    TransientStore.provider.set('entityDetailView', value);
  }

  close(entityId: string) {
    if (this.entityId !== entityId) return;
    this.entityId = null;
    this.dispose();
  }

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes);
    this.handleAdditions(changes);
    this.handleUpdates(changes);

    changes.updated.forEach((update) => this.handleCommandEventsChanges(update.entity));
    changes.added.forEach((entity) => this.handleCommandEventsChanges(entity));
    changes.deleted.forEach((entity) => {
      const modal = this.modelCommandsEventsCache[entity.id];
      if (modal) {
        delete this.modelCommandsEventsCache[entity.id];
        if (!this.entityId) return;
        this.updateStore(this.entityId);
      }
      if (entity.type === EntityType.Command || entity.type === EntityType.Event || entity.type === 'State') {
        Object.entries(this.modelCommandsEventsCache).forEach(([key, value]) => {
          if (value[entity.id]) {
            delete this.modelCommandsEventsCache[key][entity.id];
          }
        });
        if (!this.entityId) return;
        this.updateStore(this.entityId);
      }
    });
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      if (entity.id === this.entityId) {
        this.entityId = null;
        this.dispose();
      }
    });
  }

  private handleCommandEventsChanges(entity: EntityBase) {
    if (!(entity.type === EntityType.Command || entity.type === EntityType.Event || entity.type === 'State')) {
      return;
    }

    if (!(entity.parent instanceof ScriptBase)) {
      Object.entries(this.modelCommandsEventsCache).forEach(([key, value]) => {
        if (value[entity.id]) {
          delete this.modelCommandsEventsCache[key][entity.id];
        }
      });
      if (!this.entityId) return;
      this.updateStore(this.entityId);
      return;
    }

    const current = this.modelCommandsEventsCache[entity.parent.id]
      ? this.modelCommandsEventsCache[entity.parent.id]
      : {};
    current[entity.id] = entity as ConstructBase;
    this.modelCommandsEventsCache[entity.parent.id] = current;
    if (!this.entityId) return;
    this.updateStore(this.entityId);
  }

  private updateStore(entityId: string) {
    if (this.entityId !== entityId) return;
    const entity = this.entityRepository.get(entityId)!;
    const constructs = Object.values(
      entity.type === 'GWT' && entity.parent instanceof ScriptBase
        ? this.modelCommandsEventsCache[entity.parent.id]
        : {},
    ).map((c) => ({ ...c, payload: parsePayload(c.payload) }));

    this.store.getState().setEntityDetails({
      ...this.store.getState().entityDetails,
      id: entity.id,
      type: entity.type,
      name: entity.name,
      ...(entity instanceof Attachment ? { asset: entity.asset, subType: entity.subType } : {}),
      queryText: entity instanceof Query ? entity.queryText : undefined,
      iconUrl: entity.parent instanceof Attachment ? entity.parent.iconUrl : undefined,
      detailsPaneLayout: entity.getDetailsPane(),
      editor: entity instanceof ConstructBase ? entity.fileConfig.type : EntityDetailsEditor.SLATE,
      commands: constructs
        .filter((c) => c.type === EntityType.Command && c.payload.name)
        .reduce((previousValue, currentValue) => {
          if (!currentValue.payload.name) return previousValue;
          return {
            ...previousValue,
            [currentValue.payload.name]: { fields: currentValue.payload.fields ?? [] },
          };
        }, {}),
      events: constructs
        .filter((c) => c.type === EntityType.Event && c.payload.name)
        .reduce((previousValue, currentValue) => {
          if (!currentValue.payload.name) return previousValue;
          return {
            ...previousValue,
            [currentValue.payload.name]: { fields: currentValue.payload.fields ?? [] },
          };
        }, {}),
      states: constructs
        .filter((c) => c.type === 'State' && c.payload.name)
        .reduce((previousValue, currentValue) => {
          if (!currentValue.payload.name) return previousValue;
          return {
            ...previousValue,
            [currentValue.payload.name]: { fields: currentValue.payload.fields ?? [] },
          };
        }, {}),
      commandStrategy: parsePayload(entity.payload).strategy as CommandStrategy | undefined,
    } satisfies EntityDetailsState);
  }

  public dispose() {
    this.entityId = null;
    this.store.getState().setEntityDetails(null);
  }
}

const parsePayload = (payload: string) => {
  try {
    return JSON.parse(payload);
  } catch {
    return {};
  }
};
