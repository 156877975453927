import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { ExplorerSort } from '../../read-models/explorer/ExplorerItem';

interface SetExplorerSortParams extends IParams {
  sort: ExplorerSort;
}

export class SetExplorerSortEvent extends EventBase {
  static eventType = 'SetExplorerSortEvent';

  constructor(
    public readonly params: SetExplorerSortParams,
    public readonly source = SetExplorerSortCommand,
  ) {
    super();
  }
}

export class SetExplorerSortCommand extends CommandBase<SetExplorerSortParams> {
  execute(params: SetExplorerSortParams): SetExplorerSortEvent | CommandError | undefined {
    const { sort } = params;
    this.applySort(sort);
    return new SetExplorerSortEvent(params);
  }

  private applySort(sort: ExplorerSort) {
    this.getModelContext().explorer.updateSort(sort);
  }
}
