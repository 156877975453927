export const MouseWheel = () => {
  return (
    <>
      <path
        d="M12 6V10M12 2C15.866 2 19 5.13401 19 9V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 5.13401 8.13401 2 12 2Z"
        stroke="#27272A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 6V10" stroke="#599AFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </>
  );
};
