import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../dropdown-menu/dropdown-menu';
import { SidebarMenu, SidebarMenuItem } from './sidebar';
import { ReactNode } from 'react';
import { AddFileMenuItem } from '@xspecs/single-source-model';
import { Icon, useCommandDispatch } from '@xspecs/design-system';

export interface SidebarNewFileDropdownProps {
  trigger: ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  align?: 'end' | 'center' | 'start' | undefined;
  options: AddFileMenuItem[];
}

export function SidebarNewFileDropdown(props: SidebarNewFileDropdownProps) {
  const { trigger, side, align, options } = props;

  const { dispatchCommand } = useCommandDispatch();

  const onItemClick = (onClick: AddFileMenuItem['onClick']) => {
    dispatchCommand(onClick.command, onClick.params);
  };

  return (
    <SidebarMenu>
      <SidebarMenuItem className="flex justify-center items-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={side}
            align={align}
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 text-sm text-muted-foreground leading-3 px-2 py-1.5">
              Create new file
            </DropdownMenuLabel>
            <DropdownMenuGroup>
              {options.map((option) => (
                <DropdownMenuItem
                  key={option.label}
                  className="text-primary"
                  onClick={() => onItemClick(option.onClick)}
                >
                  <Icon name={option.icon} className="text-icon-muted" />
                  {option.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
