import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { EntityBase } from '../../entities/EntityBase';
import { CommandError } from '../../ErrorStore';

interface AddEntitiesToLabelParams extends IParams {
  labelId: string;
  entityIds: string[];
}

export class EntitiesAddedToLabelEvent extends EventBase {
  static eventType = 'EntitiesAddedToLabelEvent';

  constructor(
    public readonly params: AddEntitiesToLabelParams,
    public readonly source = AddEntitiesToLabelCommand,
  ) {
    super();
  }
}

export class AddEntitiesToLabelCommand extends CommandBase<AddEntitiesToLabelParams> {
  execute(params: AddEntitiesToLabelParams): EventBase | CommandError | undefined {
    const modelContext = this.getModelContext();
    const label = modelContext.entityRepository.get<Label>(params.labelId)!;
    const entities = params.entityIds.map((id) => modelContext.entityRepository.get<EntityBase>(id)!);
    label.addEntities(entities);
    for (const entity of entities) {
      modelContext.entityRepository.update(entity);
    }
    modelContext.entityRepository.update(label);
    modelContext.entityRepository.filterEntities(
      entities,
      this.appState.store.getState().savedFilters[0] ?? this.appState.store.getState().unsavedFilter,
    );
    return new EntitiesAddedToLabelEvent(params);
  }
}
