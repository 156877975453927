import { Home, LucideIcon } from 'lucide-react';
import { ExplorerMenuItem } from './explorer-menu-item';
import { UserDropdown } from '../sidebar/user-dropdown';
import { Avatar, AvatarFallback, AvatarImage } from '../avatar/avatar';
import { cn } from '../../utils';
import { useTheme } from '@xspecs/design-system';

export interface ExplorerMenuItemProps {
  name: string;
  icon: LucideIcon;
  active: boolean;
  count?: number;
  onClick: () => void;
  variant: 'primary' | 'secondary';
}

export interface ExplorerMenuProps {
  topItems: ExplorerMenuItemProps[];
  bottomItems: ExplorerMenuItemProps[];
  showUser: boolean;
  user: {
    name: string;
    email: string;
    avatar: string;
  };
  logout: () => void;
  viewSpaces: () => void;
}

export const ExplorerMenu = (props: ExplorerMenuProps) => {
  const { user, showUser, logout, topItems, bottomItems, viewSpaces } = props;
  const { theme } = useTheme();

  return (
    <div
      className={cn(
        'w-8 h-full border-r bg-background-grey pt-2.5 px-1 flex flex-col gap-11',
        showUser ? 'pb-2.5' : 'pb-19.5',
      )}
    >
      <div className={cn('relative w-6 h-6 rounded-sm flex justify-center items-center bg-accent cursor-pointer')}>
        <Home className="size-4 text-base-primary" onClick={viewSpaces} />
      </div>
      <div className="flex-1 w-full flex flex-col justify-between">
        <ul className="flex flex-col gap-3">
          {topItems?.map((item) => <ExplorerMenuItem key={item.name} {...item} />)}
        </ul>
        {theme === 'dark' ? (
          <ul className="flex flex-col gap-3">
            {bottomItems?.map((item) => <ExplorerMenuItem key={item.name} {...item} />)}
          </ul>
        ) : null}
      </div>
      {showUser ? (
        <UserDropdown
          logout={logout}
          trigger={
            <Avatar className="h-6 w-6 rounded-md cursor-pointer">
              <AvatarImage src={user.avatar} alt={user.name} />
              <AvatarFallback className="rounded-lg">CN</AvatarFallback>
            </Avatar>
          }
        />
      ) : null}
    </div>
  );
};
