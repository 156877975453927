import { Plans } from '../../components/settings/plans/plans';
import { Billing } from '../../components/settings/billing/billing';
import { navBarOverridePropsVar } from '../../state/state';
import { useCallback, useEffect } from 'react';
import { PricingLayout } from '@xspecs/design-system';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useAuth } from '../../auth';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useMenuItemSettings } from '../../hooks/use-menu-item-settings';

export const SettingsRoute = () => {
  const auth = useAuth();
  const logout = auth?.logout;
  const { organization } = useActiveOrganization();
  const menuItemSettings = useMenuItemSettings();

  useEffect(() => {
    navBarOverridePropsVar({ hideWorkspaces: true });
    return () => {
      navBarOverridePropsVar({});
    };
  }, []);

  const handleLogout = useCallback(() => {
    logout?.({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <PricingLayout
      topMenu={{
        logout: handleLogout,
        hasApps: false,
        organizationName: organization?.name ?? '',
        menuItemSettingsActions: menuItemSettings,
        hideCodeActions: true,
      }}
    >
      <div className="p-3 h-full flex flex-col overflow-y-auto gap-4 w-full bg-background-grey">
        <Plans />
        <Billing />
        {/*<Apps />*/}
      </div>
    </PricingLayout>
  );
};
