import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { StoreWithSelectors as ModelStoreWithSelectors } from '@xspecs/single-source-model';

export type StoreWithSelectors = ModelStoreWithSelectors extends { getState: () => infer T }
  ? ModelStoreWithSelectors & { use: { [K in keyof T]: () => T[K] } }
  : never;

const StoreContext = createContext<{
  store: StoreWithSelectors;
}>(null!);

type StoreProviderProps = {
  store: StoreWithSelectors;
  children: ReactNode;
};
export const StoreProvider: FC<StoreProviderProps> = ({ store, children }) => {
  const value = useMemo(() => ({ store }), [store]);

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const useStoreContext = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStoreContext must be used within a StoreProvider');
  }
  return context;
};
