import { HocuspocusProviderWebsocket } from '@hocuspocus/provider';
import { IStore } from './Store';
import { FileBase } from './FileBase';
import { FileEvents, FileType, Status } from './File.types';
import { ModelFile } from './ModelFile';
import { DocFile } from './DocFile';
import { SpecFile } from './SpecFile';
import { User } from '../types';
import { SpaceFile } from './SpaceFile';

class TestProvider {
  private static instanceRef: TestProvider;

  static instance() {
    if (!TestProvider.instanceRef) TestProvider.instanceRef = new TestProvider();
    return TestProvider.instanceRef as unknown as HocuspocusProviderWebsocket;
  }

  on(_event: string, _cb: (e: Event) => void) {
    // console.log('TestProvider on', event);
  }

  attach() {
    // console.log('TestProvider attach');
  }

  send() {
    // console.log('TestProvider send');
  }

  destroy() {
    // console.log('TestProvider destroy');
  }
}

export interface IFileStoreClient {
  files: Record<string, FileBase>;

  loadModelVersion({ fileId, version }: { fileId: string; version?: string }): ModelFile;

  loadFile<T extends FileBase>({
    fileId,
    version,
    type,
    onSynced,
  }: {
    fileId: string;
    version?: string;
    type: FileType;
    onSynced?: () => void;
  }): T;

  removeFile(fileId: string): void;

  dispose(): void;
}

export class FileStoreClient implements IFileStoreClient {
  public readonly status: Status;
  private readonly store: IStore;
  private readonly websocketProvider: HocuspocusProviderWebsocket;
  private user: User;
  private token: string;
  private host: string | undefined;

  public files: Record<string, FileBase> = {};
  private readonly appVersion: number;

  constructor(url: string, store: IStore, appVersion: number, testEnv: boolean) {
    this.store = store;
    this.appVersion = appVersion;
    if (testEnv) this.websocketProvider = TestProvider.instance();
    else
      this.websocketProvider = new HocuspocusProviderWebsocket({
        url,
        connect: true,
        messageReconnectTimeout: 10000,
        providerMap: new Map(),
      });
    this.initStatusListeners();
  }

  setContext({ token, user, host }: { token: string; user: User; host?: string }) {
    this.updateInternals({ token, user, host });
  }

  private updateInternals({ token, user, host }: { token: string; user: User; host?: string }) {
    this.token = token;
    this.user = user;
    this.host = host;
  }

  private initStatusListeners() {
    Object.entries({
      // open: FileEvents.open,
      // connect: FileEvents.connect,
      // status: FileEvents.status,
      // message: FileEvents.message, // very noise
      // outgoingMessage: FileEvents.outgoingMessage, // very noise
      close: FileEvents.close,
      destroy: FileEvents.destroy,
      disconnect: FileEvents.disconnect,
      awarenessUpdate: FileEvents.awarenessUpdate,
    }).forEach(([event, eventType]) => this.websocketProvider.on(event, (e: Event) => this.setStatus(eventType, e)));
  }

  private setStatus(eventType: FileEvents, _e: Event) {
    if (eventType === FileEvents.awarenessUpdate) {
      // console.log('FileStoreClient AWARENESS UPDATE', e);
    }
    if ([FileEvents.close, FileEvents.disconnect, FileEvents.destroy].includes(eventType)) {
      // console.log('FileStoreClient WS DISCONNECTED');
    }
  }

  loadModelVersion({ fileId, version }: { fileId: string; version?: string }): ModelFile {
    const modelFile = new ModelFile({
      fileId,
      version,
      token: this.token,
      host: this.host,
      websocketProvider: this.websocketProvider,
      user: this.user,
      store: this.store,
      appVersion: this.appVersion,
    });
    modelFile.load();
    return modelFile;
  }

  /**
   * Loads a file dynamically based on its type, reusing an existing instance if available.
   */
  loadFile<T extends FileBase>({
    fileId,
    version,
    type,
    onSynced,
  }: {
    fileId: string;
    version?: string;
    type: FileType;
    onSynced?: () => void;
  }): T {
    if (this.files[fileId]) return this.files[fileId] as T;
    let file: FileBase;
    switch (type) {
      case FileType.Model:
        file = new ModelFile({
          fileId,
          version,
          token: this.token,
          host: this.host,
          websocketProvider: this.websocketProvider,
          user: this.user,
          store: this.store,
          appVersion: this.appVersion,
          onSynced,
        });
        break;

      case FileType.Doc:
        file = new DocFile({
          fileId,
          version,
          token: this.token,
          host: this.host,
          websocketProvider: this.websocketProvider,
          user: this.user,
          store: this.store,
          appVersion: this.appVersion,
        });
        break;

      case FileType.Spec:
        file = new SpecFile({
          fileId,
          version,
          token: this.token,
          host: this.host,
          websocketProvider: this.websocketProvider,
          user: this.user,
          store: this.store,
          appVersion: this.appVersion,
        });
        break;

      case FileType.Space:
        file = new SpaceFile({
          fileId,
          version,
          token: this.token,
          host: this.host,
          websocketProvider: this.websocketProvider,
          user: this.user,
          store: this.store,
          appVersion: this.appVersion,
        });
        break;

      default:
        throw new Error(`Unknown file type: ${type}`);
    }

    file.load();
    this.files[fileId] = file;
    this.websocketProvider.attach(file.provider);
    return file as T;
  }

  public removeFile(fileId: string): void {
    const file = this.files[fileId];
    if (!file) {
      return;
    }
    file.dispose();
    this.websocketProvider.detach(file.provider);
    delete this.files[fileId];
    this.store.getState().setFileById(fileId, undefined);
  }

  dispose() {
    this.websocketProvider.destroy();
  }
}

// const isActiveUser = (obj: any): obj is ActiveUser => {
//   return obj.name && obj.color && obj.sub && obj.id;
// };
