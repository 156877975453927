import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@xspecs/design-system/src/ui/dialog/dialog';
import { useReactiveVar } from '@apollo/client';
import { showInviteUsersVar } from '../../state/state';
import { InvitePeopleContent } from '../../pages/invite-people/invite-people';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const InviteUsersModal = () => {
  const open = useReactiveVar(showInviteUsersVar);
  const { organization } = useActiveOrganization();
  const { formatMessage: f } = useIntl();

  const onOpenChange = useCallback((open: boolean) => showInviteUsersVar(open), []);

  const onCancel = useCallback(() => showInviteUsersVar(false), []);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-md p-6 flex flex-col gap-2">
        <DialogHeader>
          <DialogTitle>{f({ id: 'invite-people-organization' }, { name: organization?.name })}</DialogTitle>
          <DialogDescription>{f({ id: 'currently-on-free-plan-plans-and-billing-soon' })}</DialogDescription>
        </DialogHeader>

        <InvitePeopleContent onSendInvitationSuccess={onCancel} onInviteLater={onCancel} />
      </DialogContent>
    </Dialog>
  );
};
