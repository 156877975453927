import { EntityType } from '../../entities/EntityType';
import { ConstructShape } from '../../entities/constructs/ConstructBase';

export type ExplorerState = {
  results: ExplorerResult;
  searchQuery: string;
  sortQuery: ExplorerSort;
};

export enum ExplorerSort {
  Newest = 'newest',
  Ascending = 'ascending',
  Descending = 'descending',
}

export type ExplorerResult = ExplorerItem[];

export type ExplorerItem = {
  id: string;
  text: string;
  type: EntityType;
  children: ExplorerItem[];
  shape: ConstructShape;
  parent?: string;
  isHighlighted: boolean;
  isExpanded: boolean;
  scopes: string[];
  style?: object;
};
