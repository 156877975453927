import { FileBase } from './FileBase';
import { FileType, Status } from './File.types';
import { Awareness } from 'y-protocols/awareness';
import { YMap } from 'yjs/dist/src/types/YMap';

export class SpaceFile extends FileBase {
  public filesMap: YMap<any>;
  public settingsMap: YMap<any>;

  getType(): FileType {
    return FileType.Space;
  }

  public get awareness(): Awareness | null {
    return this.provider.awareness;
  }

  load(): void {
    this.status = Status.Initial;
    this.filesMap = this.document!.getMap<any>('files');
    this.settingsMap = this.document!.getMap<any>('settings');
  }
}
