export const FormatLogPart = (logText: string) => {
  const match = logText.match(/\[(.*?)\]\(([^\s]+)\)/);

  if (match) {
    const customText = match[1];
    const url = match[2];

    return (
      <a href={`https://${url}`} target="_blank" rel="noopener noreferrer" className="underline">
        {customText}
      </a>
    );
  } else {
    return logText;
  }
};
