import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
} from '@xspecs/design-system';
import { Upload } from 'lucide-react';

export type UploadLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (url: string) => void;
};

const INITIAL_URL = '';

export const UploadLinkModal = (props: UploadLinkModalProps) => {
  const { isOpen, onClose: onCloseProp, onUpload: onUploadProp } = props;

  const { formatMessage: f } = useIntl();

  const [url, setUrl] = useState(INITIAL_URL);
  const disabled = !isValidUrl(url);

  const onUpload = useCallback(
    (e) => {
      e.preventDefault();
      onUploadProp(url);
      onCloseProp();
      setUrl(INITIAL_URL);
    },
    [onCloseProp, onUploadProp, url],
  );

  const onClose = useCallback(() => {
    onCloseProp();
    setUrl(INITIAL_URL);
  }, [onCloseProp]);

  return (
    <Dialog data-id="UploadLinkModal" open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex items-center gap-1">
            <Upload />
            <DialogTitle>{f({ id: 'upload-link' })}</DialogTitle>
          </div>
          <DialogDescription>{f({ id: 'upload-link-desc' })}</DialogDescription>
        </DialogHeader>
        <form onSubmit={onUpload}>
          <Input placeholder="https://" autoFocus value={url} onChange={(e) => setUrl(e.target.value)} />
        </form>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" onClick={onClose}>
              {f({ id: 'cancel' })}
            </Button>
          </DialogClose>
          <Button variant="default" disabled={disabled} onClick={onUpload}>
            {f({ id: 'upload' })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

function isValidUrl(urlString: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$',
    'i', // fragment locator
  );

  return !!pattern.test(urlString);
}
