import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, cn, Dialog, DialogContent, DialogHeader, DialogTitle, Input } from '@xspecs/design-system';
import { ADD_ORGANIZATION_MUTATION } from '../../graphql/mutations';
import { isOrganizationNameValid } from '../../utils';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { Loading } from '../../components/loading/loading';

export const CreateOrganization = () => {
  const [organizationName, setOrganizationName] = useState('');
  const [organizationError, setOrganizationError] = useState('');
  const [organizationWarning, setOrganizationWarning] = useState('');

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvents();

  const organizationUrl = `${location.origin}/${organizationName || '...'}`;
  const [addOrganization, { client, loading: loadingAddOrganization }] = useMutation(ADD_ORGANIZATION_MUTATION);

  const onOrganizationNameChange = useCallback((event) => {
    setOrganizationName(event.target.value);
  }, []);

  const onOrganizationNameBlur = useCallback(() => {
    if (!organizationName) {
      setOrganizationWarning('');
    }
  }, [organizationName]);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const organizationId = uuidv4();
      const organizationResponse = await addOrganization({
        variables: { args: { organizationId, name: organizationName } },
      });
      const organizationError = organizationResponse.data?.addOrganization?.error;
      if (organizationError) {
        setOrganizationError(organizationError);
        return;
      }

      trackEvent(AppTypeEvent.OrganizationCreated, { organizationId });

      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'organizations' });
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName }));
    },
    [addOrganization, organizationName, trackEvent, client.cache, navigate],
  );

  useEffect(() => {
    if (!organizationName) return;
    if (isOrganizationNameValid(organizationName)) {
      setOrganizationError('');
      setOrganizationWarning('');
    } else {
      setOrganizationWarning(f({ id: 'organization-name-input-warning' }));
    }
  }, [f, organizationName]);

  return (
    <Dialog open={true} modal={false}>
      <DialogContent noClose>
        <DialogHeader>
          <DialogTitle>{f({ id: 'create-a-organization' })}</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmit} className="w-full flex flex-col items-center">
          <Input
            placeholder={f({ id: 'company-team-ask-name' })}
            onBlur={onOrganizationNameBlur}
            onChange={onOrganizationNameChange}
            value={organizationName}
            className={cn(
              'mt-4 w-full border rounded-md p-2',
              organizationError ? 'border-red-500 text-red-600' : 'border-gray-300',
              organizationWarning && 'border-yellow-500 text-yellow-600',
            )}
          />
          {organizationWarning ? <p className="text-warning mt-2">{organizationWarning}</p> : null}
          <p
            data-testid="create-organization-organization-text"
            className={cn('mt-3 w-full', organizationError ? 'text-error' : 'text-muted')}
          >
            {organizationError ? f({ id: organizationError }, { name: organizationUrl }) : organizationUrl}
          </p>
          <Button
            data-testid="create-organization-next-button"
            type="submit"
            className="mt-4 w-full h-10"
            disabled={!organizationName || Boolean(organizationError) || Boolean(organizationWarning)}
          >
            {loadingAddOrganization ? <Loading /> : f({ id: 'next' })}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
