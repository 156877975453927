import { Stack } from '@mui/material';
import { ToolbarLayout } from '@xspecs/single-source-model';
import { renderPaletteComponent } from '../components/palette-renderer';

type CanvasToolbarHeaderProps = ToolbarLayout['header'] & {
  expanded: boolean;
};

export const CanvasToolbarHeader = (props: CanvasToolbarHeaderProps) => {
  const { rows, expanded } = props;
  return (
    <Stack
      alignSelf="center"
      alignItems="center"
      direction="row"
      gap={1}
      sx={{
        pointerEvents: 'auto',
        width: 'fit-content',
        padding: '4px',
        borderRadius: '16px',
        borderBottomLeftRadius: expanded ? 0 : undefined,
        borderBottomRightRadius: expanded ? 0 : undefined,
        boxShadow: expanded ? undefined : '0px 1px 4px rgba(0, 0, 0, 0.2)',
        maxWidth: 'fit-content',
      }}
      className="bg-background-paper border"
    >
      {rows.map((row, rowIndex) =>
        row.elements.map((item, index) =>
          renderPaletteComponent({
            element: item,
            key: `CanvasToolbarHeaderRow-${rowIndex}${index}`,
          }),
        ),
      )}
    </Stack>
  );
};
