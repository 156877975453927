import { CommentsListItem } from './item/comments-list-item';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

export type CommentsListHandle = {
  scrollToBottom: () => void;
};

type CommentsListProps = {
  comments: any[];
};

export const CommentsList = forwardRef<CommentsListHandle, CommentsListProps>((props, ref) => {
  const { comments } = props;

  const listRef = useRef<HTMLUListElement>(null);

  const scrollToBottom = useCallback((behavior?: ScrollBehavior) => {
    listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior });
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      scrollToBottom: () => {
        scrollToBottom('smooth');
      },
    }),
    [scrollToBottom],
  );

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  return (
    <ul className="max-h-[300px] overflow-auto flex flex-col gap-3 my-3" ref={listRef}>
      {comments.map((comment) => (
        <CommentsListItem key={comment.id} data={comment} />
      ))}
    </ul>
  );
});

CommentsList.displayName = 'CommentsList';
