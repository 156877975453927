import { Box, IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { MouseEventHandler, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Icon, useCommandDispatch } from '@xspecs/design-system';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { Attachment } from '@xspecs/single-source-model';

type ShowDetailsButtonProps = Omit<IconButtonProps, 'id' | 'type'> &
  Pick<TooltipProps, 'placement'> & {
    id: string;
    type: string;
    constructToPanTo?: string;
  };

export const ShowDetailsButton = (props: ShowDetailsButtonProps) => {
  const { id, type, placement, onClick: onClickProp, constructToPanTo, ...rest } = props;

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const setAssetToDelete = useSingleSourceStore.use.setAssetToDelete();
  const [, setQueryParams] = useSearchParams();
  const { dispatchCommand } = useCommandDispatch();

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setConstructToPanTo(constructToPanTo || id);
      if (!organization || !workspace) return;
      setQueryParams({ entityId: id });
      dispatchCommand('ViewEntityDetailsCommand', { entityId: id });
      // navigate(
      //   getRoutePathFromParams(RoutePaths.File, {
      //     organizationName: organization?.name,
      //     workspaceName: workspace?.name,
      //     fileId: id,
      //   }),
      // );
      onClickProp?.(event);
      if (Attachment.isValidAttachmentType(type)) {
        setAssetToDelete(id);
      }
    },
    [
      constructToPanTo,
      dispatchCommand,
      id,
      onClickProp,
      organization,
      setAssetToDelete,
      setConstructToPanTo,
      setQueryParams,
      type,
      workspace,
    ],
  );

  return (
    <Tooltip title={f({ id: 'show-entity-details' }, { type })} disableInteractive placement={placement ?? 'right'}>
      <Box>
        <IconButton size="small" {...rest} onClick={onClick}>
          <Icon name="show-details" height={20} width={20} />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
