import { EntityRepository } from '../../data/EntityRepository';
import { IStore } from '../../data/Store';
import { EntityChanges } from '../../types';
import { ExplorerResult, ExplorerSort } from './ExplorerItem';
import { Capability } from '../../entities/scripts/Capability';
import { Edge } from '../../entities/transitions/Edge';
import { Narrative } from '../../entities/constructs/Narrative';
import { NarrativeScript } from '../../entities/scripts/NarrativeScript';
import { ActionScript } from '../../entities/scripts/ActionScript';
import { EntityBase } from '../../entities/EntityBase';
import { Attachment } from '../../entities/assets/Attachment';
import { Label } from '../../entities/assets/Label';
import { Filter } from '../../entities/Filter';
import { Thread } from '../../entities/threads/Thread';
import { FileTreeItem } from '../file-tree/FileTree.types';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

const invalidConstructs = [NarrativeScript, ActionScript, Attachment, Label, Filter, Thread, ScriptBase];

export class Explorer {
  constructor(
    public readonly entityRepository: EntityRepository,
    public readonly store: IStore,
  ) {}

  results: ExplorerResult = [];
  cache: Record<string, FileTreeItem> = {};
  edgesCache: Record<string, { narrativeId: string; capabilityId: string }> = {};
  search: string = '';
  sort: ExplorerSort = ExplorerSort.Newest;

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleAdditions(changes);
    this.handleUpdates(changes);
    this.handleDeletions(changes);
  }

  private isValidExplorerConstruct(entity: EntityBase) {
    return !invalidConstructs.some((Type) => entity instanceof Type) && !Attachment.isValidAttachmentType(entity.type);
  }

  private getParentOfEntity(entity: EntityBase): EntityBase | undefined {
    let parent: EntityBase | undefined;

    if (entity.parent?.id?.includes('_script')) {
      return entity.parent?.parent;
    } else if (entity instanceof Narrative && this.cache[entity.id]) {
      const narrativeParent = this.cache[entity.id];
      parent = { id: narrativeParent.parent } as EntityBase;
    } else {
      parent = entity.parent;
    }

    return parent;
  }

  private handleEdgeOfNarrative(edge: Edge) {
    let narrativeParentId: string = '';
    let narrativeId: string = '';
    if (edge.target instanceof Capability && edge.source instanceof Narrative) {
      narrativeParentId = edge.target.id;
      narrativeId = edge.source.id;
    }
    if (edge.target instanceof Narrative && edge.source instanceof Capability) {
      narrativeParentId = edge.source.id;
      narrativeId = edge.target.id;
    }

    if (narrativeParentId && this.cache[narrativeId]) {
      this.cache = {
        ...this.cache,
        [narrativeId]: {
          ...this.cache[narrativeId],
          parent: narrativeParentId,
        },
      };

      this.edgesCache = {
        ...this.edgesCache,
        [edge.id]: {
          narrativeId: narrativeId,
          capabilityId: narrativeParentId,
        },
      };
    }
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      if (!this.isValidExplorerConstruct(entity)) return;
      if (entity instanceof Edge) {
        this.handleEdgeOfNarrative(entity);
        this.updateStore();
        return;
      }

      const parent = this.getParentOfEntity(entity);

      this.cache = {
        ...this.cache,
        [entity.id]: {
          id: entity.id,
          text: entity.name,
          parent: parent?.id || 'structure',
          droppable: ['EventModel', 'Narrative'].includes(entity.type),
          data: {
            icon: entity.width === entity.height ? 'box' : 'rectangle',
            iconProps: { ...(entity instanceof ConstructBase ? entity.style : {}) },
          },
          // type: entity.type as never as EntityType,
          // shape: entity.width === entity.height ? ConstructShape.SQUARE : ConstructShape.RECTANGLE,
          // children: [],
          // isHighlighted: false,
          // isExpanded: false,
          // scopes: entity.scopes,
          // style: {
          //   ...(entity instanceof ConstructBase ? entity.style : {}),
          // },
        },
      };
    });
    this.updateStore();
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      const entity = update.entity;

      if (!this.isValidExplorerConstruct(entity)) return;
      if (entity instanceof Edge) {
        this.handleEdgeOfNarrative(entity);
        this.updateStore();
        return;
      }

      const parent = this.getParentOfEntity(entity);

      this.cache = {
        ...this.cache,
        [entity.id]: {
          id: entity.id,
          text: entity.name,
          parent: parent?.id || 'structure',
          droppable: ['EventModel', 'Narrative'].includes(entity.type),
          data: {
            icon: entity.width === entity.height ? 'box' : 'rectangle',
            iconProps: { ...(entity instanceof ConstructBase ? entity.style : {}) },
          },
          // type: entity.type as never as EntityType,
          // shape: entity.width === entity.height ? ConstructShape.SQUARE : ConstructShape.RECTANGLE,
          // children: [],
          // isHighlighted: false,
          // isExpanded: false,
          // scopes: entity.scopes,
          // style: {
          //   ...(entity instanceof ConstructBase ? entity.style : {}),
          // },
        },
      };
    });
    this.updateStore();
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      const narrativeEdge = this.edgesCache?.[entity.id];
      if (narrativeEdge) {
        this.cache = {
          ...this.cache,
          [narrativeEdge.narrativeId]: {
            ...this.cache[narrativeEdge.narrativeId],
            parent: 'structure',
          },
        };
      } else {
        delete this.cache?.[entity.id];
      }
    });
    this.updateStore();
  }

  // buildTree(data: ExplorerItem[]) {
  //   const map = new Map();
  //   const tree: ExplorerItem[] = [];
  //
  //   data.forEach((item) => {
  //     map.set(item.id, { ...item, children: [] });
  //   });
  //
  //   data.forEach((item) => {
  //     if (item.parentId) {
  //       const parent = map.get(item.parentId);
  //       if (parent) {
  //         parent.children.unshift(map.get(item.id));
  //       }
  //     } else {
  //       tree.push(map.get(item.id));
  //     }
  //   });
  //
  //   return tree;
  // }

  private updateStore() {
    const results = Object.values(this.cache || {});
    // const scopedResults: FileTreeItem[] = this.applyCurrentScope(cacheArray);

    // if (this.search) {
    //   storeResults = this.applySearch(cacheArray);
    // }
    //
    // if (this.sort && !this.search) {
    //   storeResults = this.applySort(storeResults);
    // }

    this.results = [];
    this.store.getState().setExplorer({
      results: this.results,
      searchQuery: this.search,
      sortQuery: this.sort,
    });
  }

  // private handleSortAscending(a: { text: string }, b: { text: string }) {
  //   return a.text.localeCompare(b.text);
  // }
  //
  // private handleSortDescending(a: { text: string }, b: { text: string }) {
  //   return b.text.localeCompare(a.text);
  // }

  // private handleChildSort(children: ExplorerItem[]): ExplorerItem[] {
  //   if (children.length === 0) return children;
  //
  //   switch (this.sort) {
  //     case ExplorerSort.Newest:
  //       return children.reverse();
  //     case ExplorerSort.Ascending:
  //       return children.sort(this.handleSortAscending).map((child) => ({
  //         ...child,
  //         children: this.handleChildSort(child.children),
  //       }));
  //     case ExplorerSort.Descending:
  //       return children.sort(this.handleSortDescending).map((child) => ({
  //         ...child,
  //         children: this.handleChildSort(child.children),
  //       }));
  //     default:
  //       return children;
  //   }
  // }

  // private applySort(results: ExplorerResult): ExplorerResult {
  //   switch (this.sort) {
  //     case ExplorerSort.Newest:
  //       return results.reverse();
  //     case ExplorerSort.Ascending:
  //       return results.sort(this.handleSortAscending).map((item) => ({
  //         ...item,
  //         children: this.handleChildSort(item.children),
  //       }));
  //     case ExplorerSort.Descending:
  //       return results.sort(this.handleSortDescending).map((item) => ({
  //         ...item,
  //         children: this.handleChildSort(item.children),
  //       }));
  //     default:
  //       return results;
  //   }
  // }

  public updateSort(sort: ExplorerSort) {
    this.sort = sort;
    this.updateStore();
  }

  public updateSearch(search: string) {
    this.search = search;
    this.updateStore();
  }

  // private applySearch(results: ExplorerResult): ExplorerResult {
  //   const fuse = new Fuse(results, {
  //     keys: ['name'],
  //     threshold: 0.4,
  //   });
  //   return fuse.search(this.search).map((fuseItem) => fuseItem.item);
  // }

  // private applyCurrentScope(results: ExplorerItem[]): ExplorerItem[] {
  //   const scope = this.entityRepository.getScopes()[0];
  //
  //   return results.filter((entity) => entity.scopes.includes(scope));
  // }

  public dispose() {
    this.results = [];
    this.search = '';
    this.sort = ExplorerSort.Newest;
    this.store.getState().setExplorer({
      results: [],
      searchQuery: '',
      sortQuery: ExplorerSort.Newest,
    });
  }
}
