import { memo, useCallback, useMemo, useState } from 'react';
import { Box, List, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { AddCircleOutline, MoreHoriz, MoreVert } from '@mui/icons-material';
import { DragHandlers, HeaderProps, InsertHandlers } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';
import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  useClick as useClickFloating,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
} from '@floating-ui/react';

const EMPTY_IMAGE = new Image(1, 1);
EMPTY_IMAGE.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

type HeaderComponentProps = {
  orientation?: 'horizontal' | 'vertical';
  bgColor?: string;
  isGroupHeader?: boolean;
  headerProps: HeaderProps;
  onMenuOpenChange: (isOpen: boolean) => void;
} & DragHandlers &
  InsertHandlers;

export const Header = memo((props: HeaderComponentProps) => {
  const {
    orientation = 'horizontal',
    bgColor = 'rgba(228, 228, 231, 1)',
    isGroupHeader,
    onDrag,
    onDragEnd,
    onDragStart,
    headerProps,
    onMenuOpenChange,
  } = props;
  const { onInsertAfter, onInsertBefore } = headerProps;

  const theme = useTheme();
  const isVertical = orientation === 'vertical';
  const draggable = Boolean(onDragStart && onDrag && onDragEnd);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAddBeforeMenuOpen, setIsAddBeforeMenuOpen] = useState(false);
  const [isAddAfterMenuOpen, setIsAddAfterMenuOpen] = useState(false);
  const dispatchCommand = useCommandDispatch();

  const menuFloatingConfig = useMemo<UseFloatingOptions>(
    () =>
      ({
        middleware: [
          offset(10),
          autoPlacement({
            allowedPlacements: ['right-start'],
          }),
        ],
        open: isMenuOpen,
        onOpenChange: (isOpen) => {
          setIsMenuOpen(isOpen);
          onMenuOpenChange(isOpen);
        },
        whileElementsMounted: autoUpdate,
      }) satisfies UseFloatingOptions,
    [isMenuOpen, onMenuOpenChange],
  );
  const { floatingStyles: menuFloatingStyles, refs: menuRefs, context: menuContext } = useFloating(menuFloatingConfig);
  const menuClick = useClickFloating(menuContext);
  const menuDismiss = useDismiss(menuContext);
  const { getReferenceProps: getMenuReferenceProps, getFloatingProps: getMenuFloatingProps } = useInteractions([
    menuClick,
    menuDismiss,
  ]);

  const addBeforeFloatingConfig = useMemo<UseFloatingOptions>(
    () =>
      ({
        middleware: [
          offset(10),
          autoPlacement({
            allowedPlacements: ['bottom-start'],
          }),
        ],
        open: isAddBeforeMenuOpen,
        onOpenChange: (isOpen) => {
          setIsAddBeforeMenuOpen(isOpen);
          onMenuOpenChange(isOpen);
        },
        whileElementsMounted: autoUpdate,
      }) satisfies UseFloatingOptions,
    [isAddBeforeMenuOpen, onMenuOpenChange],
  );
  const {
    floatingStyles: addBeforeFloatingStyles,
    refs: addBeforeRefs,
    context: addBeforeContext,
  } = useFloating(addBeforeFloatingConfig);
  const addBeforeClick = useClickFloating(addBeforeContext);
  const addBeforeDismiss = useDismiss(addBeforeContext);
  const { getReferenceProps: getAddBeforeReferenceProps, getFloatingProps: getAddBeforeFloatingProps } =
    useInteractions([addBeforeClick, addBeforeDismiss]);

  const addAfterFloatingConfig = useMemo<UseFloatingOptions>(
    () =>
      ({
        middleware: [
          offset(10),
          autoPlacement({
            allowedPlacements: ['bottom-start'],
          }),
        ],
        open: isAddAfterMenuOpen,
        onOpenChange: (isOpen) => {
          setIsAddAfterMenuOpen(isOpen);
          onMenuOpenChange(isOpen);
        },
        whileElementsMounted: autoUpdate,
      }) satisfies UseFloatingOptions,
    [isAddAfterMenuOpen, onMenuOpenChange],
  );
  const {
    floatingStyles: addAfterFloatingStyles,
    refs: addAfterRefs,
    context: addAfterContext,
  } = useFloating(addAfterFloatingConfig);
  const addAfterClick = useClickFloating(addAfterContext);
  const addAfterDismiss = useDismiss(addAfterContext);
  const { getReferenceProps: getAddAfterReferenceProps, getFloatingProps: getAddAfterFloatingProps } = useInteractions([
    addAfterClick,
    addAfterDismiss,
  ]);

  const direction = useMemo(
    () =>
      ({
        horizontal: 'row',
        vertical: 'column',
      }) as const,
    [],
  );

  const sx = useMemo(
    () => ({
      fontSize: isGroupHeader ? 28 : 20,
      cursor: 'pointer',
    }),
    [isGroupHeader],
  );

  const handleMenuItemClick = useCallback(
    (command, params) => {
      dispatchCommand(command, params);
    },
    [dispatchCommand],
  );

  const handleDragStart = useCallback(
    (event) => {
      if (!onDragStart) return;
      event.dataTransfer.setDragImage(EMPTY_IMAGE, 0, 0);
      dispatchCommand(onDragStart.command, {
        ...onDragStart.params,
        [onDragStart.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDragStart, dispatchCommand],
  );

  const handleDrag = useCallback(
    (event) => {
      if (!onDrag) return;
      dispatchCommand(onDrag.command, {
        ...onDrag.params,
        [onDrag.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDrag, dispatchCommand],
  );

  const handleDragEnd = useCallback(
    (event) => {
      if (!onDragEnd) return;
      dispatchCommand(onDragEnd.command, {
        ...onDragEnd.params,
        [onDragEnd.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDragEnd, dispatchCommand],
  );

  const disableDrag = headerProps.disableDrag;

  const onAddInsertBefore = useCallback(() => {
    if (onInsertBefore && 'command' in onInsertBefore) {
      handleMenuItemClick(onInsertBefore.command, onInsertBefore.params);
    }
  }, [handleMenuItemClick, onInsertBefore]);

  const doesInsertBeforeHaveMenu = onInsertBefore && 'items' in onInsertBefore;
  const doesInsertAfterHaveMenu = onInsertAfter && 'items' in onInsertAfter;

  return (
    <Stack
      direction={direction[orientation]}
      alignItems="center"
      justifyItems="center"
      gap={1}
      width="100%"
      height="100%"
      draggable={!disableDrag && draggable}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      zIndex={1000000}
    >
      {onInsertBefore ? (
        <AddCircleOutline
          ref={doesInsertBeforeHaveMenu ? addBeforeRefs.setReference : undefined}
          sx={sx}
          className="text-icon-dark"
          onClick={onAddInsertBefore}
          {...(doesInsertBeforeHaveMenu ? getAddBeforeReferenceProps() : {})}
        />
      ) : null}
      {onInsertBefore && 'items' in onInsertBefore && isAddBeforeMenuOpen && (
        <FloatingPortal>
          <List
            ref={addBeforeRefs.setFloating}
            style={{
              ...addBeforeFloatingStyles,
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[4],
              zIndex: 100000,
            }}
            {...getAddBeforeFloatingProps()}
          >
            {onInsertBefore.items.map((item) => (
              <MenuItem
                key={item.id}
                style={{ gap: '8px' }}
                onClick={() => {
                  handleMenuItemClick(item.command, item.params);
                  setIsAddBeforeMenuOpen(false);
                  onMenuOpenChange(false);
                }}
              >
                {item.icon && <Icon name={item.icon} color="grey" />}
                <Typography color="text.primary">{item.label}</Typography>
              </MenuItem>
            ))}
          </List>
        </FloatingPortal>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={bgColor}
        borderRadius="4px"
        sx={{
          flexGrow: 1,
          cursor: !disableDrag ? 'grab' : 'default',
          userSelect: 'none',
          height: isGroupHeader ? 28 : 20,
          width: isGroupHeader ? 28 : 20,
          position: 'relative',
        }}
      >
        {!disableDrag ? <Icon name="GripHorizontal" /> : null}
        <Box
          ref={menuRefs.setReference}
          position="absolute"
          top={orientation === 'horizontal' ? '50%' : 16}
          right={orientation === 'horizontal' ? 0 : undefined}
          left={isVertical ? '50%' : undefined}
          sx={{
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)',
            backgroundColor: isMenuOpen ? 'primary.main' : '',
            height: isVertical ? undefined : 18,
            width: isVertical ? 18 : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: isMenuOpen ? 'white' : 'action',
            borderRadius: '4px',
          }}
          {...getMenuReferenceProps()}
        >
          {isVertical ? (
            <MoreVert className={isMenuOpen ? 'text-button-white' : 'text-icon-dark'} />
          ) : (
            <MoreHoriz className={isMenuOpen ? 'text-button-white' : 'text-icon-dark'} />
          )}
          {headerProps.menu && isMenuOpen && (
            <FloatingPortal>
              <List
                ref={menuRefs.setFloating}
                style={{
                  ...menuFloatingStyles,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.shadows[4],
                  zIndex: 100000,
                }}
                {...getMenuFloatingProps()}
              >
                {headerProps.menu.items.map((item) => (
                  <MenuItem
                    key={item.id}
                    style={{ gap: '8px' }}
                    onClick={() => {
                      handleMenuItemClick(item.command, item.params);
                      setIsMenuOpen(false);
                    }}
                  >
                    {item.icon && <Icon name={item.icon} color="grey" />}
                    <Typography color="text.primary">{item.label}</Typography>
                  </MenuItem>
                ))}
              </List>
            </FloatingPortal>
          )}
        </Box>
      </Box>
      {onInsertAfter ? (
        <AddCircleOutline
          ref={doesInsertAfterHaveMenu ? addAfterRefs.setReference : undefined}
          sx={sx}
          className="text-icon-dark"
          onClick={() => {
            if ('command' in onInsertAfter) {
              handleMenuItemClick(onInsertAfter.command, onInsertAfter.params);
            }
          }}
          {...(doesInsertAfterHaveMenu ? getAddAfterReferenceProps() : {})}
        />
      ) : null}
      {onInsertAfter && doesInsertAfterHaveMenu && isAddAfterMenuOpen && (
        <FloatingPortal>
          <List
            ref={addAfterRefs.setFloating}
            style={{
              ...addAfterFloatingStyles,
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[4],
              zIndex: 100000,
            }}
            {...getAddAfterFloatingProps()}
          >
            {onInsertAfter.items.map((item) => (
              <MenuItem
                key={item.id}
                style={{ gap: '8px' }}
                onClick={() => {
                  handleMenuItemClick(item.command, item.params);
                  setIsAddAfterMenuOpen(false);
                  onMenuOpenChange(false);
                }}
              >
                {item.icon && <Icon name={item.icon} color="grey" />}
                <Typography color="text.primary">{item.label}</Typography>
              </MenuItem>
            ))}
          </List>
        </FloatingPortal>
      )}
    </Stack>
  );
});
Header.displayName = 'Header';
