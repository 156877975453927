import { getNodeParent, getPluginType, useEditorState } from '@udecode/plate-common';
import { useCallback, useMemo } from 'react';
import { ELEMENT_OL, ELEMENT_UL, getListItemEntry, toggleList } from '@udecode/plate-list';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ToggleGroup, ToggleGroupItem } from '@xspecs/design-system';
import { List, ListOrdered } from 'lucide-react';

export const BalloonMenuLists = () => {
  const editor = useEditorState();

  const handleToggleList = useCallback(
    (type: string) => {
      if (editor) return () => toggleList(editor, { type });
    },
    [editor],
  );

  const isParagraph = useMemo(() => {
    if (!editor?.selection?.anchor) return false;
    const node = getNodeParent(editor, editor.selection.anchor.path);
    return getPluginType(editor, node.type as string) === getPluginType(editor, ELEMENT_PARAGRAPH);
  }, [editor]);

  return (
    <ToggleGroup
      type="single"
      size="sm"
      data-testid="balloon-menu-marks"
      value={Boolean(editor?.selection) && getListItemEntry(editor)?.list[0].type}
      disabled={!isParagraph}
    >
      <ToggleGroupItem
        data-testid="balloon-menu-bulleted-list"
        value={getPluginType(editor, ELEMENT_UL)}
        onMouseDown={handleToggleList(getPluginType(editor, ELEMENT_UL))}
      >
        <List />
      </ToggleGroupItem>
      <ToggleGroupItem
        data-testid="balloon-menu-numbered-list"
        value={getPluginType(editor, ELEMENT_OL)}
        onMouseDown={handleToggleList(getPluginType(editor, ELEMENT_OL))}
      >
        <ListOrdered />
      </ToggleGroupItem>
    </ToggleGroup>
  );
};
