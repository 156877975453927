export const AutoLogoSkeleton = () => {
  return (
    <svg width="172" height="172" viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M163.4 0H8.6C3.82222 0 0 3.82222 0 8.6V163.4C0 168.178 3.82222 172 8.6 172H163.4C168.178 172 172 168.178 172 163.4V8.6C172 3.82222 168.178 0 163.4 0Z"
        style={{ fill: 'hsl(var(--text-white))' }}
      />
      <path
        d="M124.223 150.978C139.034 150.978 150.978 139.034 150.978 124.223C150.978 109.412 139.034 97.4673 124.223 97.4673C109.412 97.4673 97.4673 109.412 97.4673 124.223C97.4673 139.034 109.412 150.978 124.223 150.978Z"
        style={{ fill: 'hsl(var(--muted))' }}
      />
      <path
        d="M21.0218 98.0406C21.0218 97.754 21.3085 97.4673 21.5952 97.4673H74.0552C74.3418 97.4673 74.6285 97.754 74.6285 98.0406V123.745C74.6285 124.032 74.3418 124.318 74.0552 124.318H62.2063C61.9196 124.318 61.6329 124.605 61.6329 124.892V150.596C61.6329 150.883 61.3463 151.17 61.0596 151.17H34.9729C34.6863 151.17 34.3996 150.883 34.3996 150.596V124.892C34.3996 124.605 34.1129 124.318 33.8263 124.318H21.4996C21.2129 124.318 20.9263 124.032 20.9263 123.745V98.0406H21.0218Z"
        style={{ fill: 'hsl(var(--muted))' }}
      />
      <path
        d="M98.0406 21.0239C97.754 21.0239 97.4673 21.3106 97.4673 21.5973V48.6395C97.4673 62.9728 109.412 74.535 124.223 74.535C139.034 74.535 150.978 62.9728 150.978 48.6395V21.5973C150.978 21.3106 150.692 21.0239 150.405 21.0239H97.9451H98.0406Z"
        style={{ fill: 'hsl(var(--muted))' }}
      />
      <path
        d="M21.1182 73.6742C20.927 74.0564 21.1182 74.4387 21.5959 74.4387H73.9604C74.3426 74.4387 74.6293 74.0564 74.4382 73.6742L48.2559 21.4053C48.0648 21.0231 47.4915 21.0231 47.3004 21.4053L21.1182 73.6742Z"
        style={{ fill: 'hsl(var(--muted))' }}
      />
    </svg>
  );
};
