import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface DeleteScriptLaneParams extends IParams {
  scriptId: string;
  laneId: string;
}

interface ScriptLaneDeletedParams extends IParams {
  scriptId: string;
  laneId: string;
}

export class ScriptLaneDeletedEvent extends EventBase {
  static eventType = 'ScriptLaneDeletedEvent';

  constructor(
    public readonly params: ScriptLaneDeletedParams,
    public readonly source = DeleteScriptLaneCommand,
  ) {
    super();
  }
}

export class DeleteScriptLaneCommand extends CommandBase<DeleteScriptLaneParams> {
  execute(params: DeleteScriptLaneParams): ScriptLaneDeletedEvent | CommandError {
    const modelContext = this.getModelContext();
    const script = modelContext.entityRepository.get<ScriptBase>(params.scriptId)!;
    const laneIndex = script.lanes.findIndex((lane) => lane.id === params.laneId);
    const entities = script.getEntitiesInLane(laneIndex);
    const entityIdsToDelete = entities.map((entity) => entity.id);
    if (!script.removeLane(params.laneId)) return CommandError.of(script.getError()!, 'error');
    entityIdsToDelete.forEach((entityId) => modelContext.entityRepository.delete(entityId));
    modelContext.entityRepository.update(script);
    return new ScriptLaneDeletedEvent({ scriptId: params.scriptId, laneId: params.laneId });
  }
}
