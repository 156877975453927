import { CommandBase, IParams } from '../framework/CommandBase';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { Filter, FilterType } from '../../entities/Filter';
import { CommandError } from '../../ErrorStore';

interface RemoveFilterCriteriaParams extends IParams {
  id: string;
  type: FilterType;
  criteriaIds: string[];
}

export class FilterCriteriaRemovedEvent extends EventBase {
  static eventType = 'FilterCriteriaRemovedEvent';
  constructor(
    public params: RemoveFilterCriteriaParams,
    public source = RemoveFilterCriteriaCommand,
  ) {
    super();
  }
}

export class RemoveFilterCriteriaCommand extends CommandBase<RemoveFilterCriteriaParams> {
  execute(params: RemoveFilterCriteriaParams): FilterCriteriaRemovedEvent | CommandError | undefined {
    const modelContext = this.getModelContext();
    const filter =
      params.type === FilterType.unsaved
        ? (this.appState.store.getState().unsavedFilter as Filter)
        : modelContext.entityRepository.get<Filter>(params.id);
    if (!filter) return;
    if (!filter.criteria) return;
    const criteriaEntities = params.criteriaIds.map((id) => modelContext.entityRepository.get(id));
    const updatedCriteria = filter.removeCriteria(criteriaEntities.map((criteriaEntity) => criteriaEntity!.id));
    if (params.type != FilterType.unsaved) {
      modelContext.entityRepository.update(filter);
    }
    const updatedFilter = new Filter(
      filter.id,
      filter.name,
      filter.filterType,
      updatedCriteria,
      filter.scopes,
      filter.createdBy,
    );
    this.appState.messageBus.send(ApplyFiltersCommand, {
      filter: updatedFilter,
    });
    return new FilterCriteriaRemovedEvent(params);
  }
}
