interface SchemaField {
  name: string;
}

interface Schemas {
  commands: Record<string, { fields: SchemaField[] }>;
  events: Record<string, { fields: SchemaField[] }>;
  states: Record<string, { fields: SchemaField[] }>;
}

/**
 * Helper: Sets nested property in an object, creating arrays/objects as needed.
 * For example:
 *   setNestedProperty(obj, "items[0].itemId", "abc")
 * will produce:
 *   obj.items = [{ itemId: "abc" }]
 */
function setNestedProperty(obj: Record<string, any>, path: string, value: any): void {
  const parts = path.split('.');
  let current: Record<string, any> = obj;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    const arrayMatch = part.match(/(.*?)\[(\d+)\]/);

    // Check if this path segment references an array index, e.g. items[0]
    if (arrayMatch) {
      const propertyName = arrayMatch[1];
      const index = parseInt(arrayMatch[2], 10);

      // If property doesn't exist yet, initialize as an array
      if (!current[propertyName]) {
        current[propertyName] = [];
      }

      // If array element doesn't exist, initialize with object
      if (!current[propertyName][index]) {
        // If we're at the last segment, assign directly; otherwise create an object
        if (i === parts.length - 1) {
          current[propertyName][index] = value;
        } else {
          current[propertyName][index] = {};
        }
      }

      // Descend one level into the array element
      current = current[propertyName][index];
    } else {
      // If this is the last part, assign the value directly
      if (i === parts.length - 1) {
        current[part] = value;
      } else {
        // Otherwise, ensure an object is present for next level
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    }
  }
}

/**
 * Determines the type of a message (COMMAND, EVENT, or STATE) using the schemas
 * or a default mapping per keyword.
 */
function determineMessageType(
  name: string,
  schemas: Schemas,
  defaultKeywordTypeMap: Record<string, 'EVENT' | 'COMMAND' | 'STATE'>,
): 'EVENT' | 'COMMAND' | 'STATE' {
  if (name in schemas.commands) {
    return 'COMMAND';
  }
  if (name in schemas.events) {
    return 'EVENT';
  }
  if (name in schemas.states) {
    return 'STATE';
  }
  return defaultKeywordTypeMap[name] || 'EVENT';
}

/**
 * Parses GWT text into a structured JSON.
 */
export function parseGWT(input: string, schemas: Schemas, name: string) {
  // Final output structure
  const output = {
    name,
    examples: [] as Array<{
      name: string;
      steps: Array<{
        keyword: 'GIVEN' | 'WHEN' | 'THEN' | 'AND';
        message:
          | {
              type: 'STATE';
              name: string;
              // Arbitrary properties assigned to it
              [key: string]: any;
            }
          | {
              type: 'EVENT' | 'COMMAND';
              name: string;
              fields: Array<{ name: string; value: any }>;
            };
      }>;
    }>,
  };

  // Default fallback mapping: GIVEN -> EVENT, WHEN -> COMMAND, THEN -> EVENT, AND -> EVENT
  const defaultKeywordTypeMap: Record<string, 'EVENT' | 'COMMAND' | 'STATE'> = {
    GIVEN: 'EVENT',
    WHEN: 'COMMAND',
    THEN: 'EVENT',
    AND: 'EVENT',
  };

  // Split the input on "Scenario:" to get each scenario block
  const scenarios = input.split(/Scenario:/).filter((s) => s.trim().length > 0);

  scenarios.forEach((scenario) => {
    // The first non-empty line is the scenario name
    const lines = scenario.split('\n').filter((line) => line.trim().length > 0);
    const scenarioName = lines[0].trim();

    // Construct an example with a name and a steps array
    const example = {
      name: scenarioName,
      steps: [] as Array<{
        keyword: 'GIVEN' | 'WHEN' | 'THEN' | 'AND';
        message:
          | {
              type: 'STATE';
              name: string;
              [key: string]: any;
            }
          | {
              type: 'EVENT' | 'COMMAND';
              name: string;
              fields: Array<{ name: string; value: any }>;
            };
      }>,
    };

    let currentKeyword: 'GIVEN' | 'WHEN' | 'THEN' | 'AND' | null = null;
    let currentMessage:
      | {
          type: 'STATE';
          name: string;
          [key: string]: any;
        }
      | {
          type: 'EVENT' | 'COMMAND';
          name: string;
          fields: Array<{ name: string; value: any }>;
        }
      | null = null;

    let previousKeyword: 'GIVEN' | 'WHEN' | 'THEN' | 'AND' | null = null;

    /**
     * Helper: pushes the current step into the example's steps array
     * (if we actually have a message).
     */
    const pushCurrentStep = () => {
      if (currentMessage && currentKeyword) {
        example.steps.push({
          keyword: currentKeyword,
          message: currentMessage,
        });
      }
    };

    // Iterate over each line after the scenario name
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();

      // Identify the keyword lines
      if (line.startsWith('Given ')) {
        pushCurrentStep();
        previousKeyword = currentKeyword;
        currentKeyword = 'GIVEN';

        const msgName = line.substring(6).trim().replace(/[<>]/g, '');
        const messageType = determineMessageType(msgName, schemas, defaultKeywordTypeMap);

        if (messageType === 'STATE') {
          currentMessage = { type: 'STATE', name: msgName };
        } else {
          currentMessage = { type: messageType, name: msgName, fields: [] };
        }
      } else if (line.startsWith('When ')) {
        pushCurrentStep();
        previousKeyword = currentKeyword;
        currentKeyword = 'WHEN';

        const msgName = line.substring(5).trim().replace(/[<>]/g, '');
        const messageType = determineMessageType(msgName, schemas, defaultKeywordTypeMap);

        if (messageType === 'STATE') {
          currentMessage = { type: 'STATE', name: msgName };
        } else {
          currentMessage = { type: messageType, name: msgName, fields: [] };
        }
      } else if (line.startsWith('Then ')) {
        pushCurrentStep();
        previousKeyword = currentKeyword;
        currentKeyword = 'THEN';

        const msgName = line.substring(5).trim().replace(/[<>]/g, '');
        const messageType = determineMessageType(msgName, schemas, defaultKeywordTypeMap);

        if (messageType === 'STATE') {
          currentMessage = { type: 'STATE', name: msgName };
        } else {
          currentMessage = { type: messageType, name: msgName, fields: [] };
        }
      } else if (line.startsWith('And ')) {
        pushCurrentStep();
        previousKeyword = currentKeyword;
        currentKeyword = 'AND';

        const msgName = line.substring(4).trim().replace(/[<>]/g, '');
        const messageType = determineMessageType(msgName, schemas, defaultKeywordTypeMap);

        if (messageType === 'STATE') {
          currentMessage = { type: 'STATE', name: msgName };
        } else {
          currentMessage = { type: messageType, name: msgName, fields: [] };
        }
      }
      // Lines starting with "." are field assignments
      else if (line.startsWith('.') && currentMessage) {
        const fieldLine = line.substring(1).trim();
        // Example: items[0].itemId = item1
        const [rawFieldName, rawValue] = fieldLine.split(' = ').map((part) => part.trim());

        // Convert numeric values if possible
        let parsedValue: any = rawValue;
        if (!isNaN(Number(rawValue))) {
          parsedValue = Number(rawValue);
        }

        // Strip quotes if they exist around a string (optional)
        if (typeof parsedValue === 'string' && parsedValue.startsWith('"') && parsedValue.endsWith('"')) {
          parsedValue = parsedValue.slice(1, -1);
        }

        // If it's a STATE, set nested property directly on the message
        if (currentMessage.type === 'STATE') {
          setNestedProperty(currentMessage, rawFieldName, parsedValue);
        } else {
          // For EVENT or COMMAND, push into fields array
          currentMessage.fields.push({
            name: rawFieldName,
            value: parsedValue,
          });
        }
      }
    }

    // Push the last step in the scenario
    pushCurrentStep();
    // Add this scenario to the output
    output.examples.push(example);
  });

  return output;
}

/**
 * A convenience function that parses GWT input and returns a JSON string.
 */
export function convertGWTToJSON(input: string, schemas: Schemas, name: string): string {
  const result = parseGWT(input, schemas, name);
  return JSON.stringify(result, null, 2);
}
