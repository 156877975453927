import {
  HocuspocusProvider,
  HocuspocusProviderConfiguration,
  HocuspocusProviderWebsocket,
  onAwarenessUpdateParameters,
} from '@hocuspocus/provider';
import { IStore } from './Store';
import { User } from '../types';
import * as Y from 'yjs';
import { ActiveUser, FileEvents, FileType, SerializedFile, Status } from './File.types';
import { IndexeddbPersistence } from 'y-indexeddb';
import { DEBUG_CONFIG } from '../debug-config';
import { StatesArray } from '@hocuspocus/provider/src/types';
import { logger } from '@xspecs/logger';
import { SynchronizedUlid } from './EntitySelectionTracker';

const UNSYNCED_CHANGES_THRESHOLD = 50;
let reloading = false;

type Params = {
  fileId: string;
  version: string | undefined;
  appVersion: number;
  token: string;
  host: string | undefined;
  websocketProvider: HocuspocusProviderWebsocket;
  user: User;
  store: IStore;
  onSynced?: () => void;
};

export class ProviderFactor {
  public static yDocOverride: Y.Doc;
  private static state = {};

  private static mockProvider(config) {
    return {
      document: this.yDocOverride,
      on: () => {},
      awareness: {
        getLocalState: () => {
          return ProviderFactor.state;
        },
        setLocalState: (state) => {
          ProviderFactor.state = state;
          config.onAwarenessUpdate({ states: [ProviderFactor.state] });
        },
        setLocalStateField: (field, value) => {
          ProviderFactor.state[field] = value;
          config.onAwarenessUpdate({ states: [ProviderFactor.state] });
        },
      },
    };
  }

  public static getProvider(config: HocuspocusProviderConfiguration): HocuspocusProvider {
    if (ProviderFactor.yDocOverride) return this.mockProvider(config) as unknown as HocuspocusProvider;
    //logger.log('filebase: config: ', config);
    return new HocuspocusProvider(config);
  }
}

export abstract class FileBase {
  private static readonly DEFAULT_INITIAL_VALUE: string = '';
  public readonly fileId: string;
  public readonly provider: HocuspocusProvider;
  protected indexedDb?: IndexeddbPersistence;
  public readonly activeUser: ActiveUser;
  public activeUsers: ActiveUser[];
  private internalStatus: Status = Status.Initial;
  protected readonly store: IStore;
  private onAwarenessUpdateCallback: (states: onAwarenessUpdateParameters['states']) => void;
  private focusTimeout: number | null = null;
  private isPersistenceEnabled: boolean = true;

  private readonly readOnly: boolean = false;

  get document() {
    if (DEBUG_CONFIG.disableIndexedDb || this.readOnly) return this.provider.document;
    else return this.indexedDb?.doc;
  }

  constructor({ fileId, version, token, host, websocketProvider, user, store, appVersion, onSynced }: Params) {
    this.fileId = fileId;
    this.store = store;
    const parameters = { initialValue: this.initialValue(), type: this.getType(), token, version, appVersion };
    if (host) parameters['host'] = host;
    const config = this.getConfig(fileId, parameters, websocketProvider);
    this.provider = ProviderFactor.getProvider(config);
    this.initStatusListeners({ onSynced });
    this.activeUser = this.initActiveUser(user);
    this.readOnly = Boolean(version);
    if (!DEBUG_CONFIG.disableIndexedDb && !this.readOnly)
      //this.indexedDb = new IndexeddbPersistence(this.fileId, this.provider.document);
      this.enableIndexDb();
    this.enableIndexDbOnlyForActiveTab();
    this.load();
  }

  private enableIndexDbOnlyForActiveTab() {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;
    window.addEventListener('blur', () => {
      this.handleBlurForIndexDb();
    });
    window.addEventListener('focus', () => {
      this.handleFocusForIndexDb();
    });
    document.addEventListener('visibilitychange', async () => {
      this.handleVisibilityChangeForIndexDb();
    });
  }

  private handleVisibilityChangeForIndexDb() {
    if (document.visibilityState !== 'visible' && this.isPersistenceEnabled) {
      if (this.focusTimeout) clearTimeout(this.focusTimeout);
      this.focusTimeout = window.setTimeout(async () => {
        await this.disableIndexDb();
        this.isPersistenceEnabled = false;
      }, 1000);
    } else if (document.visibilityState === 'visible' && !this.isPersistenceEnabled) {
      if (this.focusTimeout) clearTimeout(this.focusTimeout);
      this.enableIndexDb();
      this.isPersistenceEnabled = true;
    }
  }

  private handleFocusForIndexDb() {
    if (this.focusTimeout) clearTimeout(this.focusTimeout);
    if (!this.isPersistenceEnabled) {
      this.enableIndexDb();
      this.isPersistenceEnabled = true;
    }
  }

  private handleBlurForIndexDb() {
    if (this.focusTimeout) clearTimeout(this.focusTimeout);
    this.focusTimeout = window.setTimeout(async () => {
      if (this.isPersistenceEnabled) {
        await this.disableIndexDb();
        this.isPersistenceEnabled = false;
      }
    }, 1000);
  }

  private enableIndexDb() {
    this.indexedDb = new IndexeddbPersistence(this.fileId, this.provider.document);
    this.indexedDb.on('synced', () => {});
  }

  private async disableIndexDb() {
    if (this.indexedDb) {
      await this.indexedDb.destroy();
      this.indexedDb = undefined;
    }
  }

  private getConfig(
    fileId: string,
    parameters: {
      type: FileType;
      initialValue: string;
      version?: string;
      appVersion: number;
      token: string;
    },
    websocketProvider: HocuspocusProviderWebsocket,
  ) {
    websocketProvider.on('error', (e) => {
      logger.error('Websocket error', e);
    });
    const config: HocuspocusProviderConfiguration = {
      name: fileId,
      token: JSON.stringify(parameters),
      websocketProvider,
      connect: true,
      preserveConnection: true,
      onAwarenessUpdate: ({ states }) => this.handleAwarenessUpdate(states),
      onStateless: (e) => {
        const payload = JSON.parse(e.payload);
        if (payload['type'] === 'MISMATCHED_APP_VERSION') {
          logger.debug('App version mismatch, reloading.');
          if (reloading) return;
          reloading = true;
          alert(
            `🚀 A new version of Auto is here!` +
              `\n\n🔄 Your browser will be reloaded to bring you the latest features and improvements.` +
              `\n\n⚠️ Just a quick note: If you've made changes offline and haven't connected recently, we’re truly sorry, but those changes might be lost. 💔.`,
          );
          window.location.reload(); // this will end up purging the databases when the Application initializes
        }
        if (payload.clock) SynchronizedUlid.updateTime(payload.clock);
      },
    };
    return config;
  }

  protected initialValue(): string {
    return FileBase.DEFAULT_INITIAL_VALUE;
  }

  public onAwarenessUpdate(callback: (states: StatesArray) => void) {
    this.onAwarenessUpdateCallback = callback;
  }

  private handleAwarenessUpdate(states: StatesArray) {
    const isActiveUser = (obj: any): obj is ActiveUser => {
      return obj.name && obj.color && obj.sub && obj.id;
    };
    this.activeUsers = states.filter(isActiveUser) as unknown as ActiveUser[];
    this.updateActiveUsers();
    if (this.onAwarenessUpdateCallback) this.onAwarenessUpdateCallback(states);
    // .filter((user) =>
    // scopes.includes(user.workspaceId),
    // );
    // console.log('this.activeUsers', scopes, this.activeUsers);
    //this.store.getState().setActiveUsersByFile(this.fileId, this.activeUsers);
  }

  public updateScopes() {
    this.updateActiveUsers();
    this.updateScopesInAwareness();
  }

  private updateActiveUsers() {
    if (!this.store.getState()) return;
    this.store.getState().setActiveUsersByFile(this.fileId, this.activeUsers);
  }

  abstract getType(): FileType;

  get clientId(): string {
    return `${this.provider.document.clientID}`;
  }

  public get awareness() {
    return this.provider.awareness;
  }

  abstract load(): void;

  private updateScopesInAwareness() {
    const states = this.awareness?.getLocalState() ?? {};
    states['changeType'] = 'metadata';
    this.awareness?.setLocalState(states);
  }

  private initActiveUser(user: User): ActiveUser {
    const clientId = `${this.provider.document.clientID}`;
    const activeUser: ActiveUser = {
      ...this.provider.awareness?.getLocalState(),
      name: user.nickname ?? user.name ?? user.email ?? 'Unknown',
      picture: user.picture ?? '',
      // color: seedToColor(`${indexedDb.doc.clientID}`),
      color: seedToColor(clientId),
      sub: user.sub ?? '',
      id: clientId,
    };
    this.provider.awareness?.setLocalState(activeUser);
    return activeUser;
  }

  private initStatusListeners({ onSynced }: { onSynced?: () => void }) {
    this.provider.document.on(FileEvents.update, (e) => this.setStatus(FileEvents.update, e as any));
    if (onSynced)
      this.provider.on('synced', () => {
        onSynced?.();
      });
    Object.entries({
      open: FileEvents.open,
      connect: FileEvents.connect,
      authenticated: FileEvents.authenticated,
      authenticationFailed: FileEvents.authenticationFailed,
      status: FileEvents.status,
      // message: FileEvents.message, // very noise
      // outgoingMessage: FileEvents.outgoingMessage, // very noise
      synced: FileEvents.synced,
      close: FileEvents.close,
      disconnect: FileEvents.disconnect,
      destroy: FileEvents.destroy,
      // awarenessUpdate: FileEvents.awarenessUpdate,
      // awarenessChange: FileEvents.awarenessChange,
      // stateless: FileEvents.stateless,
    }).forEach(([event, eventType]) =>
      this.provider.on(event, (e: { status: string; state: string }) => this.setStatus(eventType, e)),
    );
  }

  public get status(): Status {
    return this.internalStatus;
  }

  protected set status(value: Status) {
    this.internalStatus = value;
    this.store.getState().setFileById(this.fileId, this.serialize());
  }

  private setStatus(eventType: FileEvents, e: { status: string; state: string }) {
    switch (eventType) {
      case FileEvents.open:
      case FileEvents.connect:
        this.status = Status.Connected;
        break;
      case FileEvents.status:
        if (e?.status === 'connected') this.status = Status.Connected;
        if (e?.status === 'disconnected') this.status = Status.Disconnected;
        if (e?.status === 'connecting') this.status = Status.Connecting;
        break;
      case FileEvents.authenticated:
        this.status = Status.Authenticated;
        break;
      case FileEvents.synced:
        if (String(e?.state) === 'true') this.status = Status.Synced;
        if (String(e?.state) === 'false') this.status = Status.Unsynced;
        break;
      case FileEvents.authenticationFailed:
        this.status = Status.AuthenticationFailed;
        break;
      case FileEvents.close:
      case FileEvents.disconnect:
      case FileEvents.destroy:
        this.status = Status.Disconnected;
        break;
      case FileEvents.update:
        this.status = this.provider.unsyncedChanges > UNSYNCED_CHANGES_THRESHOLD ? Status.Unsynced : Status.Synced;
        break;
      // case FileEvents.outgoingMessage:
      // case FileEvents.message:
      //   break;
      default:
        logger.warn('Received unknown file status', eventType, e);
        this.status = Status.Unknown;
        break;
    }
  }

  serialize(): SerializedFile {
    return {
      fileId: this.fileId,
      status: this.status,
      clientId: this.clientId,
      type: this.getType(),
      activeUser: this.activeUser,
      file: this,
    };
  }

  dispose() {
    window.removeEventListener('blur', this.handleBlurForIndexDb);
    window.removeEventListener('focus', this.handleFocusForIndexDb);
    document.removeEventListener('visibilitychange', this.handleVisibilityChangeForIndexDb);
    void this.disableIndexDb();
    if (this.provider?.awareness) {
      this.provider.awareness.setLocalState(null);
    }
    if (this.provider) {
      this.provider.disconnect();
      // this.provider.destroy();
    }
  }
}

const seedToColor = (seed: string): string => {
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    const char = seed.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  const hue = Math.abs(hash) % 360;
  return `hsl(${hue}, 100%, 50%)`;
};
