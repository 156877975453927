import { MutableRefObject, useEffect, useRef } from 'react';
import * as monaco from 'monaco-editor';
import { logger } from '@xspecs/logger';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { singleSourceStore, useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { PlaceholderContentWidget } from '../../spec/placeholder-content-widget';
import { FileType, LoadFileCommand, SpecFile, Status } from '@xspecs/single-source-model';
import { MonacoBinding } from 'y-monaco';
import { Schemas } from './gherkin-editor';
import { convertGWTToJSON } from './gherkin-editor.utils';

export const useMonacoAutocomplete = (
  fileId: string,
  editorRef: MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>,
  editorContainerRef: MutableRefObject<HTMLDivElement | null>,
  schemas: Schemas,
  setJsonOutput: (json: string) => void,
  onEditorChange?: (value: string) => void,
  name?: string,
  initialValue?: string,
) => {
  const { application } = useApplication();

  const schemasRef = useRef(schemas);
  const nameRef = useRef(name ?? '');

  const files = useSingleSourceStore.use.filesById();
  const file = files[fileId];

  useEffect(() => {
    schemasRef.current = schemas;
  }, [schemas]);

  useEffect(() => {
    nameRef.current = name ?? '';
  }, [name]);

  useEffect(() => {
    const editor = editorRef.current;
    if (editor && file?.status === Status.Synced && initialValue) {
      if (!editor.getModel()?.getValue()) {
        editor.getModel()?.setValue(initialValue);
      }
    }
  }, [file?.status, initialValue, editorRef]);

  useEffect(() => {
    if (!editorContainerRef.current) return;

    if (editorRef.current) {
      editorRef.current.dispose(); // ✅ Cleanup old editor instance
    }

    // Create the editor instance
    editorRef.current = monaco.editor.create(editorContainerRef.current, {
      value: '',
      language: 'gwt', // ✅ Ensure it's using "gwt"
      theme: 'gwt-theme', // ✅ Apply custom theme
      automaticLayout: true,
      wordWrap: 'on',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: true,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
      quickSuggestions: false,
      wordBasedSuggestions: 'off',
      fixedOverflowWidgets: true,
    });

    const editor = editorRef.current;

    // Load the file into the editor
    application?.state.messageBus.sendInternal(LoadFileCommand, {
      fileId,
      version: undefined,
      fileType: FileType.Spec,
      fileExtension: 'spec',
    });

    const file = singleSourceStore.getState().filesById[fileId].file as SpecFile;
    new PlaceholderContentWidget('Write your Given-When-Then scenarios here...', editor);
    const ytext = file.sharedType;
    const model = editor.getModel();

    if (!model) {
      logger.error('Editor model not found');
      return;
    }

    new MonacoBinding(ytext, model, new Set([editor]), file.awareness);

    // Store disposable references for later cleanup
    const disposables: monaco.IDisposable[] = [];

    editorRef.current?.onDidChangeModelContent(() => {
      if (!editorRef.current) return;

      const value = editorRef.current.getValue() || '';
      const gwtToJSON = convertGWTToJSON(value, schemasRef.current, nameRef.current);
      const parsedValue = JSON.parse(gwtToJSON);
      const stringifiedValue = JSON.stringify(parsedValue, null, 2);
      onEditorChange?.(stringifiedValue);
      setJsonOutput(stringifiedValue);

      const position = editorRef.current.getPosition();
      if (!position) return;

      const model = editorRef.current.getModel();
      const lineContent = model?.getLineContent(position.lineNumber) || '';

      if (lineContent.trim() === '.') {
        setTimeout(() => {
          editorRef.current?.trigger('keyboard', 'editor.action.triggerSuggest', {});
        }, 100);
      }
    });

    editorRef.current?.onDidBlurEditorText(() => {
      const value = editorRef.current?.getValue() || '';
      let convertedJson = JSON.parse(convertGWTToJSON(value, schemasRef.current, nameRef.current));
      convertedJson = JSON.stringify(convertedJson, null, 2);
      onEditorChange?.(convertedJson);
      setJsonOutput(convertedJson);
    });

    // Register custom completion provider
    const gwtCompletionProvider = monaco.languages.registerCompletionItemProvider('gwt', {
      triggerCharacters: [' ', '.'],
      provideCompletionItems: (model, position) => {
        const lineContent = model.getLineContent(position.lineNumber).trim();
        const word = model.getWordUntilPosition(position);
        const range = new monaco.Range(position.lineNumber, word.startColumn, position.lineNumber, word.endColumn);

        const createSuggestions = (items: string[], type: string, appendText: string = '') => {
          const uniqueItems = [...new Set(items)]; // ✅ Remove duplicates
          return uniqueItems.map((item) => ({
            label: item,
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: `${item}${appendText}`, // ✅ Append extra text if needed
            range: range,
            detail: type,
          }));
        };

        const eventSuggestions = createSuggestions(Object.keys(schemasRef.current.events), 'Event');
        const commandSuggestions = createSuggestions(Object.keys(schemasRef.current.commands), 'Command');
        const stateSuggestions = createSuggestions(Object.keys(schemasRef.current.states), 'State');

        if (/^(Then)\s*$/.test(lineContent)) {
          return { suggestions: [...eventSuggestions, ...stateSuggestions] };
        }

        if (/^(Given|And|Then)\s*$/.test(lineContent)) {
          return { suggestions: eventSuggestions };
        }

        if (/^When\s*$/.test(lineContent)) {
          return { suggestions: commandSuggestions };
        }

        if (lineContent === '.') {
          let previousLineNumber = position.lineNumber - 1;
          let previousLine = model.getLineContent(previousLineNumber)?.trim() || '';
          let eventName = '';

          while (previousLine.startsWith('.') && previousLineNumber > 1) {
            previousLineNumber--;
            previousLine = model.getLineContent(previousLineNumber)?.trim() || '';
          }

          const words = previousLine.split(' ');
          if (words.length > 1) {
            eventName = words[words.length - 1];
          }

          logger.log('🧐 Extracted Event Name:', eventName);

          const event = schemasRef.current.events[eventName];
          const command = schemasRef.current.commands[eventName];
          const temp = event || command;
          if (temp && temp.fields) {
            return {
              suggestions: createSuggestions(
                temp.fields.map((field) => field.name),
                'Field',
                ' = ',
              ),
            };
          } else {
            logger.warn('⚠ No fields found or invalid event structure for:', eventName);
          }
        }

        return { suggestions: [] };
      },
    });

    disposables.push(gwtCompletionProvider);

    // Cleanup function
    return () => {
      editorRef.current?.dispose();

      // ✅ Dispose of the language completion provider
      disposables.forEach((disposable) => disposable.dispose());

      // ✅ Remove the language if needed (Experimental: This unregisters but won't remove from Monaco fully)
      // monaco.languages.setLanguageConfiguration("gwt", {}); // Resets language configuration

      logger.log('✅ Language and providers cleaned up');
    };
  }, [application?.state.messageBus, editorContainerRef, editorRef, fileId, onEditorChange, setJsonOutput]); // ✅ Proper dependencies
};
