type Primitive = string | number | boolean | null;
type InputType = Record<string, any>;

type FieldType = { name: string; type: string | number | boolean | null | FieldType[] };
type OutputType = { name: string; fields: FieldType[] };

const isInt = (n: unknown) => Number(n) === n && n % 1 === 0;

const isFloat = (n: unknown) => Number(n) === n && n % 1 !== 0;

export const safeParseJson = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
};

// Define types for input and output structures
interface InputObject {
  [key: string]: any;
  __typename?: string;
}

interface SchemaField {
  name: string;
  type: string;
  fields?: SchemaField[];
}

interface SchemaObject {
  name: string;
  type: string;
  fields: SchemaField[];
}

/**
 * Converts a JSON object to a schema representation
 * @param input The input JSON object
 * @returns A schema representation of the input
 */
export function convertJsonToSchema(input: InputObject): SchemaObject {
  // Helper function to determine the type of a value
  function getType(value: any): string {
    if (Array.isArray(value)) return 'array';
    if (typeof value === 'number') {
      if (isInt(value)) return 'int';
      if (isFloat(value)) return 'float';
      return 'number';
    }
    return typeof value;
  }

  // Helper function to process an object recursively
  function processObject(obj: InputObject): SchemaObject {
    const typeName = obj.__typename || 'Object';
    const result: SchemaObject = {
      name: typeName,
      type: 'object',
      fields: [],
    };

    // Process all fields except __typename
    Object.entries(obj).forEach(([key, value]) => {
      if (key === '__typename') return;

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // Handle nested objects
        result.fields.push({
          name: key,
          type: 'object',
          fields: processObject(value as InputObject).fields,
        });
      } else if (Array.isArray(value) && value.length > 0) {
        // Handle arrays
        if (typeof value[0] === 'object' && value[0] !== null) {
          // Array of objects
          result.fields.push({
            name: key,
            type: 'array',
            [key]: processObject(value[0] as InputObject),
          });
        } else {
          // Array of primitives
          result.fields.push({
            name: key,
            type: 'array',
            [key]: {
              type: typeof value[0],
            },
          });
        }
      } else {
        // Handle primitive values
        result.fields.push({
          name: key,
          type: getType(value),
        });
      }
    });

    return result;
  }

  // Get the name from __typename or use "Root"
  const schemaName = input.__typename || 'Root';

  // Start processing from the top level
  const output: SchemaObject = {
    name: schemaName,
    type: 'object',
    fields: [],
  };

  // Process top-level fields
  Object.entries(input).forEach(([key, value]) => {
    if (key === '__typename') return; // Skip the typename field as it's used for the schema name

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Handle nested objects
      output.fields.push({
        name: key,
        type: 'object',
        fields: processObject(value as InputObject).fields,
      });
    } else if (Array.isArray(value) && value.length > 0) {
      // Handle arrays
      if (typeof value[0] === 'object' && value[0] !== null) {
        output.fields.push({
          name: key,
          type: 'array',
          [key]: processObject(value[0] as InputObject),
        });
      } else {
        output.fields.push({
          name: key,
          type: 'array',
          [key]: {
            type: typeof value[0],
          },
        });
      }
    } else {
      // Handle primitive fields
      output.fields.push({
        name: key,
        type: getType(value),
      });
    }
  });

  return output;
}
