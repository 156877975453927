import { useMutation } from '@apollo/client';
import { useAuth } from '../../auth';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavBar } from '../../components/nav-bar/nav-bar';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { VERIFY_EMAIL_MUTATION } from '../../graphql/mutations';
import { Icon } from '@xspecs/design-system';

export const ConfirmEmail = () => {
  const [emailSent, setEmailSent] = useState(false);

  const [sendVerificationEmail] = useMutation(VERIFY_EMAIL_MUTATION);

  const { logout } = useAuth();

  const { formatMessage: f } = useIntl();

  const theme = useTheme();

  const resendEmail = useCallback(async () => {
    const response = await sendVerificationEmail();
    if (!response.data?.verifyEmail?.error) {
      setEmailSent(true);
    }
  }, [sendVerificationEmail]);

  const goToLogin = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
      }}
    >
      <NavBar isLoggedIn isPrimary hideOrganizations hideTextLogo />
      <Box
        sx={{
          mt: { xs: 15, md: 'auto' },
          mb: { md: 'auto' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Icon name="on-auto-icon" height={40} width={40} />
        <Typography variant="h6" sx={{ mt: 4.5, textAlign: 'center' }}>
          {f({ id: 'verify-to-continue' })}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1.5, width: '315px', textAlign: 'center' }}>
          {f({ id: 'ask-to-click-link' })}
        </Typography>
        {emailSent ? (
          <Typography
            variant="body1"
            color="warning.main"
            sx={{ mt: 1.5, display: 'flex', gap: 0.5 }}
            onClick={resendEmail}
          >
            <DoneIcon />
            {f({ id: 'email-resent' })}
          </Typography>
        ) : (
          <Typography variant="body1" color="primary" sx={{ mt: 1.5, cursor: 'pointer' }} onClick={resendEmail}>
            {f({ id: 'resent-email-message' })}
          </Typography>
        )}
        <Typography variant="body1" color="primary" sx={{ mt: 1.5, cursor: 'pointer' }} onClick={goToLogin}>
          {f({ id: 'go-to-login' })}
        </Typography>
      </Box>
    </Box>
  );
};
