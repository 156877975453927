import { EventHandlerBase } from '../../commands/framework/EventHandlerBase';
import { DropTargetsHighlightedEvent } from '../../commands/entities/HighlightDropTargetsCommand';
import { EntitiesMovedEvent } from '../../commands/entities/MoveEntitiesCommand';

export class DropTargetHighlighterPolicy extends EventHandlerBase {
  handles() {
    return [DropTargetsHighlightedEvent, EntitiesMovedEvent];
  }

  execute(event: DropTargetsHighlightedEvent | EntitiesMovedEvent) {
    const modelContext = this.getModelContext();
    modelContext.graph.clearDropTargets();
    if (event instanceof DropTargetsHighlightedEvent) {
      event.params.accepted.forEach((dropTarget) => {
        modelContext.graph.addValidDropTarget(dropTarget);
      });
      event.params.rejected.forEach((dropTarget) => {
        modelContext.graph.addInvalidDropTarget(dropTarget);
      });
      // if (event.params.error) {
      //   this.model.errorStore.addError(CommandError.of(event.params.error, 'error'));
      // }
    }
    if (event instanceof EntitiesMovedEvent) {
      modelContext.graph.clearDropTargets();
    }
  }
}
