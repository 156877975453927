import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { FileTreeEntry } from '../../read-models/file-tree/FileTree';
import { EventBase } from '../framework/EventBase';

interface RenameFileTreeItemCommandParams extends IParams {
  id: string;
  value: string;
}

interface FileTreeItemRenamedEventParams extends IParams {
  items: FileTreeEntry[];
}

export class FileTreeItemRenamedEvent extends EventBase {
  static eventType = 'FileTreeItemRenamedEvent';
  constructor(
    public readonly params: FileTreeItemRenamedEventParams,
    public readonly source = RenameFileTreeItemCommand,
  ) {
    super();
  }
}

export class RenameFileTreeItemCommand extends CommandBase<RenameFileTreeItemCommandParams> {
  execute(params: RenameFileTreeItemCommandParams): FileTreeItemRenamedEvent | CommandError {
    const item = this.appState.spaceRepository.get(params.id);
    if (!item) return CommandError.of(new Error(`Cannot find file with id ${params.id}`), 'error');
    const updatedItem: FileTreeEntry = { ...item, fileName: params.value, text: params.value };
    this.appState.spaceRepository.update(params.id, updatedItem);
    this.appState.spaceRepository.save();
    return new FileTreeItemRenamedEvent({ items: [updatedItem] });
  }
}
