import { useCallback, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useIntl } from 'react-intl';
import { Box, Button, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { CopyLinkButton } from '../../copy-link-button/copy-link-button';
import { useCopyEntityLink } from '../canvas/hooks/use-copy-entity-link';
import { DeleteAssetButton } from '../../delete-asset-button/delete-asset-button';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

type EntityDetailsMenuProps = {
  id: string;
  type: string;
  name: string;
};

export const EntityDetailsMenu = (props: EntityDetailsMenuProps) => {
  const { id, type, name } = props;

  const [showFloating, setShowFloating] = useState(false);

  const { onCopyEntityLink } = useCopyEntityLink();

  const setShowDeleteAssetModal = useSingleSourceStore.use.setShowDeleteAssetModal();
  const assetToDelete = useSingleSourceStore.use.assetToDelete();
  const setAssetToDelete = useSingleSourceStore.use.setAssetToDelete();

  const { formatMessage: f } = useIntl();

  const arrowRef = useRef(null);

  const { floatingStyles, refs, context } = useFloating({
    placement: 'bottom-end',
    middleware: [
      offset(10),
      autoPlacement({ allowedPlacements: ['bottom-end'] }),
      arrow({ element: arrowRef.current }),
    ],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const copyEntityLink = async () => {
    await onCopyEntityLink(id, type, name);
    setShowFloating(false);
  };

  const floatingSx: SxProps<Theme> = (theme) => ({
    padding: '4px',
    borderRadius: 1,
    width: '200px',
    background: theme.palette.background.paper,
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    zIndex: 'calc(infinity)',
  });

  const menuButtonSx: SxProps<Theme> = (theme) => ({
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'left',
    justifyContent: 'start',
    textTransform: 'none',
    ':hover': {
      background: theme.palette.action.hover,
      cursor: 'pointer',
    },
  });

  const deleteAsset = useCallback(() => {
    setShowDeleteAssetModal(true);
    setShowFloating(false);
    setAssetToDelete(id);
  }, [id, setAssetToDelete, setShowDeleteAssetModal]);

  return (
    <div>
      <Box ref={refs.setReference} {...getReferenceProps()}>
        <IconButton size="small">
          <MoreVertIcon fontSize="small" sx={{ p: 0 }} className="text-primary" />
        </IconButton>
      </Box>
      {showFloating ? (
        <FloatingPortal>
          <Box ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles} sx={floatingSx}>
            <FloatingArrow width={20} height={12} ref={arrowRef} context={context} fill="white" />
            <Stack gap={0.25} alignItems="start">
              <Button
                sx={menuButtonSx}
                startIcon={<CopyLinkButton onClick={copyEntityLink} />}
                onClick={copyEntityLink}
              >
                {f({ id: 'copy-link' })}
              </Button>
              {assetToDelete === id ? (
                <Button sx={menuButtonSx} startIcon={<DeleteAssetButton onClick={deleteAsset} />} onClick={deleteAsset}>
                  {f({ id: 'delete-asset' })}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </FloatingPortal>
      ) : null}
    </div>
  );
};
