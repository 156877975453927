import { useCallback } from 'react';
import { RoutePaths } from '../../../config/route-paths/route-paths';
import { useAuth } from '../../../auth';
import { useIntl } from 'react-intl';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@xspecs/design-system';

export type ProfileDetailsProps = {
  name: string;
  email: string;
  picture: string;
  collapsed: boolean;
};

export const ProfileDetails = (props: ProfileDetailsProps) => {
  const { name, email, picture, collapsed } = props;
  const auth = useAuth();
  const logout = auth?.logout;

  const { formatMessage: f } = useIntl();

  const handleLogout = useCallback(() => {
    logout?.({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  const initials =
    name
      ?.split(' ')
      .map((w) => w[0])
      .slice(0, 2)
      .join('')
      .toUpperCase() ?? '';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="profile-menu-trigger">
        <div className="flex bg-background-grey p-2 gap-2">
          <Avatar>
            <AvatarImage src={picture} alt={`${name} profile picture`} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
          {!collapsed && (
            <div className="flex flex-col">
              <p className="text-primary text-sm">{name}</p>
              <p className="text-primary text-xs">{email}</p>
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="right" align="center">
        <DropdownMenuItem onClick={handleLogout} data-testid="profile-menu-logout">
          {f({ id: 'logout' })}
        </DropdownMenuItem>
      </DropdownMenuContent>

      {/*<Box sx={profileDetailsSx} onClick={handleClick} data-testid="profile-menu-button">*/}
      {/*  {picture ? (*/}
      {/*    <img alt="Profile Picture" src={picture} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />*/}
      {/*  ) : (*/}
      {/*    <AccountCircleRounded color="inherit" />*/}
      {/*  )}*/}
      {/*  {!collapsed ? (*/}
      {/*    <Box>*/}
      {/*      <Typography variant="subtitle2" color="textPrimary">*/}
      {/*        {name}*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="caption" color="textSecondary">*/}
      {/*        {email}*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  ) : null}*/}
      {/*</Box>*/}
      {/*<Menu*/}
      {/*  data-testid="profile-menu"*/}
      {/*  anchorEl={profileAnchorEl}*/}
      {/*  open={Boolean(profileAnchorEl)}*/}
      {/*  onClose={handleClose}*/}
      {/*  onClick={handleClose}*/}
      {/*  PaperProps={MenuPaperProps}*/}
      {/*  transformOrigin={{ horizontal: 'left', vertical: 'center' }}*/}
      {/*  anchorOrigin={{ horizontal: 'right', vertical: 'center' }}*/}
      {/*  TransitionProps={{*/}
      {/*    timeout: { enter: 200, exit: 200 },*/}
      {/*    easing: { enter: 'ease-out', exit: 'ease-out' },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <MenuItem data-testid="profile-menu-logout" onClick={handleLogout} sx={menuItemSx}>*/}
      {/*    <Logout color="action" fontSize="small" />*/}
      {/*    <Typography variant="body1">{f({ id: 'logout' })}</Typography>*/}
      {/*  </MenuItem>*/}
      {/*</Menu>*/}
    </DropdownMenu>
  );
};
