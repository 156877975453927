import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Edge } from '../../entities/transitions/Edge';
import { EdgeStyles } from './UpdateEdgeCommand';

interface PasteEdgeStyleCommandParams extends IParams {}

interface PasteEdgeStyleParams extends IParams {}

export class EdgeStylePasted extends EventBase {
  static eventType = 'EdgeUpdatedEvent';

  constructor(
    public readonly params: PasteEdgeStyleParams,
    public readonly source = PasteEdgeStyleCommand,
  ) {
    super();
  }
}

export class PasteEdgeStyleCommand extends CommandBase<PasteEdgeStyleCommandParams> {
  execute(params: PasteEdgeStyleCommandParams): EdgeStylePasted | CommandError {
    const modelContext = this.getModelContext();
    const selectedEdges = Object.keys(modelContext.entitySelectionTracker.localSelections)
      .map((id) => modelContext.entityRepository.get(id))
      .filter((entity) => entity instanceof Edge) as Edge[];

    modelContext.clipboard.read<EdgeStyles>().then((result) => {
      if (result.type !== 'edgeStyles') {
        return;
      }
      selectedEdges.forEach((edge) => {
        edge.color = result.data.color ?? edge.color;
        edge.lineStyle = result.data.lineStyle ?? edge.lineStyle;
        edge.lineWeight = result.data.lineWeight ?? edge.lineWeight;
        edge.labelColor = result.data.labelColor ?? edge.labelColor;
        edge.labelFontSize = result.data.labelFontSize ?? edge.labelFontSize;
        edge.lineType = result.data.lineType ?? edge.lineType;
        modelContext.entityRepository.update(edge);
      });
      modelContext.entityRepository.save();
    });

    return new EdgeStylePasted({
      ...params,
    });
  }
}
