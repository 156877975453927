import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import rough from 'roughjs';

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const px = (value: string | number) => (value.toString().endsWith('px') ? value.toString() : `${value}px`);

export const generator = rough.generator();
