import { ApplicationContext } from '../../ApplicationContext';
import { EventConstructor, EventBase } from './EventBase';
import { ModelContext } from '../../ModelContext';

export abstract class EventHandlerBase {
  constructor(protected readonly appState: ApplicationContext) {}

  public abstract handles(): EventConstructor<EventBase>[];

  public getModelContext = (): ModelContext => ModelContext.getInstance();

  abstract execute(event: EventBase): void | Error;
}
