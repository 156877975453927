import { SpaceFile } from './data/SpaceFile';

export class SpaceContext {
  private static instance: SpaceContext;
  private activeSpace?: SpaceFile;

  private constructor() {}

  public static getInstance(): SpaceContext {
    if (!SpaceContext.instance) {
      SpaceContext.instance = new SpaceContext();
    }
    return SpaceContext.instance;
  }

  setActiveSpace(spaceFile: SpaceFile | undefined) {
    const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
    this.activeSpace = spaceFile;
    if (isBrowser) {
      window['space'] = this.getActiveSpace();
    }
  }

  getActiveSpace(): SpaceFile | undefined {
    return this.activeSpace;
  }

  dispose() {
    this.activeSpace?.dispose();
    this.activeSpace = undefined;
  }
}
