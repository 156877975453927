import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SelectFileTreeItemCommandParams extends IParams {
  id: string;
}

export class SelectFileTreeItemCommandEvent extends EventBase {
  static eventType = 'SelectFileTreeItemCommandEvent';

  constructor(
    public readonly params: SelectFileTreeItemCommandParams,
    public readonly source = SelectFileTreeItemCommand,
  ) {
    super();
  }
}

export class SelectFileTreeItemCommand extends CommandBase<SelectFileTreeItemCommandParams> {
  execute(params: SelectFileTreeItemCommandParams): SelectFileTreeItemCommandEvent | CommandError | undefined {
    const { id } = params;
    this.appState.fileTree.selectItem(id);
    return new SelectFileTreeItemCommandEvent(params);
  }
}
