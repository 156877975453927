import { PhotoOutlined } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { OpenEntityAnnotatorCommand, Query } from '@xspecs/single-source-model';
import { useCallback, useMemo } from 'react';

type AnnotationSelectProps = {
  queryId: string;
  noAnnotateDesign?: boolean;
};

export const AnnotationSelect = (props: AnnotationSelectProps) => {
  const { queryId, noAnnotateDesign } = props;

  const { formatMessage: f } = useIntl();

  const { application } = useApplication();

  const query = useMemo(() => {
    const entityRepository = application?.getModelContext()?.entityRepository;
    return entityRepository?.get<Query>(queryId);
  }, [application, queryId]);

  const onAnnotateDesign = useCallback(() => {
    application?.state.messageBus.send(OpenEntityAnnotatorCommand, { entityId: queryId });
  }, [application?.state.messageBus, queryId]);

  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      {query?.upload ? (
        <img
          alt={query.upload.name}
          src={query.upload.url}
          style={{
            aspectRatio: query.upload.width / query.upload.height,
            width: 187,
            borderRadius: 8,
            objectFit: 'contain',
            border: '1px solid #D8D8D8',
          }}
        />
      ) : (
        <Stack width={187} height={110} bgcolor="#D8D8D8" alignItems="center" justifyContent="center" borderRadius={2}>
          <PhotoOutlined color="action" fontSize="large" />
        </Stack>
      )}
      {!noAnnotateDesign ? (
        <Button variant="contained" onClick={onAnnotateDesign}>
          {f({ id: 'annotate-design' })}
        </Button>
      ) : null}
    </Stack>
  );
};
