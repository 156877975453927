import { useEffect } from 'react';
import { Analytics } from '@xspecs/single-source-model';
import { AppTypeEvent } from './use-track-events';
import { getUser } from '../lib/utils';
import dayjs from 'dayjs';

export const THRESHOLD_MINUTES = 5;

export const useTrackUserLoggedIn = () => {
  useEffect(() => {
    const trackUserLoggedIn = () => {
      const user = getUser();
      if (!user) return;
      const lastLoggedIn = localStorage.getItem('lastLoggedIn') || '';
      const now = dayjs().toISOString();
      if (!lastLoggedIn) {
        Analytics.getInstance().track({
          event: AppTypeEvent.LoggedIn,
          params: {
            id: user.id,
            email: user.email,
          },
        });
        localStorage.setItem('lastLoggedIn', now);
        return;
      }

      const lastLoggedInTime = dayjs(lastLoggedIn);
      if (dayjs().diff(lastLoggedInTime, 'minutes') > THRESHOLD_MINUTES) {
        Analytics.getInstance().track({
          event: AppTypeEvent.LoggedIn,
          params: {
            id: user.id,
            email: user.email,
          },
        });
        localStorage.setItem('lastLoggedIn', now);
        return;
      }
    };
    trackUserLoggedIn();
  }, []);
};
