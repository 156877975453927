import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AssetSort } from '../../read-models/asset-explorer/AssetExplorerItem';

interface SetAssetExplorerSortParams extends IParams {
  sort: AssetSort;
}

export class SetAssetExplorerSortEvent extends EventBase {
  static eventType = 'SetAssetExplorerSortEvent';

  constructor(
    public readonly params: SetAssetExplorerSortParams,
    public readonly source = SetAssetExplorerSortCommand,
  ) {
    super();
  }
}

export class SetAssetExplorerSortCommand extends CommandBase<SetAssetExplorerSortParams> {
  execute(params: SetAssetExplorerSortParams): SetAssetExplorerSortEvent | CommandError | undefined {
    const { sort } = params;
    this.applySort(sort);
    return new SetAssetExplorerSortEvent(params);
  }

  private applySort(sort: AssetSort) {
    this.getModelContext().assetExplorer.updateSort(sort);
  }
}
