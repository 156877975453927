import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { SpaceContext } from '../../SpaceContext';
import { sid } from '@xspecs/short-id';
import { FileTreeEntry } from '../../read-models/file-tree/FileTree';

interface CreateNewFileCommandParams extends IParams {
  fileType: string;
  entityType?: string;
  fileName?: string;
}

interface NewFileCreatedEventParams extends IParams {
  items: FileTreeEntry[];
}

export class NewFileCreatedEvent extends EventBase {
  static eventType = 'NewFileCreatedEvent';

  constructor(
    public readonly params: NewFileCreatedEventParams,
    public readonly source = CreateNewFileCommand,
  ) {
    super();
  }
}

export class CreateNewFileCommand extends CommandBase<CreateNewFileCommandParams> {
  execute(params: CreateNewFileCommandParams): NewFileCreatedEvent | CommandError | undefined {
    try {
      const space = SpaceContext.getInstance().getActiveSpace();
      if (!space) return CommandError.of(new Error('No active space found'), 'error');
      const orgId = space.fileId.split('/')[0];
      const fileTreeEntry = {
        id: `${orgId}/${sid()}`,
        fileName: params.fileName || '',
        fileType: params.fileType,
        entityType: params.entityType,
        parentId: undefined,
        text: params.fileName || '',
        content: '',
      } as FileTreeEntry;
      this.appState.spaceRepository.add(fileTreeEntry);
      this.appState.spaceRepository.save();
      return new NewFileCreatedEvent({ items: [fileTreeEntry] });
    } catch (error) {
      return CommandError.of(error, 'error');
    }
  }
}
