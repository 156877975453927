import { BalloonMenuLink } from './balloon-menu-link/balloon-menu-link';
import { BalloonMenuTextTypeSelection } from './balloon-menu-text-type-selection';
import { BalloonMenuMarks } from './balloon-menu-marks/balloon-menu-marks';
import { BalloonMenuLists } from './balloon-menu-lists';
import { BalloonMenuAlignment } from './balloon-menu-alignment/balloon-menu-alignment';
import { Separator } from '@xspecs/design-system';

type BalloonMenuProps = {
  closeMenu: () => void;
};

export const BalloonMenu = (props: BalloonMenuProps) => {
  return (
    <div className="h-full flex items-center py-2">
      <BalloonMenuTextTypeSelection {...props} />
      {/*<Divider data-testid="text-comment-separator" orientation="vertical" />*/}
      {/*<BalloonMenuComment {...props} />*/}
      <Separator data-testid="text-link-separator" orientation="vertical" />
      <BalloonMenuLink {...props} />
      <Separator data-testid="link-marks-separator" orientation="vertical" />
      <BalloonMenuMarks />
      <Separator data-testid="marks-lists-separator" orientation="vertical" />
      <BalloonMenuLists />
      <Separator data-testid="alignment-separator" orientation="vertical" />
      <BalloonMenuAlignment />
    </div>
  );
};
