import { SxProps, Theme, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { DocLinkIcon } from '@xspecs/ui-assets';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSingleSourceExplorer } from '../../../../hooks/use-single-source-explorer';

type LinkDocProps = {
  name: string;
  documentId: string;
  isFocused: boolean;
  isSelected: boolean;
  url: string;
};

export const LinkDoc = (props: LinkDocProps) => {
  const { documentId, isFocused, isSelected, name, url } = props;

  const { explorerItems } = useSingleSourceExplorer();

  const theme = useTheme();

  const { formatMessage: f } = useIntl();

  const document = useMemo(() => {
    return explorerItems.find((item) => item.id === documentId);
  }, [documentId, explorerItems]);

  const rootSx = useMemo<SxProps<Theme>>(
    () => ({
      alignItems: 'center',
      display: 'inline-flex',
      gap: 0.5,
      px: 1,
      borderRadius: '10px',
      border: `2px solid ${isSelected && isFocused ? theme.palette.action.active : theme.palette.action.disabled}`,
      cursor: document ? 'pointer' : 'default',
    }),
    [document, isFocused, isSelected, theme.palette.action.active, theme.palette.action.disabled],
  );

  const resolveName = useCallback(() => {
    if (document) return document.text || 'Untitled';
    return `${name || 'Untitled'} (${f({ id: 'deleted' })})`;
  }, [document, name, f]);

  return (
    <Link to={url} style={{ textDecoration: 'none', color: 'unset' }}>
      <Typography variant="body1" component="span" sx={rootSx}>
        <DocLinkIcon />
        {resolveName()}
      </Typography>
    </Link>
  );
};
