import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../resizable/resizable';
import { ExplorerMenu, ExplorerMenuItemProps } from '../explorer-menu/explorer-menu';
import { TopMenu, TopMenuProps } from '../top-menu/top-menu';
import { IdeSidebar, IdeSidebarProps } from '../sidebar/ide-sidebar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { DockViewProps } from '../dock-view/dock-view';
import { SidebarProvider } from '../sidebar/sidebar';
import { Newspaper, Shapes } from 'lucide-react';
import { useIntl } from 'react-intl';
import { DeployLogs } from '@xspecs/design-system/src/ui/deploy-logs/deploy-logs';
import { BuildLogState } from '@xspecs/single-source-model/src/read-models/build-log/BuildLog.types';
import { useStoreContext } from '@xspecs/design-system';
import { debounce } from '@xspecs/design-system/src/ui/ux-sketch-components/utils/debounce';

export interface IdeLayoutProps {
  topMenu: TopMenuProps;
  sidebar: IdeSidebarProps;
  dockview: DockViewProps;
  children: React.ReactNode;
  logs: BuildLogState | undefined;
}

export const IdeLayout = (props: IdeLayoutProps) => {
  const { topMenu, sidebar, dockview, children, logs } = props;
  const [sidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [logsCollapsed, setIsLogsCollapsed] = useState(false);
  const sidebarRef = useRef<ImperativePanelHandle>(null);
  const logsRef = useRef<ImperativePanelHandle>(null);
  const { formatMessage: f } = useIntl();
  const [logType, setLogType] = useState('');
  const { store } = useStoreContext();
  const ideSidebar = store.use.panels()['ide-sidebar'];
  const logsPanel = store.use.panels()['logs'];
  const setPanels = store.use.setPanels();
  const devMode = store.use.devMode();

  const debouncedSidebarSize = debounce((size) => setPanels('ide-sidebar', 'size', size), 300);
  const debouncedLogsSize = debounce((size) => setPanels('logs', 'size', size), 300);

  useEffect(() => {
    if (ideSidebar?.collapsed && sidebarRef.current) {
      sidebarRef.current.collapse();
    }
  }, [ideSidebar?.collapsed]);

  useEffect(() => {
    if (logsPanel?.collapsed && logsRef.current) {
      logsRef.current.collapse();
    }
  }, [logsPanel?.collapsed]);

  const toggleSidebarPanel = useCallback(() => {
    const panel = sidebarRef.current;
    if (!panel) return;
    if (panel.isCollapsed()) {
      panel.expand();
      setPanels('ide-sidebar', 'collapsed', false);
    } else {
      panel.collapse();
      setPanels('ide-sidebar', 'collapsed', true);
    }
  }, [setPanels]);

  const toggleLogsPanel = useCallback(
    (type: string, open?: boolean) => {
      const panel = logsRef.current;
      if (!panel) return;
      if (panel.isCollapsed() || open) {
        panel.expand();
        setPanels('logs', 'collapsed', false);
      } else {
        panel.collapse();
        setPanels('logs', 'collapsed', true);
      }
      setLogType(type);
    },
    [setPanels],
  );

  const explorerMenuTopItems: ExplorerMenuItemProps[] = useMemo(
    () => [
      {
        name: f({ id: 'file-explorer' }),
        icon: Shapes,
        count: undefined,
        active: !sidebarCollapsed,
        onClick: toggleSidebarPanel,
        variant: 'primary',
      },
    ],
    [f, sidebarCollapsed, toggleSidebarPanel],
  );

  const explorerMenuBottomItems: ExplorerMenuItemProps[] = useMemo(
    () => [
      {
        name: f({ id: 'logs' }),
        icon: Newspaper,
        count: undefined,
        active: !logsCollapsed,
        onClick: () => toggleLogsPanel('Logs'),
        variant: 'secondary',
      },
    ],
    [f, logsCollapsed, toggleLogsPanel],
  );

  return (
    <ResizablePanelGroup direction="horizontal">
      <SidebarProvider>
        <div className="flex w-screen">
          <ExplorerMenu
            logout={sidebar.logout}
            user={sidebar.user}
            showUser={sidebarCollapsed}
            bottomItems={explorerMenuBottomItems}
            topItems={explorerMenuTopItems}
            viewSpaces={topMenu.viewSpaces || (() => {})}
          />
          <div className="flex-1 flex flex-col max-w-[calc(100%_-_32px)]">
            <TopMenu {...topMenu} toggleLogsPanel={toggleLogsPanel} />
            <div className="flex h-full">
              <ResizablePanel
                className="flex"
                defaultSize={ideSidebar?.size || 20}
                onCollapse={() => setIsSidebarCollapsed(true)}
                onExpand={() => setIsSidebarCollapsed(false)}
                minSize={10}
                collapsible
                ref={sidebarRef}
                id="ide-sidebar"
                onResize={debouncedSidebarSize}
              >
                <IdeSidebar {...sidebar} collapsed={sidebarCollapsed} collapse={toggleSidebarPanel} />
              </ResizablePanel>
              <ResizableHandle />
              <ResizablePanel>
                <ResizablePanelGroup direction="vertical">
                  <ResizablePanel id="file-panel" defaultSize={devMode ? 100 - logsPanel.size : 100}>
                    {children}
                  </ResizablePanel>
                  {devMode && !logsPanel.collapsed ? <ResizableHandle className="bg-border dark:bg-border" /> : null}
                  {devMode ? (
                    <ResizablePanel
                      ref={logsRef}
                      id="ide-logs"
                      collapsible
                      minSize={15}
                      onCollapse={() => setIsLogsCollapsed(true)}
                      onExpand={() => setIsLogsCollapsed(false)}
                      defaultSize={logsPanel?.size || 0}
                      onResize={debouncedLogsSize}
                    >
                      <DeployLogs type={logType} onClose={toggleLogsPanel} logs={logs} />
                    </ResizablePanel>
                  ) : null}
                </ResizablePanelGroup>
                {/*<DockView {...dockview} />*/}
              </ResizablePanel>
            </div>
          </div>
        </div>
      </SidebarProvider>
    </ResizablePanelGroup>
  );
};
